import { useStyletron } from 'baseui';
import { useTranslation } from 'react-i18next';

import { HorizontalDivider } from 'components/UI/Dividers/HorizontalDivider';

import { IObjProps } from 'shared/consts/types';
import { CTA_BUTTONS } from 'shared/enum';

interface ITemplateButtonsPopOver {
	heading: string;
	buttonsArray: Array<IObjProps>;
	setSeeAllOptionsButtonClicked: (val: boolean) => void;
	cssOverride?: IObjProps;
}

function getButtonType(button: IObjProps) {
	if (Array.isArray(button?.type) && button.type.length > 0) {
		return button.type[0].id;
	}
	if (typeof button?.type === 'string') {
		return button.type;
	}
}

function TemplateButtonsPopOver(props: ITemplateButtonsPopOver) {
	const [css, theme]: any = useStyletron();
	const { t }: any = useTranslation(['common']);
	const { heading, buttonsArray, setSeeAllOptionsButtonClicked, cssOverride = {} } = props;

	const transformedButtonsArray = buttonsArray.map((button: IObjProps) => ({
		...button,
		type: getButtonType(button),
	}));

	const styles = {
		container: {
			width: '18.3rem',
			border: `0.063rem solid  ${theme.templateMessagePopUpBorder}`,
			borderRadius: '0.625rem',
			backgroundColor: theme.colors.primaryB,
			zIndex: theme.zIndex200,
			...cssOverride,
		},
		header: {
			marginTop: '1rem',
			position: 'relative',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			padding: '0.625rem 1rem',
		},
		closeButton: {
			position: 'absolute',
			left: '1rem',
			border: 'none',
			background: 'none',
			fontSize: '1.25rem',
			cursor: 'pointer',
			color: theme.colors.primaryA,
		},
		heading: {
			fontSize: '1rem',
			fontWeight: 700,
			textAlign: 'center',
			alignItems: 'center',
			flex: 1,
			color: theme.colors.primaryA,
		},
		buttonContainer: {
			padding: '0.625rem 1rem',
			height: '10.5rem',
			overflowY: 'auto',
		},
		button: {
			padding: '0.625rem 0',
			cursor: 'pointer',
			textAlign: 'left',
			marginLeft: '0.5rem',
			color: theme.colors.primaryA,
		},
	};

	const closeButton = () => (
		<button className={css(styles.closeButton)} onClick={() => setSeeAllOptionsButtonClicked(false)}>
			&times;
		</button>
	);

	return (
		<div className={css(styles.container)}>
			<div className={css(styles.header)}>
				{closeButton()}
				<span className={css(styles.heading)}>{heading}</span>
			</div>
			<div className={css(styles.buttonContainer)}>
				{transformedButtonsArray.map((btn: IObjProps, index: number) => {
					const hasButtonTypeChanged = btn?.type !== transformedButtonsArray[index - 1]?.type;
					const showSeparator = index > 0 && hasButtonTypeChanged;

					return (
						<div key={`fragment-${btn.id}-${index}`}>
							{showSeparator && <HorizontalDivider height='1rem' />}
							<div
								key={`preview-card-${btn.id}-${index}`}
								className={css(styles.button)}
								onClick={() => {
									if (btn.type === CTA_BUTTONS.URL) {
										window.open(btn.value, '_blank');
									}
								}}
							>
								{btn.label || btn.text || t('common:button')}
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default TemplateButtonsPopOver;
