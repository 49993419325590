import React from 'react';
import { useStyletron } from 'baseui';

function Level3({
	size,
	fillColor,
	height,
	width,
}: {
	size?: number;
	fillColor?: string;
	height: number;
	width: number;
}) {
	const [, theme] = useStyletron();

	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48' id='graduate' height={height} width={width}>
			<g>
				<g>
					<path
						fill='#656769'
						d='m36.6 28.4-4.3 2.8c-5.1 3.2-11.4 3.2-16.5 0l-4.3-2.8c-1-.6-1.6-1.7-1.6-2.9v-6h28.3v6c-.1 1.2-.7 2.3-1.6 2.9z'
					/>
					<path fill='#525252' d='M38.1 19.5H9.9v4.6l10.7 6.2c2.1 1.3 4.8 1.3 6.9 0l10.7-6.2v-4.6z' />
					<path
						fill='none'
						stroke='#45413c'
						strokeLinecap='round'
						strokeLinejoin='round'
						strokeMiterlimit='10'
						d='m36.6 28.4-4.3 2.8c-5.1 3.2-11.4 3.2-16.5 0l-4.3-2.8c-1-.6-1.6-1.7-1.6-2.9v-6h28.3v6c-.1 1.2-.7 2.3-1.6 2.9z'
					/>
					<ellipse cx='24' cy='44.1' fill='#45413c' opacity='.15' rx='13' ry='1.7' />
					<path
						fill='#656769'
						d='m5.7 18 14.8 8.7c2.1 1.3 4.8 1.3 6.9 0L42.3 18c2.8-1.6 2.7-5.7-.2-7.2L27.2 3c-2-1.1-4.4-1.1-6.4 0L5.9 10.9c-2.9 1.5-3 5.5-.2 7.1z'
					/>
					<path
						fill='#87898c'
						d='m5.9 15.5 14.9-6c2-.8 4.4-.8 6.4 0l14.9 6a5 5 0 0 1 1.6 1.1c1.2-1.9.6-4.6-1.6-5.8L27.2 3c-2-1.1-4.4-1.1-6.4 0L5.9 10.9c-2.2 1.2-2.7 3.9-1.6 5.8.3-.5.9-.9 1.6-1.2z'
					/>
					<path
						fill='none'
						stroke='#45413c'
						strokeLinecap='round'
						strokeLinejoin='round'
						strokeMiterlimit='10'
						d='m5.7 18 14.8 8.7c2.1 1.3 4.8 1.3 6.9 0L42.3 18c2.8-1.6 2.7-5.7-.2-7.2L27.2 3c-2-1.1-4.4-1.1-6.4 0L5.9 10.8c-2.9 1.5-3 5.6-.2 7.2z'
					/>
					<path
						fill='none'
						stroke='#45413c'
						strokeLinecap='round'
						strokeLinejoin='round'
						strokeMiterlimit='10'
						d='m22.3 15-10 6.5v14.4'
					/>
					<path
						fill='#ffe500'
						stroke='#45413c'
						strokeLinecap='round'
						strokeLinejoin='round'
						strokeMiterlimit='10'
						d='m11.7 38-1.8 5.3c-.2.6 0 1.2.5 1.5.5.3 1.2.7 2 .7s1.5-.3 2-.7c.5-.3.6-1 .5-1.5L13 38h-1.3z'
					/>
					<circle
						cx='12.3'
						cy='38'
						r='2.1'
						fill='#ffe500'
						stroke='#45413c'
						strokeLinecap='round'
						strokeLinejoin='round'
						strokeMiterlimit='10'
					/>
					<ellipse
						cx='24'
						cy='13.9'
						fill='#ffe500'
						stroke='#45413c'
						strokeLinecap='round'
						strokeLinejoin='round'
						strokeMiterlimit='10'
						rx='2.7'
						ry='1.4'
					/>
				</g>
			</g>
		</svg>
	);
}

export default Level3;
