import React from 'react';
import { useStyletron } from 'baseui';
import { Paragraph2 } from 'baseui/typography';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import NoDataTemplate from 'components/UI/NoDataTemplate/NoDataTemplate';

import NoMessagesListIcon from 'shared/icons/NoMessagesListIcon.svg';

import { fontStyleSelector } from 'store/App/User';

function NoUsersAvailable() {
	const { t } = useTranslation(['common']);
	const [css]: any = useStyletron();

	const fontStyle: string = useSelector(fontStyleSelector);

	return (
		<NoDataTemplate
			bannerImage={{
				altText: t('components:noMessagesAvailableIconAltText'),
				imageUrl: NoMessagesListIcon,
			}}
			className={css({ width: '100% !important', fontFamily: fontStyle })}
			heading={t('pages:messages.emptyMessagesListHeader')}
		>
			<Paragraph2>{t('pages:messages.emptyMessagesListContent')}</Paragraph2>
		</NoDataTemplate>
	);
}

export default NoUsersAvailable;
