import React from 'react';

function DownArrow({ fillColor }: { fillColor?: string }) {
	return (
		<svg width='9' height='15' viewBox='0 0 9 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M7.45161 9.19777L7.4516 9.19776L7.45039 9.19897L5.27643 11.3716V1.41178C5.27643 0.96355 4.91307 0.600195 4.46484 0.600195C4.0166 0.600195 3.65325 0.96355 3.65325 1.41178V11.3715L1.48072 9.19899L1.48073 9.19898L1.47948 9.19777C1.15707 8.88639 0.64331 8.89533 0.331931 9.2177L0.403857 9.28717L0.331928 9.2177C0.0281594 9.53222 0.0281594 10.0308 0.331928 10.3453L0.331917 10.3453L0.333146 10.3466L3.89092 13.9043C4.20748 14.2217 4.72133 14.2223 5.03866 13.9058L5.03875 13.9057L5.04005 13.9044L8.59791 10.3466L8.59792 10.3466L8.59912 10.3453C8.91055 10.0229 8.90161 9.50915 8.5792 9.19777L8.50973 9.2697L8.57919 9.19777C8.26468 8.89405 7.76609 8.89404 7.45161 9.19777Z'
				fill={fillColor || 'white'}
				stroke={fillColor || 'white'}
				strokeWidth='0.2'
			/>
		</svg>
	);
}

export default DownArrow;
