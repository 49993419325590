import React from 'react';
import { useStyletron } from 'baseui';

function Level9({
	size,
	fillColor,
	height,
	width,
}: {
	size?: number;
	fillColor?: string;
	height: number;
	width: number;
}) {
	const [, theme] = useStyletron();

	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 168 168' id='challenge' height={height} width={width}>
			<circle cx='2' cy='148.926' r='2' fill='#2d4356' />
			<path
				fill='#2d4356'
				d='M11 146.92648H8a2 2 0 0 0 0 4h3a2 2 0 0 0 0-4zM160 146.92648h-3a2 2 0 0 0 0 4h3a2 2 0 0 0 0-4z'
			/>
			<circle cx='166' cy='148.926' r='2' fill='#2d4356' />
			<path
				fill='#0bceb2'
				d='M118.15387 154.92648h-8.30774a2.00641 2.00641 0 0 0 0 4h8.30774a2.00641 2.00641 0 0 0 0-4zM58.15387 154.92648H49.84613a2.00641 2.00641 0 0 0 0 4h8.30774a2.00641 2.00641 0 0 0 0-4zM104 154.92648H64a2 2 0 0 0 0 4H79.94v2H72a2 2 0 0 0 0 4H97a2 2 0 0 0 0-4H88.06v-2H104a2 2 0 0 0 0-4z'
			/>
			<path
				fill='#2d4356'
				d='M64,36.92623a7,7,0,1,0-7-7A7.00786,7.00786,0,0,0,64,36.92623Zm0-10a3,3,0,1,1-3,3A3.00328,3.00328,0,0,1,64,26.92623Z'
			/>
			<path
				fill='#0bceb2'
				d='M51.6665,49.59274a2.0001,2.0001,0,0,0,2-2v-8a2,2,0,0,0-4,0v8A2.0001,2.0001,0,0,0,51.6665,49.59274Z'
			/>
			<path
				fill='#2d4356'
				d='M150.72,146.92648H93.55l-.99-2.35,16.63-8.89a1.97711,1.97711,0,0,0,1.03-1.44,2.00655,2.00655,0,0,0-.52-1.7l-11.61-12.25,11.81995-24.94,12.37006,12.48a1.99291,1.99291,0,0,0,2.97-.15l6.34-7.76H146a2,2,0,0,0,0-4h-7v-10h-4v10h-4.36a1.992,1.992,0,0,0-1.55.74l-5.53,6.77-12.81-12.92a2.00711,2.00711,0,0,0-3.23.55l-13.63,28.75a2.01532,2.01532,0,0,0,.36,2.24l10.74,11.34-15.93,8.52a1.98046,1.98046,0,0,0-.9,2.54l1.05,2.47H78.79l1.05-2.47a1.98046,1.98046,0,0,0-.9-2.54l-15.93-8.52,10.74-11.34a2.01532,2.01532,0,0,0,.36-2.24l-13.63-28.75a2.00711,2.00711,0,0,0-3.23-.55l-12.81,12.92-5.53-6.77a1.992,1.992,0,0,0-1.55-.74H34v-10H30v10H22a2,2,0,0,0,0,4H36.41l6.34,7.76a1.99291,1.99291,0,0,0,2.97.15L58.09,95.35647l11.81995,24.94-11.61,12.25a2.00655,2.00655,0,0,0-.52,1.7,1.97711,1.97711,0,0,0,1.03,1.44l16.63,8.89-.99,2.35H17.28a2.0172,2.0172,0,1,0,0,4H150.72a2.0172,2.0172,0,1,0,0-4Zm-11.72-68a2,2,0,1,0-4,0v3h4Zm-90.52,2.81a1.9832,1.9832,0,0,0,.85.19h.01a1.9927,1.9927,0,0,0,1.8-1.15l5.34-11.33a2.39051,2.39051,0,0,0,.14-.43l2-9.31,4.88,7.48v12.74a2,2,0,0,0,4,0v-13.33a1.9708,1.9708,0,0,0-.33-1.1l-7.5-11.5v-10.95l5.67,5.04a1.97325,1.97325,0,0,0,1.33.51h8a2,2,0,0,0,0-4H67.43l-8.43-7.5a2.00031,2.00031,0,0,0-3.33,1.5v15.78L52.76,67.94644l-5.24,11.13A1.99893,1.99893,0,0,0,48.48,81.73648ZM34,78.92648a2,2,0,0,0-4,0v3h4Z'
			/>
			<path
				fill='#0bceb2'
				d='M142,83.92648a2.00591,2.00591,0,0,1-2,2H28a2,2,0,0,1,0-4H140A2.00587,2.00587,0,0,1,142,83.92648Z'
			/>
			<path
				fill='#2d4356'
				d='M94.55471 66.92648a3 3 0 1 0-3-3A3.00344 3.00344 0 0 0 94.55471 66.92648zm0-4.5a1.5 1.5 0 1 1-1.5 1.5A1.50148 1.50148 0 0 1 94.55471 62.42648zM162.25 35.67648a2 2 0 1 0 2 2A2.00229 2.00229 0 0 0 162.25 35.67648zm0 3a1 1 0 1 1 1-1A1.001 1.001 0 0 1 162.25 38.67648zM110 23.17648a2 2 0 1 0 2 2A2.00229 2.00229 0 0 0 110 23.17648zm0 3a1 1 0 1 1 1-1A1.001 1.001 0 0 1 110 26.17648zM140.30471 6.17648a2 2 0 1 0 2 2A2.00229 2.00229 0 0 0 140.30471 6.17648zm0 3a1 1 0 1 1 1-1A1.001 1.001 0 0 1 140.30471 9.17648zM39.30471 46.17648a2 2 0 1 0-2 2A2.00229 2.00229 0 0 0 39.30471 46.17648zm-3 0a1 1 0 1 1 1 1A1.001 1.001 0 0 1 36.30471 46.17648z'
			/>
			<polygon
				fill='#0bceb2'
				points='10.888 67.938 12.375 65.982 11.436 65.45 10.481 67.64 10.45 67.64 9.48 65.466 8.525 66.013 9.996 67.922 9.996 67.954 7.695 67.656 7.695 68.72 10.011 68.423 10.011 68.454 8.525 70.363 9.416 70.926 10.434 68.72 10.465 68.72 11.404 70.911 12.39 70.347 10.888 68.47 10.888 68.438 13.25 68.72 13.25 67.656 10.888 67.969 10.888 67.938'
			/>
			<polygon
				fill='#0bceb2'
				points='11.831 15.993 10.975 17.092 11.489 17.417 12.075 16.146 12.092 16.146 12.633 17.408 13.201 17.083 12.336 16.002 12.336 15.984 13.696 16.146 13.696 15.533 12.336 15.714 12.336 15.696 13.192 14.569 12.652 14.263 12.102 15.524 12.084 15.524 11.525 14.272 10.975 14.587 11.822 15.687 11.822 15.705 10.497 15.533 10.497 16.146 11.831 15.975 11.831 15.993'
			/>
			<polygon
				fill='#0bceb2'
				points='140.529 56.884 140.529 55.84 138.213 56.147 138.213 56.116 139.671 54.198 138.75 53.676 137.814 55.825 137.783 55.825 136.832 53.691 135.895 54.229 137.338 56.101 137.338 56.132 135.081 55.84 135.081 56.884 137.353 56.592 137.353 56.623 135.895 58.495 136.769 59.048 137.768 56.884 137.798 56.884 138.719 59.032 139.686 58.48 138.213 56.638 138.213 56.607 140.529 56.884'
			/>
			<polygon
				fill='#0bceb2'
				points='77.621 5.178 78.879 3.524 78.084 3.074 77.277 4.926 77.25 4.926 76.43 3.087 75.621 3.55 76.866 5.165 76.866 5.192 74.92 4.94 74.92 5.84 76.879 5.589 76.879 5.615 75.621 7.23 76.376 7.707 77.237 5.84 77.263 5.84 78.057 7.693 78.892 7.217 77.621 5.628 77.621 5.602 79.619 5.84 79.619 4.94 77.621 5.205 77.621 5.178'
			/>
		</svg>
	);
}

export default Level9;
