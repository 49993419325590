import React from 'react';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { useSelector } from 'react-redux';

import { useBannerVisiblityFinder } from 'components/UI/ENGTBanner/ENGTBannerContext';

import { BOT_LAUNCH_ICON } from 'shared/consts/consts';
import { getBannerExcludedPageHeight } from 'shared/helpers';
import ENGTLoaderIcon from 'shared/icons/ENGTLoaderIcon.gif';
import NonENGTLoaderIcon from 'shared/icons/NonENGTLoader.gif';

import { RootState } from 'store/rootReducer';

import { i18nHelper } from 'i18nHelper';

import ENGTFooter from '../ENGTFooter/ENGTFooter';

import 'components/FadeAnimation/FadeAnimation.scss';

function Loader({
	loaderBlockHeight,
	loaderBlockWidth,
	showEngatiLoaderOnly,
	showNonEngatiLoaderOnly,
	showFooter = false,
	showText = false,
	size = 60,
	text = '',
	overrides = {},
}: {
	loaderBlockHeight?: string;
	loaderBlockWidth?: string;
	showEngatiLoaderOnly?: boolean;
	showNonEngatiLoaderOnly?: boolean;
	showFooter?: boolean;
	showText?: boolean;
	size?: number;
	text?: React.ReactNode | string;
	overrides?: any;
}) {
	const [css, theme] = useStyletron();
	let isWhiteLabelPartner = useSelector((state: RootState) => !state.User.botAdmin.data.is_engati_brand);
	isWhiteLabelPartner = typeof isWhiteLabelPartner === 'boolean' ? isWhiteLabelPartner : true;
	const bannerHeight = useBannerVisiblityFinder();

	const renderLoaderIcon = () => {
		if (showEngatiLoaderOnly) {
			return <img src={ENGTLoaderIcon} alt='engt-loader' height={size} width={size} />;
		}
		if (showNonEngatiLoaderOnly) {
			return <img src={NonENGTLoaderIcon} alt='loader-gif' height={size} width={size} />;
		}

		return (
			<img
				className={css({
					animation: 'heartbeat 1.5s infinite',
				})}
				src={isWhiteLabelPartner ? NonENGTLoaderIcon : BOT_LAUNCH_ICON}
				alt='loader-gif'
				height={size}
				width={size}
			/>
		);
	};

	return (
		<Block
			className={overrides}
			width={[loaderBlockWidth || '100%']}
			height={[loaderBlockHeight || getBannerExcludedPageHeight(bannerHeight)]}
			display='flex'
			justifyContent='center'
			alignItems='center'
			flexDirection='column'
		>
			{renderLoaderIcon()}
			{showText && (
				<p
					className={css({
						fontStyle: 'normal',
						fontWeight: 600,
						fontSize: '1rem',
						lineHeight: '1.25rem',
						marginTop: '2rem',
						color: theme.colors.primaryA,
					})}
				>
					{text || i18nHelper('components:loader.defaultText')}
				</p>
			)}
			{showFooter && (
				<div
					className={css({
						position: 'absolute',
						bottom: '1rem',
					})}
				>
					<ENGTFooter />
				</div>
			)}
		</Block>
	);
}

export default Loader;
