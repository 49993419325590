import React, { useState } from 'react';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';

import { LabelWithTooltip } from 'components/UI/LabelWithToolTip/LabelWithTooltip';

import { MESSAGE_TYPE_MAP } from 'shared/consts/consts';

interface IENGTStory {
	backgroundColor: string;
	message: string;
	messageMediaUrl: string;
	messageDescription: string;
	messageUnavailableText: string;
	caption: string;
	messageType: string;
}

const storyCss = {
	display: 'flex',
	alignItems: 'flex-end',
	paddingLeft: '1rem',
	paddingTop: '1rem',
};

const commentCss = {
	paddingLeft: '0.375rem',
	paddingRight: '0.375rem',
	display: 'flex',
	alignItems: 'start',
	justifyContent: 'flex-start',
	borderTopLeftRadius: '0.5rem',
	borderTopRightRadius: '0.5rem',
	borderBottomLeftRadius: '0.5rem',
	borderBottomRightRadius: '0.5rem',
	flexDirection: 'column',
};

function MediaResponse(props: IENGTStory) {
	const {
		backgroundColor,
		message,
		caption,
		messageMediaUrl,
		messageDescription,
		messageUnavailableText,
		messageType,
	} = props;
	const [css, theme]: [any, any] = useStyletron();
	const { t } = useTranslation(['components', 'common']);
	const [isVideoStory, setIsVideoStory] = useState(true);
	const [isImageStory, setIsImageStory] = useState(true);

	let mediaText = '';

	const getStoryText = () => {
		let text = '';
		if (messageDescription && !isImageStory && !isVideoStory && messageMediaUrl) {
			text = `${messageDescription} | ${messageUnavailableText}`;
		} else if (messageDescription) {
			text = `${messageDescription}:`;
		} else if ((!isImageStory && !isVideoStory) || !messageMediaUrl) {
			text = `${messageUnavailableText}`;
			if (messageType === MESSAGE_TYPE_MAP.AD_REFERRAL_RECEIVED) {
				mediaText = t('components:messagesPreview.adReferralPreviewUnavailable1');

				return mediaText;
			}
		}

		return text;
	};

	const processedCaption = () => {
		const updatedCaption = caption && caption.replaceAll('\n', '<br>');

		return (
			<LabelWithTooltip
				maxWidth='50%'
				characterLimit={40}
				dangerouslySetHTML
				appendToBody
				style={{ zIndex: theme.zIndex400 }}
			>
				{updatedCaption}
			</LabelWithTooltip>
		);
	};

	return (
		<Block
			className={css({
				display: 'flex',
				flexDirection: 'column',
				backgroundColor,
				borderTopLeftRadius: '0.5rem',
				borderTopRightRadius: '0.5rem',
				borderBottomLeftRadius: '0.5rem',
				borderBottomRightRadius: '0.5rem',
			})}
		>
			<Block className={css(storyCss)}>
				{messageMediaUrl && isImageStory && (
					<a
						href={messageMediaUrl}
						target='_blank'
						className={css({
							display: 'flex',
							justifyContent: 'flex-start',
						})}
						rel='noreferrer'
					>
						<img
							src={messageMediaUrl}
							onLoad={() => setIsVideoStory(false)}
							onError={() => setIsImageStory(false)}
							className={css({
								height: '7.5rem',
								width: '7.5rem',
								borderTopLeftRadius: '0.5rem',
								borderTopRightRadius: '0.5rem',
								borderBottomLeftRadius: '0.5rem',
								borderBottomRightRadius: '0.5rem',
							})}
						/>
					</a>
				)}
				{messageMediaUrl && isVideoStory && (
					<a
						target='_blank'
						className={css({
							display: 'flex',
							justifyContent: 'flex-start',
						})}
					>
						<video
							src={messageMediaUrl}
							controls
							controlsList='nodownload'
							onLoadedData={() => setIsImageStory(false)}
							onError={() => setIsVideoStory(false)}
							className={css({
								height: '7.5rem',
								width: '7.5rem',
								borderTopLeftRadius: '0.5rem',
								borderTopRightRadius: '0.5rem',
								borderBottomLeftRadius: '0.5rem',
								borderBottomRightRadius: '0.5rem',
							})}
						/>
					</a>
				)}
				<div className={css(commentCss)}>
					{getStoryText() && (
						<div>
							<div
								className={css({
									paddingTop: '1rem',
									color: theme.colors.accent50,
									fontSize: '0.875rem',
								})}
							>
								{getStoryText()}
								{mediaText && (
									<span>
										<a
											target='_blank'
											rel='noopener noreferrer'
											href={messageMediaUrl}
											className={css({
												outline: 'none',
												color: theme.colors.accent50,
											})}
										>
											{t('common:clickHereCap')}
										</a>
										{t('components:messagesPreview.adReferralPreviewUnavailable2')}
									</span>
								)}
							</div>
						</div>
					)}

					<div
						className={css({
							fontSize: '0.875 rem',
							marginTop: '0rem',
							marginBottom: '0rem',
						})}
					>
						{processedCaption()}
					</div>
				</div>
			</Block>
			<div
				className={css({
					paddingBottom: '0.5rem',
					fontSize: '0.875rem',
					paddingLeft: '1rem',
					paddingTop: '1rem',
				})}
			>
				{parse(message)}
			</div>
		</Block>
	);
}

export default React.memo(MediaResponse);
