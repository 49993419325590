import { CSSProperties, useEffect, useState } from 'react';

const XpBar = (props: any) => {
	const { bgcolor, completed, currentXp, maxXp } = props;
	const [width, setWidth] = useState(0);
	const [displayedPercent, setDisplayedPercent] = useState(0);

	useEffect(() => {
		setWidth(completed);

		let start = 0;
		const increment = completed / 100;
		const interval = setInterval(() => {
			start += increment;
			setDisplayedPercent(Math.min(Math.round(start), completed));

			if (start >= completed) {
				clearInterval(interval); // Stop the interval when completed is reached
			}
		}, 18); // Adjust this interval for smoother/faster animation

		return () => clearInterval(interval); // Cleanup interval on unmount
	}, [completed]);

	const containerStyles: CSSProperties = {
		height: 20,
		width: '100%',
		backgroundColor: '#e0e0de',
		borderRadius: 50,
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
	};

	const fillerStyles: CSSProperties = {
		height: '100%',
		width: `${width}%`,
		backgroundColor: bgcolor,
		borderRadius: 'inherit',
		textAlign: 'right',
		position: 'relative',
		transition: 'width 1.5s ease-in-out',
	};

	const labelStyles: CSSProperties = {
		padding: 5,
		color: 'white',
		fontWeight: 'bold',
	};

	const currentXpStyles: CSSProperties = {
		position: 'absolute',
		bottom: -25,
		left: 0,
		fontSize: 12,
		color: 'black',
	};

	const maxXpStyles: CSSProperties = {
		position: 'absolute',
		bottom: -25,
		right: 0,
		fontSize: 12,
		color: 'black',
	};

	return (
		<div style={containerStyles}>
			<div style={fillerStyles}>
				<span style={labelStyles}>{`${displayedPercent}%`}</span>
			</div>
			<div style={currentXpStyles}>{`${currentXp}`}</div>
			<div style={maxXpStyles}>{`${maxXp}`}</div>
		</div>
	);
};

export default XpBar;
