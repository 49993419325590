import { IObjProps } from 'shared/consts/types';
import { EnglishLanguages } from 'shared/enum/templatesEnum';
import { templateLanguageCodeMapper } from 'shared/helpers';

const VARIABLES_REGEX = new RegExp(/{{\d+}}/g);

const DEFAULT_LANGUAGE_CODE = 'en_US';

export const getVariables = (formInput: string) => {
	const arr = formInput.match(VARIABLES_REGEX) || [];

	return new Set(arr.map((val) => val.substring(2, val.indexOf('}}'))));
};

export const detectAndSetVariables = (mapKey: string, inputValue: string, variablesMap: Map<string, Set<string>>) => {
	const variables = getVariables(inputValue);

	if (variables.size) {
		variablesMap.set(mapKey, variables);
	}
};

const TEMPLATE_PARAMS_REGEX = new RegExp(/{{\d+}}/gm);

export const getNextAvailableVariable = (value: string) => {
	if (!value) {
		return 1;
	}

	const regexMatches = (value.match(TEMPLATE_PARAMS_REGEX) || [])
		.map((regData: string) => parseInt(regData.replace('{{', '').replace('}}', '')))
		.sort((a: number, b: number) => a - b);
	const set = new Set(regexMatches);

	for (let i = 1; i <= regexMatches.length + 1; i++) {
		if (!set.has(i)) {
			return i;
		}
	}

	return 1;
};

export const isVariablesInOrder = (value: string): boolean => {
	if (!value) {
		return true;
	}

	const variables = getVariables(value);

	if (variables.size) {
		const isVariablesOrdered = Array.from(variables).every((e: string, index: number) => parseInt(e) === index + 1);

		return isVariablesOrdered;
	}

	return true;
};

export const isAddVariableDisabled = (value: string) => {
	if (!value) {
		return false;
	}

	const variables = value.match(VARIABLES_REGEX) || [];

	return variables.length > 0;
};

export const availableTemplateLanguages = Object.entries(templateLanguageCodeMapper()).map(
	(language: Array<string>) => ({
		id: language[0],
		label: language[1],
	})
);

const getLanguageFromAvailableLanguages = (language: string) => [
	availableTemplateLanguages.find((lang) => lang.id === language) || {},
];

export const getLanguageMap = (language: string) => {
	const { EN, EN_GB } = EnglishLanguages;
	const targetLanguage = language
		? language === EN || language === EN_GB
			? DEFAULT_LANGUAGE_CODE
			: language
		: DEFAULT_LANGUAGE_CODE;

	return getLanguageFromAvailableLanguages(targetLanguage);
};

export const getHtmlFormatText = (() => {
	const cache: IObjProps = {};

	return (content: string) => {
		if (!content) {
			return '';
		}

		if (cache[content] !== undefined) {
			return cache[content];
		}

		let editorContent: string = content;

		const htmlFormat = [
			{ symbol: '```', exp: /```.*```/g, tag: 'code' },
			{ symbol: '_', exp: /_.*_/g, tag: 'em' },
			{ symbol: '*', exp: /\*.*\*/g, tag: 'b' },
			{ symbol: '~', exp: /~.*~/g, tag: 'del' },
		];

		try {
			htmlFormat.forEach(({ symbol, tag }) => {
				const boundary = `(?<![a-zA-Z0-9])(?=[a-zA-Z0-9])|(?<=[a-zA-Z0-9])(?![a-zA-Z0-9])`;
				const regex = new RegExp(
					`(\\${symbol})([^${symbol}\\s]*)(${boundary})([^*]*)(${boundary})([^${symbol}\\s]*)(\\${symbol})`,
					'gm'
				);
				const matches = editorContent?.match(regex);
				if (!matches) {
					return editorContent;
				}

				matches?.forEach((match) => {
					const formatted = match.replace(symbol, `<${tag}>`).replace(symbol, `</${tag}>`);
					editorContent = editorContent.replace(match, formatted);
				});
			});

			editorContent = editorContent.replaceAll('<br></br>', '\n\n').replaceAll('<br>', '\n\n');
		} catch (e) {
			try {
				htmlFormat.forEach(({ symbol, exp, tag }) => {
					editorContent = editorContent.replace(exp, (match: string, _: any) =>
						match.replace(symbol, `<${tag}>`).replace(symbol, `</${tag}>`)
					);
				});
			} catch (exception) {
				console.error(exception);
			}
		}

		cache[content] = editorContent;

		return editorContent;
	};
})();

export const MIN_BODY_LIMIT_TO_FETCH_SUGGESTIONS = 10;

export const MAX_BODY_LIMIT_TO_FETCH_SUGGESTIONS = 1024;
