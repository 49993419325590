import React from 'react';
import { useStyletron } from 'baseui';

function Level4({
	size,
	fillColor,
	height,
	width,
}: {
	size?: number;
	fillColor?: string;
	height: number;
	width: number;
}) {
	const [, theme] = useStyletron();

	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 16.933 16.933'
			id='direction-board'
		>
			<path d='M8.47 1.058c-.581 0-1.057.477-1.058 1.058H9.53c0-.581-.477-1.058-1.059-1.058ZM4.76 2.647a.53.53 0 0 0-.523.529v2.115c0 .285.235.53.523.53h9.529c.07 0 .137-.027.187-.077l1.322-1.324a.265.265 0 0 0 0-.373l-1.322-1.324a.265.265 0 0 0-.187-.076Zm.801 1.322h5.026c.36-.008.36.537 0 .53H5.561c-.344-.008-.344-.522 0-.53zm6.084 0h1.056c.36-.008.36.537 0 .53h-1.056c-.345-.008-.345-.522 0-.53zM7.672 6.346a.265.265 0 0 0-.26.27v.529c0 .151.128.272.279.263h1.555c.153.011.283-.11.283-.263v-.53a.265.265 0 0 0-.27-.27c-.528.005-1.057.005-1.587 0zM2.62 7.939h-.001a.265.265 0 0 0-.16.076L1.135 9.339a.265.265 0 0 0 0 .373l1.324 1.325c.05.049.117.076.187.075h9.526a.53.53 0 0 0 .523-.529V8.468a.53.53 0 0 0-.523-.529H2.62zM5.322 9.26h.003c.364.008.334.56-.03.528H4.238c-.326-.009-.35-.487-.025-.527a.27.27 0 0 1 .025 0h1.059a.257.257 0 0 1 .026-.001zm6.084 0h.003c.364.008.334.56-.03.528H6.355c-.327-.009-.35-.487-.026-.527a.27.27 0 0 1 .026 0h5.026l.026-.001zm-3.734 2.378a.265.265 0 0 0-.26.267v3.44H4.768c-.345.008-.345.521 0 .529h5.025c.345-.008.345-.522 0-.53H9.53v-3.439a.265.265 0 0 0-.272-.264c-.527-.003-1.062.005-1.585-.003zm3.18 3.707c-.346.008-.346.521 0 .529h1.056c.345-.008.345-.522 0-.53z' />
		</svg>
	);
}

export default Level4;
