import React, { useRef } from 'react';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { KIND, SIZE } from 'baseui/button';

import ApiTriggerMessageTag from 'components/shared/ApiTriggerMessageTag/ApiTriggerMessageTag';
import ENGTButton from 'components/UI/ENGTButton/ENGTButton';
import ENGTImage from 'components/UI/ENGTImage/ENGTImage';
import ENGTTooltip from 'components/UI/ENGTTooltip/ENGTTooltip';

import { IObjProps } from 'shared/consts/types';
import LeftArrowIcon from 'shared/icons/LeftArrowIcon';
import RightArrowIcon from 'shared/icons/RightArrowIcon';

interface IENGTCarousel {
	backgroundColor: string;
	carousel: Array<IObjProps>;
	fillColor: string;
	showApiTriggerMessageTag?: boolean;
}

const width = '9.375rem';

const textCss = {
	width,
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	fontSize: '0.75rem',
};

const navigationCss = {
	outline: 'none',
	borderLeft: 'none',
	borderRight: 'none',
	borderTop: 'none',
	borderBottom: 'none',
	backgroundColor: 'transparent',
	cursor: 'pointer',
	marginTop: '0.625rem',
};

function Carousel(props: IENGTCarousel) {
	const { backgroundColor, carousel, fillColor, showApiTriggerMessageTag = false } = props;
	const [css]: [any, any] = useStyletron();

	const carouselContainer: any = useRef(null);

	return (
		<>
			<Block
				className={css({
					maxWidth: '100%',
					overflowX: 'auto',
					whiteSpace: 'nowrap',
					'scrollbar-width': 'none',
					'-ms-overflow-style': 'none',
					'::-webkit-scrollbar': {
						display: 'none',
					},
					scrollBehavior: 'smooth',
				})}
				ref={carouselContainer}
			>
				{carousel.map((option, index) => (
					<Block
						className={css({
							display: 'inline-block',
							paddingLeft: '1rem',
							paddingRight: '1rem',
							paddingTop: '0.875rem',
							paddingBottom: '0.875rem',
							marginLeft: '0.5rem',
							backgroundColor,
							textAlign: 'left',
							borderTopLeftRadius: '0.5rem',
							borderTopRightRadius: '0.5rem',
							borderBottomLeftRadius: '0.5rem',
							borderBottomRightRadius: '0.5rem',
						})}
						key={`${option.id}-${index}`}
					>
						{showApiTriggerMessageTag && <ApiTriggerMessageTag />}
						<div>
							<ENGTImage
								lazy
								src={option.image_url}
								alt=''
								className={css({
									objectFit: 'contain',
									height: '7.5rem',
									width,
								})}
							/>
						</div>
						<div
							className={css({
								...textCss,
								fontWeight: 600,
								paddingTop: '1rem',
								paddingRight: '0rem',
								paddingBottom: '0.375rem',
								paddingLeft: '0rem',
							})}
						>
							{option.title}
						</div>
						<div className={css(textCss)}>{option.subtitle ? option.subtitle : '-'}</div>

						<div>
							{option.buttons.map((button: any, buttonIndex: number) => (
								<div key={buttonIndex}>
									<ENGTTooltip content={button.title}>
										<span>
											<ENGTButton
												label={
													button.title.length > 25
														? `${button.title.slice(0, 25)}...`
														: button.title
												}
												kind={KIND['secondary']}
												size={SIZE.default}
												className={css({
													marginBottom: '0.25rem',
													width: '13rem',
													fontWeight: 'normal !important',
													borderRadius: '0.625rem',
												})}
											/>
										</span>
									</ENGTTooltip>
								</div>
							))}
						</div>
					</Block>
				))}
			</Block>
			{carousel.length && (
				<Block>
					<button
						type='button'
						onClick={() => {
							const scrollX = carouselContainer.current.scrollWidth / carousel.length;
							carouselContainer.current.scrollBy(-scrollX, 0);
						}}
						className={css(navigationCss)}
					>
						<LeftArrowIcon fillColor={fillColor} height='1rem' width='1rem' />
					</button>
					<button
						type='button'
						onClick={() => {
							const scrollX = carouselContainer.current.scrollWidth / carousel.length;
							carouselContainer.current.scrollBy(scrollX, 0);
						}}
						className={css(navigationCss)}
					>
						<RightArrowIcon fillColor={fillColor} height='1rem' width='1rem' />
					</button>
				</Block>
			)}
		</>
	);
}

export default React.memo(Carousel);
