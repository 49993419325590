import { Suspense, useEffect, useRef, useState } from 'react';
import { useStyletron } from 'baseui';
import { toaster } from 'baseui/toast';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ENGTMentions from 'components/UI/ENGTMentions/ENGTMentions';
import ENGTToasterContainer from 'components/UI/ENGTToaster/ENGTToasterContainer';
import Form from 'components/UI/Form/Form/Form';
import { FormInput } from 'components/UI/Form/FormInput/FormInput';
import Loader from 'components/UI/Loader/Loader';
import ModalWithSections from 'components/UI/Modal/ModalWithSections/ModalWithSections';
import QuillEditor from 'components/UI/Quill';

import { ENGATI_RESPONSE_CODE } from 'shared/consts/consts';
import { EDIT_QUICK_REPLY } from 'shared/consts/QAConsts';
import { isValidResponseObject } from 'shared/helpers';
import { processQuillContent } from 'shared/helpers/quillHelper';
import { tagsNotAllowed } from 'shared/pathBuilderHelpers';

import { API } from 'store/Configure/LiveChat/api';
import { openErrorSavingModalAction, openInfoModalAction } from 'store/Modal/actions';
import { RootState } from 'store/rootReducer';

interface IEditQuickReplyModalProps {
	isModalOpen: boolean;
	payload: any;
	setModelState: any;
	getAllQuickReplies: any;
	isAddModal?: boolean;
}

interface EditQuickReplyFormInputs {
	displayName: string;
	title: string;
}

function EditQuickReplyModal(props: IEditQuickReplyModalProps) {
	const { payload, isModalOpen, setModelState, getAllQuickReplies, isAddModal } = props;
	const { t } = useTranslation(['components']);
	const [css, theme]: [any, any] = useStyletron();
	const [isConfirmBtnDisabled, setConfirmBtnDisabled] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const dispatch = useDispatch<any>();

	const botRef = useSelector((state: RootState) => state.Bot.data.bot_ref);
	const uid = useSelector((state: RootState) => state.User.botAdmin.data.uid);
	const roleName = useSelector((state: RootState) => state.User.profile.data.roleName);
	const cid = useSelector((state: RootState) => state.User.botAdmin.data.cid);
	const quillRef = useRef<any>(null);
	const [description, setDescription] = useState(false);

	const onCloseFn = () => {
		setConfirmBtnDisabled(false);
		setIsLoading(false);
		setValue('displayName', payload.display_name || '');
		setValue('title', payload.title || '');
		setModelState(false);
	};

	const defaultValues: any = {
		displayName: payload?.display_name || '',
		title: payload?.title || '',
	};

	const methods = useForm<EditQuickReplyFormInputs>({
		defaultValues,
		shouldUnregister: false,
	});

	const { setValue, control, getValues, handleSubmit } = methods;

	useEffect(() => {
		setValue('displayName', defaultValues['displayName']);
		setValue('title', defaultValues['title']);
	}, [payload?.display_name]);

	useEffect(() => {
		if (getValues('displayName')?.length > 2000 || processQuillContent(quillRef?.current?.value)?.length === 0) {
			setDescription(true);
		} else {
			setDescription(false);
		}
	}, [getValues('displayName')]);

	const updateQuickReplyHandler = (values: any) => {
		setIsLoading(true);
		const displayName = processQuillContent(values.displayName);
		const data = {
			bot_ref: botRef,
			user_id: uid,
			user_role: roleName,
			display_name: displayName,
			reply_text: displayName,
			title: values.title.trim(),
			reply_id: payload.reply_id,
			available_for: payload.available_for,
		};

		return API.updateQuickReply(data)
			.then((response: any) => {
				if (isValidResponseObject(response)) {
					getAllQuickReplies();
				} else if (response?.data?.status?.code === 1050) {
					dispatch(
						openInfoModalAction(
							t('common:error'),
							t('pages:configure.liveChat.quickReplies.duplicateQuickReplyToaster')
						)
					);
				} else if (response?.data?.status?.code === 1023) {
					dispatch(
						openInfoModalAction(
							t('common:error'),
							t('pages:configure.liveChat.quickReplies.textTooBigError')
						)
					);
				} else if (response?.data?.status?.code === 1041) {
					dispatch(
						openInfoModalAction(
							t('common:error'),
							t('pages:configure.liveChat.quickReplies.titleTooBigError')
						)
					);
				} else {
					dispatch(openErrorSavingModalAction());
				}
				setModelState(false);
				setIsLoading(false);
			})
			.catch((e: any) => {
				dispatch(openErrorSavingModalAction());
			});
	};
	const addQuickReply = (data: any) => {
		const displayName = processQuillContent(data.displayName);
		const payload = {
			bot_ref: botRef,
			customer_id: cid,
			user_id: uid,
			available_for: 'ALL',
			user_role: roleName,
			locale: 'en',
			channel: 'Text',
			reply_text: displayName,
			title: data.title.trim(),
			display_name: displayName,
		};

		return API.addQuickReply(payload)
			.then((response: any) => {
				if (isValidResponseObject(response)) {
					toaster.positive(
						<ENGTToasterContainer
							title={t('pages:configure.liveChat.quickReplies.quickReplyToasterHeading')}
							description={t('common:changesSuccessfulSaved')}
						/>,
						{}
					);
					getAllQuickReplies(1, 10);
				} else if (response?.data?.status?.code === ENGATI_RESPONSE_CODE.STATUS_1050) {
					dispatch(
						openInfoModalAction(
							t('common:error'),
							t('pages:configure.liveChat.quickReplies.duplicateQuickReplyToaster')
						)
					);
				} else if (response?.data?.status?.code === ENGATI_RESPONSE_CODE.STATUS_1023) {
					dispatch(
						openInfoModalAction(
							t('common:error'),
							t('pages:configure.liveChat.quickReplies.textTooBigError')
						)
					);
				} else if (response?.data?.status?.code === ENGATI_RESPONSE_CODE.STATUS_1041) {
					dispatch(
						openInfoModalAction(
							t('common:error'),
							t('pages:configure.liveChat.quickReplies.titleTooBigError')
						)
					);
				} else {
					dispatch(openErrorSavingModalAction());
				}
				setModelState(false);

				return response;
			})
			.catch((e: any) => {
				dispatch(openErrorSavingModalAction());

				return e;
			});
	};

	return (
		<ModalWithSections
			modalSubHeading=''
			heading={
				isAddModal
					? t('pages:configure.liveChat.quickReplies.addQuickReply')
					: t('pages:configure.liveChat.quickReplies.editQuickReply')
			}
			isOpen={isModalOpen}
			isLoading={isLoading}
			width='30rem'
			isConfirmBtnDisabled={isConfirmBtnDisabled}
			onClose={onCloseFn}
			onConfirm={handleSubmit(isAddModal ? addQuickReply : updateQuickReplyHandler)}
			dataQa={EDIT_QUICK_REPLY}
		>
			<FormProvider {...methods}>
				<Form
					onSubmit={
						!isConfirmBtnDisabled &&
						!isLoading &&
						handleSubmit(isAddModal ? addQuickReply : updateQuickReplyHandler)
					}
				>
					<Controller
						control={control}
						name='title'
						rules={{
							validate: (value) => {
								if (value) {
									const processedContent = processQuillContent(value);
									setValue('title', processedContent);
									if (value.length > 30) {
										return t('components:editQuickReplyModal.titleTooLarge') as string;
									}

									return tagsNotAllowed(t, processedContent, true);
								}

								return true;
							},
						}}
						render={({ onChange, ref, value }) => (
							<Suspense fallback={<Loader />}>
								<FormInput
									name='title'
									width='100%'
									label={t('components:editQuickReplyModal.replyTitle')}
									placeholder={t('components:editQuickReplyModal.enterTitle')}
									onChange={onChange}
									value={value}
									inputRef={ref}
								/>
							</Suspense>
						)}
					/>
					<Controller
						control={control}
						name='displayName'
						rules={{
							validate: (value) => {
								if (value) {
									const processedContent = processQuillContent(value);
									if (value.length > 2000) {
										return t('components:editQuickReplyModal.descriptionTooLarge') as string;
									}

									return tagsNotAllowed(t, processedContent, true);
								}
								if (!getValues('displayName') && !value) {
									return t('components:editQuickReplyModal.errors.mandatory.name') as string;
								}

								return true;
							},
							required: t('components:node.sm.requiredErrorMessage') as string,
						}}
						render={({ onChange, ref, value }) => (
							<Suspense fallback={<Loader />}>
								<ENGTMentions inputRef={ref} callback={onChange} isQuill id='displayName'>
									<QuillEditor
										inputRef={quillRef}
										name='displayName'
										label={t('pages:configure.liveChat.quickReplies.enterQuickReply')}
										width='100%'
										marginBottom='0rem'
										placeholder={t('components:oneViewTextArea.quickReply.placeholder')}
										value={value || ''}
										toolbarHeight='0px'
										onChange={onChange}
										borderBottom={
											description
												? `1px solid ${theme.colors.inputErrorMsgColor}`
												: `1px solid ${theme.colors.inputBorderColor}`
										}
									/>
								</ENGTMentions>
							</Suspense>
						)}
					/>
				</Form>
			</FormProvider>
		</ModalWithSections>
	);
}

export default EditQuickReplyModal;
