import React from 'react';
import { useStyletron } from 'baseui';

function EmptyMedal({
	size,
	fillColor,
	height,
	width,
}: {
	size?: number;
	fillColor?: string;
	height: number;
	width: number;
}) {
	const [, theme] = useStyletron();

	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' id='medal' height={height} width={width}>
			<rect width='10.95' height='10.95' x='26.52' y='18.49' fill='#fdaf3f' />
			<circle cx='32' cy='43.23' r='16.92' fill='#ffe550' />
			<path
				fill='#fb5058'
				d='M44.19,3.86H19.81a2.75,2.75,0,0,0-2.76,2.76v6.12a5.25,5.25,0,0,0,2.05,4.17l7.42,5.73h11l7.42-5.73A5.25,5.25,0,0,0,47,12.74V6.62A2.75,2.75,0,0,0,44.19,3.86Z'
			/>
			<rect width='10.95' height='18.78' x='26.52' y='3.86' fill='#e7e7e6' />
			<path
				fill='#fdaf3f'
				d='M33,37.18,34.36,40l3.13.4a1.1,1.1,0,0,1,.62,1.9l-2.3,2.18.58,3.1a1.1,1.1,0,0,1-1.61,1.18L32,47.28,29.22,48.8a1.1,1.1,0,0,1-1.61-1.18l.58-3.1-2.3-2.18a1.1,1.1,0,0,1,.62-1.9l3.13-.4L31,37.18A1.1,1.1,0,0,1,33,37.18Z'
			/>
			<path d='M38.48,26.54V23.13l7-5.43a6.32,6.32,0,0,0,2.43-5V6.62a3.77,3.77,0,0,0-3.76-3.76H19.81a3.77,3.77,0,0,0-3.76,3.76v6.12a6.32,6.32,0,0,0,2.43,5l7,5.43v3.41a17.92,17.92,0,1,0,13,0Zm-11-21.68h9V21.64h-9ZM46,6.62v6.12a4.28,4.28,0,0,1-1.66,3.37L38.48,20.6V4.86h5.71A1.76,1.76,0,0,1,46,6.62Zm-27.9,6.12V6.62a1.76,1.76,0,0,1,1.76-1.76h5.71V20.6l-5.81-4.48A4.31,4.31,0,0,1,18.05,12.74Zm9.47,10.9h9V25.9a17.3,17.3,0,0,0-9,0ZM32,59.14A15.92,15.92,0,1,1,47.92,43.23,15.93,15.93,0,0,1,32,59.14ZM45.52,43.23A13.52,13.52,0,1,1,21.28,35a1,1,0,0,1,1.59,1.22,11.52,11.52,0,1,0,20.65,7A11.54,11.54,0,0,0,32,31.71a11.39,11.39,0,0,0-4.81,1,1,1,0,0,1-.83-1.82A13.32,13.32,0,0,1,32,29.71,13.54,13.54,0,0,1,45.52,43.23ZM27.11,44.87l-.49,2.57a2.13,2.13,0,0,0,2.07,2.5,2.14,2.14,0,0,0,1-.26L32,48.42l2.3,1.26a2.11,2.11,0,0,0,3.08-2.24l-.49-2.57,1.91-1.8a2.11,2.11,0,0,0-1.18-3.62L35,39.11,33.9,36.75a2.1,2.1,0,0,0-3.8,0L29,39.11l-2.59.34a2.11,2.11,0,0,0-1.18,3.62Zm-.47-3.44,3.13-.4a1,1,0,0,0,.78-.57l1.35-2.85a.11.11,0,0,1,.2,0h0l1.35,2.85a1,1,0,0,0,.78.57l3.13.4s.07,0,.09.08a.1.1,0,0,1,0,.11l-2.3,2.17a1,1,0,0,0-.29.91l.58,3.11s0,.06,0,.1a.09.09,0,0,1-.12,0l-2.77-1.51a1,1,0,0,0-1,0l-2.77,1.51a.11.11,0,0,1-.12,0c-.05,0-.05-.08,0-.1l.58-3.11a1,1,0,0,0-.29-.91l-2.3-2.17a.1.1,0,0,1,0-.11C26.57,41.44,26.61,41.44,26.64,41.43Z' />
		</svg>
	);
}

export default EmptyMedal;
