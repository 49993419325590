import { useEffect, useRef, useState } from 'react';
import { useStyletron } from 'baseui';
import { KIND, SIZE } from 'baseui/button';
import { FileUploader } from 'baseui/file-uploader';
import { toaster } from 'baseui/toast';
import { LabelMedium } from 'baseui/typography';
import { Panel } from 'baseui-new/accordion';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import TemplatePreview, { TEMPLATE_VARIABLE_LENGTH } from 'components/shared/Template/Template';
import ENGTAccordion from 'components/UI/ENGTAccordion/ENGTAccordion';
import ENGTImage from 'components/UI/ENGTImage/ENGTImage';
import ENGTMentions from 'components/UI/ENGTMentions/ENGTMentions';
import { FormInput } from 'components/UI/Form/FormInput/FormInput';
import FormLabel from 'components/UI/Form/FormLabel/FormLabel';
import FormRadio from 'components/UI/Form/FormRadio/FormRadio';
import FormSelect from 'components/UI/Form/FormSelect/FormSelect';
import Loader from 'components/UI/Loader/Loader';

import { ALLOWED_ATTACHMENT_SIZE } from 'shared/consts/consts';
import { TEMPLATE_MESSAGE } from 'shared/consts/QA/PathBuilder';
import { IObjProps, ISelectValue } from 'shared/consts/types';
import { Content, OPT_OUT_BUTTON_OPTION } from 'shared/enum';
import {
	extractTextFromHtml,
	getSizeInBytes,
	getTemplateCTAStaticUrlSlug,
	getTemplateUrlBasedOnCTAVersion,
	hexToRgb,
	isValidResponseObject,
	newLineCountFn,
	PLATFORM,
	WHATSAPP_PROVIDERS,
} from 'shared/helpers';
import { checkIfValidUrl } from 'shared/helpers/broadcastHelpers';
import AddIcon from 'shared/icons/AddIcon';
import datePickerIcon from 'shared/icons/DatePickerIcon.svg';
import { tagsNotAllowed, validateImageUrl, validateVideoUrl } from 'shared/pathBuilderHelpers';
import { componentFormat, templateComponentParameters } from 'shared/whatsappTemplateHelpers';

import { isTemplateCacheEntitlementEnabled, isWhatsappAcceleratorTrialEnabled } from 'store/App/User/selectors';
import { API } from 'store/Broadcast/api';
import { messageTemplatesSelector } from 'store/Broadcast/selectors';
import { API as IMAGE_UPLOAD_API } from 'store/PathBuilder/api';
import { RootState } from 'store/withReducer';

import ENGTButton from '../UI/ENGTButton/ENGTButton';
import ENGTInput from '../UI/ENGTInput/ENGTInput';
import ENGTToasterContainer from '../UI/ENGTToaster/ENGTToasterContainer';

import AttributesAsQuickReply from './AttributesAsQuickReply';
import RenderDateTimePicker from './RenderDateTimePicker';

// Currently this is used for Dialog 360 as a Provider

interface IENGTTemplateProps {
	currentTemplate?: any;
	pathVariables: any;
	availableFlows: any;
	flowAttributes: any;
	languageCodeMapper: any;
	templateLanguage: any;
	isCopyMode: boolean;
	isEditMode: boolean;
	isResumeMode: boolean;
	setTemplatePayload?: any;
	setTemplateComponents?: any;
	setCurrentTemplate?: any;
	setIncorrectTemplateSelected?: any;
	setTemplateLanguage: any;
	methods: any;
	initialTemplate?: string;
	setIsErrorState: any;
	templates: any;
	setTemplates: any;
	setLoading?: any;
	isTemplateNode?: boolean;
	isBroadcast?: boolean;
	formSuffix?: string;
	dateTimeError?: string;
	setDateTimeError?: Function;
	stageIndex?: number;
	currentTemplateData?: any;
	setCurrentTemplateData?: any;
	setTemplateComponentsData?: any;
	templateComponentsData?: any;
	isFileValidation?: boolean;
	optOutButtonOptions?: Array<IObjProps>;
	defaultSelection?: string;
	isPhoneNumber?: boolean;
}

function WhatsappTemplateMessage({
	currentTemplate,
	pathVariables,
	dateTimeError,
	setDateTimeError,
	availableFlows,
	flowAttributes,
	languageCodeMapper,
	templateLanguage,
	isCopyMode,
	isEditMode,
	isResumeMode,
	initialTemplate,
	setIncorrectTemplateSelected,
	setTemplatePayload,
	setTemplateComponents,
	setCurrentTemplate,
	setTemplateLanguage,
	methods,
	setIsErrorState,
	templates,
	setTemplates,
	setLoading,
	isTemplateNode,
	isBroadcast,
	formSuffix = '',
	stageIndex = -1,
	setCurrentTemplateData,
	currentTemplateData,
	setTemplateComponentsData,
	templateComponentsData,
	isFileValidation,
	optOutButtonOptions,
	defaultSelection = '',
	isPhoneNumber = false,
}: IENGTTemplateProps) {
	const { t } = useTranslation(['common', 'pages', 'errors', 'components']);

	const FILE_FORMATS_IMAGE = ['image/jpg', 'image/jpeg', 'image/png'];

	const maxLTOOfferCodeTextLimit = 15;

	const FILE_FORMATS_DOCUMENT = ['.pdf', 'application/pdf'];

	const FILE_FORMATS_VIDEO = ['video/mp4', 'video/3gpp'];

	const MAX_VIDEO_SIZE = 16000000;

	const MAX_IMAGE_SIZE = 5000000;

	const botRef = useSelector((state: RootState) => state.Bot.data.bot_ref);

	const isDefaultValueUpdated = useRef<boolean>(false);

	const [openDateTime, toggelDateTime] = useState<boolean>(false);

	const [css, theme]: [any, any] = useStyletron();

	const TEMPLATE_PARAMS_REGEX = new RegExp(/{{\d+}}/gm);

	const PARAM_REGEX = new RegExp(TEMPLATE_PARAMS_REGEX);

	const provider = useSelector((state: RootState) => state.Bot.data.provider_configured);

	const isTemplateCacheEnabled = useSelector(isTemplateCacheEntitlementEnabled);
	const isCreateMessageTemplatesV2Enabled = useSelector(
		(state: RootState) => state.User.botAdmin.data.enable_create_message_templates_v2
	);
	const whatsappAcceleratorTrialEnabled = useSelector(isWhatsappAcceleratorTrialEnabled);
	const messageTemplates = useSelector(messageTemplatesSelector);

	const { register, control, setValue, setError, getValues, handleSubmit, formState, reset, clearErrors } =
		useFormContext();

	const formSelectValidation = (value: any) => {
		if (!value[0]?.id) {
			return t('errors:requiredErrorMessage') as string;
		}

		return true;
	};

	const quickReplyPostbackOptions = [
		{
			name: 'Path',
			value: 'path',
			dataQa: TEMPLATE_MESSAGE.PATH,
		},
		{
			name: 'Path and Attribute',
			value: 'pathAndAttributes',
			dataQa: TEMPLATE_MESSAGE.PATH_ATTRIBUTES,
		},
	];

	const uniqueTemplateMap = new Map();

	const [dataAttributes, setDataAttributes] = useState<Array<{ id: string; label: string }>>([]);
	const [uniqueTemplateOptions, setUniqueTemplateOptions] = useState<Array<{ id: string; label: string }>>([]);
	const [availableLanguages, setAvailableLanguages] = useState<Array<{ id: string; label: string }>>([]);
	const [isUploading, setIsUploading] = useState<boolean>(false);
	const [initialTemplateData, setInitialTemplateData] = useState<IObjProps>({});
	const [isLoading, setLoader] = useState<boolean>(false);
	const [dateValue, setDateValue] = useState<Date>();
	const [hideTemplate, setHideTemplate] = useState<boolean>(false);
	const whatsAppCloudAccessToken = useSelector((state: RootState) => state.Bot.data.nexmo_private_key_contents ?? '');
	const whatsAppBusinessId = useSelector((state: RootState) => state.Bot.data.whatsapp_business_id ?? '');
	const whatsAppApiKey = useSelector((state: RootState) => state.Bot.data.nexmo_application_id);
	const [showPreview, setShowPreview] = useState(whatsappAcceleratorTrialEnabled);
	const [quickReplyPostbackIndex, setQuickReplyPostbackIndex] = useState<number>(-1);

	const RESTRICTED_ATTRIBUTE_TYPES = ['OBJECT', 'LOCATION'];

	enum buttonType {
		quickReply = 'QUICK_REPLY',
		url = 'URL',
		copy_code = 'COPY_CODE',
	}

	enum contentType {
		header = 'HEADER',
		buttons = 'BUTTONS',
	}

	enum OptOutFields {
		BUTTON_LIST = 'buttonList',
	}

	const TEMPLATE_APPROVAL_TIME_IN_MILLI_SECONDS = 1800000;

	const errorMsgCss = {
		fontSize: '0.875rem',
		lineHeight: '1.25rem',
		marginTop: dateTimeError?.length ? '0rem' : '0.5rem',
		color: theme.inputErrorMsgColor,
		textAlign: 'left',
	};

	const setTemplateDataWithIDForD360 = (setTemplates: any, fetchedTemplatesDataWithId: any) => {
		setTemplates(
			fetchedTemplatesDataWithId.map((key: any) => ({
				id: key.name as string,
				label: key.name as string,
				components: key.components,
				namespace: key.namespace as string,
				language: key.language,
				created_at: key.created_at as string,
			}))
		);
	};

	const extractCode = (buttons: IObjProps) => {
		if (buttons.type === 'BUTTONS' && Array.isArray(buttons.buttons)) {
			const copyCodeButton = buttons.buttons.find(
				(button: IObjProps) => button.type.toUpperCase() === buttonType.copy_code
			);
			if (copyCodeButton) {
				return Array.isArray(copyCodeButton.example) ? copyCodeButton.example[0] : copyCodeButton.example;
			}
		}

		return '';
	};

	const loadWhatsAppTemplates = () => {
		setLoading(true);
		if (provider === WHATSAPP_PROVIDERS.dialog360) {
			if (whatsappAcceleratorTrialEnabled) {
				setTemplateDataWithIDForD360(setTemplates, messageTemplates);
			} else if (isTemplateCacheEnabled) {
				API.fetchCachedApprovedTemplates(botRef, PLATFORM.DIALOG360).then((response: any) => {
					if (response.data?.responseObject) {
						setTemplateDataWithIDForD360(setTemplates, response.data?.responseObject?.waba_templates);
						setLoader(true);
					} else {
						setIsErrorState(true);
					}
				});
			} else {
				API.fetchBroadCastTemplates(botRef, PLATFORM.DIALOG360).then((response: any) => {
					if (response.data) {
						setTemplateDataWithIDForD360(setTemplates, response.data?.waba_templates);
						setLoader(true);
					} else {
						setIsErrorState(true);
					}
				});
			}
			setLoading(false);
		} else if (provider === WHATSAPP_PROVIDERS.whatsAppCloud) {
			API.fetchBroadCastTemplates(botRef, 'whatsapp').then((response: any) => {
				if (response.data) {
					setTemplates(
						response.data?.data?.map((key: any) => ({
							id: key.name as string,
							label: key.name as string,
							components: key.components,
							language: key.language,
						}))
					);
				} else {
					setIsErrorState(true);
				}
			});
			setLoading(false);
		} else if (provider === WHATSAPP_PROVIDERS.airtel) {
			airtelResponseHandler();
		}
	};

	const getAllWhatsAppTemplates = () => {
		if (isTemplateCacheEnabled) {
			API.fetchCachedApprovedTemplates(botRef, PLATFORM.DIALOG360, 'all').then((response: IObjProps) => {
				if (response?.data?.responseObject) {
					const foundTemplate = response.data.responseObject?.waba_templates?.find(
						(template: IObjProps) => template.name === initialTemplate
					);

					setInitialTemplateData(foundTemplate);
				}
			});
		} else {
			API.getMessageTemplateDetails(
				provider === WHATSAPP_PROVIDERS.whatsAppCloud ? whatsAppCloudAccessToken : whatsAppApiKey,
				whatsAppBusinessId,
				provider === WHATSAPP_PROVIDERS.whatsAppCloud ? PLATFORM.WHATSAPP : PLATFORM.DIALOG360
			).then((response: IObjProps) => {
				if (isValidResponseObject(response)) {
					const foundTemplate = response.data.responseObject?.waba_templates?.find(
						(template: IObjProps) => template.name === initialTemplate
					);

					setInitialTemplateData(foundTemplate);
				}
			});
		}
	};

	const airtelResponseHandler = () => {
		API.fetchBroadCastTemplates(botRef, WHATSAPP_PROVIDERS.airtel).then((response: any) => {
			if (response.data) {
				setTemplates(
					response.data?.waba_templates?.map(
						({ templateName, components, namespace, language, templateId }: any) => ({
							id: templateName,
							label: templateName,
							components,
							namespace,
							language,
							templateId,
						})
					)
				);
				setLoader(true);
			} else {
				setIsErrorState(true);
			}
		});
		setLoading(false);
	};

	const resetTemplateValues = () => {
		Object.keys(getValues(`${formSuffix}parameters`)).forEach((element: any) => {
			getValues(`${formSuffix}parameters`)[element].forEach((val: any, index: number) => {
				setValue(`${formSuffix}parameters.${element}[${index}]`, '');
			});
		});
		setValue(`${formSuffix}buttons`, [{}]);
	};

	const resetCarouselTemplateValues = () => {
		getValues(`${formSuffix}carousel`)?.forEach((carousel: any, cardIndex: number) => {
			const card = getValues(`${formSuffix}parameters.carousel[${cardIndex}]`);
			// reset header params
			if (carousel?.fileUrl) {
				setValue(`${formSuffix}carousel[${cardIndex}].fileUrl`, '');
			} else if (carousel?.video) {
				setValue(`${formSuffix}carousel[${cardIndex}].video`, '');
			}

			// reset body params
			card?.body?.forEach((body: any, index: number) => {
				setValue(`${formSuffix}parameters.carousel[${cardIndex}].body[${index}]`, '');
			});

			// reset button params
			carousel?.buttons?.forEach((button: any, buttonIndex: number) => {
				setValue(`${formSuffix}carousel[${cardIndex}].buttons[${buttonIndex}].payload`, []);
				setValue(`${formSuffix}carousel[${cardIndex}].buttons[${buttonIndex}].value`, '');
				setValue(`${formSuffix}carousel[${cardIndex}].buttons[${buttonIndex}].url`, '');
				button?.attributes?.forEach((attributes: any, index: number) => {
					setValue(
						`${formSuffix}carousel[${cardIndex}].buttons[${buttonIndex}].attributes[${index}].name`,
						''
					);
					setValue(
						`${formSuffix}carousel[${cardIndex}].buttons[${buttonIndex}].attributes[${index}].value`,
						''
					);
					setValue(
						`${formSuffix}carousel[${cardIndex}].buttons[${buttonIndex}].attributes[${index}].dateValue`,
						''
					);
				});
			});
		});
	};

	const compareArrays = (arr1: Array<IObjProps>, arr2: Array<IObjProps>) => {
		if (arr1.length !== arr2.length) {
			return false;
		}

		return arr1.every((obj1, i) => {
			const obj2 = arr2[i];

			if (obj1.type !== obj2.type) {
				return false;
			}

			if (obj1.type === contentType.header) {
				return obj1.format === obj2.format;
			}

			if (obj1.type === contentType.buttons) {
				if (obj1.buttons.length !== obj2.buttons.length) {
					return false;
				}

				return obj1.buttons.every((button: any, j: any) => button.type === obj2.buttons[j].type);
			}

			return true;
		});
	};

	const checkTemplateStructure = () => {
		if (initialTemplateData?.components && currentTemplate?.components) {
			if (compareArrays(initialTemplateData.components, currentTemplate.components)) {
				setIncorrectTemplateSelected(false);
				setHideTemplate(false);
			} else {
				setIncorrectTemplateSelected(true);
				setValue(`${formSuffix}templateName`, '');
				setHideTemplate(true);
			}
		}
	};

	useWatch({
		control,
		name: [
			'options',
			'selectedPlatforms',
			'broadcastTitle',
			'templateName',
			'fileUrl',
			'parameters',
			'fileUrlDoc',
			'media',
			'mediaDoc',
			'buttonTypeOptions',
			'templateTitle',
			'headerType',
			'quickReplyPostback',
			'templates',
			'uniqueTemplateOptions',
			'language',
			'video',
			'mediaVideo',
		],
	});

	useEffect(() => {
		loadWhatsAppTemplates();
		isResumeMode && getAllWhatsAppTemplates();
	}, []);

	useEffect(() => {
		getValues(`${formSuffix}templateName`) &&
			changeCurrentTemplate(getValues(`${formSuffix}templateName`)?.[0]?.id);
		if (!isCopyMode && !isEditMode && !isResumeMode && !isTemplateNode && !whatsappAcceleratorTrialEnabled) {
			setValue(`${formSuffix}fileUrl`, '');
			setValue(`${formSuffix}fileUrlDoc`, '');
			setValue(`${formSuffix}video`, '');
		}
	}, [getValues(`${formSuffix}templateName`)]);

	useEffect(() => {
		templates?.forEach((template: IObjProps) => {
			uniqueTemplateMap.set(template.id, template.id);
		});
		setUniqueTemplateOptions(Array.from(uniqueTemplateMap, ([key, value]) => ({ id: key, label: value })));
		setIsErrorState(false);
	}, [templates, getValues(`${formSuffix}templateName`)]);

	useEffect(() => {
		if (templateLanguage) {
			changeCurrentTemplateLanguage(templateLanguage.templateName, templateLanguage.languageCode);
		}
	}, [templateLanguage]);

	useEffect(() => {
		if (dateValue) {
			const epochTimestamp = dateValue.getTime();
			setValue(`${formSuffix}expirationDateTime`, epochTimestamp);
			setDateTimeError && setDateTimeError('');
		} else {
			setValue(`${formSuffix}expirationDateTime`, '');
		}
	}, [dateValue]);

	useEffect(() => {
		if (availableLanguages?.length === 1) {
			setValue(`${formSuffix}tempLanguage`, availableLanguages[0].id);
			setValue(`${formSuffix}language`, [availableLanguages[0]]);
		}
	}, [availableLanguages]);

	useEffect(() => {
		const header: any = fetchComponentData('HEADER');
		const body: any = fetchComponentData('BODY');
		const buttons: any = fetchComponentData('BUTTONS');
		const carousel: any = fetchComponentData('CAROUSEL');
		const lto: any = fetchComponentData(Content.LTO);

		const components: Array<any> = [];
		header && components.push(header?.format);
		if (body?.text.match(TEMPLATE_PARAMS_REGEX)) {
			body && components.push('body');
		}
		buttons && components.push('buttons');
		if (carousel) {
			components.push('carousel');
			carousel?.cards?.map((card: any, index: number) => {
				const cardHeader: any = fetchCardComponentData(card, 'HEADER');
				const cardBody: any = fetchCardComponentData(card, 'BODY');
				const cardButtons: any = fetchCardComponentData(card, 'BUTTONS');

				cardHeader && components.push(`card${index}.${cardHeader?.format}`);
				if (cardBody && cardBody.text.match(TEMPLATE_PARAMS_REGEX)) {
					components.push(`card${index}.body`);
				}
				cardButtons && components.push(`card${index}.buttons`);
			});
		}
		if (lto) {
			components.push(templateComponentParameters.lto);
			if (lto.limited_time_offer && lto.limited_time_offer.has_expiration) {
				components.push(templateComponentParameters.expiration);
			}
			!isEditMode && setValue(`${formSuffix}offerCode`, extractCode(buttons));
		}

		if (formSuffix) {
			const data: IObjProps = {};
			data[`${stageIndex}`] = components;
			setTemplateComponentsData({ ...templateComponentsData, ...data });
		} else {
			setTemplateComponents(components);
		}
		setValue(`${formSuffix}templateComponent`, components);

		if (formSuffix && currentTemplateData) {
			setValue(`${formSuffix}templateNamespace`, currentTemplateData[stageIndex]?.namespace);
		} else {
			setValue(`${formSuffix}templateNamespace`, currentTemplate?.namespace);
		}
		if (!isEditMode && !isCopyMode && !isResumeMode && !whatsappAcceleratorTrialEnabled) {
			setValue(`${formSuffix}parameters`, {});
			setValue(`${formSuffix}buttons`, [{}]);
			setValue(`${formSuffix}fileUrl`, '');
			setValue(`${formSuffix}fileUrlDoc`, '');
			setValue(`${formSuffix}video`, '');
		}
		isResumeMode && checkTemplateStructure();

		setValue(`${formSuffix}templateIsPhoneNumber`, isPhoneNumber);
	}, [currentTemplate, currentTemplateData?.[stageIndex]]);

	useEffect(() => {
		setDataAttributes(
			Object.keys(flowAttributes)
				.filter(
					(key) =>
						flowAttributes[key]?.id?.startsWith('attr_') &&
						flowAttributes[key]?.name !== 'user_whatsapp.opted_out'
				)
				.map((e: any) => ({
					id: flowAttributes[e]?.id,
					label: flowAttributes[e]?.name,
				}))
				.filter((attribute) => !RESTRICTED_ATTRIBUTE_TYPES.includes(getAttributeType(attribute.id)))
		);
	}, [flowAttributes]);

	const fileUpload = (
		acceptedFormats: any,
		fileName: string,
		file: string,
		fileSize: number,
		cardIndexId?: string
	) => (
		<FileUploader
			onDropAccepted={(acceptedFile) => {
				setValue(`${formSuffix}${file}`, acceptedFile);
				clearErrors(`${formSuffix}${cardIndexId}${fileName}`);
				setIsUploading(true);
				IMAGE_UPLOAD_API.postAttchment(acceptedFile[0], provider).then((resp: any) => {
					if (resp.data) {
						setValue(`${formSuffix}${cardIndexId}${fileName}`, resp.data.url);
						clearErrors(`${formSuffix}${cardIndexId}${fileName}`);
					} else {
						setValue(`${formSuffix}${cardIndexId}${fileName}`, '');
						toaster.negative(
							<ENGTToasterContainer
								title={t('common:error')}
								description={t('components:node.sendImage.failToUpload')}
							/>,
							{}
						);
					}
					setIsUploading(false);
				});
			}}
			onDropRejected={(rejectedFile) => {
				if (isFileValidation) {
					setValue(`${formSuffix}${cardIndexId}${fileName}`, rejectedFile[0].name);
					setValue(`${formSuffix}${file}`, rejectedFile);
					setError(`${formSuffix}${cardIndexId}${fileName}`, {
						type: 'validate',
						message: t('errors:fileSizeError'),
					});
				} else {
					toaster.negative(
						<ENGTToasterContainer title={t('common:failed')} description={t('errors:fileSizeTooLong')} />,
						{}
					);
				}
				setIsUploading(false);
			}}
			maxSize={fileSize}
			accept={acceptedFormats}
			overrides={{
				Root: {
					style: {
						backgroundColor: 'transparent',
					},
				},
				FileDragAndDrop: {
					style: {
						backgroundColor: 'transparent',
						borderBottomStyle: 'none',
						borderLeftStyle: 'none',
						borderRightStyle: 'none',
						borderTopStyle: 'none',
						paddingBottom: '0px',
						paddingRight: '0px',
						paddingTop: '0px',
					},
				},
				ContentMessage: {
					style: {
						display: 'none',
					},
				},
				ButtonComponent: {
					props: {
						overrides: {
							BaseButton: (props: any) => (
								<ENGTButton
									kind={KIND['secondary']}
									size={SIZE.compact}
									label={t('common:browse')}
									startEnhancer={<AddIcon fillColor={theme.colors.primaryA} size={18} />}
									className={css({
										minWidth: '20%',
										fontWeight: '500 !important',
									})}
									dataQa={TEMPLATE_MESSAGE.BROWSE}
									{...props}
								/>
							),
						},
					},
				},
			}}
		/>
	);

	let changeCurrentTemplate = (name: any) => {
		setTemplatePayload && setTemplatePayload({});
		setAvailableLanguages(
			templates
				.filter((filterTemplate: any) => filterTemplate.label === name)
				.map((template: IObjProps) => ({
					id: template.language,
					label: template.language.startsWith('en') ? 'English' : languageCodeMapper[template.language],
				}))
		);
		if (formSuffix) {
			const template: IObjProps = {};
			template[`${stageIndex}`] = templates.find(({ label }: any) => label === name);
			setCurrentTemplateData({ ...currentTemplateData, ...template });
		} else {
			setCurrentTemplate(templates.find(({ label }: any) => label === name));
		}
	};

	let changeCurrentTemplateLanguage = (name: any, languageCode: any) => {
		setTemplatePayload && setTemplatePayload({});
		if (formSuffix) {
			const template: IObjProps = {};
			template[`${stageIndex}`] = templates.find(
				({ label, language }: any) => label === name && language === languageCode
			);
			setCurrentTemplateData({ ...currentTemplateData, ...template });
		} else {
			setCurrentTemplate(
				templates.find(({ label, language }: any) => label === name && language === languageCode)
			);
		}
		setValue(`${formSuffix}tempLanguage`, languageCode);
	};

	let getAttributeType = (attrId: string) => {
		let attributeType = '';
		flowAttributes?.forEach((attribute: any) => {
			if (attribute.id === attrId) {
				attributeType = attribute.type;
			}
		});

		return attributeType;
	};

	const onTemplateNameChange = (onChange: (val: Array<ISelectValue>) => void) => (value: Array<ISelectValue>) => {
		const targetWhatsAppNumber = getValues('targetWhatsAppNumber');
		isTemplateNode &&
			reset({
				targetWhatsAppNumber,
				templateName: value,
			});
		if (isBroadcast && !formSuffix) {
			resetCarouselTemplateValues();
			resetTemplateValues();
			reset(getValues());
			setTemplateLanguage(null);
		}
		methods.setValue(
			OptOutFields.BUTTON_LIST,
			optOutButtonOptions?.filter((optedOut) => optedOut.id === OPT_OUT_BUTTON_OPTION.NONE)
		);
		onChange(value);
		setShowPreview(true);
	};

	useEffect(() => {
		const isWATemplatePreSelected =
			defaultSelection && uniqueTemplateOptions?.length && !isDefaultValueUpdated.current;

		if (isWATemplatePreSelected) {
			onTemplateNameChange((value: Array<ISelectValue>) => {
				setValue(`${formSuffix}templateName`, value);
			})([
				{
					id: defaultSelection,
					label: defaultSelection,
				},
			]);
			isDefaultValueUpdated.current = true;
		}
	}, [defaultSelection, uniqueTemplateOptions]);

	const renderTemplateHeader = (component: any, cardIndex?: number) => {
		const cardIndexId = cardIndex !== undefined ? `carousel[${cardIndex}].` : '';
		if (component.format === componentFormat.text) {
			const params = component.text.match(TEMPLATE_PARAMS_REGEX);

			return renderTemplateParams(params, 'header');
		}

		if (component.format === componentFormat.image) {
			return (
				<Controller
					name={`${formSuffix}${cardIndexId}fileUrl`}
					control={control}
					rules={{
						required: t('errors:requiredErrorMessage').toString(),
						validate: (value) => {
							if (!isTemplateNode && !validateImageUrl(value)) {
								return t('errors:invalidMediaUrl', { mediaType: 'Image' }).toString();
							}
						},
					}}
					defaultValue={getValues(`${formSuffix}${cardIndexId}fileUrl`)}
					render={({ ref, value, onChange }) =>
						isTemplateNode ? (
							<ENGTMentions
								data={pathVariables}
								inputRef={ref}
								isQuill={false}
								fieldVal={value}
								id={`${formSuffix}${cardIndexId}fileUrl`}
								callback={(data: any) => setValue(`${formSuffix}${cardIndexId}fileUrl`, data)}
							>
								<FormInput
									clearable
									inputRef={ref}
									errorCondition={{
										required: 'Required',
									}}
									value={value || ''}
									defaultValue={value || ''}
									name={`${formSuffix}${cardIndexId}fileUrl`}
									endEnhancer={
										value ? (
											<></>
										) : isUploading ? (
											<Loader loaderBlockHeight='1px' />
										) : (
											fileUpload(
												FILE_FORMATS_IMAGE,
												'fileUrl',
												'media',
												MAX_IMAGE_SIZE,
												cardIndexId
											)
										)
									}
									fontSize='0.875rem'
									label={t('pages:broadcast.whatsapp.imageHeader')}
									placeholder={t('pages:broadcast.whatsapp.documentPlaceholder')}
									onChange={onChange}
									dataQa={TEMPLATE_MESSAGE.FILE_URL}
								/>
							</ENGTMentions>
						) : (
							<FormInput
								clearable
								name={`${formSuffix}${cardIndexId}fileUrl`}
								onChange={(e: any) => {
									onChange(e);
								}}
								inputRef={ref}
								endEnhancer={
									value ? (
										<></>
									) : isUploading ? (
										<Loader loaderBlockHeight='1px' />
									) : (
										fileUpload(FILE_FORMATS_IMAGE, 'fileUrl', 'media', MAX_IMAGE_SIZE, cardIndexId)
									)
								}
								label={t('pages:broadcast.whatsapp.imageHeader')}
								value={value}
								placeholder={t('pages:broadcast.whatsapp.documentPlaceholder')}
								dataQa={TEMPLATE_MESSAGE.FILE_URL}
							/>
						)
					}
				/>
			);
		}
		if (component.format === componentFormat.documentType) {
			return (
				<Controller
					name={`${formSuffix}fileUrlDoc`}
					control={control}
					rules={{
						required: t('errors:requiredErrorMessage').toString(),
					}}
					defaultValue={getValues(`${formSuffix}fileUrlDoc`)}
					render={({ ref, value, onChange }) => (
						<FormInput
							clearable
							name={`${formSuffix}fileUrlDoc`}
							onChange={onChange}
							inputRef={ref}
							endEnhancer={
								value ? (
									<></>
								) : isUploading ? (
									<Loader loaderBlockHeight='1px' />
								) : (
									fileUpload(
										FILE_FORMATS_DOCUMENT,
										'fileUrlDoc',
										'mediaDoc',
										ALLOWED_ATTACHMENT_SIZE,
										cardIndexId
									)
								)
							}
							label={t('pages:broadcast.whatsapp.documentHeader')}
							placeholder={t('pages:broadcast.whatsapp.documentPlaceholder')}
							dataQa={TEMPLATE_MESSAGE.FILE_URL_DOC}
							value={value}
						/>
					)}
				/>
			);
		}
		if (component.format === componentFormat.video) {
			return (
				<Controller
					name={`${formSuffix}${cardIndexId}video`}
					control={control}
					rules={{
						validate: (value) => {
							if (!value) {
								return t('errors:requiredErrorMessage').toString();
							}

							return API.getFileSize(value).then((resp: any) => {
								const fileSize = resp?.data?.size?.toFixed(2);
								if (!validateVideoUrl(value)) {
									return t('errors:invalidMediaUrl', { mediaType: 'Video' }).toString();
								}
								if (!(resp?.status >= 200 && resp?.status < 300)) {
									return t('errors:invalidMediaUrl', { mediaType: 'Video' }) as string;
								}
								if (
									resp?.status >= 200 &&
									resp?.status < 300 &&
									getSizeInBytes(fileSize) > MAX_VIDEO_SIZE // valid file size 16MB
								) {
									setValue(`${formSuffix}${cardIndexId}video`, '');
									toaster.negative(
										<ENGTToasterContainer
											title={t('common:failed')}
											description={t('errors:fileSizeTooLong')}
										/>,
										{}
									);

									return false;
								}
							});
						},
					}}
					defaultValue={getValues(`${formSuffix}${cardIndexId}video`)}
					render={({ ref, value, onChange }) => (
						<FormInput
							clearable
							name={`${formSuffix}${cardIndexId}video`}
							inputRef={ref}
							onChange={onChange}
							endEnhancer={
								value ? (
									<></>
								) : isUploading ? (
									<Loader loaderBlockHeight='1px' />
								) : (
									fileUpload(FILE_FORMATS_VIDEO, 'video', 'mediaVideo', MAX_VIDEO_SIZE, cardIndexId)
								)
							}
							label={t('pages:broadcast.whatsapp.videoHeader')}
							placeholder={t('pages:broadcast.whatsapp.documentPlaceholder')}
							dataQa={TEMPLATE_MESSAGE.VIDEO}
							value={value}
						/>
					)}
				/>
			);
		}
	};

	const renderTemplateBody = (component: any, cardIndex?: number) => {
		let params: any = new Set(component.text.match(TEMPLATE_PARAMS_REGEX));
		params = [...params];

		return renderTemplateParams(params, 'body', cardIndex);
	};

	const pathAsQuickReplyButton = (index: number, cardIndexId?: string) => (
		<Controller
			control={control}
			name={`${formSuffix}${cardIndexId}buttons[${index}].payload`}
			rules={{
				validate: (value) => formSelectValidation(value),
				required: t('errors:requiredErrorMessage').toString(),
			}}
			defaultValue={getValues(`${formSuffix}${cardIndexId}buttons[${index}].payload`)}
			render={({ ref, value, onChange }) => (
				<FormSelect
					name={`${formSuffix}${cardIndexId}buttons[${index}].payload`}
					inputRef={ref}
					options={availableFlows}
					popupIndex={theme.zIndex400}
					placeholder={t('pages:broadcast.basic.placeholder.selectName')}
					selectedValue={value}
					onChange={onChange}
					label={t('pages:broadcast.basic.placeholder.selectPath')}
					dataQa={`buttons-payload-${index}`}
					searchable
				/>
			)}
		/>
	);

	const attributesAsQuickReplyButton = (buttonIndex: number, cardIndexId: string) => (
		<AttributesAsQuickReply
			buttonIndex={buttonIndex}
			dataAttributes={dataAttributes}
			flowAttributes={flowAttributes}
			formSuffix={formSuffix}
			cardIndexId={cardIndexId}
		/>
	);

	useEffect(() => {
		if (quickReplyPostbackIndex !== -1) {
			let currentButtonValue = getValues(`${formSuffix}buttons[${quickReplyPostbackIndex}]`);
			if (currentButtonValue) {
				currentButtonValue = Object.keys(currentButtonValue)
					.filter((key) => key !== 'attributes')
					.reduce((obj: any, key: any) => {
						obj[key] = currentButtonValue[key];

						return obj;
					}, {});
				setValue(`${formSuffix}buttons[${quickReplyPostbackIndex}]`, currentButtonValue);
			}
			setQuickReplyPostbackIndex(-1);
		}
	}, [quickReplyPostbackIndex]);

	const renderTemplateButtons = (component: any, cardIndex?: number) =>
		component?.buttons?.map((button: any, index: number) => {
			const cardIndexId = cardIndex !== undefined ? `carousel[${cardIndex}].` : '';
			const isParamUrl = button?.url?.toString().match(PARAM_REGEX);
			setValue(`${formSuffix}${cardIndexId}buttons[${index}].text`, button?.text);
			setValue(`${formSuffix}${cardIndexId}buttons[${index}].type`, button?.type);
			if (button.type === buttonType.url && isParamUrl) {
				register(`${formSuffix}parameters.${cardIndexId}button[${index}]`);
				setValue(
					`${formSuffix}${cardIndexId}buttons[${index}].url`,
					button.url.substring(0, button.url.length - 5)
				);
				const buttonUrl = getValues(`${formSuffix}${cardIndexId}buttons[${index}].url`);
				const isStaticUrl = checkIfValidUrl(buttonUrl, getTemplateCTAStaticUrlSlug(buttonUrl));
				const buttonText = getValues(`${formSuffix}${cardIndexId}buttons[${index}].value`);
				if (!buttonText || buttonText === '/') {
					setValue(`${formSuffix}${cardIndexId}buttons[${index}].value`, isStaticUrl ? '/' : '');
				}

				return (
					!isStaticUrl && (
						<Controller
							control={control}
							name={`${formSuffix}${cardIndexId}buttons[${index}].value`}
							defaultValue={getValues(`${formSuffix}${cardIndexId}buttons[${index}].value`)}
							rules={{
								validate: (value) => {
									if (value) {
										return tagsNotAllowed(t, value, false);
									}

									return true;
								},
								required: t('errors:requiredErrorMessage').toString(),
							}}
							render={({ ref, value, onChange }: any) => (
								<ENGTMentions
									data={pathVariables}
									inputRef={ref}
									isQuill={false}
									fieldVal={value}
									id={`${formSuffix}${cardIndexId}buttons[${index}].value`}
									callback={(data: any) => {
										setValue(`${formSuffix}${cardIndexId}buttons[${index}].value`, data);
									}}
								>
									<FormLabel
										id={`${formSuffix}${cardIndexId}buttons[${index}].value`}
										label={t(`pages:broadcast.whatsapp.button`, {
											number: index + 1,
										}).toString()}
										className={{ textAlign: 'left' }}
									/>
									<ENGTInput
										disabled
										name={`${formSuffix}${cardIndexId}buttonsUrl[${index}].value`}
										placeholder={
											checkIfValidUrl(button?.url)
												? decodeURIComponent(getTemplateUrlBasedOnCTAVersion(button?.url))
												: button.url.substring(0, button.url.length - TEMPLATE_VARIABLE_LENGTH)
										}
										overrides={{
											Root: {
												style: {
													borderBottomRightRadius: 0,
													borderBottomLeftRadius: 0,
												},
											},
										}}
									/>
									<FormInput
										errorCondition={{
											required: 'Required',
										}}
										key={index}
										value={value || ''}
										inputRef={ref}
										name={`${formSuffix}${cardIndexId}buttons[${index}].value`}
										id={`${formSuffix}${cardIndexId}buttons[${index}].value`}
										fontSize='0.875rem'
										marginBottom='0.5rem'
										placeholder={t(`pages:broadcast.whatsapp.enterUrl`)}
										overrides={{
											Root: {
												style: {
													borderTopRightRadius: 0,
													borderTopLeftRadius: 0,
												},
											},
										}}
										onChange={(value: string) => {
											setValue(`${formSuffix}parameters.${cardIndexId}button[${index}]`, value);
											onChange(value);
										}}
										dataQa={`buttons-value-${index}`}
									/>
								</ENGTMentions>
							)}
						/>
					)
				);
			}
			if (button.type === buttonType.quickReply) {
				return (
					<div>
						<FormLabel
							id={`${cardIndexId}label${index}`}
							label={`${t(`pages:broadcast.whatsapp.button`, {
								number: index + 1,
							}).toString()} - ${button.text}`}
							className={{ textAlign: 'left' }}
						/>
						<FormRadio
							name={`${formSuffix}${cardIndexId}quickReplyPostback[${index}]`}
							radioButtons={quickReplyPostbackOptions}
							initialValue={
								getValues(`${formSuffix}${cardIndexId}quickReplyPostback[${index}]`) || 'path'
							}
							onChange={(value: any) => {
								setQuickReplyPostbackIndex(index);
							}}
							align='horizontal'
						/>
						{(getValues(`${formSuffix}${cardIndexId}quickReplyPostback[${index}]`) === 'path' ||
							!getValues(`${formSuffix}${cardIndexId}quickReplyPostback[${index}]`)) &&
							pathAsQuickReplyButton(index, cardIndexId)}
						{/* {getValues('quickReplyPostback[' + index + ']') === 'attributes' &&
							attributesAsQuickReplyButton(index)} */}
						{getValues(`${formSuffix}${cardIndexId}quickReplyPostback[${index}]`) === 'pathAndAttributes' &&
							pathAndAttributesAsQuickReplyButton(index, cardIndexId)}
					</div>
				);
			}
			if (button.type === buttonType.copy_code) {
				setValue(`${formSuffix}${cardIndexId}buttons[${index}].copy_code`, true);
			}

			return <></>;
		});

	let renderTemplateParams = (parameters: Array<any>, name: string, cardIndex?: number) =>
		parameters?.map((parameter, index) => {
			const cardIndexId = cardIndex !== undefined ? `carousel[${cardIndex}].` : '';

			return (
				<div key={`${name}.${index}`}>
					<Controller
						defaultValue={getValues(`${formSuffix}parameters.${cardIndexId}${name}[${index}]`) || ''}
						control={control}
						name={`${formSuffix}parameters.${cardIndexId}${name}[${index}]`}
						rules={{
							validate: (value) => {
								if (value) {
									return tagsNotAllowed(t, value, true);
								}

								return true;
							},
							required: t('errors:requiredErrorMessage').toString(),
						}}
						render={({ ref, value, onChange }: any) => (
							<ENGTMentions
								data={pathVariables}
								inputRef={ref}
								isQuill={false}
								fieldVal={value}
								id={`${formSuffix}parameters.${cardIndexId}${name}[${index}]`}
								callback={(data: any) => {
									setValue(`${formSuffix}parameters.${cardIndexId}${name}[${index}]`, data);
								}}
							>
								<FormInput
									inputRef={ref}
									errorCondition={{
										required: 'Required',
									}}
									value={value || ''}
									defaultValue={value || ''}
									name={`${formSuffix}parameters.${cardIndexId}${name}[${index}]`}
									fontSize='0.875rem'
									marginBottom='0.5rem'
									label={
										name === 'header'
											? (t(`pages:broadcast.whatsapp.headerParameter`, {
													number: index + 1,
												}) as string)
											: (t(`pages:broadcast.whatsapp.bodyParameter`, {
													number: index + 1,
												}) as string)
									}
									placeholder={`${t(
										'pages:broadcast.basic.placeholder.valuePlaceholder'
									)} ${parameter}`}
									onChange={onChange}
									dataQa={
										cardIndex !== undefined
											? `param-card-${cardIndex}-${name}-${index}`
											: `param-${name}-${index}`
									}
								/>
							</ENGTMentions>
						)}
					/>
				</div>
			);
		});

	const pathAndAttributesAsQuickReplyButton = (index: number, cardIndexId: string) => (
		<div>
			{pathAsQuickReplyButton(index, cardIndexId)}
			{attributesAsQuickReplyButton(index, cardIndexId)}
		</div>
	);
	const borderColor = dateTimeError?.length ? theme.colors.inputBorderError : theme.colors.horizantalDividerColor;

	const renderSpan = (value: string, limit: number) => {
		const textLength = (value && extractTextFromHtml(value).length + newLineCountFn(value)) || 0;

		return (
			<span
				className={css({
					marginTop: '0.5rem',
					fontSize: '0.875rem',
					marginRight: '0.75rem',
					color: textLength <= limit ? theme.colors.accent50 : theme.failure,
				})}
			>
				{`${textLength}/${limit}`}
			</span>
		);
	};

	let fetchComponentData = (type: string) => {
		if (formSuffix && currentTemplateData) {
			return currentTemplateData[stageIndex]?.components.filter((component: any) => component.type === type)[0];
		}

		return currentTemplate?.components.filter((component: any) => component.type === type)[0];
	};

	const fetchCardComponentData = (card: any, type: string) =>
		card?.components.filter((component: any) => component.type === type)[0];

	const renderCarouselTemplate = (carousel: any) => {
		const cards: any = carousel?.cards;

		return (
			<ENGTAccordion expandedPanel='0' overrides={{ root: { marginBottom: '1rem' } }}>
				{cards.map((card: any, index: number) => {
					const header: any = fetchCardComponentData(card, 'HEADER');
					const body: any = fetchCardComponentData(card, 'BODY');
					const buttons: any = fetchCardComponentData(card, 'BUTTONS');

					return (
						<Panel
							title={
								<p
									className={css({
										color: theme.colors.primaryA,
										marginTop: '0rem',
										marginBottom: '0rem',
									})}
								>
									{t('components:node.carousel.carouselCard', { value: index + 1 })}
								</p>
							}
						>
							{getValues(`${formSuffix}language`) && header && renderTemplateHeader(header, index)}
							{getValues(`${formSuffix}language`) && body && renderTemplateBody(body, index)}
							{getValues(`${formSuffix}language`) && buttons && renderTemplateButtons(buttons, index)}
						</Panel>
					);
				})}
			</ENGTAccordion>
		);
	};

	const renderLTOTemplate = (lto: any) => {
		const expiration: any = lto?.limited_time_offer.has_expiration;

		return (
			<>
				<Controller
					name={`${formSuffix}offerCode`}
					control={control}
					rules={{
						validate: (value) => {
							if (!value) {
								return t('errors:requiredErrorMessage') as string;
							}
							if (value.includes(' ')) {
								return t('errors:spacesNotAllowed') as string;
							}

							return true;
						},
						maxLength: {
							value: maxLTOOfferCodeTextLimit,
							message: t('errors:maxLengthLimit', { maxLength: maxLTOOfferCodeTextLimit }),
						},
					}}
					render={({ ref, value, onChange }) => (
						<>
							<div
								className={css({
									fontSize: '1rem',
									color: theme.colors.primaryA,
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-between',
								})}
							>
								<span>{t('pages:broadcast.messageTemplates.createTemplate.lto.offerCode')}</span>
								{renderSpan(value, maxLTOOfferCodeTextLimit)}
							</div>
							<FormInput
								clearable
								name={`${formSuffix}offerCode`}
								onChange={onChange}
								inputRef={ref}
								placeholder={t(
									'pages:broadcast.messageTemplates.createTemplate.lto.offerCodePlaceholder'
								)}
								dataQa={TEMPLATE_MESSAGE.OFFER_CODE}
								value={value}
							/>
						</>
					)}
				/>
				{expiration && (
					<>
						<LabelMedium
							marginBottom='0.5rem'
							color={theme.colors.modalTextColor}
							className={css({ textAlign: 'left' })}
						>
							{t('pages:broadcast.messageTemplates.createTemplate.lto.OfferExpiryText2')}
						</LabelMedium>
						<div
							onClick={() => toggelDateTime(true)}
							className={css({
								display: 'flex',
								justifyContent: 'space-between',
								paddingLeft: '1rem',
								marginBottom: dateTimeError?.length ? '0.5rem' : '1rem',
								paddingRight: '1rem',
								paddingTop: '1rem',
								paddingBottom: '1rem',
								borderTopWidth: '1px',
								borderLeftWidth: '1px',
								borderRightWidth: '1px',
								borderBottomWidth: '1px',
								borderStyle: 'solid',
								backgroundColor: theme.colors.primaryB,
								borderTopColor: borderColor,
								borderBottomColor: borderColor,
								borderLeftColor: borderColor,
								borderRightColor: borderColor,
								borderTopRightRadius: '0.5rem!important',
								borderTopLeftRadius: '0.5rem!important',
								borderBottomRightRadius: '0.5rem!important',
								borderBottomLeftRadius: '0.5rem!important',
								cursor: 'pointer',
							})}
						>
							<LabelMedium>
								{dateValue ? (
									`${dateValue?.toLocaleDateString?.()} ${dateValue?.toLocaleTimeString?.('en-US', {
										hour: '2-digit',
										minute: '2-digit',
										hour12: true,
									})}`
								) : (
									<span
										className={css({
											color: hexToRgb(theme.colors.accent50, 0.75),
										})}
									>
										{t('components:getProfessionalHelpModal.selectDateTime')}
									</span>
								)}
							</LabelMedium>
							<ENGTImage lazy src={datePickerIcon} alt='date-picker' />
						</div>
						{dateTimeError && dateTimeError.length > 0 && (
							<div className={css(errorMsgCss)}>{dateTimeError}</div>
						)}
					</>
				)}
			</>
		);
	};

	const renderTemplate = () => {
		const header: any = fetchComponentData('HEADER');
		const body: any = fetchComponentData('BODY');
		const buttons: any = fetchComponentData('BUTTONS');
		const carousel: any = fetchComponentData('CAROUSEL');
		const lto: any = fetchComponentData('LIMITED_TIME_OFFER');

		return (
			<>
				{availableLanguages?.length > 1 && renderLanguageDropdown()}
				{getValues(`${formSuffix}language`) && header && renderTemplateHeader(header)}
				{getValues(`${formSuffix}language`) && body && renderTemplateBody(body)}
				{getValues(`${formSuffix}language`) && buttons && renderTemplateButtons(buttons)}
				{getValues(`${formSuffix}language`) && carousel && renderCarouselTemplate(carousel)}
				{getValues(`${formSuffix}language`) && lto && buttons && renderLTOTemplate(lto)}
			</>
		);
	};

	let renderLanguageDropdown = () => (
		<Controller
			control={control}
			name={`${formSuffix}language`}
			rules={{
				required: t('errors:requiredErrorMessage').toString(),
			}}
			defaultValue={getValues(`${formSuffix}language`)}
			render={({ ref, value, onChange }) => (
				<FormSelect
					name={`${formSuffix}language`}
					inputRef={ref}
					options={availableLanguages}
					placeholder={t('pages:broadcast.basic.selectLanguage')}
					popupIndex={theme.zIndex400}
					selectedValue={value}
					onChange={(language) => {
						const targetWhatsAppNumber = getValues('targetWhatsAppNumber');
						if (formSuffix) {
							setTemplateLanguage({
								templateName: currentTemplateData[stageIndex].id,
								languageCode: language?.[0]?.id,
							});
						} else {
							setTemplateLanguage({
								templateName: currentTemplate.id,
								languageCode: language?.[0]?.id,
							});
						}
						isTemplateNode &&
							reset({
								templateName: getValues(`${formSuffix}templateName`),
								language,
								targetWhatsAppNumber,
							});
						if (isBroadcast && !formSuffix) {
							resetCarouselTemplateValues();
							resetTemplateValues();
							reset(getValues());
						}

						onChange(language);
					}}
					marginBottom='0.5rem'
					label={t('pages:broadcast.basic.selectLanguage')}
					dataQa={TEMPLATE_MESSAGE.SELECT_LANGUAGE}
				/>
			)}
		/>
	);

	const showPreviewButton = () => (
		<div
			className={css({
				marginTop: '1rem',
				paddingTop: '1rem',
				borderTopStyle: 'solid',
				borderTopWidth: '0.03rem',
				borderBottomWidth: '0.03rem',
				borderBottomStyle: 'solid',
				borderTopColor: theme.dividerDarkColor,
				borderBottomColor: theme.dividerDarkColor,
				marginBottom: '0.5rem',
				paddingBottom: '1rem',
			})}
		>
			<ENGTButton
				onClick={() => setShowPreview(true)}
				kind={KIND.primary}
				size={SIZE.compact}
				label={t('pages:broadcast.messageTemplates.createTemplate.showPreview')}
				className={css({ display: 'block', marginTop: '1rem', marginBottom: '1rem' })}
			/>
		</div>
	);

	const showTemplateWarning = () => (
		<div>
			{currentTemplate?.created_at &&
				new Date().valueOf() - new Date(currentTemplate.created_at).valueOf() <
					TEMPLATE_APPROVAL_TIME_IN_MILLI_SECONDS && (
					<p className={css(errorMsgCss)}>
						<b>{t('errors:templateCaution')}</b>
						{t('errors:templateConfirmation')}
					</p>
				)}
		</div>
	);

	return (
		<>
			<Controller
				control={control}
				name={`${formSuffix}templateName`}
				rules={{
					validate: (value) => formSelectValidation(value),
					required: t('errors:requiredErrorMessage').toString(),
				}}
				defaultValue={getValues(`${formSuffix}templateName`)}
				render={({ ref, value, onChange }) => (
					<div>
						<FormSelect
							name={`${formSuffix}templateName`}
							inputRef={ref}
							options={uniqueTemplateOptions}
							placeholder={t('pages:broadcast.basic.selectTemplate')}
							popupIndex={theme.zIndex400}
							selectedValue={value}
							onChange={onTemplateNameChange(onChange)}
							marginLeft='0.05rem'
							marginBottom='1rem'
							label={t('pages:broadcast.basic.selectTemplate')}
							dataQa={TEMPLATE_MESSAGE.TEMPLATE_NAME}
							searchable
							noResultsMsg={!isLoading ? <Loader loaderBlockHeight='50%' /> : 'no results'}
						/>
						{showTemplateWarning()}
					</div>
				)}
			/>
			{openDateTime && (
				<RenderDateTimePicker
					openDateTime={openDateTime}
					toggelDateTime={toggelDateTime}
					setDateValue={setDateValue}
					dateValue={dateValue}
				/>
			)}

			{!hideTemplate &&
				(formSuffix ? currentTemplateData && renderTemplate() : currentTemplate && renderTemplate())}

			{!isCreateMessageTemplatesV2Enabled &&
				!hideTemplate &&
				(getValues(`${formSuffix}templateName`) && getValues(`${formSuffix}language`) && !showPreview ? (
					showPreviewButton()
				) : (
					<div
						className={css({
							marginTop: '1rem',
							paddingTop: '1rem',
							borderTopStyle: 'solid',
							borderTopWidth: '0.5px',
							borderBottomWidth: '0.5px',
							borderBottomStyle: 'solid',
							borderTopColor: theme.dividerDarkColor,
							borderBottomColor: theme.dividerDarkColor,
							paddingBottom: '1rem',
							marginBottom: '0.5rem',
						})}
					>
						<div style={{ display: 'flex', justifyContent: 'left' }}>
							<TemplatePreview
								template={formSuffix ? currentTemplateData : currentTemplate}
								values={getValues}
								addMargin
								formSuffix={formSuffix}
								stageIndex={stageIndex}
							/>
						</div>
					</div>
				))}
		</>
	);
}
export default WhatsappTemplateMessage;
