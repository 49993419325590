import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { useTranslation } from 'react-i18next';

import FormLabel from 'components/UI/Form/FormLabel/FormLabel';

interface ICart {
	backgroundColor: string;
	data: any;
}

function Cart({ backgroundColor, data }: ICart) {
	const cartCss = {
		display: 'flex',
		paddingLeft: '0.5rem',
		alignItems: 'flex-end',
	};

	const { t } = useTranslation(['components', 'common']);

	const [css, theme]: [any, any] = useStyletron();
	const { productItems: cartItems, message } = data;

	let totalItems = 0;
	let totalPrice = 0;

	const cartImageUrl = cartItems[0]?.image_url;
	const cartCurrency = cartItems[0]?.currency;

	cartItems?.map(
		(cartItem: any) => (
			(totalItems += parseInt(cartItem?.quantity)),
			(totalPrice += parseInt(cartItem?.quantity) * parseFloat(cartItem?.item_price))
		)
	);

	return (
		<Block
			className={css({
				display: 'flex',
				flexDirection: 'column',
				backgroundColor,
				width: '13.875rem',
				paddingTop: '1rem',
				paddingBottom: '1rem',
				borderTopLeftRadius: '0.5rem',
				borderTopRightRadius: '0.5rem',
				borderBottomLeftRadius: '0.5rem',
				borderBottomRightRadius: '0.5rem',
			})}
		>
			<div className={css(cartCss)}>
				<img
					src={cartImageUrl}
					alt='Product'
					className={css({
						margin: '0.5rem',
						width: '5rem',
						height: '5rem',
						borderTopLeftRadius: '0.5rem',
						borderTopRightRadius: '0.5rem',
						borderBottomLeftRadius: '0.5rem',
						borderBottomRightRadius: '0.5rem',
					})}
				/>
				<div
					className={css({
						display: 'flex',
						flexDirection: 'column',
					})}
				>
					<FormLabel
						id='cartText'
						label={t('components:productCart.cartLabel')}
						className={{
							color: theme.colors.primaryA,
							fontWeight: '600',
							marginBottom: '0rem',
						}}
						fontSize='0.8rem'
					/>
					<FormLabel
						id='cartText'
						label={`${totalItems.toString()} item(s)`}
						fontSize='0.75rem'
						className={{
							color: theme.colors.accent50,
							fontWeight: '400',
							marginBottom: '0rem',
						}}
					/>
					<FormLabel
						id='cartText'
						label={`${cartCurrency} ${totalPrice.toString()}`}
						fontSize='0.75rem'
						className={{ color: theme.colors.accent50, fontWeight: '400', paddingBottom: '0rem' }}
					/>
				</div>
			</div>
			{message && (
				<div
					className={css({
						paddingLeft: '0.5rem',
						paddingRight: '0.5rem',
						marginTop: '0.5rem',
						marginLeft: '0.5rem',
					})}
				>
					{message}
				</div>
			)}
		</Block>
	);
}

export default Cart;
