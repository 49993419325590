import React from 'react';
import { useStyletron } from 'baseui';

function BronzeMedal({
	size,
	fillColor,
	height,
	width,
}: {
	size?: number;
	fillColor?: string;
	height: number;
	width: number;
}) {
	const [, theme] = useStyletron();

	return (
		<svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none' id='bronze-medal'>
			<path fill='#C52229' d='M70 10H26a2 2 0 1 0 0 4h44a2 2 0 1 0 0-4Z' />
			<path
				fill='#9B0F15'
				fillRule='evenodd'
				d='M26 8h44a4 4 0 0 1 0 8H26a4 4 0 0 1 0-8Zm0 2h44a2 2 0 1 1 0 4H26a2 2 0 1 1 0-4Z'
				clipRule='evenodd'
			/>
			<path
				fill='#DF3840'
				fillRule='evenodd'
				d='M25 11a1 1 0 0 1 1-1h9.5a1 1 0 1 1 0 2H26a1 1 0 0 1-1-1Z'
				clipRule='evenodd'
			/>
			<path fill='#F1333B' d='M63 8H33a1 1 0 0 0-1 1v50a1 1 0 0 0 1 1h30a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1Z' />
			<path
				fill='#9B0F15'
				fillRule='evenodd'
				d='M33 6h30a3 3 0 0 1 3 3v50a3 3 0 0 1-3 3H33a3 3 0 0 1-3-3V9a3 3 0 0 1 3-3Zm0 2h30a1 1 0 0 1 1 1v50a1 1 0 0 1-1 1H33a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1Z'
				clipRule='evenodd'
			/>
			<path
				fill='#FE666D'
				fillRule='evenodd'
				d='M33.875 9.125a1 1 0 0 1 1 1V56.5H60.5a1 1 0 1 1 0 2H33.875a1 1 0 0 1-1-1V10.125a1 1 0 0 1 1-1Z'
				clipRule='evenodd'
			/>
			<path
				fill='#DB1B24'
				d='M56.034 11.85c0 .567-.448 1.026-1 1.026-.553 0-1-.46-1-1.025 0-.567.447-1.026 1-1.026.552 0 1 .46 1 1.026zm-3.513.25c0 .705-.557 1.276-1.245 1.276-.687 0-1.244-.571-1.244-1.275 0-.705.557-1.276 1.245-1.276.687 0 1.244.571 1.244 1.276zm3.113 3.652c0 .704-.557 1.275-1.244 1.275-.687 0-1.245-.57-1.245-1.275 0-.704.558-1.275 1.245-1.275s1.244.57 1.244 1.275zm2.845-1.738a.63.63 0 0 1-.623.637.63.63 0 0 1-.622-.637.63.63 0 0 1 .622-.638.63.63 0 0 1 .623.638zm-6.58 1.1a.63.63 0 0 1-.623.638.63.63 0 0 1-.622-.638.63.63 0 0 1 .622-.637.63.63 0 0 1 .623.637zm-3.467-3.901a.63.63 0 0 1-.622.638.63.63 0 0 1-.623-.638.63.63 0 0 1 .623-.638.63.63 0 0 1 .622.638zm9.425 7.252a.63.63 0 0 1-.623.637.63.63 0 0 1-.622-.637.63.63 0 0 1 .622-.638.63.63 0 0 1 .623.638zm-3.202.637a.63.63 0 0 1-.622.638.63.63 0 0 1-.622-.637.63.63 0 0 1 .622-.638.63.63 0 0 1 .622.637zm7.217 4.348a.63.63 0 0 1-.622.638.63.63 0 0 1-.622-.638.63.63 0 0 1 .622-.637.63.63 0 0 1 .622.637zm-2.375-12.687a.63.63 0 0 1-.622.637.63.63 0 0 1-.622-.637.63.63 0 0 1 .622-.638.63.63 0 0 1 .622.638zm1.363 6.027c0 .573-.453 1.038-1.013 1.038-.559 0-1.012-.465-1.012-1.038s.453-1.038 1.012-1.038c.56 0 1.013.465 1.013 1.038zm1.013-4.452c0 .573-.454 1.038-1.013 1.038-.559 0-1.012-.465-1.012-1.038S60.3 11.3 60.86 11.3c.56 0 1.013.465 1.013 1.038zm-1.992 9.078c0 .573-.453 1.038-1.012 1.038-.56 0-1.013-.465-1.013-1.038s.454-1.038 1.013-1.038c.56 0 1.012.465 1.012 1.038zm2.299-1.676a.63.63 0 0 1-.622.638.63.63 0 0 1-.623-.638.63.63 0 0 1 .623-.637.63.63 0 0 1 .622.637z'
			/>
			<path
				fill='#DCA16A'
				d='M56.967 39.353c4.146-.609 7.748.178 10.125 2.555 2.377 2.377 3.164 5.98 2.555 10.125C73.01 54.535 75 57.638 75 61s-1.99 6.465-5.353 8.966c.609 4.147-.178 7.749-2.555 10.126-2.377 2.377-5.98 3.164-10.126 2.555C54.466 86.01 51.362 88 48 88c-3.362 0-6.465-1.99-8.966-5.353-4.146.61-7.749-.178-10.126-2.555-2.377-2.377-3.164-5.98-2.555-10.126C22.99 67.466 21 64.362 21 61c0-3.362 1.99-6.465 5.353-8.967-.609-4.145.178-7.748 2.555-10.125 2.377-2.377 5.98-3.164 10.125-2.555C41.535 35.991 44.638 34 48 34s6.465 1.99 8.967 5.353Z'
			/>
			<path
				fill='#C38144'
				fillRule='evenodd'
				d='M57.844 37.236c4.086-.37 7.94.535 10.662 3.258 2.723 2.723 3.627 6.576 3.259 10.662C74.915 53.786 77 57.15 77 61c0 3.851-2.085 7.215-5.235 9.844.369 4.086-.536 7.94-3.259 10.662-2.723 2.723-6.576 3.628-10.663 3.259C55.215 87.915 51.852 90 48 90c-3.85 0-7.215-2.085-9.843-5.235-4.087.369-7.94-.536-10.663-3.259s-3.628-6.576-3.259-10.662C21.085 68.214 19 64.85 19 61c0-3.851 2.085-7.215 5.235-9.844-.368-4.086.536-7.939 3.259-10.662 2.723-2.723 6.576-3.627 10.662-3.258C40.785 34.085 44.15 32 48 32c3.851 0 7.215 2.085 9.844 5.236Zm-.877 2.117C54.465 35.991 51.362 34 48 34s-6.465 1.99-8.967 5.353c-4.146-.609-7.748.178-10.125 2.555-2.377 2.377-3.164 5.98-2.555 10.125C22.99 54.535 21 57.638 21 61s1.99 6.465 5.353 8.966c-.609 4.147.178 7.749 2.555 10.126 2.377 2.377 5.98 3.164 10.126 2.555C41.534 86.01 44.638 88 48 88c3.362 0 6.465-1.99 8.966-5.353 4.147.61 7.749-.178 10.126-2.555 2.377-2.377 3.164-5.98 2.555-10.126C73.01 67.466 75 64.362 75 61c0-3.362-1.99-6.465-5.353-8.967.609-4.145-.178-7.748-2.555-10.125-2.377-2.377-5.98-3.164-10.125-2.555Z'
				clipRule='evenodd'
			/>
			<path
				fill='#CF945D'
				fillRule='evenodd'
				d='M73 60a1 1 0 0 1 1 1c0 3.367-1.897 6.37-4.89 8.744.438 3.794-.344 7.26-2.725 9.64-2.381 2.382-5.847 3.164-9.641 2.726C54.37 85.103 51.367 87 48 87a1 1 0 1 1 0-2c2.686 0 5.295-1.589 7.5-4.553a1 1 0 0 1 .947-.393c3.656.537 6.624-.184 8.524-2.083 1.899-1.9 2.62-4.868 2.083-8.523a1 1 0 0 1 .393-.948C70.41 66.295 72 63.686 72 61a1 1 0 0 1 1-1Z'
				clipRule='evenodd'
			/>
			<path
				fill='#F0B57F'
				fillRule='evenodd'
				d='M48 37c-2.686 0-5.295 1.59-7.5 4.553a1 1 0 0 1-.948.393c-3.655-.537-6.623.185-8.523 2.084-1.898 1.899-2.62 4.867-2.083 8.522a1 1 0 0 1-.393.948C25.59 55.705 24 58.314 24 61a1 1 0 1 1-2 0c0-3.367 1.898-6.37 4.89-8.744-.438-3.794.345-7.26 2.725-9.64 2.381-2.381 5.847-3.163 9.64-2.726C41.63 36.898 44.634 35 48 35a1 1 0 1 1 0 2Z'
				clipRule='evenodd'
			/>
			<path
				fill='#F7BA82'
				d='M54.642 44.965c3.071-.45 5.74.132 7.5 1.893 1.761 1.76 2.344 4.43 1.893 7.5C66.525 56.211 68 58.51 68 61s-1.475 4.79-3.965 6.642c.45 3.071-.132 5.74-1.893 7.5-1.76 1.761-4.429 2.344-7.5 1.893C52.789 79.525 50.49 81 48 81s-4.789-1.475-6.642-3.965c-3.071.451-5.74-.132-7.5-1.893-1.761-1.76-2.344-4.429-1.893-7.5C29.475 65.789 28 63.49 28 61s1.475-4.79 3.965-6.642c-.45-3.071.132-5.74 1.893-7.5 1.76-1.76 4.429-2.344 7.5-1.893C43.211 42.475 45.51 41 48 41s4.79 1.475 6.642 3.965Z'
			/>
			<path
				fill='#C28044'
				fillRule='evenodd'
				d='M33.858 75.142c-1.761-1.76-2.344-4.429-1.893-7.5C29.475 65.789 28 63.49 28 61s1.475-4.79 3.965-6.642c-.45-3.071.132-5.74 1.893-7.5 1.76-1.76 4.429-2.344 7.5-1.893C43.211 42.475 45.51 41 48 41s4.79 1.475 6.642 3.965c3.071-.45 5.74.132 7.5 1.893 1.761 1.76 2.344 4.43 1.893 7.5C66.525 56.211 68 58.51 68 61s-1.475 4.79-3.965 6.642c.45 3.071-.132 5.74-1.893 7.5-1.76 1.761-4.429 2.344-7.5 1.893C52.789 79.525 50.49 81 48 81s-4.789-1.475-6.642-3.965c-3.071.451-5.74-.132-7.5-1.893Zm6.628 4c-3.032.22-5.945-.488-8.042-2.585-2.098-2.098-2.805-5.011-2.586-8.043C27.56 66.526 26 63.966 26 61s1.56-5.526 3.858-7.515c-.219-3.03.488-5.944 2.586-8.041 2.097-2.098 5.01-2.805 8.041-2.586C42.474 40.56 45.034 39 48 39c2.967 0 5.526 1.56 7.515 3.858 3.03-.219 5.944.488 8.041 2.586 2.098 2.097 2.805 5.01 2.586 8.041C68.44 55.474 70 58.035 70 61c0 2.966-1.56 5.526-3.858 7.514.219 3.032-.488 5.945-2.586 8.043-2.097 2.097-5.01 2.804-8.042 2.585C53.526 81.44 50.966 83 48 83s-5.526-1.56-7.514-3.858Z'
				clipRule='evenodd'
			/>
			<path
				fill='#E5A66C'
				fillRule='evenodd'
				d='M66 60a1 1 0 0 1 1 1c0 2.49-1.378 4.69-3.506 6.418.283 2.726-.298 5.256-2.059 7.017-1.761 1.761-4.29 2.342-7.017 2.06C52.69 78.621 50.49 80 48 80a1 1 0 1 1 0-2c1.814 0 3.619-1.073 5.175-3.165a1 1 0 0 1 .948-.393c2.58.379 4.615-.138 5.898-1.421 1.283-1.283 1.8-3.318 1.421-5.898a1 1 0 0 1 .392-.948C63.927 64.62 65 62.815 65 61a1 1 0 0 1 1-1Z'
				clipRule='evenodd'
			/>
			<path
				fill='#FFD5AE'
				fillRule='evenodd'
				d='M48 44c-1.814 0-3.619 1.073-5.175 3.166a1 1 0 0 1-.948.392c-2.58-.379-4.615.139-5.898 1.421-1.282 1.283-1.8 3.318-1.421 5.898a1 1 0 0 1-.392.948C32.073 57.38 31 59.185 31 61a1 1 0 1 1-2 0c0-2.49 1.378-4.69 3.506-6.418-.283-2.726.298-5.256 2.059-7.017 1.761-1.761 4.29-2.342 7.017-2.06C43.31 43.379 45.509 42 48 42a1 1 0 1 1 0 2Z'
				clipRule='evenodd'
			/>
			<path
				fill='#FFD5AE'
				d='M45.605 58.969h1.805c.86 0 1.496-.215 1.91-.645.414-.43.621-1 .621-1.71 0-.688-.207-1.223-.62-1.606-.407-.383-.97-.574-1.688-.574-.649 0-1.192.18-1.63.539-.437.351-.655.812-.655 1.383H41.96c0-.891.238-1.688.715-2.391.484-.711 1.156-1.266 2.015-1.664.868-.399 1.82-.598 2.86-.598 1.804 0 3.218.434 4.242 1.3 1.023.86 1.535 2.048 1.535 3.563 0 .782-.238 1.5-.715 2.157a4.645 4.645 0 0 1-1.875 1.511c.961.344 1.676.86 2.145 1.547.476.688.715 1.5.715 2.438 0 1.515-.555 2.73-1.664 3.644-1.102.914-2.563 1.371-4.383 1.371-1.703 0-3.098-.449-4.184-1.347-1.078-.899-1.617-2.086-1.617-3.563h3.387c0 .64.238 1.164.715 1.57.484.407 1.078.61 1.78.61.806 0 1.434-.211 1.888-.633.46-.43.69-.996.69-1.7 0-1.702-.937-2.554-2.812-2.554h-1.793V58.97Z'
			/>
			<path
				fill='#C28044'
				fillRule='evenodd'
				d='M43.701 58.355h-1.74a2 2 0 0 1-2-2c0-1.27.347-2.461 1.06-3.512l.002-.004c.708-1.04 1.674-1.818 2.827-2.353l.006-.003c1.152-.529 2.393-.78 3.695-.78 2.112 0 4.039.51 5.532 1.772 1.537 1.293 2.245 3.075 2.245 5.091a5.59 5.59 0 0 1-1.096 3.332 6.987 6.987 0 0 1-.142.188c.157.176.305.363.44.562.734 1.06 1.068 2.28 1.068 3.57 0 2.075-.793 3.87-2.39 5.186-1.548 1.284-3.501 1.83-5.657 1.83-2.068 0-3.944-.553-5.459-1.806l-.005-.005c-1.56-1.3-2.337-3.064-2.337-5.099a2 2 0 0 1 2-2h1.984a1.995 1.995 0 0 1-.129-.707V58.97c0-.214.034-.42.096-.614Zm3.307 5.262a1.997 1.997 0 0 1 .13.746.15.15 0 0 0 .006.005c.083.068.207.136.489.136.23 0 .368-.03.439-.055a.23.23 0 0 0 .084-.04m-1.148-.792h.39c.497 0 .7.086.755.115l.005.011a1.3 1.3 0 0 1 .053.429.55.55 0 0 1-.032.21.17.17 0 0 1-.023.026m3.778 3.455c1.109-.914 1.664-2.129 1.664-3.644 0-.938-.239-1.75-.715-2.438a3.482 3.482 0 0 0-.252-.325c-.459-.527-1.09-.934-1.893-1.222a4.657 4.657 0 0 0 1.875-1.511 3.59 3.59 0 0 0 .715-2.157c0-1.515-.512-2.703-1.535-3.562-1.023-.867-2.438-1.3-4.242-1.3-1.04 0-1.992.198-2.86.597-.859.398-1.53.953-2.015 1.664a4.206 4.206 0 0 0-.715 2.39h3.387c0-.57.218-1.03.656-1.382.437-.36.98-.54 1.629-.54.719 0 1.281.192 1.687.575.414.383.621.918.621 1.605 0 .711-.207 1.282-.62 1.711-.415.43-1.051.645-1.91.645h-1.806v2.648h1.793c1.875 0 2.813.852 2.813 2.555 0 .703-.23 1.27-.691 1.7-.454.421-1.083.632-1.887.632-.703 0-1.297-.203-1.781-.61-.477-.406-.715-.93-.715-1.57H41.75c0 .74.135 1.406.405 2 .27.593.674 1.114 1.212 1.563 1.086.898 2.48 1.347 4.184 1.347 1.82 0 3.281-.457 4.383-1.37ZM47.252 56.97c.05-.157.082-.322.092-.493a.768.768 0 0 1 .289-.042c.16 0 .253.018.298.031.006.031.01.08.01.148a.841.841 0 0 1-.043.298 1.58 1.58 0 0 1-.488.058h-.158Z'
				clipRule='evenodd'
			/>
		</svg>
	);
}

export default BronzeMedal;
