import { getUpgradeHeadingText, upgradeBusiness } from 'shared/consts/Nodes';
import { isPlanGreaterOrEqualTo } from 'shared/helpers';

export const updateNodeUpgradeMarker = (
	nodeData: any,
	customerPlan: string,
	entitlements: any,
	shopifyOfferingKey: string,
	trialPlanOfferingKey: string
) => {
	const shopifyPlan = shopifyOfferingKey && !customerPlan.includes('SHOPIFY') ? shopifyOfferingKey : customerPlan;
	if (
		['ENGATI_WA_RAPID', 'ENGATI_GROWTH_2024', 'ENGATI_PRO_2024', 'ENGATI_ENTERPRISE_2024'].indexOf(customerPlan) >
			-1 ||
		(Object.keys(entitlements).indexOf(nodeData.entitlementAllowed) > -1 &&
			entitlements[nodeData.entitlementAllowed])
	) {
		nodeData.upgradeMarker = '';
	} else {
		switch (nodeData.upgradeMarker) {
			case 'BIZ':
				nodeData.upgradeMarker = isPlanGreaterOrEqualTo(
					shopifyOfferingKey ? shopifyPlan : trialPlanOfferingKey || customerPlan,
					'ENGATI_BUSINESS'
				)
					? ''
					: shopifyOfferingKey
						? 'PRE'
						: nodeData.upgradeMarker;
				if (shopifyOfferingKey) {
					nodeData.upgradeHeader = getUpgradeHeadingText(nodeData.label, 'Premium');
					nodeData.upgradeDescription = upgradeBusiness('Premium');
				}
				break;
			case 'PRO':
				nodeData.upgradeMarker = isPlanGreaterOrEqualTo(
					shopifyOfferingKey ? shopifyPlan : trialPlanOfferingKey || customerPlan,
					'ENGATI_PROFESSIONAL'
				)
					? ''
					: nodeData.upgradeMarker;
				break;
			case 'ENT':
				nodeData.upgradeMarker = isPlanGreaterOrEqualTo(
					shopifyOfferingKey ? shopifyPlan : trialPlanOfferingKey || customerPlan,
					'ENGATI_ENTERPRISE'
				)
					? ''
					: nodeData.upgradeMarker;
				break;
		}
	}

	return nodeData;
};
