import React from 'react';
import { useStyletron } from 'baseui';
import { KIND, SIZE } from 'baseui/button';
import { useTranslation } from 'react-i18next';

import { HorizontalDivider } from 'components/UI/Dividers/HorizontalDivider';
import ENGTButton from 'components/UI/ENGTButton/ENGTButton';

import { IObjProps } from 'shared/consts/types';
import { decodeAndSanitizeInput, getEllipsisedText, isEmpty } from 'shared/helpers';

interface IWhatsAppFlow {
	flowData: IObjProps;
	messageStatus?: JSX.Element | null;
	messageId?: string;
}

const flexCss = (direction: string) => ({
	display: 'flex',
	alignItems: 'flex-start',
	justifyContent: 'flex-start',
	flexDirection: direction,
});

const textCss = (fontWeight: number, fontSize: string) => ({
	fontSize,
	fontWeight,
	lineHeight: '1rem',
});

const WhatsAppFlowMessage = ({ flowData, messageStatus, messageId }: IWhatsAppFlow) => {
	const { t } = useTranslation(['components']);

	const [css, theme]: [any, any] = useStyletron();

	const { action, body } = flowData;
	const { flow_cta, flow_action_payload = {}, flow_id } = action?.parameters || {};
	const { data = {} } = flow_action_payload;

	return (
		<div
			className={css({
				display: 'inline-block',
				background: theme.colors.primaryB,
				borderTopLeftRadius: '0.5rem',
				borderTopRightRadius: '0.5rem',
				borderBottomLeftRadius: '0.5rem',
				borderBottomRightRadius: '0.5rem',
			})}
			id={messageId}
		>
			<div
				className={css({
					marginLeft: '1rem',
					marginTop: '1rem',
					marginBottom: '1rem',
					marginRight: '1rem',
				})}
			>
				<div className={css({ ...textCss(400, '0.875rem'), ...flexCss('row'), marginBottom: '0.5rem' })}>
					{t('components:node.whatsAppFLows.flows')}
					<span
						className={css({
							fontWeight: '600',
							paddingLeft: '0.5rem',
						})}
					>
						{flow_id}
					</span>
				</div>
				<HorizontalDivider height={1} />
				<span
					className={css({
						...textCss(400, '0.875rem'),
						paddingTop: '0.75rem',
						paddingBottom: '0.75rem',
						float: 'left',
						textAlign: 'left',
						'white-space': 'pre-wrap',
					})}
					dangerouslySetInnerHTML={{ __html: decodeAndSanitizeInput(body?.text) }}
				/>
				<ENGTButton
					kind={KIND.secondary}
					type='button'
					size={SIZE.mini}
					label={flow_cta}
					className={css({
						width: '100%',
						...textCss(400, '0.875rem'),
						cursor: 'not-allowed',
					})}
				/>
			</div>
			<div
				className={css({
					marginLeft: '1rem',
					marginTop: '1rem',
					marginBottom: '1rem',
					marginRight: '1rem',
				})}
			>
				{!isEmpty(data) ? <HorizontalDivider height={1} /> : <></>}
				{Object.entries(data).map((attributeData: IObjProps) => (
					<div
						className={css({
							marginTop: '0.5rem',
							flexDirection: 'row',
							alignItems: 'flex-end',
							display: 'flex',
							justifyContent: 'space-between',
						})}
					>
						<div
							className={css({
								...textCss(400, '0.875rem'),
								color: theme.colors.primaryA,
							})}
						>
							{getEllipsisedText(attributeData[0], 20)}
						</div>
						<div
							className={css({
								...textCss(600, '0.875rem'),
								color: theme.colors.accent50,
							})}
						>
							{getEllipsisedText(attributeData[1], 20)}
						</div>
					</div>
				))}
			</div>
			<div
				className={css({
					marginRight: '0.5rem',
					marginTop: '0.25rem',
					marginBottom: '0.25rem',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'flex-end',
				})}
			>
				{messageStatus}
			</div>
		</div>
	);
};

export default React.memo(WhatsAppFlowMessage);
