import { API } from 'components/Gamification/api.js';

import { isValidResponseObjectViaStatus } from 'shared/helpers';

import { actions } from './store';

const { setUserXp, setLoading, setError, increaseUserXp } = actions;

export const fetchUserXp = () => async (dispatch: any) => {
	dispatch(setLoading(true));
	try {
		const response = await API.getUserXp();
		if (isValidResponseObjectViaStatus(response)) {
			dispatch(setUserXp(response.data.responseObject));
		} else {
			dispatch(setError(true));
		}
	} catch (error) {
		dispatch(setError(true));
	} finally {
		dispatch(setLoading(false));
	}
};

export const increaseUserXpAction = (category: string) => async (dispatch: any) => {
	try {
		const response = await API.increaseUserXp(category);
		if (isValidResponseObjectViaStatus(response)) {
			dispatch(increaseUserXp());
		} else {
			dispatch(setError(true));
		}
	} catch (error) {
		dispatch(setError(true));
	} finally {
		dispatch(setLoading(false));
	}
};
