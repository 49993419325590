import { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { Modal } from 'baseui/modal';

import { IObjProps } from 'shared/consts/types';
import { borderRadius } from 'shared/helpers';
import ClearTextIcon from 'shared/icons/ClearTextIcon';
import LeftArrowIcon from 'shared/icons/LeftArrowIcon';
import RightArrowIcon from 'shared/icons/RightArrowIcon';

interface ICarouselPreviewProps {
	isOpen: boolean;
	multipleImageUrls: Array<string>;
	onClose: Function;
	previewIndex: number;
	setCarouselPreview?: Function;
}

const CrossButton = (props: IObjProps) => {
	const { onClose } = props;
	const [css, theme]: any = useStyletron();

	return (
		<div
			className={css({
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				backgroundColor: theme.colors.accent100,
				cursor: 'pointer',
				position: 'absolute',
				width: '2rem',
				height: '2rem',
				top: '1rem',
				right: '1rem',
				...borderRadius('50%'),
			})}
			onClick={onClose}
		>
			<ClearTextIcon size={12} fillColor={theme.colors.primaryBtnFontColor} />
		</div>
	);
};

function CarouselPreview(props: ICarouselPreviewProps) {
	const { isOpen, multipleImageUrls, onClose, previewIndex, setCarouselPreview } = props;
	const [css, theme]: any = useStyletron();
	const [previewImageIndex, setPreviewImageIndex] = useState(previewIndex);
	const navigationCss = {
		outline: 'none',
		borderLeft: 'none',
		borderRight: 'none',
		borderTop: 'none',
		borderBottom: 'none',
		backgroundColor: theme.colors.accent100,
		borderRadius: '2.5rem',
		height: '1.5rem',
		width: '1.5rem',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginLeft: '1rem',
		marginRight: '1rem',
	};

	useEffect(() => {
		setPreviewImageIndex(previewIndex);
	}, [previewIndex]);

	const renderCarouselPreview = () => (
		<Block display='flex' justifyContent='center' alignItems='center'>
			<button
				onClick={() => {
					setPreviewImageIndex(previewImageIndex > 0 ? previewImageIndex - 1 : 0);
				}}
				disabled={previewImageIndex === 0}
				className={css({ ...navigationCss, cursor: previewImageIndex === 0 ? 'auto' : 'pointer' })}
			>
				<LeftArrowIcon fillColor={theme.colors.defaultBlack} height='1rem' width='0.5rem' />
			</button>

			<div
				className={css({
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					overflowX: 'auto',
					whiteSpace: 'nowrap',
					'scrollbar-width': 'none',
					'-ms-overflow-style': 'none',
					'::-webkit-scrollbar': {
						display: 'none',
					},
					scrollBehavior: 'smooth',
					position: 'relative',
					height: '26rem',
					width: '34.25rem',
					backgroundColor: theme.colors.primaryA,
					...borderRadius('1rem'),
				})}
			>
				<img
					src={multipleImageUrls[previewImageIndex]}
					alt='uploaded-image'
					className={css({
						maxWidth: '100%',
						maxHeight: '100%',
						cursor: 'pointer',
					})}
				/>
			</div>
			<button
				onClick={() => {
					setPreviewImageIndex(
						previewImageIndex < multipleImageUrls.length - 1
							? previewImageIndex + 1
							: multipleImageUrls.length - 1
					);
				}}
				disabled={previewImageIndex === multipleImageUrls.length - 1}
				className={css({
					...navigationCss,
					cursor: previewImageIndex === multipleImageUrls.length - 1 ? 'auto' : 'pointer',
				})}
			>
				<RightArrowIcon fillColor={theme.colors.defaultBlack} height='0.7rem' width='0.5rem' />
			</button>
		</Block>
	);

	return (
		<Modal
			overrides={{
				Root: {
					style: {
						zIndex: theme.zIndex300,
					},
				},
				Dialog: {
					style: {
						width: 'auto',
						background: 'transparent',
						textAlign: 'center',
					},
				},
				Close: {
					style: {
						width: '2rem',
						height: '2rem',
						background: theme.colors.accent100,
						color: theme.colors.primaryBtnFontColor,
						borderWidth: '0rem',
						maskBorderWidth: '0rem',
						...borderRadius('50%'),
						marginRight: '3.75rem',
						':focus': {
							outline: 'none',
						},
					},
				},
			}}
			animate
			closeable
			isOpen={isOpen}
			onClose={() => {
				setCarouselPreview?.(false);
			}}
		>
			{renderCarouselPreview()}
		</Modal>
	);
}
export default CarouselPreview;
