import React from 'react';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { KIND } from 'baseui/button';
import { useTranslation } from 'react-i18next';

import ENGTButton from 'components/UI/ENGTButton/ENGTButton';

import { IObjProps } from 'shared/consts/types';

interface IPayment {
	paymentData: IObjProps;
	messageStatus?: JSX.Element | null;
}

const flexCss = (direction: string) => ({
	display: 'flex',
	alignItems: 'flex-start',
	justifyContent: 'flex-start',
	flexDirection: direction,
});

const textCss = (fontWeight: number, fontSize: string) => ({
	fontSize,
	fontWeight,
	lineHeight: '1rem',
});

const PaymentMessage = ({ paymentData, messageStatus }: IPayment) => {
	const { t } = useTranslation(['components']);

	const [css, theme]: [any, any] = useStyletron();

	const BUTTON_TEXT = 'Review and pay';

	const { action, image_url, body, footer } = paymentData;
	const { reference_id, order = {}, total_amount } = action?.parameters || {};
	const { items } = order;

	const getPayableAmount = () => {
		if (total_amount?.offset && total_amount?.value && total_amount.offset > 0) {
			return total_amount.value / total_amount.offset;
		}

		return '-';
	};

	return (
		<Block
			className={css({
				display: 'inline-block',
				background: theme.colors.primaryB,
				borderTopLeftRadius: '0.5rem',
				borderTopRightRadius: '0.5rem',
				borderBottomLeftRadius: '0.5rem',
				borderBottomRightRadius: '0.5rem',
			})}
		>
			<div
				className={css({
					marginLeft: '1rem',
					marginTop: '1rem',
					marginBottom: '1rem',
					marginRight: '1rem',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'flex-start',
				})}
			>
				<div className={css(textCss(400, '0.875rem'))}>
					{t('components:paymentMessage.orderId') + reference_id}
				</div>
				<div className={css({ ...flexCss('row'), paddingTop: '1rem' })}>
					<div
						className={css({
							borderTopLeftRadius: '0.4375rem',
							borderTopRightRadius: '0.4375rem',
							borderBottomLeftRadius: '0.4375rem',
							borderBottomRightRadius: '0.4375rem',
							borderStyle: 'solid',
							borderColor: theme.colors.backgroundSecondary,
							borderWidth: '0.05rem',
						})}
					>
						<a
							href={image_url}
							target='_blank'
							className={css({
								display: 'flex',
								justifyContent: 'flex-start',
								float: 'right',
							})}
							rel='noreferrer'
						>
							<img
								src={image_url}
								className={css({
									height: '7.5rem',
									width: '7.5rem',
								})}
							/>
						</a>
					</div>
					<div
						className={css({
							...flexCss('column'),
							marginLeft: '0.5rem',
						})}
					>
						<div
							className={css({
								...textCss(600, '0.875rem'),
								color: theme.colors.primaryA,
							})}
						>
							{items?.[0]?.name}
						</div>
						<div
							className={css({
								...textCss(400, '0.875rem'),
								color: theme.colors.accent50,
								paddingTop: '0.375rem',
							})}
						>
							{t('components:paymentMessage.quantity') + items?.length}
						</div>
						<div
							className={css({
								...textCss(400, '0.875rem'),
								color: theme.colors.accent50,
								paddingTop: '0.375rem',
							})}
						>
							{t('components:paymentMessage.total') + getPayableAmount()}
						</div>
					</div>
				</div>
				<div className={css({ ...textCss(400, '0.875rem'), paddingTop: '0.5rem', paddingBottom: '0.5rem' })}>
					{body?.text}
				</div>
				<div className={css({ ...textCss(400, '0.75rem'), paddingBottom: '0.5rem' })}>{footer?.text}</div>
				<ENGTButton
					kind={KIND['secondary']}
					label={BUTTON_TEXT}
					isDisabled
					className={css({
						width: '100%',
						...textCss(400, '0.875rem'),
					})}
				/>
			</div>
			<div
				className={css({
					marginRight: '0.5rem',
					marginTop: '0.25rem',
					marginBottom: '0.25rem',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'flex-end',
				})}
			>
				{messageStatus}
			</div>
		</Block>
	);
};

export default React.memo(PaymentMessage);
