import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStyletron } from 'styletron-react';

import Bold from 'shared/icons/tooltip/Bold.svg';
import Check from 'shared/icons/tooltip/CheckMarkSolo.svg';
import Font from 'shared/icons/tooltip/Font.svg';
import Italic from 'shared/icons/tooltip/Italic.svg';
import Link from 'shared/icons/tooltip/Link.svg';
import Underline from 'shared/icons/tooltip/Underline.svg';

import { HorizontalDivider } from '../Dividers/HorizontalDivider';

const channels = [
	// {
	// 	name: 'Gbm',
	// 	url:
	// 		'https://branding-resources.s3.ap-south-1.amazonaws.com/default/portal/channels-icons-svg-21/ic_channel_gbm.svg',
	// 	bold: true,
	// 	italic: true,
	// 	underline: false,
	// 	strike: false,
	// 	link: false,
	// },
	{
		name: 'Webchat',
		url: 'https://branding-resources.s3.ap-south-1.amazonaws.com/default/portal/channels-icons-svg/ic_channel_bot.svg',
		bold: true,
		italic: true,
		underline: true,
		strike: true,
		link: true,
	},
	{
		name: 'Whatsapp',
		url: '/static/portalV2/images/connect/channels/ic_channel_whatsapp.svg',
		bold: true,
		italic: true,
		underline: false,
		strike: true,
		link: false,
	},
	{
		name: 'Facebook',
		url: '/static/portalV2/images/connect/channels/ic_channel_messenger.svg',
		bold: false,
		italic: false,
		underline: false,
		strike: false,
		link: false,
	},
	{
		name: 'Telegram',
		url: '/static/portalV2/images/connect/channels/ic_channel_telegram.svg',
		bold: true,
		italic: true,
		underline: true,
		strike: true,
		link: true,
	},
	{
		name: 'Slack',
		url: '/static/portalV2/images/channels/ic_channel_slack.svg',
		bold: true,
		italic: true,
		underline: false,
		strike: true,
		link: true,
	},
	{
		name: 'Twitter',
		url: 'https://branding-resources.s3.ap-south-1.amazonaws.com/default/portal/channels-icons-svg/ic_channel_twitter.svg',
		bold: false,
		italic: false,
		underline: false,
		strike: false,
		link: false,
	},
	{
		name: 'Skype',
		url: 'https://branding-resources.s3.ap-south-1.amazonaws.com/default/portal/channels-icons-svg/ic_channel_skype.svg',
		bold: true,
		italic: true,
		underline: false,
		strike: true,
		link: true,
	},
	{
		name: 'MS Teams',
		url: 'https://branding-resources.s3.ap-south-1.amazonaws.com/default/portal/channels-icons-svg/ic_channel_miscrsoft_teams.svg',
		bold: true,
		italic: true,
		underline: false,
		strike: true,
		link: true,
	},
	{
		name: 'Line',
		url: 'https://branding-resources.s3.ap-south-1.amazonaws.com/default/portal/channels-icons-svg/ic_channel_line.svg',
		bold: false,
		italic: false,
		underline: false,
		strike: false,
		link: false,
	},
	{
		name: 'Instagram',
		url: 'https://branding-resources.s3.ap-south-1.amazonaws.com/default/portal/channels-icons-svg-21/ic_channel_instagram.svg',
		bold: false,
		italic: false,
		underline: false,
		strike: false,
		link: false,
	},
];

function FormatSupport(): any {
	const [css] = useStyletron();
	const { t } = useTranslation(['components']);

	const Hyphen = (
		<div
			style={{
				fontSize: '0.875rem',
				lineHeight: '1.25rem',
				paddingLeft: '1.1rem',
				width: '1.25rem',
				textAlign: 'center',
			}}
		>
			-
		</div>
	);

	return (
		<div
			className={css({
				backgroundColor: '#FFFFFF',
				fontSize: '0.75rem',
				lineHeight: '1rem',
				borderTopRightRadius: '0.5rem',
				borderTopLeftRadius: '0.5rem',
				borderBottomLeftRadius: '0.5rem',
				borderBottomRightRadius: '0.5rem',
				color: 'black',
				width: '19rem',
			})}
		>
			<div
				className={css({
					display: 'flex',
					alignItems: 'center',
					paddingTop: '0.5rem',
					paddingRight: '1rem',
					paddingLeft: '1rem',
					paddingBottom: '0.5rem',
				})}
			>
				<p style={{ margin: '0' }}>{t('components:quill.formattingSupport')}</p>
				<span style={{ marginLeft: 'auto', display: 'flex' }}>
					<img src={Bold} style={{ paddingLeft: '1.125rem' }} alt='Bold' />
					<img src={Italic} style={{ paddingLeft: '1.125rem' }} alt='Italic' />
					<img src={Underline} style={{ paddingLeft: '1.125rem' }} alt='Underline' />
					<img src={Font} style={{ paddingLeft: '1.125rem' }} alt='Font' />
					<img src={Link} style={{ paddingLeft: '1.125rem' }} alt='Link' />
				</span>
			</div>
			<HorizontalDivider height={1} />
			<div
				className={css({
					display: 'flex',
					flexDirection: 'column',
					height: '6.625rem',
					overflow: 'scroll',
					scrollbarWidth: 'thin',
					paddingBottom: '1rem',
					paddingRight: '1rem',
					paddingLeft: '1rem',
				})}
			>
				{channels.map((el) => (
					<div
						className={css({
							display: 'flex',
							fontSize: '0.75rem',
							lineHeight: '1rem',
							paddingTop: '0.625rem',
						})}
					>
						<span className={css({ display: 'flex' })}>
							<img
								src={el.url}
								style={{
									paddingRight: '0.375rem',
									height: '1.125rem',
									width: '1.125rem',
								}}
								alt={el.name}
							/>
							<span style={{ paddingTop: '0.125rem', minWidth: '3.625rem' }}>{el.name}</span>
						</span>
						<span style={{ marginLeft: 'auto', display: 'flex' }}>
							{el.bold ? <img src={Check} style={{ paddingLeft: '1.1rem' }} alt='check' /> : Hyphen}
							{el.italic ? <img src={Check} style={{ paddingLeft: '1.1rem' }} alt='check' /> : Hyphen}
							{el.underline ? <img src={Check} style={{ paddingLeft: '1.1rem' }} alt='check' /> : Hyphen}
							{el.strike ? <img src={Check} style={{ paddingLeft: '1.1rem' }} alt='check' /> : Hyphen}
							{el.link ? <img src={Check} style={{ paddingLeft: '1.1rem' }} alt='check' /> : Hyphen}
						</span>
					</div>
				))}
			</div>
		</div>
	);
}
export default FormatSupport;
