import React from 'react';
import { useStyletron } from 'baseui';

function Level1({
	size,
	fillColor,
	height,
	width,
}: {
	size?: number;
	fillColor?: string;
	height: number;
	width: number;
}) {
	const [, theme] = useStyletron();

	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			enableBackground='new 0 0 24 24'
			viewBox='0 0 24 24'
			id='helmet'
			height={height}
			width={width}
		>
			<g>
				<path
					d='M12.5,6c-0.2761-0.2762-0.7239-0.2762-1,0l-0.75,0.75c-0.2761,0.2761-0.2761,0.7238,0,1L11.5,8.5
		c0.2761,0.2761,0.7239,0.2761,1,0l0.75-0.75c0.2761-0.2762,0.2761-0.7239,0-1L12.5,6z'
				/>
				<path
					d='M20.6016,16.7217l-1.1211-8.0771c-0.1689-1.2227-0.6367-2.4043-1.3506-3.4131c-0.7158-1.0244-1.6641-1.8584-2.7432-2.4121
		c-2.0879-1.0723-4.6826-1.0742-6.7764,0.002C6.4043,3.9648,4.875,6.1406,4.5186,8.6455l-1.1172,8.0723
		c-0.2939,2.1064,1.0088,4.085,3.0313,4.6025l2.5293,0.6484c0.2783,0.0684,0.5752,0.0186,0.8115-0.1426
		c0.2383-0.1631,0.3945-0.4189,0.4287-0.7051l0.79-6.4688c0.0508-0.416-0.1631-0.8193-0.5352-1.0107L9,12.8936v-1.1807l2.751,0.707
		c0.1641,0.041,0.334,0.041,0.498,0L15,11.7129v1.1807l-1.457,0.748c-0.3721,0.1914-0.5859,0.5947-0.5352,1.0107l0.79,6.4688
		c0.0342,0.2861,0.1904,0.542,0.4277,0.7051C14.3936,21.9404,14.5908,22,14.79,22c0.083,0,0.166-0.0107,0.248-0.0313l2.5322-0.6484
		C19.5898,20.8027,20.8936,18.8262,20.6016,16.7217z M17.0742,19.3828l-1.4297,0.3662l-0.5674-4.6465l1.3799-0.709
		C16.79,14.2217,17,13.8789,17,13.5039v-3.0801c0-0.3086-0.1426-0.6006-0.3867-0.79c-0.2451-0.1895-0.5645-0.2549-0.8623-0.1787
		L12,10.4189L8.249,9.4551C7.9502,9.3799,7.6318,9.4443,7.3867,9.6338C7.1426,9.8232,7,10.1152,7,10.4238v3.0801
		c0,0.375,0.21,0.7178,0.543,0.8896l1.3799,0.709L8.3555,19.749l-1.4268-0.3662c-1.0352-0.2646-1.7002-1.292-1.5469-2.3877
		L6.5,8.9219c0.2637-1.8613,1.3975-3.4785,3.0273-4.3232c1.5234-0.7832,3.4209-0.7832,4.9463,0
		c0.79,0.4053,1.4873,1.0195,2.0195,1.7832C17.0264,7.1348,17.374,8.0117,17.5,8.9189l1.1211,8.0771
		C18.7725,18.0928,18.1074,19.1182,17.0742,19.3828z'
				/>
			</g>
		</svg>
	);
}

export default Level1;
