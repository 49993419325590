import React from 'react';
import { useStyletron } from 'baseui';

function Level10({
	size,
	fillColor,
	height,
	width,
}: {
	size?: number;
	fillColor?: string;
	height: number;
	width: number;
}) {
	const [, theme] = useStyletron();

	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			enableBackground='new 0 0 66 66'
			viewBox='0 0 66 66'
			id='crown-tattoo'
			height={height}
			width={width}
		>
			<path d='M58.63 46.935v-.14L64 31.045l-6.89-11.22-5.84 9.52-6.22-15.52-5.89 14.75-6.14-20.68L33 7.825l-.01.05-6.15 20.7-5.89-14.75-6.22 15.52-5.84-9.52L2 31.045l5.37 15.75v.14H58.63zM51.09 39.625c.54-1.42 2.35-2.05 4-1.27 1.65.78 2.34 2.58 1.59 3.91s-2.5 1.76-3.94 1.09C51.3 42.665 50.54 41.055 51.09 39.625zM42.79 37.165c.29-1.49 1.94-2.43 3.67-1.97 1.7401.47 2.71 2.11 2.21 3.55-.5 1.4399-2.12 2.1899-3.64 1.78C43.52 40.115 42.5 38.665 42.79 37.165zM37.52 33.615c1.78.16 3.01 1.61 2.76 3.11-.24 1.51-1.69 2.53-3.24 2.39-1.55-.14-2.8-1.39-2.77-2.92C34.29 34.675 35.75 33.465 37.52 33.615zM28.47 33.615c1.78-.15 3.24 1.06 3.26 2.58.02 1.53-1.22 2.78-2.78 2.92-1.55.14-3-.88-3.2401-2.39C25.47 35.225 26.7 33.775 28.47 33.615zM19.53 35.195c1.73-.46 3.39.48 3.67 1.97.29 1.5-.72 2.95-2.2401 3.36-1.5099.41-3.13-.34-3.63-1.78C16.82 37.305 17.8 35.665 19.53 35.195zM10.91 38.355c1.65-.78 3.46-.15 4 1.27.54 1.4301-.21 3.05-1.65 3.73-1.45.67-3.19.23-3.94-1.09C8.57 40.935 9.26 39.135 10.91 38.355zM58.63 48.925H7.37v9.25h51.26V48.925zM17.6 56.495l-2.94-2.95 2.94-2.94 2.95 2.94L17.6 56.495zM27.87 56.495l-2.95-2.95 2.95-2.94 2.94 2.94L27.87 56.495zM38.13 56.495l-2.95-2.95 2.95-2.94 2.95 2.94L38.13 56.495zM48.39 56.495l-2.94-2.95 2.94-2.94 2.95 2.94L48.39 56.495z' />
		</svg>
	);
}

export default Level10;
