import Level1 from './Level1';
import Level2 from './Level2';
import Level3 from './Level3';
import Level4 from './Level4';
import Level5 from './Level5';
import Level6 from './Level6';
import Level7 from './Level7';
import Level8 from './Level8';
import Level9 from './Level9';
import Level10 from './Level10';

const getLevelAvatar = (level: number, height: number = 40, width: number = 40) => {
	const levelNames = [
		{
			level: 1,
			name: 'The Novice',
			component: <Level1 height={height} width={width} />,
		},
		{
			level: 2,
			name: 'The Explorer',
			component: <Level2 height={height} width={width} />,
		},
		{
			level: 3,
			name: 'The Apprentice',
			component: <Level3 height={height} width={width} />,
		},
		{
			level: 4,
			name: 'The Pathfinder',
			component: <Level4 height={height} width={width} />,
		},
		{
			level: 5,
			name: 'The Awakening',
			component: <Level5 height={height} width={width} />,
		},
		{
			level: 6,
			name: 'The Adventurer',
			component: <Level6 height={height} width={width} />,
		},
		{
			level: 7,
			name: 'The Seeker',
			component: <Level7 height={height} width={width} />,
		},
		{
			level: 8,
			name: 'The Challenger',
			component: <Level8 height={height} width={width} />,
		},
		{
			level: 9,
			name: 'The Conqueror',
			component: <Level9 height={height} width={width} />,
		},
		{
			level: 10,
			name: 'The Champion',
			component: <Level10 height={height} width={width} />,
		},
	];

	const levelData = levelNames.find((lev) => lev.level === level);

	return {
		component: levelData?.component || <div>Avatar not available</div>,
		name: levelData?.name || 'Avatar not available',
	};
};

export default getLevelAvatar;
