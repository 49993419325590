export const TABS_FOR_WORK_FLOW_DATA = (t: any, isShopifyEnabled: boolean) => ({
	SEARCH_PRODUCTS: {
		Action: [
			{
				dataType: 'STRING',
				defaultValue: '',
				description: t(
					'components:node.whatsAppCommerce.workflows.searchProduct.action.productNameOrId.placeholder'
				),
				displayName: t('components:node.whatsAppCommerce.workflows.searchProduct.action.productNameOrId.label'),
				inputType: 'TEXT',
				valueType: 'CLIENT_INPUT',
				isRequired: true,
				key: 'searchTerm',
				metadata: undefined,
				type: 'BODY',
				value: '',
			},
		],
		Retrieve: [
			{
				dataType: 'STRING',
				defaultValue: '',
				description: t(
					'components:node.whatsAppCommerce.workflows.searchProduct.retrieve.productDetails.placeholder'
				),
				displayName: t(
					'components:node.whatsAppCommerce.workflows.searchProduct.retrieve.productDetails.label'
				),
				inputType: 'TEXT',
				valueType: 'HARDCODED',
				isRequired: false,
				key: 'productName',
				metadata: undefined,
				type: 'BODY',
				value: '',
			},
		],
		Error: [],
	},
	DISPLAY_PRODUCTS: {
		Action: [
			{
				dataType: 'STRING',
				defaultValue: '',
				description: '',
				displayName: t('components:node.whatsAppCommerce.workflows.displayProducts.action.productName.label'),
				inputType: 'TEXT',
				valueType: isShopifyEnabled ? 'HARDCODED' : 'CLIENT_INPUT',
				isRequired: true,
				tooltip: `${t(
					'components:node.whatsAppCommerce.workflows.displayProducts.action.productName.tooltip'
				)}{{commerce.display_product}}`,
				key: 'displayProducts',
				metadata: undefined,
				type: 'BODY',
				value: '',
			},
		],
		Error: [],
	},
	DISPLAY_COLLECTIONS: {
		Action: [
			{
				dataType: 'STRING',
				defaultValue: '',
				description: '',
				displayName: t('components:node.whatsAppCommerce.workflows.viewExistingCart.displayMessage'),
				inputType: 'TEXT',
				valueType: 'CLIENT_INPUT',
				isRequired: true,
				tooltip: t('components:node.whatsAppCommerce.workflows.viewExistingCart.displayMessageTooltip'),
				key: 'displayMessage',
				metadata: undefined,
				type: 'BODY',
				value: '',
			},
			{
				dataType: 'STRING',
				defaultValue: '',
				description: '',
				displayName: t('components:node.whatsAppCommerce.workflows.displayProducts.action.productName.label'),
				inputType: 'TEXT',
				valueType: isShopifyEnabled ? 'HARDCODED' : 'CLIENT_INPUT',
				isRequired: true,
				tooltip: `${t(
					'components:node.whatsAppCommerce.workflows.displayProducts.action.productName.tooltip'
				)}{{commerce.display_product}}`,
				key: 'displayProducts',
				metadata: undefined,
				type: 'BODY',
				value: '',
			},
			{
				dataType: 'STRING',
				defaultValue: '',
				description: '',
				displayName: t('components:node.whatsAppCommerce.workflows.displayCollections.noCollections'),
				inputType: 'TEXT',
				valueType: 'CLIENT_INPUT',
				isRequired: true,
				tooltip: t(
					'components:node.whatsAppCommerce.workflows.displayCollections.noCollectionsToDisplayTooltip'
				),
				key: 'noCollectionsToDisplay',
				metadata: undefined,
				type: 'BODY',
				value: '',
			},
		],
		Error: [],
	},
});

export const WORK_FLOW_TAB_MAP: any = {
	SEARCH_PRODUCTS: ['searchTerm'],
	DISPLAY_PRODUCTS: ['displayProducts'],
	DISPLAY_COLLECTIONS: ['displayMessage', 'noCollectionsToDisplay'],
};
