import { combineReducers, StoreEnhancer } from 'redux';

import { createStore } from '@reduxjs/toolkit';

import { botSlice } from 'store/App/Bot';
import { botListSlice } from 'store/App/BotList';
import { jitsiSlice } from 'store/App/Jitsi';
import { notificationSlice } from 'store/App/Notifications';
import { planSlice } from 'store/App/Plan';
import { preferenceSlice } from 'store/App/Preferences';
import { tokenSlice } from 'store/App/Token';
import { userDetailsSlice } from 'store/App/User';
import { broadcastSlice } from 'store/Broadcast';
import { commonSlice } from 'store/Common';
import { userXpSlice } from 'store/Gamification';
import { messageSlice } from 'store/Message';
import { modalSlice } from 'store/Modal';
import { builderSlice } from 'store/PathBuilder';

import campaignSlice from './Campaign/store';

export const reducer = {
	[botSlice.name]: botSlice.reducer,
	[botListSlice.name]: botListSlice.reducer,
	[builderSlice.name]: builderSlice.reducer,
	[planSlice.name]: planSlice.reducer,
	[userDetailsSlice.name]: userDetailsSlice.reducer,
	[messageSlice.name]: messageSlice.reducer,
	[notificationSlice.name]: notificationSlice.reducer,
	[preferenceSlice.name]: preferenceSlice.reducer,
	[jitsiSlice.name]: jitsiSlice.reducer,
	[modalSlice.name]: modalSlice.reducer,
	[tokenSlice.name]: tokenSlice.reducer,
	[broadcastSlice.name]: broadcastSlice.reducer,
	[userXpSlice.name]: userXpSlice.reducer,
	[campaignSlice.name]: campaignSlice.reducer,
	[commonSlice.name]: commonSlice.reducer,
};

export const reducers = combineReducers(reducer);

export default function configureStore(initialState: StoreEnhancer<any>) {
	const store = createStore(createReducer(), initialState);

	store.asyncReducers = {};

	store.injectReducer = (key: string, asyncReducer: any) => {
		store.asyncReducers[key] = asyncReducer;
		store.replaceReducer(createReducer(store.asyncReducers));
	};

	return store;
}

function createReducer(asyncReducers?: any) {
	return combineReducers({
		...reducer,
		...asyncReducers,
	});
}

export type RootState = ReturnType<typeof reducers>;
