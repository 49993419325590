import React, { useEffect } from 'react';
import { useStyletron } from 'baseui';
import { KIND, SIZE } from 'baseui/button';
import { MonoLabelMedium } from 'baseui/typography';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ENGTButton from 'components/UI/ENGTButton/ENGTButton';
import DefaultDatePicker from 'components/UI/ENGTDatePicker/DefaultDatePicker';
import { FormInput } from 'components/UI/Form/FormInput/FormInput';
import FormSelect from 'components/UI/Form/FormSelect/FormSelect';
import FormTimePicker from 'components/UI/Form/FormTimePicker/FormTimePicker';

import { getTimeFromDate, setTimeToDate } from 'shared/helpers';
import AddIcon from 'shared/icons/AddIcon';
import MinusIcon from 'shared/icons/MinusIcon';

interface IAttribute {
	id: string;
	name: string;
	type: string;
	validation: string;
}

interface IENGTAttributeProps {
	buttonIndex: any;
	dataAttributes: any;
	formSuffix: string;
	flowAttributes: Array<IAttribute>;
	cardIndexId: string;
}

function AttributesAsQuickReply({
	buttonIndex,
	dataAttributes,
	flowAttributes,
	formSuffix,
	cardIndexId,
}: IENGTAttributeProps) {
	const [css, theme]: [any, any] = useStyletron();
	const { t } = useTranslation(['common', 'pages', 'errors', 'components']);

	const methods = useFormContext<any>();

	const { control, setValue, getValues } = methods;

	const { fields, remove, append } = useFieldArray({
		control,
		keyName: 'attributeId',
		name: `${formSuffix}${cardIndexId}buttons[${buttonIndex}].attributes`,
	});

	const handleAttributeOptionAddition = () => append({});
	const handleAttributeOptionDelete = (index: any) => remove(index);

	const formSelectValidation = (value: any) => {
		if (!value[0]?.id) {
			return t('errors:requiredErrorMessage') as string;
		}

		return true;
	};

	useWatch({
		control,
		name: ['buttons'],
	});

	useEffect(() => {
		fields.length == 0 && append({});
	}, []);

	const toDate = (data: any, type: any) => {
		if (data) {
			switch (type) {
				case 'DATE':
					const defaultDate = data.split('/');
					if (defaultDate?.length === 3) {
						const year = defaultDate[2];
						const month = defaultDate[1] - 1;
						const day = defaultDate[0];

						return new Date(year, month, day);
					}

					return new Date(data);

				case 'TIME':
					const defaultTime = data.split(':');
					const now = new Date();
					if (defaultTime?.length === 3) {
						now.setHours(defaultTime[0]);
						now.setMinutes(defaultTime[1]);
						now.setSeconds(defaultTime[2]);
					}

					return now;

				case 'DATETIME':
					const value = data.split(' ');
					const date = value[0]?.split('/');
					const time = value[1]?.split(':');
					if (date?.length === 3 && time?.length === 3) {
						return new Date(date[2], date[1] - 1, date[0], time[0], time[1], time[2]);
					}

					return new Date(data);
			}
		}
	};

	const renderInputComponent = (field: any, index: any) => {
		const attribute = flowAttributes.find(
			({ id }) => id === getValues(`${cardIndexId}buttons[${buttonIndex}].attributes[${index}].name[0].id`)
		);
		switch (attribute?.type) {
			case 'ARRAY':
				return (
					<Controller
						control={control}
						name={`${formSuffix}${cardIndexId}buttons[${buttonIndex}].attributes[${index}].value`}
						defaultValue={getValues(
							`${formSuffix}${cardIndexId}buttons[${buttonIndex}].attributes[${index}].value`
						)}
						rules={{
							validate: (value) => {
								if (value) {
									try {
										JSON.parse(value);

										return true;
									} catch (e) {
										return t('components:node.setUserAttribute.arrayNotValid').toString();
									}
								}

								return t('components:node.setUserAttribute.arrayRequired').toString();
							},
							required: t('components:node.setUserAttribute.arrayRequired').toString(),
						}}
						render={({ ref, value, onChange }) => (
							<FormInput
								key={index}
								value={value}
								inputRef={ref}
								width='inherit'
								marginBottom='0.5rem'
								placeholder={t('components:node.setUserAttribute.value')}
								id={`${formSuffix}${cardIndexId}buttons[${buttonIndex}].attributes[${index}].value`}
								name={`${formSuffix}${cardIndexId}buttons[${buttonIndex}].attributes[${index}].value`}
								onChange={onChange}
								errorCondition={{
									required: 'Required',
								}}
							/>
						)}
					/>
				);

			case 'NUMBER':
				return (
					<Controller
						control={control}
						name={`${formSuffix}${cardIndexId}buttons[${buttonIndex}].attributes[${index}].value`}
						defaultValue={getValues(
							`${formSuffix}${cardIndexId}buttons[${buttonIndex}].attributes[${index}].value`
						)}
						rules={{
							validate: (value) => {
								if (isNaN(value)) {
									return 'Not a valid number';
								}

								return true;
							},
							required: t('errors:requiredErrorMessage').toString(),
						}}
						render={({ ref, value, onChange }) => (
							<FormInput
								key={index}
								value={value}
								inputRef={ref}
								width='inherit'
								marginBottom='0.5rem'
								placeholder={t('components:node.setUserAttribute.value')}
								id={`${formSuffix}${cardIndexId}buttons[${buttonIndex}].attributes[${index}].value`}
								name={`${formSuffix}${cardIndexId}buttons[${buttonIndex}].attributes[${index}].value`}
								onChange={onChange}
								errorCondition={{
									required: 'Required',
								}}
							/>
						)}
					/>
				);

			case 'DATE':
				const defaultDate = toDate(
					getValues(
						`${formSuffix}${cardIndexId}buttons[${buttonIndex}].attributes[${index}].value`
					)?.toString(),
					'DATE'
				);

				return (
					<Controller
						control={control}
						defaultValue={defaultDate}
						key={`${formSuffix}${cardIndexId}buttons[${buttonIndex}].attributes[${index}].value`}
						name={`${formSuffix}${cardIndexId}buttons[${buttonIndex}].attributes[${index}].value`}
						rules={{
							required: t('errors:requiredErrorMessage').toString(),
						}}
						render={({ ref, value, onChange }) => (
							<DefaultDatePicker
								value={value}
								inputPlaceholder='Select Date'
								onChange={(date: any) => {
									onChange(date);
									setValue(
										`${formSuffix}${cardIndexId}buttons[${buttonIndex}].attributes[${index}].dateValue`,
										date?.toLocaleDateString()
									);
								}}
								popupIndex={3}
								isDateField
							/>
						)}
					/>
				);

			case 'TIME':
				const defaultTime = toDate(
					getValues(
						`${formSuffix}${cardIndexId}buttons[${buttonIndex}].attributes[${index}].value`
					)?.toString(),
					'TIME'
				);

				return (
					<Controller
						control={control}
						defaultValue={defaultTime}
						name={`${formSuffix}${cardIndexId}buttons[${buttonIndex}].attributes[${index}].value`}
						rules={{
							required: t('errors:requiredErrorMessage').toString(),
						}}
						render={({ ref, value, onChange }) => (
							<FormTimePicker
								name={`${formSuffix}${cardIndexId}buttons[${buttonIndex}].attributes[${index}].dateValue`}
								inputRef={ref}
								value={getTimeFromDate(value)}
								onChange={(time: string) => {
									setValue(
										`${formSuffix}${cardIndexId}buttons[${buttonIndex}].attributes[${index}].dateValue`,
										time
									);
									onChange(time);
								}}
								placeholder={t('common:selectTime')}
								popupIndex={theme.zIndex300}
							/>
						)}
					/>
				);

			case 'DATETIME':
				const defDateAndTime = toDate(
					getValues(
						`${formSuffix}${cardIndexId}buttons[${buttonIndex}].attributes[${index}].value`
					)?.toString(),
					'DATETIME'
				);

				return (
					<Controller
						key={`${formSuffix}${cardIndexId}buttons[${buttonIndex}].attributes[${index}].value`}
						control={control}
						name={`${formSuffix}${cardIndexId}buttons[${buttonIndex}].attributes[${index}].value`}
						defaultValue={defDateAndTime}
						rules={{
							required: t('errors:requiredErrorMessage').toString(),
						}}
						render={({ onChange, onBlur, value }) => (
							<>
								<div
									className={css({
										width: '100%',
									})}
								>
									<DefaultDatePicker
										value={value === '' ? undefined : value}
										onChange={(date: any) => {
											onChange(setTimeToDate(getTimeFromDate(value), date));
											setValue(
												`${formSuffix}${cardIndexId}buttons[${buttonIndex}].attributes[${index}].dateValue`,
												setTimeToDate(getTimeFromDate(value), date)
											);
										}}
										inputPlaceholder={t('common:date')}
										popupIndex={3}
										isDateField
									/>
								</div>
								<div
									className={css({
										width: '100%',
										marginTop: '0.625rem',
									})}
								>
									<FormTimePicker
										name={`${formSuffix}${cardIndexId}buttons[${buttonIndex}].attributes[${index}].dateValue`}
										inputRef=''
										value={getTimeFromDate(value)}
										onChange={(time: string) => {
											setValue(
												`${formSuffix}${cardIndexId}buttons[${buttonIndex}].attributes[${index}].dateValue`,
												setTimeToDate(time, value)
											);
											onChange(setTimeToDate(time, value));
										}}
										placeholder={t('common:selectTime')}
										popupIndex={theme.zIndex300}
									/>
								</div>
							</>
						)}
					/>
				);

			case 'TEXT':
			default:
				return (
					<Controller
						control={control}
						name={`${formSuffix}${cardIndexId}buttons[${buttonIndex}].attributes[${index}].value`}
						defaultValue={getValues(
							`${formSuffix}${cardIndexId}buttons[${buttonIndex}].attributes[${index}].value`
						)}
						rules={{
							required: t('errors:requiredErrorMessage').toString(),
						}}
						render={({ ref, value, onChange }) => (
							<FormInput
								errorCondition={{
									required: 'Required',
								}}
								key={index}
								value={value}
								id={`${formSuffix}${cardIndexId}buttons[${buttonIndex}].attributes[${index}].value`}
								name={`${formSuffix}${cardIndexId}buttons[${buttonIndex}].attributes[${index}].value`}
								inputRef={ref}
								onChange={(value: any) => {
									onChange(value);
								}}
								placeholder={t('components:node.setUserAttribute.value')}
								marginBottom='0.5rem'
								width='inherit'
							/>
						)}
					/>
				);
		}
	};

	return (
		<>
			<MonoLabelMedium
				className={css({
					fontWeight: 500,
					fontSize: '0.875rem ! important',
					lineHeight: '1.25rem ! important',
					textAlign: 'left',
				})}
			>
				{t('common:attribute')}
			</MonoLabelMedium>
			{fields.map((attribute, index) => (
				<div key={attribute.attributeId} className={css({ display: 'flex' })}>
					<div className={css({ flex: 1, marginRight: '0.5rem' })}>
						<Controller
							control={control}
							defaultValue={attribute.name}
							name={`${formSuffix}${cardIndexId}buttons[${buttonIndex}].attributes[${index}].name`}
							rules={{
								validate: (value) => formSelectValidation(value),
								required: t('errors:requiredErrorMessage').toString(),
							}}
							render={({ ref, value, onChange }) => (
								<FormSelect
									inputRef={ref}
									selectedValue={value}
									options={dataAttributes}
									createable
									popupIndex={theme.zIndex400}
									searchable
									marginBottom='0.5rem'
									name={`${formSuffix}${cardIndexId}buttons[${buttonIndex}].attributes[${index}].name`}
									placeholder={t('components:node.setUserAttribute.name')}
									onChange={(value) => {
										onChange(value);
										setValue(
											`${formSuffix}${cardIndexId}buttons[${buttonIndex}].attributes[${index}].value`,
											''
										);
										setValue(
											`${formSuffix}${cardIndexId}buttons[${buttonIndex}].attributes[${index}].dateValue`,
											''
										);
									}}
								/>
							)}
						/>
					</div>
					<div className={css({ flex: 1, marginLeft: '0.5rem' })}>
						<span>{renderInputComponent(attribute, index)}</span>
					</div>
					<span
						style={{ padding: '0.0.4375rem', marginTop: '0.8rem' }}
						onClick={() => {
							fields.length > 1 && handleAttributeOptionDelete(index);
						}}
					>
						<MinusIcon />
					</span>
				</div>
			))}
			<span style={{ display: 'flex', alignItems: 'flex-start' }}>
				<ENGTButton
					type='button'
					className={css({
						backgroundColor: '#E8E6E7',
						paddingTop: '0 !important',
						paddingBottom: '0 !important',
						marginTop: '0.5rem',
						marginBottom: '0.5rem',
						width: 'inherit',
					})}
					label={t('pages:broadcast.basic.add')}
					kind={KIND.secondary}
					size={SIZE.mini}
					startEnhancer={<AddIcon size={20} fillColor={theme.colors.primaryA} />}
					onClick={handleAttributeOptionAddition}
				/>
			</span>
		</>
	);
}

export default AttributesAsQuickReply;
