import React from 'react';
import { useStyletron } from 'baseui';

function Level5({
	size,
	fillColor,
	height,
	width,
}: {
	size?: number;
	fillColor?: string;
	height: number;
	width: number;
}) {
	const [, theme] = useStyletron();

	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' id='sun' height={height} width={width}>
			<path
				fill='#f5e680'
				fillRule='evenodd'
				d='M32 18.76a15.63 15.63 0 0 0-15.64 15.63 14.22 14.22 0 0 0 .1 1.73L32.86 40l14.68-3.91a14.22 14.22 0 0 0 .1-1.73A15.63 15.63 0 0 0 32 18.76Z'
			/>
			<path
				fill='#e9c03d'
				fillRule='evenodd'
				d='M32 3.33a1.92 1.92 0 0 1 1.93 1.93v6.39a1.93 1.93 0 0 1-3.86 0V5.26A1.92 1.92 0 0 1 32 3.33zm-19 6.8a1.93 1.93 0 0 1 2.72.27l4.06 4.94a1.93 1.93 0 1 1-3 2.45l-4.05-4.94a1.93 1.93 0 0 1 .27-2.72zM3.07 25.52a1.93 1.93 0 0 1 2.37-1.36l6.17 1.65a1.93 1.93 0 1 1-1 3.73l-6.17-1.65a1.94 1.94 0 0 1-1.37-2.37zM51 10.13a1.93 1.93 0 0 0-2.72.27l-4.06 4.94a1.93 1.93 0 0 0 3 2.45l4.05-4.94a1.93 1.93 0 0 0-.27-2.72zm9.93 15.39a1.93 1.93 0 0 0-2.37-1.36l-6.17 1.65a1.93 1.93 0 1 0 1 3.73l6.17-1.65a1.94 1.94 0 0 0 1.37-2.37z'
			/>
			<path
				fill='#d9f0f3'
				fillRule='evenodd'
				d='M8.75 34.23a8.23 8.23 0 0 0-1.75.19 19.91 19.91 0 0 0-4.17 1.8A3.6 3.6 0 0 0 1 39.46v6.85h62v-6.85a3.76 3.76 0 0 0-2-3.32l-1.85-1a8.46 8.46 0 0 0-7.74 0 8.44 8.44 0 0 1-7.76 0 8.46 8.46 0 0 0-7.74 0 8.44 8.44 0 0 1-7.76 0 8.46 8.46 0 0 0-7.74 0 8.42 8.42 0 0 1-7.75 0 8.48 8.48 0 0 0-3.88-.94Z'
			/>
			<path
				fill='#b8e3ee'
				fillRule='evenodd'
				d='M63 53.37v-7a3.74 3.74 0 0 0-2-3.26l-1.85-1a8.46 8.46 0 0 0-7.74 0 8.42 8.42 0 0 1-7.75 0 8.48 8.48 0 0 0-7.75 0 8.42 8.42 0 0 1-7.75 0 8.48 8.48 0 0 0-7.75 0 8.42 8.42 0 0 1-7.75 0 8.48 8.48 0 0 0-3.88-.94A8.23 8.23 0 0 0 7 41.4a20.48 20.48 0 0 0-4.17 1.81A3.56 3.56 0 0 0 1 46.4v6.89Z'
			/>
			<path
				fill='#d9f0f3'
				fillRule='evenodd'
				d='M8.75 48.17a8.23 8.23 0 0 0-1.75.18 19.91 19.91 0 0 0-4.17 1.8A3.6 3.6 0 0 0 1 53.39v4.72a2.57 2.57 0 0 0 2.57 2.56h56.86A2.57 2.57 0 0 0 63 58.1v-4.72a3.72 3.72 0 0 0-2-3.32l-1.85-1a8.46 8.46 0 0 0-7.74 0 8.42 8.42 0 0 1-7.75 0 8.48 8.48 0 0 0-7.75 0 8.42 8.42 0 0 1-7.75 0 8.48 8.48 0 0 0-7.75 0 8.42 8.42 0 0 1-7.75 0 8.48 8.48 0 0 0-3.88-.94Z'
			/>
			<path
				fill='#f9f3c7'
				fillRule='evenodd'
				d='M23.74 24.44a13.46 13.46 0 0 0-4 6 1 1 0 0 0 .63 1.27 1.12 1.12 0 0 0 .31.05 1 1 0 0 0 .95-.68A11.54 11.54 0 0 1 25 26a1 1 0 0 0 .11-1.41 1 1 0 0 0-.76-.35 1 1 0 0 0-.61.2Zm5.34-2.81a11.77 11.77 0 0 0-2.16.76 1 1 0 0 0-.49 1.33 1 1 0 0 0 .91.58 1 1 0 0 0 .42-.09 9.1 9.1 0 0 1 1.72-.62 1 1 0 0 0 .8-1 1.23 1.23 0 0 0 0-.2 1 1 0 0 0-1-.8Z'
			/>
		</svg>
	);
}

export default Level5;
