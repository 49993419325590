import './Mentions.scss';

import { useStyletron } from 'baseui';
import { useEffect, useRef } from 'react';

const ENGTMentions = (props) => {
	const {
		children,
		data,
		fieldVal = '',
		inputRef,
		isQuill = false,
		id = '',
		style = '',
		triggerKeyCode = 219,
		callback = (...args) => {},
	} = props;

	const TRIGGER_CHARS = { 50: '@', 51: '#', 219: '{' };
	const containerRef = useRef(null);
	const [css] = useStyletron();

	const bindEventListeners = (element) => {
		element.addEventListener('keypress', showDropdownList);
		element.addEventListener('keydown', showDropdownList);
		element.addEventListener('keyup', showDropdownList);
	};

	useEffect(() => {
		if (inputRef && inputRef.current) {
			if (!isQuill) {
				inputRef.current.value = fieldVal;
			}
		}
		if (id) {
			var inpVal = document.getElementById(id);
			if (inpVal) {
				inpVal.value = fieldVal;
			}
		}
		const inputElement =
			isQuill && inputRef.current?.getEditor
				? inputRef.current.getEditor()?.root
				: id
				? inpVal
				: inputRef.current?.tagName
				? inputRef.current
				: '';
		if (inputElement) {
			bindEventListeners(inputElement);
		}

		return () => {
			if (inputElement) {
				inputElement.removeEventListener('keypress', showDropdownList);
				inputElement.removeEventListener('keydown', showDropdownList);
				inputElement.removeEventListener('keyup', showDropdownList);
			}
		};
	}, [inputRef?.current, children, data]);

	const CLASSES = {
		marker: 'input__marker',
		visible: 'input__marker--visible',
	};

	const createMarker = (content, modifier) => {
		const marker = document.createElement('div');
		marker.classList.add(CLASSES.marker, `${CLASSES.marker}--${modifier}`);
		marker.style = style;
		marker.textContent = content;

		return marker;
	};

	const getQuillCaretXY = (editor) => {
		const index = editor && editor.getSelection() && editor.getSelection().index;
		const pos = editor && index && editor.getBounds(index);

		return {
			x: pos && pos.left - 10,
			y: pos && pos.top + 32,
		};
	};

	const getStrippedText = (e) => {
		const htmlTagsToExclude =
			/(<p[^>]+?>|<p>|<strong>|<\/strong>|<em>|<\/em>|<u>|<\/u>|<s>|<\/s>|<a[^>]+?>|<\/a>|<h1>|<\/h1>|<h2>|<\/h2>|<h3>|<\/h3>|<h4>|<\/h4>|<h5>|<\/h5>|<h6>|<\/h6>|<span[^>]+?>|<span>|<pre>|<\/pre>|<ol>|<\/ol>|<li>|<\/li>|<blockquote[^>]+?>|<\/blockquote>)/gim;
		const neglectUserEnteredTags = /(&lt;|&gt;)/g;

		const divElem = document.createElement('div');
		divElem.innerHTML = e.target.innerHTML.replace(/\n/g, ' ').replace(neglectUserEnteredTags, '.');

		let strippedText = divElem.innerHTML
			.replace(htmlTagsToExclude, '')
			.replace(/(<\/p>)/gim, '\n\n')
			.replace(/<img[^>]*>/g, ' ');
		strippedText = strippedText.replace(/(<br>)/gim, '\n');
		strippedText = strippedText.replace(/\n{3}/g, ' ');
		strippedText = strippedText.replace(/\n{2}/g, '\n');

		return strippedText;
	};

	/**
	 * check if (cmd + del) or (ctrl + bkspace) was pressed
	 */
	const isCmdAndDeletePressed = (e) => e.which === 8 && (e.metaKey || e.ctrlKey);

	const showDropdownList = (e) => {
		const { currentTarget: input, which, type } = e;

		let { selectionStart, value, innerText } = input;

		const strippedText = isQuill && getStrippedText(e);

		if (isQuill) {
			selectionStart =
				inputRef.current &&
				inputRef.current.getEditor() &&
				inputRef.current.getEditor().getSelection() &&
				inputRef.current.getEditor().getSelection().index;
			value = innerText;
		}

		const processClick = (evt) => {
			if (e !== evt && evt.target !== e.target) {
				if (input.__IS_SHOWING_CUSTOM_UI) {
					toggleCustomUI(input.__FILTER_STR);
				}
			}
		};

		const toggleItem = (dir = 'next') => {
			const list = input.__CUSTOM_UI.querySelector('ul');
			if (!input.__SELECTED_ITEM) {
				input.__SELECTED_ITEM = input.__CUSTOM_UI.querySelector('li');
				input.__SELECTED_ITEM.scrollIntoView(false);
				input.__SELECTED_ITEM.classList.add('custom-suggestions--active');
				input.__SELECTED_ITEM.id = 'engt-keyboard-active';
			} else {
				input.__SELECTED_ITEM.classList.remove('custom-suggestions--active');
				input.__SELECTED_ITEM.id = '';
				let nextActive = input.__SELECTED_ITEM[`${dir}ElementSibling`];
				if (!nextActive && dir === 'next') {
					nextActive = list.firstChild;
				} else if (!nextActive) {
					nextActive = list.lastChild;
				}
				input.__SELECTED_ITEM = nextActive;
				input.__SELECTED_ITEM.scrollIntoView(false);
				input.__SELECTED_ITEM.id = 'engt-keyboard-active';
				nextActive.classList.add('custom-suggestions--active');
			}
		};

		const filterList = () => {
			let unFilteredText = value;
			if (isQuill) {
				unFilteredText = strippedText;
			}
			/**
			 * if the trigger char is last character means we are opening new suggestion box so clear out the filter
			 * else use the previously stored filter string if its present
			 */
			const isTriggerChar = previousChar === TRIGGER_CHARS[triggerKeyCode];
			const filter = isTriggerChar
				? unFilteredText.slice(input.__EDIT_START, selectionStart).toLowerCase()
				: unFilteredText.slice(input.__EDIT_START, selectionStart).toLowerCase() || input.__FILTER_STR;
			const suggestions = data;
			const filteredSuggestions = Object.keys(suggestions).filter((entry) =>
				entry.toLowerCase().includes(filter.toLowerCase())
			);
			if (filteredSuggestions.length) {
				const sortedSuggestions = filteredSuggestions.sort(function (a, b) {
					return a.toLowerCase().localeCompare(b);
				});
				const suggestedList = document.createElement('ul');
				suggestedList.id = 'engt-attribute-list';
				suggestedList.classList.add('custom-suggestions');
				sortedSuggestions.forEach((entry) => {
					const entryItem = document.createElement('li');
					if (filter) {
						const startIdx = entry.toLowerCase().search(new RegExp(filter, 'gi'));
						const endIdx = startIdx + filter.length;
						const highlightedText = entry.substring(startIdx, endIdx);
						entryItem.innerHTML = `${entry.substring(
							0,
							startIdx
						)}<b>${highlightedText}</b>${entry.substring(endIdx, entry.length)}`;
					} else {
						entryItem.innerHTML = entry;
					}
					entryItem.addEventListener('mouseover', processEnterItem);
					entryItem.addEventListener('mouseout', processOutItem);
					suggestedList.appendChild(entryItem);
				});
				if (input.__CUSTOM_UI.firstChild) {
					input.__CUSTOM_UI.replaceChild(suggestedList, input.__CUSTOM_UI.firstChild);
				} else {
					input.__CUSTOM_UI.appendChild(suggestedList);
				}

				rePositionWithBody();
				toggleItem('next');
			} else {
				const searchStr = filter.slice(0, -1);
				toggleCustomUI(searchStr);
			}
		};

		const selectItem = (selected, click = false) => {
			let finalValue;

			if (isQuill) {
				if (triggerKeyCode === 50) {
					const editor = inputRef.current.getEditor();
					const lastRange = editor.selection.savedRange.index;
					let lastIndex = 0;
					const spaceRemovedText = strippedText;

					for (var i = lastRange; i > 0; i--) {
						if (spaceRemovedText[i] === '@') {
							lastIndex = i;
							break;
						}
					}
					editor.deleteText(lastIndex, lastRange - lastIndex);
					editor.insertText(lastIndex, `@${selected}`, 'bold', true);
					editor.setSelection(editor.root.innerText.length);
					editor.format('bold', false);
					callback(selected);
				} else {
					const editor = inputRef.current.getEditor();
					const lastRange = editor.selection.savedRange.index;
					let lastIndex = 0;
					const spaceRemovedText = strippedText;

					for (var i = lastRange; i > 0; i--) {
						if (spaceRemovedText[i] === TRIGGER_CHARS[triggerKeyCode]) {
							lastIndex = i;
							break;
						}
					}
					if (lastIndex >= 0) {
						editor.deleteText(lastIndex, lastRange - lastIndex);
						let characterCountTillTheLastValidCharacter = 0;
						let lastValidIndex = -1;
						for (let i = lastIndex - 1; i >= 0; i--) {
							if (spaceRemovedText[i] === TRIGGER_CHARS[triggerKeyCode]) {
								characterCountTillTheLastValidCharacter++;
							} else {
								lastValidIndex = i;
								break;
							}
						}
						editor.deleteText(lastValidIndex + 1, characterCountTillTheLastValidCharacter);
						if (lastValidIndex >= 0) {
							editor.insertText(lastValidIndex + 1, `{{${selected}}}`);
						} else {
							editor.insertText(0, `{{${selected}}}`);
						}
					}
					editor.setSelection(input.__EDIT_START + `{{${selected}}}`.length - 1);
				}
			} else {
				let start = input.value.slice(0, input.__EDIT_START);
				let lastValidIndex = 0;
				const spaceRemovedText = input.value;
				for (i = input.__EDIT_START - 1; i >= 0; i--) {
					if (spaceRemovedText[i] !== TRIGGER_CHARS[triggerKeyCode]) {
						lastValidIndex = i;
						break;
					}
				}
				start = start.slice(0, lastValidIndex ? lastValidIndex + 1 : lastValidIndex);
				finalValue = `${start}{{${selected}}}${input.__END_STR}`;
				input.value = finalValue;
				// set the cursor at end of string after adding the selected item instead of the default value of input.value.length
				input.selectionStart = start.length + selected.length + 4;
				input.selectionEnd = start.length + selected.length + 4;
				callback(finalValue);
			}
			toggleCustomUI();
		};

		const clickItem = (e) => {
			e.preventDefault();
			/*
			B for the filter search string, if user clicks on the bold part of string take the textcontent
			from the parent element i.e. the list item
			*/
			if (e.target.tagName === 'LI' || e.target.tagName === 'B') {
				input.focus();
				toggleCustomUI(input.__FILTER_STR);
				const textContent =
					e.target.tagName === 'B' ? e.target.parentElement.textContent : e.target.textContent;
				selectItem(textContent, true);
			}
		};

		const processEnterItem = (evt) => {
			let targetElement = evt.target;
			if (evt.target.nodeName !== 'LI') {
				targetElement =
					evt.target.className === 'check-mark'
						? evt.target.parentElement.parentElement
						: evt.target.parentElement;
			}
			targetElement.classList.add('custom-suggestions--active');

			if (input.__SELECTED_ITEM) {
				if (document.getElementById('engt-keyboard-active')) {
					input.__SELECTED_ITEM.classList.remove('custom-suggestions--active');
					input.__SELECTED_ITEM.id = '';
				}
			}
			input.__SELECTED_ITEM = targetElement;
		};

		const processOutItem = (evt) => {
			evt.target.classList.remove('custom-suggestions--active');
		};

		const toggleCustomUI = (searchStr) => {
			input.__EDIT_START = searchStr ? input.__EDIT_START : selectionStart;
			input.__FILTER_STR = searchStr || '';
			input.__IS_SHOWING_CUSTOM_UI = !input.__IS_SHOWING_CUSTOM_UI;

			if (input.__IS_SHOWING_CUSTOM_UI && !input.__CUSTOM_UI) {
				input.__CUSTOM_UI = createMarker(null, 'custom');
				document.getElementById('root').appendChild(input.__CUSTOM_UI);
				input.__CUSTOM_UI.addEventListener('click', clickItem);
				document.addEventListener('click', processClick);
			} else {
				input.__CUSTOM_UI.removeEventListener('click', clickItem);
				document.getElementById('root').removeChild(input.__CUSTOM_UI);
				const txtArea = document.getElementById('quillCopyTx');
				inputRef &&
					inputRef.current &&
					txtArea &&
					inputRef.current.getEditor().root.removeChild(document.getElementById(txtArea));
				document.removeEventListener('click', processClick);
				input.__SELECTED_ITEM = null;
				input.__CUSTOM_UI = null;
			}

			if (input.__IS_SHOWING_CUSTOM_UI) {
				filterList();
			}
		};

		const getLineNumbers = () => {
			const content = input.value.substr(0, selectionStart);
			const height = input.clientHeight;
			const lines = content.split(/\r?\n/);
			const val = 22 * lines.length;
			let ypos = val;
			if (val > height) {
				ypos = height - 10;
			}

			return { y: ypos };
		};

		const rePositionWithBody = () => {
			const editor = isQuill && inputRef.current.getEditor();
			const { y } = isQuill
				? getQuillCaretXY(editor)
				: input.tagName === 'TEXTAREA'
				? getLineNumbers()
				: { y: 40 };

			const mentioHeight = input.__CUSTOM_UI.clientHeight;
			const wrapperMiddle = Math.round((containerRef.current.clientWidth - input.__CUSTOM_UI.clientWidth) / 2);
			const coords = getCoordinatedsFromBody(containerRef.current);
			const top = coords.top - mentioHeight + y - 30;
			const left = coords.left + wrapperMiddle;

			input.__CUSTOM_UI.setAttribute('style', `top: ${top}px; left: ${left}px`);
		};

		const getCoordinatedsFromBody = (elem) => {
			const box = elem.getBoundingClientRect();

			const { body } = document;
			const docEl = document.documentElement;

			const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
			const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

			const clientTop = docEl.clientTop || body.clientTop || 0;
			const clientLeft = docEl.clientLeft || body.clientLeft || 0;

			const top = box.top + scrollTop - clientTop;
			const left = box.left + scrollLeft - clientLeft;

			return { top: Math.round(top), left: Math.round(left) };
		};

		const previousChar = isQuill ? strippedText.charAt(selectionStart - 1) : value.charAt(selectionStart - 1);

		if (which === triggerKeyCode && previousChar === TRIGGER_CHARS[triggerKeyCode]) {
			// store the string from the index mentions is triggered to the end of string
			input.__END_STR = !isQuill && input.value.slice(input.selectionStart, input.value.length);
			toggleCustomUI();
		} else if (input.__IS_SHOWING_CUSTOM_UI) {
			if (isCmdAndDeletePressed(e)) {
				toggleCustomUI();

				return;
			}
			switch (which) {
				case 32:
				case 27:
					toggleCustomUI();
					break;
				case 8:
					if (selectionStart < input.__EDIT_START) {
						toggleCustomUI();
					} else {
						filterList();
					}
					break;
				case 13:
					if (input.__SELECTED_ITEM) {
						e.preventDefault();
						e.stopPropagation();
						if (input.__CUSTOM_UI.querySelector('.custom-suggestions--active')) {
							selectItem(input.__CUSTOM_UI.querySelector('.custom-suggestions--active').textContent);
						}
					} else {
						toggleCustomUI();
					}
					break;
				case 38:
				case 40:
					if (type === 'keydown') {
						e.preventDefault();
						toggleItem(which === 38 ? 'previous' : 'next');
					}
					break;
				case 37:
				case 39:
					if (selectionStart < input.__EDIT_START + 1) {
						toggleCustomUI();
					}
					break;
				default:
					filterList();
					break;
			}
		} else if (
			!input.__IS_SHOWING_CUSTOM_UI &&
			which === 8 &&
			selectionStart > input.__EDIT_START &&
			input.__FILTER_STR
		) {
			toggleCustomUI(input.__FILTER_STR);
		}
	};

	return (
		<div
			ref={containerRef}
			className={css({
				position: 'relative',
				overflow: 'visible',
			})}
		>
			{children}
		</div>
	);
};

export default ENGTMentions;
