import React from 'react';
import { useStyletron } from 'baseui';
import { PLACEMENT } from 'baseui/popover';
import { useTranslation } from 'react-i18next';

import loadable from '@loadable/component';

import FilterDropdown from 'components/UI/FilterDropdown/FilterDropdown';

import { IObjProps } from 'shared/consts/types';
import EmoticonIcon from 'shared/icons/EmoticonIcon';

const EmojiContainer = loadable(
	() => import('components/UI/ENGTEmojis/EmojiContainer') /* webpackChunkName: "EmojiContainer" */
);

interface Props {
	quillRef: React.MutableRefObject<any>;
	overrideEmojiIconCss?: IObjProps;
}

function QuillEmoji({ quillRef, overrideEmojiIconCss = {} }: Props) {
	const { t } = useTranslation(['components']);
	const [css, theme]: [any, any] = useStyletron();

	const onEmojiSelection = (close: () => any, emoji: string) => {
		const editor = quillRef?.current?.getEditor();
		editor.insertText(editor.selection.savedRange.index, emoji);
		editor.setSelection(editor.selection.savedRange.index + 2, emoji);
		editor.focus();
		close();
	};
	const popupContainerCss = {
		marginLeft: '1rem',
		marginRight: '1rem',
		marginBottom: '1rem',
		marginTop: '1rem',
	};
	const dropdownCss = {
		position: 'absolute',
		bottom: '0.125rem',
		left: '9.5rem',
		zIndex: theme.zIndex100,
		...overrideEmojiIconCss,
	};

	return (
		<div className={css(dropdownCss)}>
			<FilterDropdown
				headingLabel={t('components:oneViewTextArea.emoticons.heading')}
				openOnClick
				popoverMargin={10}
				placement={PLACEMENT.bottomRight}
				showFooter={false}
				isOpen
				rootButton={<EmoticonIcon fillColor={theme.colors.primaryA} />}
				className={css({
					width: '20rem',
					zIndex: theme.zIndex400,
				})}
			>
				{(close: () => any) => (
					<div className={css(popupContainerCss)}>
						<EmojiContainer onSelection={(text) => onEmojiSelection(close, text)} />
					</div>
				)}
			</FilterDropdown>
		</div>
	);
}

export default QuillEmoji;
