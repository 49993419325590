import React, { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { Modal } from 'baseui/modal';
import { toaster } from 'baseui/toast';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ENGTImage from 'components/UI/ENGTImage/ENGTImage';
import ENGTToasterContainer from 'components/UI/ENGTToaster/ENGTToasterContainer';

import { REACT_APP_BASE_URL } from 'shared/config/config';
import { FILE_EXPIRY } from 'shared/consts/consts';
import { IObjProps } from 'shared/consts/types';
import { borderRadius, getResponsiveVH } from 'shared/helpers';

import { RootState } from 'store/rootReducer';

interface IENGTFileReceived {
	backgroundColor: string;
	file: IObjProps;
	textColor: string;
	timestamp: string;
	messageText?: string;
	messageId?: string;
	isPublic?: boolean;
}

const checkFileExpiry = (t: string) => {
	const timestamp = new Date(t);
	const expiryTime = new Date();
	expiryTime.setTime(timestamp.getTime() + FILE_EXPIRY);

	const currentTime = new Date();
	if (currentTime >= expiryTime) {
		return true;
	}

	return false;
};

function FileReceived(props: IENGTFileReceived) {
	const { file, timestamp, textColor, backgroundColor, messageText, messageId, isPublic } = props;
	const [css, theme]: [any, any] = useStyletron();
	const { t } = useTranslation(['common']);
	const cid = useSelector((state: RootState) => state.User.botAdmin.data.cid);
	const [isPublicUpload, setPublicUpload] = useState(true);
	const [isVideoMessage, setIsVideoMessage] = useState(true);
	const [isImageMessage, setIsImageMessage] = useState(true);
	const fileDescriptiveText = messageText || file.messageText || '';
	const [showImagePreview, setImagePreview] = useState<boolean>(false);

	const onClose = () => setImagePreview(false);

	const expired = checkFileExpiry(timestamp);

	const getUrlForToken = () => {
		const previewType = file.mimeType?.indexOf('image') > -1 ? 'preview_image' : 'download_attachment';

		return file.url?.indexOf('https') > -1
			? file.url
			: `${REACT_APP_BASE_URL}/portal/v2/${cid}/attachment/?token=${file.url}&response_type=${previewType}`;
	};
	if (expired) {
		return (
			<Block
				className={css({
					paddingLeft: '1rem',
					paddingRight: '1rem',
					paddingTop: '0.875rem',
					paddingBottom: '0.875rem',
					display: 'inline-block',
					borderRadius: '0.5rem',
					backgroundColor,
				})}
			>
				<span
					className={css({
						textDecoration: 'underline',
					})}
					onClick={() => {
						toaster.negative(
							<ENGTToasterContainer title={t('common:error')} description={t('common:fileExpired')} />,
							{}
						);
					}}
					id={messageId}
				>
					{file.name}
				</span>
			</Block>
		);
	}

	useEffect(() => {
		if (isPublic !== undefined && isPublic !== null) {
			setPublicUpload(isPublic);
		}
	}, []);

	return (
		<div
			className={css({
				paddingLeft: '1rem',
				paddingRight: '1rem',
				paddingTop: '0.875rem',
				paddingBottom: '0.875rem',
				display: 'inline-block',
				borderRadius: '0.5rem',
				backgroundColor,
			})}
			id={messageId}
		>
			{isPublicUpload && isImageMessage && (
				<a
					rel='noreferrer'
					className={css({
						display: 'flex',
						justifyContent: 'flex-start',
					})}
				>
					<Modal
						overrides={{
							Root: {
								style: {
									zIndex: theme.zIndex300,
								},
							},
							Dialog: {
								style: {
									background: 'transparent',
									textAlign: 'center',
									width: 'auto',
								},
							},
							Close: {
								style: {
									width: '2rem',
									height: '2rem',
									background: theme.colors.accent100,
									color: theme.colors.primaryBtnFontColor,
									borderWidth: '0rem',
									maskBorderWidth: '0rem',
									':focus': {
										outline: 'none',
									},
									...borderRadius('50%'),
								},
							},
						}}
						animate
						closeable
						isOpen={showImagePreview}
						onClose={() => setImagePreview(false)}
					>
						<ENGTImage
							lazy
							src={file.url}
							alt={file.name}
							className={css({
								maxHeight: getResponsiveVH(90),
								maxWidth: '90vw',
								...borderRadius('1rem'),
							})}
						/>
					</Modal>
					<ENGTImage
						lazy
						src={file.url}
						alt={file.name}
						onLoad={() => {
							setIsVideoMessage(false);
						}}
						onError={() => setIsImageMessage(false)}
						onClick={() => setImagePreview(true)}
						className={css({
							height: '7.5rem',
							width: '9.375rem',
							borderRadius: '0.5rem',
							cursor: 'pointer',
						})}
					/>
				</a>
			)}
			{isPublicUpload && isVideoMessage && (
				<a
					href={file.url}
					target='_blank'
					rel='noreferrer'
					className={css({
						display: 'flex',
						justifyContent: 'flex-start',
					})}
				>
					<video
						src={file.url}
						controls
						disablePictureInPicture
						controlsList='noplaybackrate nofullscreen nodownload'
						onLoadedData={() => {
							setIsImageMessage(false);
						}}
						onError={() => setIsVideoMessage(false)}
						className={css({
							height:
								(file?.mimeType && file.mimeType.indexOf('audio') !== -1) ||
								(file?.type && file.type.indexOf('audio') !== -1)
									? '3.5rem'
									: '7.5rem',
							width:
								(file?.mimeType && file.mimeType.indexOf('audio') !== -1) ||
								(file?.type && file.type.indexOf('audio') !== -1)
									? '16rem'
									: '9.375rem',
							borderRadius: '0.5rem',
						})}
					/>
				</a>
			)}
			{isPublicUpload && !isImageMessage && !isVideoMessage && (
				<a
					href={getUrlForToken()}
					target='_blank'
					rel='noreferrer'
					className={css({
						color: textColor,
					})}
				>
					{file.name}
				</a>
			)}
			{isPublicUpload && fileDescriptiveText && (
				<div
					className={css({
						textAlign: 'left',
						marginTop: '0.5rem',
						fontSize: '0.875rem',
						fontWeight: 'normal',
						lineHeight: '1.25rem',
					})}
				>
					{fileDescriptiveText}
				</div>
			)}
			{!isPublicUpload && file.name}
		</div>
	);
}

export default React.memo(FileReceived);
