import React from 'react';
import { useStyletron } from 'baseui';

function MedalGold({
	size,
	fillColor,
	height,
	width,
}: {
	size?: number;
	fillColor?: string;
	height: number;
	width: number;
}) {
	const [, theme] = useStyletron();

	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' id='medal' height={height} width={width}>
			<g>
				<path fill='#111315' d='m20 20 12 12 7-29H24l-4 17z' />
				<path fill='#a60416' d='m34.89 3-6.21 25.68-5.35-5.35L28.11 3h6.78z' />
				<path fill='#212529' d='M44 20 32 32 25 3h15l4 17z' />
				<path fill='#dd051d' d='m40.67 23.33-5.35 5.35L29.11 3h6.78l4.78 20.33z' />
				<circle cx='32' cy='45.5' r='15.5' fill='#fccd1d' />
				<circle cx='32' cy='45.5' r='11.392' fill='#f9a215' />
				<path
					fill='#fccd1d'
					d='m33.1 39.43 1.209 2.109a1.262 1.262 0 0 0 .853.606l2.424.473a1.225 1.225 0 0 1 .68 2.045l-1.678 1.776a1.219 1.219 0 0 0-.325.981l.289 2.4a1.255 1.255 0 0 1-1.778 1.264l-2.246-1.011a1.283 1.283 0 0 0-1.054 0l-2.246 1.011a1.255 1.255 0 0 1-1.778-1.264l.289-2.4a1.219 1.219 0 0 0-.325-.981l-1.678-1.776a1.225 1.225 0 0 1 .68-2.045l2.424-.473a1.262 1.262 0 0 0 .853-.606L30.9 39.43a1.274 1.274 0 0 1 2.2 0Z'
				/>
				<g fill='#1e120b'>
					<path d='M15.5 45.5a16.5 16.5 0 1 0 20.423-16.009l8.784-8.784a1 1 0 0 0 .267-.936l-4-17A1 1 0 0 0 40 2H24a1 1 0 0 0-.974.771l-4 17a1 1 0 0 0 .267.936l8.784 8.784A16.516 16.516 0 0 0 15.5 45.5ZM39.208 4 42.9 19.687l-1.666 1.667L37.152 4ZM35.1 4l4.472 19.017-3.7 3.7L30.381 4Zm-6.776 0 5.893 24.37-.707.706c-.4-.036-.8-.053-1.2-.06L26.271 4Zm1.87 24.78-.41-.411.156-.644ZM21.1 19.687l3.406-14.472 1.044 4.326-2.782 11.813Zm3.33 3.331 2.16-9.168 2.322 9.619-.785 3.244ZM32 31a14.5 14.5 0 1 1-14.5 14.5A14.517 14.517 0 0 1 32 31Z' />
					<path d='m28.647 41.164-2.424.474a2.246 2.246 0 0 0-1.716 1.515 2.186 2.186 0 0 0 .5 2.2l1.677 1.775a.223.223 0 0 1 .06.176l-.289 2.4a2.194 2.194 0 0 0 .895 2.054 2.28 2.28 0 0 0 2.287.24l2.246-1.011a.292.292 0 0 1 .232 0L34.362 52a2.286 2.286 0 0 0 2.287-.241 2.194 2.194 0 0 0 .895-2.054l-.289-2.4a.219.219 0 0 1 .059-.174l1.677-1.777a2.184 2.184 0 0 0 .5-2.2 2.246 2.246 0 0 0-1.716-1.515l-2.424-.474a.257.257 0 0 1-.177-.122l-1.21-2.109a2.273 2.273 0 0 0-3.932 0l-1.211 2.11a.255.255 0 0 1-.174.12Zm1.912.873 1.21-2.109a.244.244 0 0 1 .462 0l1.209 2.108a2.258 2.258 0 0 0 1.53 1.091l2.43.473a.223.223 0 0 1 .142.377l-1.679 1.778a2.212 2.212 0 0 0-.589 1.785l.289 2.4c.01.185-.158.312-.375.232l-2.246-1.011a2.285 2.285 0 0 0-1.874 0l-2.246 1.011a.252.252 0 0 1-.375-.232l.289-2.4a2.212 2.212 0 0 0-.59-1.785l-1.679-1.777a.224.224 0 0 1 .143-.377l2.425-.473a2.256 2.256 0 0 0 1.524-1.091Z' />
					<path d='M32 57.892a12.394 12.394 0 0 0 4.352-24 1 1 0 1 0-.7 1.873 10.392 10.392 0 1 1-7.3 0 1 1 0 1 0-.7-1.873 12.394 12.394 0 0 0 4.352 24Z' />
					<circle cx='32' cy='34' r='1' />
				</g>
			</g>
		</svg>
	);
}

export default MedalGold;
