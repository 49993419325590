import React, { Suspense, useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import Loader from 'components/UI/Loader/Loader';

import { MESSAGE_TAB_HEADERS_MAP } from 'shared/consts/consts';
import { IObjProps } from 'shared/consts/types';
import { getProfileColor } from 'shared/helpers';
import { getLastMessageData } from 'shared/helpers/messagesHelper';

import { getFacetsSortFromLs, getLiveChatUsersAction, setSelectedUserAction } from 'store/Message/actions';
import { RootState } from 'store/rootReducer';

import NoUsersAvailable from 'pages/Messages/MessageList/components/NoUsersAvailable';

import UserCardListItem from '../UI/UserCard/UserCardListItem';

import UserMessagesContainer from './UserMessagesContainer';

interface OpenContainer {
	user: IObjProps;
	id: number;
}

const ChatComponent: React.FC = () => {
	const [css, theme]: [any, any] = useStyletron();
	const location = useLocation();
	const { t } = useTranslation(['pages']);
	const dispatch = useDispatch<any>();

	const [isOpen, setIsOpen] = useState(false);
	const [selectedUsers, setSelectedUsers] = useState<IObjProps[]>([]);
	const [openContainers, setOpenContainers] = useState<OpenContainer[]>([]);

	const userListData = useSelector((state: RootState) => state.Message.userList);
	const lastMessageMap = useSelector((state: RootState) => state.Message.lastMessage);
	const conversationMessageDuration: any = useSelector(
		(state: RootState) => state.User.botAdmin.data.conversation_message_duration
	);
	const conversationReadStatusMap = useSelector((state: RootState) => state.Message.conversationReadStatusMap);
	const sentimentData = useSelector((state: RootState) => state.Message.sentiment);

	const MAX_CONTAINERS = 3;
	const usersArray = Array.isArray(userListData.data) ? userListData.data : [];

	const ChatContainer = styled.div<{ isOpen: boolean }>`
		position: fixed;
		bottom: 0;
		right: 0;
		width: 350px;
		height: ${(props) => (props.isOpen ? '450px' : '50px')};
		background-color: ${theme.modalPrimaryBackground};
		box-shadow: 0px 2px 14px rgba(25, 0, 0, 0.5);
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		transition: height 0.4s ease;
		overflow: hidden;
		z-index: 1000;
	`;

	const ChatHeader = styled.div`
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0.938rem;
		background: linear-gradient(135deg, #ae1536 0%, #fad0c4 100%);
		border-bottom: 1px solid #ddd;
		cursor: pointer;
		font-size: 1rem;
		font-weight: 600;
		color: white;
	`;

	const ChatContent = styled.div`
		overflow-y: auto;
		padding: 10px;
		height: calc(100% - 50px);
		zindex: 100000;
	`;

	useEffect(() => {
		if (isOpen) {
			const filters: any = dispatch(getFacetsSortFromLs(MESSAGE_TAB_HEADERS_MAP.active));
			dispatch(getLiveChatUsersAction(filters, 1, MESSAGE_TAB_HEADERS_MAP.active));
		}
	}, [isOpen, location]);

	const toggleChat = () => {
		setIsOpen((prev) => !prev);
	};

	const openUserContainer = (user: IObjProps) => {
		setOpenContainers((prev) => {
			const existingIndex = prev.findIndex((c) => c.user.user_id === user.user_id);
			if (existingIndex !== -1) {
				return prev;
			}
			let updatedContainers = [...prev, { id: user.user_id, user }];

			if (updatedContainers.length > MAX_CONTAINERS) {
				updatedContainers = updatedContainers.slice(1);
			}

			return updatedContainers;
		});
	};

	const closeUserContainer = (id: number) => {
		setOpenContainers(openContainers.filter((item) => item.id !== id));
		dispatch(setSelectedUserAction({}));
	};

	const calculateGap = (index: number) => {
		const containerWidth = 350;
		const gapBetweenContainers = 10;

		return index * (containerWidth + gapBetweenContainers) + 360;
	};

	const handleUserClick = (user: IObjProps) => {
		closeUserContainer(user.user_id);
		setSelectedUsers([...selectedUsers, user]);
		dispatch(setSelectedUserAction(user));
		openUserContainer(user);
	};

	const isConversationUnread = (user: IObjProps) =>
		conversationReadStatusMap.hasOwnProperty(user.conversation_id) &&
		!conversationReadStatusMap?.[user.conversation_id];

	return (
		<Suspense fallback={<Loader />}>
			<ChatContainer isOpen={isOpen}>
				<ChatHeader onClick={toggleChat}>
					<span>{t('pages:messages.messaging')}</span>
					<span>{isOpen ? '−' : '+'}</span>
				</ChatHeader>
				<ChatContent>
					{usersArray.length ? (
						usersArray.map((user: IObjProps, index: number) => (
							<div key={`${user.global_user_id}-${index}`} onClick={() => handleUserClick(user)}>
								<UserCardListItem
									key={`${user.global_user_id}-${index}`}
									channelName={user.platform}
									color={user.profileColor || getProfileColor(user.id || user.global_user_id || '0')}
									lastActiveAt={user.last_active_at}
									status={user.user_online}
									message={
										isConversationUnread(user)
											? t('pages:messages.newUnreadMessages')
											: getLastMessageData(
													lastMessageMap[user.conversation_id],
													conversationMessageDuration
												)
									}
									name={user.username}
									avatarSize='2.5rem'
									index={index}
									inVideoConversation={user.in_video_conversation}
									isInstagramPost={user?.custom_variable?.is_instagram_comment_user}
									highlightName={isConversationUnread(user)}
									avatar={user.profile_pic_url ? user.profile_pic_url : ''}
									sentiment={sentimentData?.data?.[user.id]}
								/>
							</div>
						))
					) : (
						<NoUsersAvailable />
					)}
				</ChatContent>
			</ChatContainer>
			{openContainers.map((container, index) => (
				<UserMessagesContainer
					key={container.id}
					isOpen
					selectedUser={container.user}
					onClose={() => closeUserContainer(container.id)}
					gap={calculateGap(index)}
				/>
			))}
		</Suspense>
	);
};
export default ChatComponent;
