import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';

import SkeletonCircleView from 'components/UI/ENGTSkeleton/ENGTSkeletonCircleView';
import SkeletonSquareView from 'components/UI/ENGTSkeleton/ENGTSkeletonSquareView';

function MessageListSkeletonView() {
	const [css, theme]: [any, any] = useStyletron();

	const userProfileAvatarCss = () => ({
		display: 'flex',
		width: '3rem',
		height: '2rem',
		position: 'relative',
		paddingBottom: '0.7rem',
		marginTop: '0.3rem',
	});

	const squareOverrideCss = {
		height: '100%',
		width: '100%',
		override: {
			Root: {
				style: { opacity: 0.09 },
			},
		},
	};

	return (
		<Block
			display='flex'
			justifyContent='left'
			className={css({
				height: '3.25rem',
				cursor: 'pointer',
				alignItems: 'center',
				marginBottom: '0.5rem',
				paddingBottom: '0.25rem',
				paddingTop: '0.25rem',
				paddingLeft: '0.5rem',
				paddingRight: '0.5rem',
			})}
		>
			<div className={css(userProfileAvatarCss())}>
				<span className={css({ height: '2.5rem' })} />
				<SkeletonCircleView diameter='2.5rem' />
			</div>
			<Block width='100%' marginLeft='0.5rem'>
				<span
					className={css({
						display: 'flex',
						height: '2.5rem',
					})}
				>
					<SkeletonSquareView
						height={squareOverrideCss.height}
						width={squareOverrideCss.width}
						override={squareOverrideCss.override}
					/>
				</span>
			</Block>
		</Block>
	);
}

export default MessageListSkeletonView;
