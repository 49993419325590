import React from 'react';
import { useStyletron } from 'baseui';

function SilverMedal({
	size,
	fillColor,
	height,
	width,
}: {
	size?: number;
	fillColor?: string;
	height: number;
	width: number;
}) {
	const [, theme] = useStyletron();

	return (
		<svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none' id='silver-medal'>
			<path
				fill='#C52229'
				fillRule='evenodd'
				d='M36.017 8h23.819c9.08 0 14.87 9.694 10.565 17.69L52.21 59.473a1 1 0 0 1-.88.526h-6.806a1 1 0 0 1-.88-.526L25.451 25.69C21.146 17.694 26.937 8 36.017 8Zm-4.09 8h32L52.132 57.275a1 1 0 0 1-.961.725H44.68a1 1 0 0 1-.961-.725L31.926 16Z'
				clipRule='evenodd'
			/>
			<path
				fill='#9B0F15'
				fillRule='evenodd'
				d='M25.45 25.69C21.147 17.693 26.937 8 36.018 8h23.818c9.08 0 14.87 9.694 10.566 17.69L52.209 59.473a1 1 0 0 1-.88.526h-6.806a.997.997 0 0 1-.88-.526L25.45 25.69ZM43.72 57.274a1 1 0 0 0 .96.725H51.173a1 1 0 0 0 .96-.725L63.926 16h-32l11.793 41.275ZM34.577 18l10.858 38h4.982l10.858-38H34.578Zm1.44-12h23.818c10.594 0 17.35 11.31 12.327 20.637L53.97 60.422A3 3 0 0 1 51.33 62h-6.806a3 3 0 0 1-2.641-1.578L23.69 26.637C18.668 17.31 25.423 6 36.017 6Z'
				clipRule='evenodd'
			/>
			<path
				fill='#F1333B'
				d='M36.017 8h23.819c9.08 0 14.87 9.694 10.565 17.69 0-8.25-9.62-9.897-14.43-9.69h-16.09c-12.873 0-14.984 6.46-14.43 9.69C21.146 17.693 26.937 8 36.017 8Z'
			/>
			<path
				fill='#9B0F15'
				fillRule='evenodd'
				d='M69.912 27.628a2 2 0 0 1-1.511-1.939c0-3.127-1.73-5.012-4.264-6.206-2.624-1.237-5.883-1.58-8.08-1.485a1.902 1.902 0 0 1-.085.002H39.88c-6.132 0-9.29 1.539-10.852 3.064-1.556 1.52-1.782 3.261-1.606 4.287a2 2 0 0 1-3.732 1.286C18.668 17.31 25.423 6 36.017 6h23.818c10.594 0 17.35 11.31 12.327 20.637a2 2 0 0 1-2.25.991ZM24.074 21.33C23.308 14.555 28.55 8 36.017 8h23.818c7.64 0 12.951 6.863 11.88 13.802A12.063 12.063 0 0 1 70.4 25.69c0-1.402-.278-2.613-.763-3.657-2.368-5.098-9.674-6.204-13.666-6.032H39.88c-8.475 0-12.285 2.8-13.741 5.635-.756 1.47-.877 2.95-.688 4.054a12.019 12.019 0 0 1-1.377-4.359Z'
				clipRule='evenodd'
			/>
			<path
				fill='#FE666D'
				fillRule='evenodd'
				d='M29.743 10.638C32.09 9.258 34.673 9 36.5 9h24a1 1 0 1 1 0 2h-24c-1.674 0-3.84.243-5.743 1.362-1.857 1.092-3.574 3.084-4.274 6.822a1 1 0 0 1-1.966-.368c.799-4.262 2.832-6.77 5.226-8.178Z'
				clipRule='evenodd'
			/>
			<path
				fill='#AA161D'
				d='M64.846 22.726c0 .566-.448 1.025-1 1.025s-1-.46-1-1.026c0-.566.448-1.025 1-1.025.553 0 1 .46 1 1.026zm-.4 3.9c0 .705-.557 1.276-1.244 1.276-.687 0-1.244-.571-1.244-1.276 0-.704.557-1.275 1.244-1.275.687 0 1.244.571 1.244 1.276zm2.845-1.737a.63.63 0 0 1-.622.637.63.63 0 0 1-.622-.637.63.63 0 0 1 .622-.638.63.63 0 0 1 .622.638zm-3.823 5.088a.63.63 0 0 1-.622.638.63.63 0 0 1-.622-.637.63.63 0 0 1 .622-.638.63.63 0 0 1 .622.637zm4.22-1.037c0 .573-.454 1.037-1.013 1.037-.56 0-1.012-.464-1.012-1.037 0-.574.453-1.038 1.012-1.038.56 0 1.013.464 1.013 1.038zm-2.025 3.71c0 .573-.454 1.037-1.013 1.037-.559 0-1.012-.464-1.012-1.037s.453-1.038 1.012-1.038c.56 0 1.013.465 1.013 1.038zm-3.44.4a.63.63 0 0 1-.621.638.63.63 0 0 1-.622-.638.63.63 0 0 1 .622-.638.63.63 0 0 1 .622.638z'
			/>
			<path
				fill='#BCCCD2'
				d='M56.967 39.353c4.146-.609 7.748.178 10.125 2.555 2.377 2.377 3.164 5.98 2.555 10.125C73.01 54.535 75 57.638 75 61s-1.99 6.465-5.353 8.966c.609 4.147-.178 7.749-2.555 10.126-2.377 2.377-5.98 3.164-10.126 2.555C54.466 86.01 51.362 88 48 88c-3.362 0-6.465-1.99-8.966-5.353-4.146.61-7.749-.178-10.126-2.555-2.377-2.377-3.164-5.98-2.555-10.126C22.99 67.466 21 64.362 21 61c0-3.362 1.99-6.465 5.353-8.967-.609-4.145.178-7.748 2.555-10.125 2.377-2.377 5.98-3.164 10.125-2.555C41.535 35.991 44.638 34 48 34s6.465 1.99 8.967 5.353Z'
			/>
			<path
				fill='#81A5B0'
				fillRule='evenodd'
				d='M57.844 37.236c4.086-.37 7.94.535 10.662 3.258 2.723 2.723 3.627 6.576 3.259 10.662C74.915 53.786 77 57.15 77 61c0 3.851-2.085 7.215-5.235 9.844.369 4.086-.536 7.94-3.259 10.662-2.723 2.723-6.576 3.628-10.663 3.259C55.215 87.915 51.852 90 48 90c-3.85 0-7.215-2.085-9.843-5.235-4.087.369-7.94-.536-10.663-3.259s-3.628-6.576-3.259-10.662C21.085 68.214 19 64.85 19 61c0-3.851 2.085-7.215 5.235-9.844-.368-4.086.536-7.939 3.259-10.662 2.723-2.723 6.576-3.627 10.662-3.258C40.785 34.085 44.15 32 48 32c3.851 0 7.215 2.085 9.844 5.236Zm-.877 2.117C54.465 35.991 51.362 34 48 34s-6.465 1.99-8.967 5.353c-4.146-.609-7.748.178-10.125 2.555-2.377 2.377-3.164 5.98-2.555 10.125C22.99 54.535 21 57.638 21 61s1.99 6.465 5.353 8.966c-.609 4.147.178 7.749 2.555 10.126 2.377 2.377 5.98 3.164 10.126 2.555C41.534 86.01 44.638 88 48 88c3.362 0 6.465-1.99 8.966-5.353 4.147.61 7.749-.178 10.126-2.555 2.377-2.377 3.164-5.98 2.555-10.126C73.01 67.466 75 64.362 75 61c0-3.362-1.99-6.465-5.353-8.967.609-4.145-.178-7.748-2.555-10.125-2.377-2.377-5.98-3.164-10.125-2.555Z'
				clipRule='evenodd'
			/>
			<path
				fill='#A2B9C1'
				fillRule='evenodd'
				d='M73 60a1 1 0 0 1 1 1c0 3.367-1.897 6.37-4.89 8.744.438 3.794-.344 7.26-2.725 9.64-2.381 2.382-5.847 3.164-9.641 2.726C54.37 85.103 51.367 87 48 87a1 1 0 1 1 0-2c2.686 0 5.295-1.589 7.5-4.553a1 1 0 0 1 .947-.393c3.656.537 6.624-.184 8.524-2.083 1.899-1.9 2.62-4.868 2.083-8.523a1 1 0 0 1 .393-.948C70.41 66.295 72 63.686 72 61a1 1 0 0 1 1-1Z'
				clipRule='evenodd'
			/>
			<path
				fill='#D0DEE3'
				fillRule='evenodd'
				d='M48 37c-2.686 0-5.295 1.59-7.5 4.553a1 1 0 0 1-.948.393c-3.655-.537-6.623.185-8.523 2.084-1.898 1.899-2.62 4.867-2.083 8.522a1 1 0 0 1-.393.948C25.59 55.705 24 58.314 24 61a1 1 0 1 1-2 0c0-3.367 1.898-6.37 4.89-8.744-.438-3.794.345-7.26 2.725-9.64 2.381-2.381 5.847-3.163 9.64-2.726C41.63 36.898 44.634 35 48 35a1 1 0 1 1 0 2Z'
				clipRule='evenodd'
			/>
			<path
				fill='#D2E2E7'
				d='M54.642 44.965c3.071-.45 5.74.132 7.5 1.893 1.761 1.76 2.344 4.43 1.893 7.5C66.525 56.211 68 58.51 68 61s-1.475 4.79-3.965 6.642c.45 3.071-.132 5.74-1.893 7.5-1.76 1.761-4.429 2.344-7.5 1.893C52.789 79.525 50.49 81 48 81s-4.789-1.475-6.642-3.965c-3.071.451-5.74-.132-7.5-1.893-1.761-1.76-2.344-4.429-1.893-7.5C29.475 65.789 28 63.49 28 61s1.475-4.79 3.965-6.642c-.45-3.071.132-5.74 1.893-7.5 1.76-1.76 4.429-2.344 7.5-1.893C43.211 42.475 45.51 41 48 41s4.79 1.475 6.642 3.965Z'
			/>
			<path
				fill='#81A5B0'
				fillRule='evenodd'
				d='M33.858 75.142c-1.761-1.76-2.344-4.429-1.893-7.5C29.475 65.789 28 63.49 28 61s1.475-4.79 3.965-6.642c-.45-3.071.132-5.74 1.893-7.5 1.76-1.76 4.429-2.344 7.5-1.893C43.211 42.475 45.51 41 48 41s4.79 1.475 6.642 3.965c3.071-.45 5.74.132 7.5 1.893 1.761 1.76 2.344 4.43 1.893 7.5C66.525 56.211 68 58.51 68 61s-1.475 4.79-3.965 6.642c.45 3.071-.132 5.74-1.893 7.5-1.76 1.761-4.429 2.344-7.5 1.893C52.789 79.525 50.49 81 48 81s-4.789-1.475-6.642-3.965c-3.071.451-5.74-.132-7.5-1.893Zm6.628 4c-3.032.22-5.945-.488-8.042-2.585-2.098-2.098-2.805-5.011-2.586-8.043C27.56 66.526 26 63.966 26 61s1.56-5.526 3.858-7.515c-.219-3.03.488-5.944 2.586-8.041 2.097-2.098 5.01-2.805 8.041-2.586C42.474 40.56 45.034 39 48 39c2.967 0 5.526 1.56 7.515 3.858 3.03-.219 5.944.488 8.041 2.586 2.098 2.097 2.805 5.01 2.586 8.041C68.44 55.474 70 58.035 70 61c0 2.966-1.56 5.526-3.858 7.514.219 3.032-.488 5.945-2.586 8.043-2.097 2.097-5.01 2.804-8.042 2.585C53.526 81.44 50.966 83 48 83s-5.526-1.56-7.514-3.858Z'
				clipRule='evenodd'
			/>
			<path
				fill='#BAD0D7'
				fillRule='evenodd'
				d='M66 60a1 1 0 0 1 1 1c0 2.49-1.378 4.69-3.506 6.418.283 2.726-.298 5.256-2.059 7.017-1.761 1.761-4.29 2.342-7.017 2.06C52.69 78.621 50.49 80 48 80a1 1 0 1 1 0-2c1.814 0 3.619-1.073 5.175-3.165a1 1 0 0 1 .948-.393c2.58.379 4.615-.138 5.898-1.421 1.283-1.283 1.8-3.318 1.421-5.898a1 1 0 0 1 .392-.948C63.927 64.62 65 62.815 65 61a1 1 0 0 1 1-1Z'
				clipRule='evenodd'
			/>
			<path
				fill='#E1F4FA'
				fillRule='evenodd'
				d='M48 44c-1.814 0-3.619 1.073-5.175 3.166a1 1 0 0 1-.948.392c-2.58-.379-4.615.139-5.898 1.421-1.282 1.283-1.8 3.318-1.421 5.898a1 1 0 0 1-.392.948C32.073 57.38 31 59.185 31 61a1 1 0 1 1-2 0c0-2.49 1.378-4.69 3.506-6.418-.283-2.726.298-5.256 2.059-7.017 1.761-1.761 4.29-2.342 7.017-2.06C43.31 43.379 45.509 42 48 42a1 1 0 1 1 0 2Z'
				clipRule='evenodd'
			/>
			<path
				fill='#E6F7FD'
				d='M53.855 69H42.16v-2.32l5.52-5.883c.758-.828 1.316-1.55 1.675-2.168.368-.617.551-1.203.551-1.758 0-.758-.191-1.352-.574-1.781-.383-.438-.93-.656-1.64-.656-.766 0-1.372.265-1.817.797-.438.523-.656 1.214-.656 2.074H41.82c0-1.04.246-1.989.739-2.848a5.246 5.246 0 0 1 2.109-2.016c.906-.492 1.934-.738 3.082-.738 1.758 0 3.121.422 4.09 1.266.976.843 1.465 2.035 1.465 3.574 0 .844-.22 1.703-.657 2.578-.437.875-1.187 1.895-2.25 3.059l-3.879 4.09h7.337V69Z'
			/>
			<path
				fill='#81A5B0'
				fillRule='evenodd'
				d='M55.855 69a2 2 0 0 1-2 2H42.16a2 2 0 0 1-2-2v-2.32a2 2 0 0 1 .542-1.369l5.51-5.873c.705-.771 1.157-1.372 1.415-1.816l.01-.016c.236-.397.27-.632.27-.735a1.344 1.344 0 0 0-.057-.426.948.948 0 0 0-.159-.011.58.58 0 0 0-.224.03.089.089 0 0 0-.02.011.256.256 0 0 0-.038.04c-.066.078-.19.277-.19.79a2 2 0 0 1-2 2H41.82a2 2 0 0 1-2-2c0-1.37.329-2.665 1.003-3.842l.007-.012a7.243 7.243 0 0 1 2.89-2.77c1.23-.667 2.589-.978 4.03-.978 2.06 0 3.953.496 5.4 1.755 1.5 1.297 2.155 3.089 2.155 5.085 0 1.2-.313 2.364-.868 3.473-.554 1.109-1.44 2.284-2.561 3.512l-.026.028-.677.713h2.682a2 2 0 0 1 2 2V69Zm-9.335-2.73 3.878-4.09c1.063-1.164 1.813-2.184 2.25-3.059.438-.875.657-1.734.657-2.578 0-1.54-.489-2.73-1.465-3.574-.969-.844-2.332-1.266-4.09-1.266-1.148 0-2.176.246-3.082.738a5.246 5.246 0 0 0-2.505 2.864 5.772 5.772 0 0 0-.343 2h3.399c0-.86.218-1.551.656-2.075.445-.53 1.05-.796 1.816-.796.711 0 1.258.218 1.641.656.383.43.574 1.023.574 1.781 0 .555-.183 1.14-.55 1.758-.36.617-.919 1.34-1.676 2.168l-5.52 5.883V69h11.696v-2.73h-7.337Z'
				clipRule='evenodd'
			/>
		</svg>
	);
}

export default SilverMedal;
