import { toaster } from 'baseui/toast';
import { NavigateFunction } from 'react-router-dom';

import ENGTToasterContainer from 'components/UI/ENGTToaster/ENGTToasterContainer';

import {
	ACCOUNT_TYPES,
	BUILDER_FORM_BUTTON_ID,
	CAROUSEL_SOURCE_TYPES,
	CURRENCY_MAP,
	EXTERNAL_INTEGRATION_PROVIDER,
} from 'shared/consts/consts';
import {
	AdditionalFieldNames,
	DATA_TYPE,
	DEFAULT_RESPONSE_ATTR,
	INPUT_TYPE,
	INTEGRATIONS,
	PARAM_MAP_RESPONSE_DATA,
	RESPONSE_ATTR_DATA,
	SEND_EMAIL_MODE,
	WORKFLOW_KEYS,
} from 'shared/consts/Integrations';
import { WORK_FLOW_TAB_MAP } from 'shared/consts/shopify/shopifyOrderListStatus';
import { IObjProps } from 'shared/consts/types';
import { WORK_FLOW_TAB_MAP as WHATSAPP_COMMERCE_WORK_FLOW_TAB_MAP } from 'shared/consts/whatsAppCommerce/tabsForWorkFlowData';
import {
	bodyType,
	BOT_VERSION,
	content,
	FLOW,
	FLOW_OPTIONS,
	NODE_TYPE,
	WA_COMMERCE_ACTIONS,
	WA_RAPIDPLAN,
} from 'shared/enum';
import {
	getEnvironment,
	isEmpty,
	isValidResponseObject,
	isValidResponseObjectForUserAPI,
	NODE_CATEGORIES,
	toSnakeCase,
} from 'shared/helpers';
import { processQuillContent } from 'shared/helpers/quillHelper';
import {
	getBodyValue,
	getNodeKey,
	processChatHistoryAttrs,
	replaceVariablesInMsg,
	replaceVariablesInMsgArray,
	validateAudioUrl,
	validateDocumentUrl,
	validateImageUrl,
} from 'shared/pathBuilderHelpers';
import { TEMPLATE_BUTTON_TYPES, templateComponentParameters } from 'shared/whatsappTemplateHelpers';

import { isAcceleratorEcomSelector } from 'store/App/User';
import { getCampaignsAction } from 'store/Broadcast';
import { CloudIntegrationsApi } from 'store/Integrations/CloudIntegrations/api';
import { openErrorSavingModalAction, openInfoModalAction } from 'store/Modal/actions';
import { updateNodeUpgradeMarker } from 'store/PathBuilder/helpers';
import { RootState } from 'store/withReducer';

import { i18nHelper } from 'i18nHelper';

import { CUSTOMS_OPTIONS, ORDER_TYPE } from 'pages/PathBuilder/components/Nodes/WhatsAppPayment/helpers';

import { API } from './api';
import { globalZoomScaleInterface } from './state';
import { actions } from './store';

const {
	fetchEntitlements,
	getAllFlows,
	getAllFlowsError,
	setAllFlowsLoader,
	getFlowAttributes,
	getAllAttributes,
	getFlowData,
	getFlowDataError,
	getNodesByQuery,
	getPathVariables,
	setNodesAttrList,
	setSelectedNodeDetails,
	setHighlightedNodeDetails,
	setFlowDataLoader,
	updateFlowDataNodes,
	updateIsFormSubmittingState,
	updateCopyAttrList,
	updateSelectedNodeName,
	setBotRef,
	setFlowDataNodeCoordinates,
	setFlowDataNodeName,
	resetAllPathBuilderData,
	setExternalIntegrationKeys,
	setExternalIntegrations,
	setConnectedUsers,
	setZoomScale,
} = actions;

const NODES_THAT_CREATE_ATTRIBUTES = [
	'SEND_CAROUSEL',
	'SET_USER_ATTRIBUTES',
	'HTTP_REQUEST',
	'SEND_MSG_OPTNS',
	'RCV_INP',
	'INTEGRATION',
	'FORM_NODE',
	'IDENTITY_NODE',
	'SLIDER_NODE',
	'TEMPLATE_MESSAGE',
];

export const addAttributeToCopyList =
	(type: string, nodeKey?: string) => (dispatch: any, getState: () => RootState) => {
		const payload = {
			type,
			nodeKey: nodeKey ? [nodeKey] : [],
			allAttributes: Object.keys(getState().PathBuilder.nodesAttrList.data),
		};
		dispatch(updateCopyAttrList(payload));
	};

export const saveFlowNodeDataCoordinateAction = (flowName: string, data: Object) => (dispatch: any) =>
	API.saveFlowNodeCoordinateData(flowName, data).then((resp: any) => {
		if (resp.data) {
		} else {
			console.log('error saving coordinate');
		}
	});

export const editNodeNameBackend =
	(pathKey: string, data: IObjProps, nodeKey: string, newName: string) => (dispatch: any) => {
		let dataToSend: any = { ...data, node_name: newName };
		if (data.node_type === 'SCRIPT_NODE') {
			dataToSend = { ...dataToSend, node_data: { ...dataToSend.node_data, error_msg: dataToSend.error_msg } };
		} else if (data.node_type === 'SUBSCRIBE_CAMPAIGN' || data.node_type === 'UNSUBSCRIBE_CAMPAIGN') {
			dataToSend = { ...dataToSend, node_type: 'CAMPAIGN_NODE' };
		}

		return API.editNodeName(dataToSend, pathKey, nodeKey).then((resp: any) => {
			if (resp.data) {
				dispatch(updateSelectedNodeNameAction(newName));

				// submit node data form after successful node name change
				const formSubmitBtn = document.getElementById(BUILDER_FORM_BUTTON_ID);
				formSubmitBtn && formSubmitBtn.click();

				toaster.positive(
					<ENGTToasterContainer
						title={i18nHelper('components:editNodeName.title')}
						description={i18nHelper('components:editNodeName.description')}
					/>,
					{}
				);
			} else {
				dispatch(updateSelectedNodeNameAction(newName));
				dispatch(openErrorSavingModalAction());
			}

			return resp;
		});
	};

export const editNodeNameRedux = (pathKey: string, newName: string) => (dispatch: any) => {
	const payload = {
		newName,
	};
	dispatch(setFlowDataNodeName(payload));
};

export const getPostBackForSmoOption = (
	option: any,
	nodeValue: string,
	msgOptionType: string,
	attributeName: string
) => {
	let postback = '';
	if (msgOptionType === 'data_attribute_trigger_node') {
		postback = `${nodeValue ? `${nodeValue}||` : ''}data_${attributeName}=${option.attr}`;
	} else if (msgOptionType === 'trigger_node') {
		postback = nodeValue || '';
	} else if (msgOptionType === 'data_attribute') {
		postback = `data_${attributeName}=${option.attr}`;
	}

	return postback;
};

export const addEditNodeAction =
	(
		pathKey: string,
		data: IObjProps,
		url: string,
		isEdit: boolean,
		nodeKey: string,
		sourceIndex: number,
		flowData: any,
		iterator: any,
		carouselCardIndex = 0
	) =>
	(dispatch: any) => {
		const dataToSend = { ...data };
		if (!isEdit) {
			delete dataToSend.node_key;
		}
		delete dataToSend.isEdit;
		delete dataToSend.coordinates;

		return API.addEditNode(dataToSend, isEdit, url).then((resp: any) => {
			if (resp.data) {
				if (isEdit) {
					if (NODES_THAT_CREATE_ATTRIBUTES.indexOf(dataToSend.node_type) > -1) {
						dispatch(getPathVariablesAction(pathKey));
						dispatch(getFlowAttributesAction());
						dispatch(getPathAttributesAction(pathKey));
					}
				} else {
					if (NODES_THAT_CREATE_ATTRIBUTES.indexOf(dataToSend.node_type) > -1) {
						dispatch(getPathVariablesAction(pathKey));
						dispatch(getFlowAttributesAction());
						dispatch(getPathAttributesAction(pathKey));
					}
					const dataCoordinate = {
						[resp.data.node_data.node_key]: {
							xCoordinate: data.coordinates?.xCoordinate,
							yCoordinate: data.coordinates?.yCoordinate,
							height: 140,
						},
					};
					dispatch(saveFlowNodeDataCoordinateAction(pathKey, dataCoordinate));

					if (nodeKey === 'triggerNode') {
						const { node_key, node_type } = flowData[sourceIndex];
						const respNodeData = resp.data.node_data;
						let modifiedData = flowData[sourceIndex].node_data;
						const triggerNodeKey =
							(respNodeData?.type === 'RCV_INP' || respNodeData?.type === 'RCV_INP_ENTITY') &&
							respNodeData?.node_key?.indexOf('_1') === -1
								? `${respNodeData?.node_key}_1`
								: respNodeData?.node_key;
						const nodeValue = `node_${triggerNodeKey}`;
						if (node_type === 'SEND_MSG_OPTNS' && respNodeData) {
							const smoNodeData = flowData[sourceIndex].node_data;
							const newOptions = smoNodeData?.options.map((option: any, index: number) =>
								index === iterator
									? {
											...option,
											node: nodeValue,
											description: option.description,
											postback: getPostBackForSmoOption(
												option,
												nodeValue,
												smoNodeData.msg_option_type,
												smoNodeData.attribute_name
											),
										}
									: option
							);

							modifiedData = {
								...flowData[sourceIndex],
								next_node: null, // TODO: Sahil : Add correct next node
								node_data: {
									...flowData[sourceIndex].node_data,
									options: [...newOptions],
								},
							};
						} else if (node_type === 'SEND_CAROUSEL') {
							const cardIndex = Math.abs(
								flowData[sourceIndex]?.node_data?.carousel?.length - carouselCardIndex - 1
							);
							const carouselNodeData = flowData[sourceIndex].node_data;
							const newButtons = carouselNodeData.carousel[cardIndex]?.buttons.map(
								(option: any, index: number) =>
									index === iterator
										? {
												...option,
												payload:
													option?.payload?.indexOf('data_') > -1
														? `${nodeValue}||${option.payload}`
														: nodeValue,
											}
										: option
							);

							const carouselData = { ...carouselNodeData.carousel[cardIndex], buttons: [...newButtons] };

							modifiedData = {
								...flowData[sourceIndex],
								next_node: null,
								node_data: {
									...flowData[sourceIndex].node_data,
									carousel: carouselNodeData?.carousel.map((card: any, index: any) =>
										index === cardIndex ? carouselData : card
									),
								},
							};
						} else if (node_type === 'EVAL_LOGIC') {
							let newPathToFollow = flowData[sourceIndex].node_data.branches[0].path_to_follow;
							if (iterator === 'false') {
								newPathToFollow = {
									...newPathToFollow,
									false: `node_${triggerNodeKey}`,
								};
							} else if (iterator === 'true') {
								newPathToFollow = {
									...newPathToFollow,
									true: `node_${triggerNodeKey}`,
								};
							}
							const newBranches = {
								...flowData[sourceIndex].node_data.branches[0],
								path_to_follow: newPathToFollow,
							};
							modifiedData = {
								...flowData[sourceIndex],
								next_node: null, // TODO: Sahil : Add correct next node
								node_data: {
									...flowData[sourceIndex].node_data,
									branches: [newBranches],
								},
							};
						}

						const editNodeurl = `/api/v1/path/${pathKey}/node/${node_key}`;

						return dispatch(
							addEditNodeAction(
								pathKey,
								modifiedData,
								editNodeurl,
								true,
								node_key,
								sourceIndex,
								flowData,
								iterator
							)
						);
					}
				}
				dispatch(setSelectedNodeAction({}));
				dispatch(updateIsFormSubmittingState(false));
				dispatch(getFlowDataAction(pathKey));
			} else {
				dispatch(updateIsFormSubmittingState(false));
				toaster.negative(
					<ENGTToasterContainer
						title={i18nHelper('common:failed')}
						description={i18nHelper('common:wentWrong')}
					/>,
					{}
				);
			}
		});
	};

export const addNewNodeAction =
	(pathKey: string, data: IObjProps, url: string, coordinates: any, currentPathKey?: string) => (dispatch: any) =>
		API.addNode(data, url).then((resp: any) => {
			if (resp.data) {
				if (resp.data.node_data && resp.data.node_data.node_key) {
					const newNodeKey: any = resp.data.node_data.node_key.toString();
					const coOrds: any = {};
					coOrds[newNodeKey] = coordinates;
					dispatch(saveFlowNodeDataCoordinateAction(pathKey, coOrds));
				}
				if (NODES_THAT_CREATE_ATTRIBUTES.indexOf(data.node_type) > -1) {
					dispatch(getPathVariablesAction(pathKey));
					dispatch(getFlowAttributesAction());
					dispatch(getPathAttributesAction(pathKey));
				}
				if (pathKey === currentPathKey) {
					dispatch(getFlowDataAction(pathKey));
				}
				dispatch(setSelectedNodeAction({}));
			} else {
				console.log('error creating a flow');
			}

			return resp;
		});

export const addFlowAction = (flowName: string, history?: NavigateFunction) => (dispatch: any) => {
	const data = {
		nodearray: { path_key: '', display_name: flowName, nodes: [] },
		type: 'add',
	};

	return API.updateFlow(data).then((resp: any) => {
		if (resp.data) {
			dispatch(getAllFlowsAction());
			history?.(`/builder/${resp.data.flow_key}`);

			return [{ id: resp.data.flow_key, label: flowName }];
		}
		console.log('error creating a flow');
		toaster.negative(
			<ENGTToasterContainer
				title={i18nHelper('common:failed')}
				description={i18nHelper('errors:addPathError')}
			/>,
			{}
		);
	});
};

export const copyFlowAction =
	(srcPathKey: string, targetPathName: string, pathAttributeToCopyLists: Array<string>) =>
	(dispatch: any, getState: () => RootState) => {
		console.log('in copy flow action, src Path:   and targetname', srcPathKey, targetPathName);
		const attributeList = getState().PathBuilder.pathAttributeToCopyLists;

		const param = {
			create_new_attr: attributeList || [],
		};

		return API.copyFlow(param, srcPathKey, targetPathName).then((resp: any) => {
			if (resp.data) {
				dispatch(getAllFlowsAction());
			} else {
				console.log('error while copying path');
			}
		});
	};

export const copyFlowToDifferentBotAction =
	(data: any, pathKey: any, flowName: any, supportEmailOrLink: any) => (dispatch: any) =>
		API.copyPathToDifferentBot(data, pathKey).then((resp: any) => {
			if (resp.data?.response === true) {
				toaster.positive(
					<ENGTToasterContainer
						title={i18nHelper('components:createCopyBotModal.pathSuccessFullyCopied')}
						description={i18nHelper('components:createCopyBotModal.pathSuccessFullyCopiedDescription', {
							pathName: flowName,
							targetPathName: data.pathName,
							botName: data.botName,
						})}
					/>,
					{}
				);
				dispatch(getAllFlowsAction());
			} else {
				dispatch(
					openInfoModalAction(
						i18nHelper(`errors:errorInCopyingPathHeading`),
						i18nHelper('errors:errorInCopyingPathDescription', {
							accountName: data.accountName,
							botName: data.botName,
						})
					)
				);
				console.log('error while copying path in different bot');
			}
		});

export const deleteFlowAction =
	(flowName: string, flowKey: string, successCB: Function, errorCB: Function) => (dispatch: any) => {
		const data = {
			nodearray: { path_key: flowKey, display_name: flowName },
			type: 'delete',
		};

		return API.updateFlow(data).then((resp: any) => {
			if (resp.data && resp.data.notifymessage) {
				dispatch(getAllFlowsAction());
				successCB();
			} else {
				console.log('error while deleting a flow!!');
				errorCB();
			}
		});
	};
export const getNodeEntitlementsAction = (botConfig: any, botData: any) => (dispatch: any) => {
	dispatch(fetchEntitlements({ botConfig, botData }));
};

export const findAllAvailableNodesForUser =
	(
		customerPlan: string,
		shopifyOfferingKey: string,
		isLivechatEnabled: boolean,
		entitlements: any,
		nodes: any,
		trialPlanOfferingKey: string
	) =>
	(dispatch: any, getState: () => RootState) => {
		const allNodes = { ...nodes };
		const commonBetweenDevelopedAndRestrictedNodesForWAAccelrator = [
			'SEND_CAROUSEL',
			'ACTN_NODE',
			'SEND_DOCUMENT',
			'SEND_AUDIO',
			'SEND_VIDEO',
			'RNDM_MSG',
			'start_a_flow',
			'email',
			'EVAL_LOGIC',
			'SCRIPT_NODE',
			'SET_USER_ATTRIBUTES',
			'SUBSCRIBE_CAMPAIGN',
			'UNSUBSCRIBE_CAMPAIGN',
			'SHOPIFY_NODE',
			'SELECT_LANGUAGE',
			'HTTP_REQUEST',
			'FORM_NODE',
			'WA_FLOW_NODE',
			'SLIDER_NODE',
			'FILE_UPLOAD',
			'ZENDESK',
			'ZAPIER',
			'HUBSPOT_CRM',
			'PIPEDRIVE_ZAPIER',
			'MAILCHIMP_ZAPIER',
			'GMAIL_ZAPIER',
			'MYSQL_ZAPIER',
			'FRESHDESK_ZAPIER',
			'ZENDESK_ZAPIER',
			'TWILIO_ZAPIER',
			'FRESHDESK',
			'GOOGLE_CALENDAR',
			'GORGIAS',
			'IDENTITY_NODE',
			'CARD_NODE',
			'WEB_VIEW_NODE',
			'SMS',
			'SALESFORCE',
			'FAQ_FILTER',
			'TEMPLATE_MESSAGE',
			'CONVERSATION_STATUS_NODE',
			'WHATSAPP_COMMERCE',
			'WA_PAYMENT_NODE',
			'RECURRING_NOTIFICATION_NODE',
			'PYTHON_SCRIPT_NODE',
			'EXTERNAL_INTEGRATION_NODE',
		];
		const developedNodes = [
			...commonBetweenDevelopedAndRestrictedNodesForWAAccelrator,
			'SEND_MSG_OPTNS',
			'SEND_MSG',
			'SEND_IMAGE',
			'FEEDBACK_NODE',
			'GOOGLE_SHEETS',
			'PAUSE_NODE',
			'LIVE_CHAT_CATEGORY',
			'RCV_INP',
		];
		const RESTRICTED_NODES = [
			'FAQ_FILTER',
			'SELECT_LANGUAGE',
			'email',
			'SUBSCRIBE_CAMPAIGN',
			'UNSUBSCRIBE_CAMPAIGN',
			'WEB_VIEW_NODE',
			'FRESHDESK',
			'GOOGLE_CALENDAR',
			'GORGIAS',
			'HTTP_REQUEST',
			'SALESFORCE',
			'SHOPIFY_NODE',
			'SMS',
			'WHATSAPP_COMMERCE',
			'WA_PAYMENT_NODE',
			'ZAPIER',
			'HUBSPOT_CRM',
			'PIPEDRIVE_ZAPIER',
			'FRESHDESK_ZAPIER',
			'ZENDESK_ZAPIER',
			'MAILCHIMP_ZAPIER',
			'TWILIO_ZAPIER',
			'GMAIL_ZAPIER',
			'MYSQL_ZAPIER',
			'ZENDESK',
			'PYTHON_SCRIPT_NODE',
		];
		const RESTRICTED_NODE_ECOM_ACCELERATOR = [
			'SHOPIFY_NODE',
			'SEND_CAROUSEL',
			'SEND_AUDIO',
			'SEND_VIDEO',
			'RNDM_MSG',
			'FILE_UPLOAD',
			'WA_FLOW_NODE',
			'start_a_flow',
			'email',
			'FAQ_FILTER',
			'SUBSCRIBE_CAMPAIGN',
			'UNSUBSCRIBE_CAMPAIGN',
			'SET_USER_ATTRIBUTES',
			'GOOGLE_CALENDAR',
			'GORGIAS',
			'ZAPIER',
			'SMS',
			'SALESFORCE',
			'SEND_DOCUMENT',
			'FORM_NODE',
			'SLIDER_NODE',
			'IDENTITY_NODE',
			'EVAL_LOGIC',
			'WEB_VIEW_NODE',
			'SCRIPT_NODE',
		];
		const RESTRICTED_NODES_WA_ACCELERATOR_PAID_AND_TRIAL = [
			...commonBetweenDevelopedAndRestrictedNodesForWAAccelrator,
		];

		const isExternalIntegration = getState().User.botAdmin.data.external_integration_provider;
		const externalIntegrations = Object.entries(getState().PathBuilder.externalIntegrations);
		const isWhatsappAcceleratorTrialEnabled = getState().User.botAdmin.data.enable_whatsapp_accelerator_trial;
		const isWhatsappAcceleratorEnabled = getState().User.botAdmin.data.enable_whatsapp_accelerator;
		const isAcceleratorEcom = isAcceleratorEcomSelector(getState());
		const isBotV2Enabled = getState().Bot.data.bot_version === BOT_VERSION.BOT_VERSION2;

		for (var key of Object.keys(nodes)) {
			if (customerPlan === WA_RAPIDPLAN.ENGATI_WA_RAPID) {
				allNodes[key] = [
					// eslint-disable-next-line no-loop-func
					...nodes[key].map((nodeData: any) => {
						if (
							RESTRICTED_NODES.indexOf(nodeData.key) > -1 &&
							(key !== 'Integrations' ||
								nodeData.entitlementAllowed === '' ||
								!(
									Object.keys(entitlements).indexOf(nodeData.entitlementAllowed) > -1 &&
									entitlements[nodeData.entitlementAllowed]
								))
						) {
							nodeData = { ...nodeData, isDisabled: true };
						}

						return nodeData;
					}),
				];
			} else if (customerPlan === 'ENGATI_GROWTH_2024') {
				allNodes[key] = [
					// eslint-disable-next-line no-loop-func
					...nodes[key].map((nodeData: any) => {
						nodeData = { ...nodeData, isDisabled: false };

						if (nodeData.isLiveChatRequired && !isLivechatEnabled) {
							nodeData.isDisabled = true;
						} else if (nodeData.entitlementAllowed !== '' && !entitlements[nodeData.entitlementAllowed]) {
							nodeData.isDisabled = true;
						}

						return nodeData;
					}),
				];
			} else {
				allNodes[key] = [
					...nodes[key].filter(
						(nodeData: any) =>
							developedNodes.indexOf(nodeData.key) > -1 &&
							((nodeData.isLiveChatRequired && isLivechatEnabled) || !nodeData.isLiveChatRequired) &&
							(nodeData.upgradeMarker !== '' ||
								nodeData.entitlementAllowed === '' ||
								(Object.keys(entitlements).indexOf(nodeData.entitlementAllowed) > -1 &&
									entitlements[nodeData.entitlementAllowed]))
					),
				];
			}

			allNodes[key] = [
				...allNodes[key]
					.filter((nodeData: IObjProps) => {
						if (isBotV2Enabled && nodeData.key === NODE_TYPE.CARD_NODE) {
							return false;
						}

						return true;
					})
					.map((nodeData: any) => {
						if (isWhatsappAcceleratorEnabled || isWhatsappAcceleratorTrialEnabled) {
							nodeData =
								RESTRICTED_NODES_WA_ACCELERATOR_PAID_AND_TRIAL.indexOf(nodeData.key) > -1
									? { ...nodeData, isDisabled: true }
									: nodeData;
						} else if (isAcceleratorEcom) {
							nodeData =
								RESTRICTED_NODE_ECOM_ACCELERATOR.indexOf(nodeData.key) > -1
									? { ...nodeData, isDisabled: true }
									: nodeData;
						}
						const updatedNode = { ...nodeData };

						return updateNodeUpgradeMarker(
							updatedNode,
							customerPlan,
							entitlements,
							shopifyOfferingKey,
							trialPlanOfferingKey
						);
					}),
			];
		}

		let updatedAllNodes = [];

		if (isExternalIntegration) {
			let updatedNodes: Array<Object> = [];
			externalIntegrations &&
				externalIntegrations.map(([appName, value]: [string, any]) => {
					const nodeext = {
						key: `Integry ${appName}`,
						label: appName,
						upgradeMarker: '',
						plansAllowed: ['ENGATI_STARTER', 'ENGATI_PROFESSIONAL', 'ENGATI_BUSINESS', 'ENGATI_ENTERPRISE'],
						isLiveChatRequired: false,
						isDisabled: false,
						entitlementAllowed: '',
						selectedNodeData: {
							node_key: '',
							node_name: appName,
							node_data: {
								application_name: appName,
								action_id: '',
								instance_id: '',
								callback_url: '',
								attribute_map: '',
								customer_id: '',
								unique_id: '',
								execution_type: '',
								node_settings_required: false,
							},
							next_node: null,
							node_type: 'EXTERNAL_INTEGRATION_NODE',
						},
					};

					updatedNodes = [...updatedNodes, nodeext];
				});
			updatedAllNodes = {
				...allNodes,
				[NODE_CATEGORIES.integrations]: [...allNodes[NODE_CATEGORIES.integrations], ...updatedNodes],
			};
		}

		dispatch(getNodesByQuery({ data: isExternalIntegration ? updatedAllNodes : allNodes, search: {}, query: '' }));
	};

export const getAllFlowsAction = () => (dispatch: any, getState: () => RootState) => {
	const botRefValue = getState().Bot.data.bot_ref;
	dispatch(setAllFlowsLoader());
	API.getAllFlows().then((resp: any) => {
		if (resp.data) {
			dispatch(setBotRef(botRefValue));
			dispatch(getAllFlows(resp.data));
		} else {
			dispatch(getAllFlowsError());
		}
	});
};

export const getFlowAttributesAction = (params?: IObjProps, successCb?: any) => (dispatch: any) => {
	API.getFlowAttributes(params).then((resp: any) => {
		if (resp.data) {
			dispatch(getFlowAttributes(resp.data));
			successCb && successCb();
		}
	});
};

export const getAllAttributesAction = () => (dispatch: any) => {
	API.getAllAttributes().then((resp: any) => {
		if (isValidResponseObjectForUserAPI(resp)) {
			dispatch(getAllAttributes(resp.data?.data));
		}
	});
};

export const setFlowAttributesAction = (data: any) => (dispatch: any) => {
	dispatch(getFlowAttributes(data));
};
export const checkAndCallCampaignAction = (data: any) => (dispatch: any) => {
	data.some((node: any) => {
		if (node.node_type === 'UNSUBSCRIBE_CAMPAIGN' || node.node_type === 'SUBSCRIBE_CAMPAIGN') {
			const params: IObjProps = {
				params: {
					page: 0,
					size: 50,
				},
				target_url: 'campaign',
			};
			dispatch(getCampaignsAction(params));

			return true;
		}

		return false;
	});
};

export const getFlowDataAction = (pathKey: string) => (dispatch: any, getState: () => RootState) => {
	API.getFlowData(pathKey).then((resp: any) => {
		if (resp.data) {
			dispatch(getFlowData(resp.data));
			if (!getState().Broadcast.campaigns.length) {
				dispatch(checkAndCallCampaignAction(resp.data?.nodes));
			}
			dispatch(getFlowNodeDataCoordinateAction(pathKey));
		} else {
			dispatch(getFlowDataError());
		}
	});
};

export const getNodesByQueryAction = (query: string, list: any) => (dispatch: any) => {
	const filteredNodes = { ...list };
	if (query) {
		let regex = '\\b(?:';
		const queryWords = query.trim()?.split(' ') || [];
		queryWords.forEach((word: string, index: number) => {
			if (index === 0) {
				regex += word;
			} else {
				regex += `+.*${word}`;
			}
		});
		regex += ')';

		const regexQuery = new RegExp(regex, 'i');
		for (const key of Object.keys(list)) {
			filteredNodes[key] = [...list[key].filter((node: any) => node.label.search(regexQuery) > -1)];
		}
	}
	const finalNodeList = Object.keys(filteredNodes)
		.filter((key) => filteredNodes[key].length > 0)
		.reduce((a, k) => ({ ...a, [k]: filteredNodes[k] }), {});
	dispatch(getNodesByQuery({ data: list, search: finalNodeList, query }));
};

export const getPathAttributesAction = (flowKey: string) => (dispatch: any) =>
	API.getPathAttributes(flowKey).then((resp: any) => {
		if (resp.data) {
			dispatch(setNodesAttrList(resp.data));
		} else {
			console.log('error while getting path attributes');
		}
	});

export const getPathVariablesAction = (pathKey?: string, successCb?: any) => (dispatch: any) => {
	API.getPathVariables(pathKey).then((resp: any) => {
		if (resp.data) {
			dispatch(getPathVariables(resp.data));
			successCb && successCb();
		} else {
			console.log('error');
		}
	});
};

export const markUnmarkPathForAgentAction =
	(key: any, availableForAgent: boolean) => (dispatch: any, getState: () => RootState) => {
		const data = {
			available_for_livechat: !availableForAgent,
			flow_key: key,
		};

		return API.markUnmarkPath(data).then((resp: any) => {
			if (resp.data) {
				dispatch(getFlowDataAction(key));
				const updatedFlows = [...getState()?.PathBuilder?.flows?.data];
				const idx = updatedFlows?.findIndex((flow: any) => flow.path_key === key);
				updatedFlows[idx] = {
					...updatedFlows[idx],
					available_for_livechat: !updatedFlows[idx].available_for_livechat,
				};
				dispatch(getAllFlows(updatedFlows));
			} else {
				console.log('error marking path a flow');
			}
		});
	};

const scriptNodeProcessData = (data: any, pathVariables: any) => {
	const scriptData: IObjProps = {};
	scriptData.error_msg = data.error_msg;
	scriptData.script = replaceVariablesInMsg(data.script, pathVariables);

	return JSON.parse(JSON.stringify(scriptData));
};
const externalIntegrationNodeProcessData = (data: any, pathvariables: any) => {
	const externalIntegrationData: IObjProps = {};
	externalIntegrationData.action_id = data?.action_id;
	externalIntegrationData.instance_id = data?.instance_id;
	externalIntegrationData.customer_id = data?.customer_id;
	externalIntegrationData.unique_id = data?.unique_id;
	externalIntegrationData.application_name = data?.application_name;
	externalIntegrationData.execution_type = data?.execution_type;
	externalIntegrationData.node_settings_required = false;
	externalIntegrationData.callback_url = data.callback_url;
	externalIntegrationData.integration_type = 'integry';

	const wrappedObject = Object.fromEntries(
		Object.entries(data?.mappedObject).map(([key, value]) => [key, `{{${value}}}`])
	);
	externalIntegrationData.attribute_map = wrappedObject;

	return JSON.parse(JSON.stringify(externalIntegrationData));
};

const pythonScriptNodeProcessData = (data: any, pathvariables: any) => {
	const pythonScriptData: IObjProps = {};
	const attributeValueList: Array<{ id: string; value: any }> = [];

	data?.params?.forEach((param: { key: { id: any }[]; value: any }) => {
		param?.key?.[0]?.id &&
			attributeValueList.push({
				id: param.key[0].id,
				value: param.value,
			});
	});

	pythonScriptData.error_msg = data?.error_msg;
	pythonScriptData.script = replaceVariablesInMsg(data?.script, pathvariables);
	pythonScriptData.identifier = data?.identifier;
	pythonScriptData.attributes = attributeValueList;

	return JSON.parse(JSON.stringify(pythonScriptData));
};

const transferAgentProcessData = (data: any) => {
	const transferAgentData: IObjProps = {};
	transferAgentData.category = '';
	transferAgentData.category_id = data.category[0].id;
	transferAgentData.trigger_live_chat = data.trigger_live_chat;

	return JSON.parse(JSON.stringify(transferAgentData));
};

const decisionProcessData = (data: any, getState: any) => {
	const decisionData: any = data;
	decisionData.branches[0].path_to_follow_when_true =
		data.branches[0].path_to_follow_when_true && data.branches[0].path_to_follow_when_true[0].id;
	decisionData.branches[0].path_to_follow_when_false =
		data.branches[0].path_to_follow_when_false && data.branches[0].path_to_follow_when_false[0].id;
	decisionData.branches[0].path_to_follow = {};
	decisionData.branches[0].path_to_follow.true =
		decisionData.branches[0] && decisionData.branches[0].path_to_follow_when_true;
	decisionData.branches[0].path_to_follow.false =
		decisionData.branches[0] && decisionData.branches[0].path_to_follow_when_false;
	for (let i = 0; i < data.conditions.length; i++) {
		decisionData.conditions[i].left_operand = replaceVariablesInMsg(
			data.conditions[i].left_operand,
			getState().PathBuilder.pathVariables
		);
		decisionData.conditions[i].operator = data.conditions[i].operator[0].id;
		decisionData.conditions[i].right_operand = replaceVariablesInMsg(
			data.conditions[i].right_operand,
			getState().PathBuilder.pathVariables
		);
	}
	const dictionary = Object.assign(
		{},
		...data.conditions.map((x: any, index: any) => ({
			[`A${index + 1}`]: x,
		}))
	);
	decisionData.conditions = dictionary;

	return JSON.parse(JSON.stringify(decisionData));
};

const feedbackNodeProcessData = (data: any, pathVariables: any) => {
	if (data.rating_type === 'EMOJI') {
		const fieldTextMapping: any = {};
		data.field_text_mapping.forEach((field: any, index: number) => {
			if (index !== 0) {
				fieldTextMapping[index] = data.field_text_mapping[index];
			}
		});
		data.field_text_mapping = fieldTextMapping;
	}
	data.feedback_prompt = replaceVariablesInMsg(data.feedback_prompt, pathVariables);
	data.post_feedback_message = replaceVariablesInMsg(data.post_feedback_message, pathVariables);

	return JSON.parse(JSON.stringify(data));
};

const RandomizeMessageProcessedData = (data: any, getState: any, isEdit = false) => ({
	random_msgs: replaceVariablesInMsgArray(data.random_msgs, getState().PathBuilder.pathVariables, true, isEdit),
});

const SendMessageProcessedData = (data: any, getState: any, isEdit = false) => ({
	message: replaceVariablesInMsg(processQuillContent(data.message, isEdit), getState().PathBuilder.pathVariables),
});

const convertToJsonString = (paramMap: any, attributeToUpdate: string) => {
	const values = JSON.parse(paramMap[attributeToUpdate].value) as string[];
	const updatedValues = JSON.stringify(['googlesheet.search', ...values]);
	paramMap[attributeToUpdate] = {
		...paramMap[attributeToUpdate],
		value: updatedValues,
	};
};

const processIntegrationNodesData = (data: any, getState: any) => {
	const formData = JSON.parse(JSON.stringify(data));
	const { columns } = data;

	const { accountId, authKey, integrationKey, workflowKey, paramMap, responseAttributes } = formData;
	const value = getState().PathBuilder.selectedNode.node_data?.param_map?.isMultiValuedSearch?.value ?? 'false';
	let isAttributesFieldFirst = false;
	const processParamData = (paramMap: any) => {
		let multisearch: string;
		Object.keys(paramMap).forEach((param: any) => {
			if (param === AdditionalFieldNames.MULTIVALUED) {
				const val = paramMap[param].value[0]?.id ?? value;
				paramMap[param] = { ...paramMap[param], value: val?.toString(), dataType: 'BOOLEAN' };
				multisearch = val;
				const attributeToUpdate = 'attributes';

				if (isAttributesFieldFirst === true && paramMap[attributeToUpdate]?.value && multisearch === 'true') {
					convertToJsonString(paramMap, attributeToUpdate);
				}
			} else if (
				paramMap[param].inputType === INPUT_TYPE.select ||
				paramMap[param].inputType === INPUT_TYPE.listMap
			) {
				const val = Array.isArray(paramMap[param].value)
					? paramMap[param].value[0]?.id
					: paramMap[param].value?.id;
				paramMap[param] = { ...paramMap[param], value: val?.toString() };
				if (paramMap[param].dependentParam) {
					paramMap[param] = { ...paramMap[param], inputType: undefined };
				}
				if (paramMap[param].value === '') {
					delete paramMap[param];
				}
			} else if (
				paramMap[param].inputType === INPUT_TYPE.text ||
				paramMap[param].inputType === INPUT_TYPE.textarea
			) {
				paramMap[param] = {
					...paramMap[param],
					value: replaceVariablesInMsg(paramMap[param].value, getState().PathBuilder.pathVariables),
				};
				if (paramMap[param].dataType === DATA_TYPE.list && paramMap[param].inputType === INPUT_TYPE.text) {
					isAttributesFieldFirst = true;
					if (param === 'LIST$$::values' && Array.isArray(paramMap[param].value)) {
						paramMap[param] = {
							...paramMap[param],
							value: paramMap[param].value.filter((v: any) => v !== undefined),
						};
					}
					if (multisearch === 'true') {
						paramMap[param] = {
							...paramMap[param],
							value: `googlesheet.search,${paramMap[param].value}`,
						};
					}
					const allValues = paramMap[param].value?.split(',') || [paramMap[param].value];
					const data = allValues?.map((val: string, index: number) => {
						if (workflowKey[0].id === 'GOOGLE_SHEETS_SEARCH_ROW' && multisearch === 'true') {
							return val === 'googlesheet.search'
								? `"${replaceVariablesInMsg(val, getState().PathBuilder.pathVariables)}"`
								: '""';
						}

						return val ? `"${replaceVariablesInMsg(val, getState().PathBuilder.pathVariables)}"` : '""';
					});

					paramMap[param] = {
						...paramMap[param],
						value: `[${data?.toString()}]`,
					};
					if (paramMap[param].dependentParam) {
						paramMap[param] = { ...paramMap[param], inputType: undefined };
					}
				}
			} else if (paramMap[param].inputType === INPUT_TYPE.tags) {
				paramMap[param] = {
					...paramMap[param],
					value: JSON.stringify(paramMap[param].value),
				};
			}
		});
	};

	let paramMapData: any = {};
	Object.keys(paramMap)
		.filter((el) => PARAM_MAP_RESPONSE_DATA[el]?.inputType === INPUT_TYPE.checkbox || paramMap[el])
		.map((param: any) => {
			if (param === AdditionalFieldNames.MULTIVALUED) {
				paramMapData[param] = {
					...PARAM_MAP_RESPONSE_DATA[param],
					value: paramMap[param],
				};
			} else {
				paramMapData[param] =
					integrationKey[0].id === INTEGRATIONS.GORGIAS && param === 'status'
						? { ...PARAM_MAP_RESPONSE_DATA[param], value: paramMap[param], dataType: 'STRING' }
						: { ...PARAM_MAP_RESPONSE_DATA[param], value: paramMap[param] };
			}

			return '';
		});

	processParamData(paramMapData);
	const responseAttrData: any = Object.keys(responseAttributes)
		.filter((resp: any) => responseAttributes[resp].attribute_name && RESPONSE_ATTR_DATA[resp])
		.map((param: any) => ({
			...RESPONSE_ATTR_DATA[param],
			name: responseAttributes[param].attribute_name,
		}));
	const finalResponseAttr =
		DEFAULT_RESPONSE_ATTR[workflowKey[0]?.id] && Array.isArray(DEFAULT_RESPONSE_ATTR[workflowKey[0]?.id])
			? [...responseAttrData, ...DEFAULT_RESPONSE_ATTR[workflowKey[0]?.id]]
			: responseAttrData;

	if (integrationKey[0].id === INTEGRATIONS.GORGIAS && workflowKey[0].id === WORKFLOW_KEYS.CREATE_TICKET_GORGIAS) {
		paramMapData = {
			...paramMapData,
			messages: {
				dataType: 'LIST',
				description: '',
				inputType: 'TEXT',
				inputValidation: '',
				value: JSON.stringify([
					{
						receiver: {},
						sender: {},
						source: {
							from: {},
						},
						from_agent: true,
						channel: 'api',
						via: 'sms',
						body_text: paramMapData.description.value,
					},
				]),
			},
			'customer::id': {
				dataType: 'INTEGER',
				description: 'Id of the customer linked to the ticket.',
				value: 123,
				inputValidation: '',
				inputType: 'TEXT',
			},
		};
		delete paramMapData.description;
	}

	return {
		account_id: accountId[0]?.id,
		auth_type: authKey,
		error_msg: 'Something went wrong. Please try again or get in touch with the administrator.',
		integration_key: integrationKey[0]?.id,
		workflow_key: workflowKey[0]?.id,
		param_map: paramMapData,
		response_attributes: finalResponseAttr,
	};
};

const sliderNodeProcessData = (data: any, getState: any, isEdit = false) => {
	const newData = data;
	newData.user_prompt = replaceVariablesInMsg(
		processQuillContent(data.user_prompt, isEdit),
		getState().PathBuilder.pathVariables
	);
	newData.input_type = data.input_type[0].id;
	newData.max_value = {
		name: data.max_value_name,
		value: parseInt(data.max_value_value),
	};
	newData.min_value = {
		name: data.min_value_name,
		value: parseInt(data.min_value_value),
	};
	newData.step_value = parseInt(newData.step_value);
	if (newData.input_type === 'RATING') {
		newData.max_value.value = parseInt(newData.scale);
		newData.min_value.value = 1;
	}

	delete newData['min_value_name'];
	delete newData['max_value_name'];
	delete newData['min_value_value'];
	delete newData['max_value_value'];

	return JSON.parse(JSON.stringify(newData));
};

const cardNodeProcessData = (data: any, getState: any) => {
	const apiData = data;
	apiData.html_content = replaceVariablesInMsg(data.html_content, getState().PathBuilder.pathVariables);
	apiData.css_content = data.css_content;

	return JSON.parse(JSON.stringify(apiData));
};

const requestUserDataProcessedData = (data: any, pathVariables: any, isEdit = false) => {
	data.inputtype = data.inputtype[0].id;
	data.inputvalidation = replaceVariablesInMsg(data.inputvalidation, pathVariables);
	data.inputmessage = replaceVariablesInMsg(processQuillContent(data.inputmessage, isEdit), pathVariables);
	data.rcv_inp_type_flag =
		data.inputtype !== 'text' &&
		data.inputtype !== 'datetime' &&
		data.inputtype !== 'number' &&
		data.inputtype !== 'datetime' &&
		data.inputtype !== 'regex'
			? data.inputtype
			: '';
	data.rcv_inp_type_flag =
		data.add_datepicker && data.inputtype === 'date'
			? 'date'
			: data.rcv_inp_type_flag === 'date'
				? ''
				: data.rcv_inp_type_flag;
	delete data.add_datepicker;
	delete data.regexType;
	if (data.default_timezone) {
		data.default_timezone = data.default_timezone[0].id;
	}
	if (Array.isArray(data.skip_node)) {
		data.skip_node = data.skip_node[0].id;
	}

	return JSON.parse(JSON.stringify(data));
};

const requestUserDataEntity = (data: any, pathVariables: any, isEdit = false) => {
	delete data.add_datepicker;
	delete data.attribute_name;
	delete data.check;
	delete data.input_regex;
	delete data.inputtype;
	delete data.inputvalidation;
	delete data.override_timezone;
	delete data.update_username;
	data.inputmessage = replaceVariablesInMsg(processQuillContent(data.inputmessage, isEdit), pathVariables);
	const newData = data;
	newData.entities.map((entity: any) => {
		entity.entity_id = entity?.entity_name[0]?.id;
		entity.entity_type = !entity.entity_type ? entity.entity_name[0].type : entity.entity_type;
		entity.entity_name = entity.entity_name[0].label;

		return entity;
	});
	if (Array.isArray(data.skip_node)) {
		data.skip_node = data.skip_node[0].id;
	}

	return JSON.parse(JSON.stringify(newData));
};
const setAttributeUserAttributeProcessData = (data: any, pathVariables: any, url: string) => {
	const attributeData = data;

	const onNodeDateChange = (date: any, type = '') => {
		let nodeDate = date ? date.getDate() : null;
		if (type === 'newNode') {
			nodeDate = date ? date.getDate() + 1 : null;
		}
		const processedDate = date
			? `${new Date(new Date(date).setDate(nodeDate)).toISOString().substring(0, 10)}T00:00:00Z`
			: null;

		return processedDate;
	};

	attributeData.attributes.map((attribute: any, index: number) => {
		if (attribute.type[0].id === 'NUMBER') {
			attribute.value = parseInt(attribute.value);
		} else if (attribute.type[0].id === 'DATE') {
			if (url.includes('nextTo') || url.includes('hangingNode') || url.includes('rootNode')) {
				attribute.value = onNodeDateChange(attribute.value, 'newNode');
			} else {
				attribute.value = onNodeDateChange(attribute.value);
			}
		} else if (attribute.type[0].id === 'DATETIME' || attribute.type[0].id === 'TIME') {
			attribute.value = attribute.value ? new Date(attribute.value).toISOString() : null;
		} else if (attribute.type[0].id === 'TEXT') {
			attribute.value = attribute.value?.trim();
		} else if (attribute.type[0].id === 'SINGLE_SELECT') {
			attribute['singleSelectData'] = data[`singleSelect${index}`];
			attribute.value = attribute.value ? attribute.value?.[0]?.id : null;
		} else if (attribute.type[0].id === 'BOOLEAN') {
			attribute.value = attribute.value ? attribute.value?.[0]?.id : null;
		} else if (attribute.type[0].id === 'ARRAY') {
			const str = attribute.value;
			const hasSingleQuotes = /'/.test(str);
			let newStr = '';
			if (hasSingleQuotes) {
				newStr = str.replace(/'/g, '"');
				attribute.value = newStr;
			}
		}

		return attribute;
	});
	attributeData.attributes.map((attribute: any) => delete attribute.isDisabled);
	const attributesArray = attributeData.attributes.map((attribute: any) => ({
		...attribute,
		attribute_name: attribute.attribute_name[0].label,
		value:
			attribute.type[0].id === 'DATE' || attribute.type[0].id === 'DATETIME' || attribute.type[0].id === 'TIME'
				? new Date(attribute.value).toISOString()
				: replaceVariablesInMsg(`${attribute.value}`, pathVariables),
		type: attribute.type[0].id,
		required: attribute.required,
		validation:
			attribute.type[0].id === 'ARRAY'
				? Array.isArray(JSON.parse(`[${attribute.value}]`))
					? 'ARRAY'
					: 'JSON_ARRAY'
				: attribute.type[0].id,
	}));
	const formData = JSON.parse(JSON.stringify(attributesArray));

	formData.forEach((element: any) => {
		if (element.reset_to_null) {
			if (element.type == 'OBJECT') {
				element.value = '{}';
			} else {
				element.value = null;
			}
		} else {
			element.reset_to_null = false;
		}
	});

	return { attributes: formData };
};

const campaignProcessedData = (data: any) => {
	const dictionary = Object.assign({}, ...data.campaigns.map((x: any) => ({ [x.id]: x.label })));
	const formData = JSON.parse(JSON.stringify(dictionary));

	return { campaigns: formData };
};

const pauseProcessedData = (data: any) => ({ input: parseInt(data.input[0].id) });

const getPostBackVal = (btn: any, pathVariables: any) => {
	if (!btn.nodeName && typeof btn.payload === 'string') {
		return btn.payload;
	}
	let val = Array.isArray(btn.nodeName) ? btn.nodeName[0]?.id : btn.nodeName.id;
	if (btn.attributeName && btn.attributeValue) {
		const updatedAttrVal = replaceVariablesInMsg(btn.attributeValue, pathVariables);
		val += `||data_${btn.attributeName}=${updatedAttrVal}`;
	}

	return val;
};

const carouselProcessedData = (data: any, pathVariables: any, isEdit = false) => {
	const formData = JSON.parse(JSON.stringify(data));
	const {
		attributeName,
		carouselCards,
		cardButtons,
		dynamicCard,
		errorMsg,
		footerButtons,
		headerButtons,
		sourceType,
		styleConfig,
	} = formData;

	const source = Array.isArray(sourceType) ? sourceType[0]?.id : sourceType.id;
	const selectedAttribute = Array.isArray(attributeName) ? attributeName[0]?.id : attributeName?.id;

	const getButtons = (buttons: any) => {
		if (!buttons) {
			return [];
		}

		return buttons.map((btn: any) => ({
			title: replaceVariablesInMsg(btn.title, pathVariables),
			type: btn.type,
			payload:
				btn.type === 'phone_number'
					? btn.phoneNumber
					: btn.type === 'web_url'
						? replaceVariablesInMsg(btn.postbackUrl, pathVariables)
						: getPostBackVal(btn, pathVariables),
		}));
	};

	const attrName = source === 'STATIC' ? '' : selectedAttribute;
	const cardsBasedOnSrc = source === 'STATIC' ? carouselCards : dynamicCard;
	const processedHeaderBtn = getButtons(headerButtons);
	const processedFooterBtn = getButtons(footerButtons);
	const carouselData = cardsBasedOnSrc.map((card: any, index: number) => ({
		title: replaceVariablesInMsg(card.title, pathVariables),
		subtitle: replaceVariablesInMsg(processQuillContent(card.subtitle, isEdit), pathVariables),
		is_image_url_resolved: card.is_image_url_resolved,
		image_url: replaceVariablesInMsg(card.image_url, pathVariables),
		image_alt_text: card.image_alt_text,
		gradient_top_color: card.gradient_top_color,
		gradient_bottom_color: card.gradient_bottom_color,
		default_action: {
			...card.default_action,
			url:
				card.default_action && card.default_action.url
					? replaceVariablesInMsg(card.default_action.url, pathVariables)
					: '',
		},
		buttons: getButtons(card.buttons),
		dropdowns: source === CAROUSEL_SOURCE_TYPES.DYNAMIC_CART ? card.dropdowns : {},
	}));
	const styleData = { ...styleConfig, width: parseInt(styleConfig['width']) };

	return {
		attribute_name: attrName,
		carousel: carouselData,
		error_msg: errorMsg,
		footer_buttons: processedFooterBtn,
		header_buttons: processedHeaderBtn,
		source,
		style_config: styleData,
	};
};

const selectLanguageProcessedData = (data: any) => {
	const dictionary = Object.assign({}, ...data.selectedLanguage.map((x: any) => ({ [x.id]: x.label })));
	const formData = JSON.parse(JSON.stringify(dictionary));

	return { selectedLanguage: Object.keys(formData)[0] };
};

const triggerPathProcessedData = (data: any) => {
	const dictionary = Object.assign({}, ...data.flow_key.map((x: any) => ({ [x.id]: x.label })));
	const formData = JSON.parse(JSON.stringify(dictionary));

	return { flow_key: Object.keys(formData)[0] };
};

const smoProcessedData = (data: any, pathVariables: any, isEdit = false) => {
	let msgOptions = '';
	const formData = JSON.parse(JSON.stringify(data));
	const {
		attributeName,
		enableAutoFill,
		messageString: message,
		triggerPath,
		searchEnabled,
		setAttribute,
		options,
		validationMessage,
		validationMode,
		uploadFileName,
		uploadBatchId,
		uploadedFile,
		uploadOptions,
		errorMsg,
		source,
		dynamicAttribute,
		...rest
	} = formData;
	const buttonType: IObjProps = {
		vertical: false,
		horizontal: false,
		false: false,
		true: true,
	};
	const buttonAligment: IObjProps = {
		vertical: 'vertical',
		horizontal: 'horizontal',
		false: 'horizontal',
		true: 'horizontal',
	};

	const processedMsg = replaceVariablesInMsg(processQuillContent(message, isEdit), pathVariables);

	if (triggerPath && setAttribute) {
		msgOptions = 'data_attribute_trigger_node';
	} else if (triggerPath) {
		msgOptions = 'trigger_node';
	} else if (setAttribute) {
		msgOptions = 'data_attribute';
	} else {
		return;
	}

	const getPostBack = (index: number) => {
		if (triggerPath && setAttribute) {
			return `${
				options[index].node[0] && options[index].node[0].id ? `${options[index].node[0].id}||` : ''
			}data_${attributeName}=${options[index].attr}`;
		}
		if (triggerPath) {
			return `${options[index].node[0] && options[index].node[0].id ? options[index].node[0].id : ''}`;
		}
		if (setAttribute) {
			return `data_${attributeName}=${options[index].attr}`;
		}
	};

	let formOptions = options.map((optionData: any, index: number) => ({
		attr: setAttribute ? optionData.attr || '' : undefined,
		node: optionData.node ? (optionData.node[0] && optionData.node[0].id ? optionData.node[0].id : '') : '',
		text: replaceVariablesInMsg(optionData.text, pathVariables) || '',
		id: `choice${index + 1}`,
		postback: getPostBack(index),
		description: optionData.description,
	}));
	if (!triggerPath) {
		formOptions = formOptions.map((optionData: any, index: number) => ({
			attr: setAttribute ? optionData.attr : undefined,
			text: replaceVariablesInMsg(optionData.text, pathVariables),
			id: optionData.id,
			postback: optionData.postback,
			description: optionData.description,
		}));
	}

	return {
		...rest,
		options: isEmpty(formOptions) ? [{ text: null, id: 'choice1', postback: '', attr: '' }] : formOptions,
		message: processedMsg,
		attribute_name: setAttribute ? attributeName : undefined,
		enable_autofill: buttonType[enableAutoFill],
		buttons_alignment: buttonAligment[enableAutoFill],
		msg_option_type: msgOptions,
		search_enabled: searchEnabled === 'true',
		validation_message: validationMessage,
		validation_mode: validationMode,
		option_list_type: data.option_list_type,
		upload_batch_id:
			searchEnabled === 'true' && setAttribute && attributeName && !triggerPath ? uploadBatchId || '' : undefined,
		error_msg: errorMsg,
		dynamic_attribute: Array.isArray(dynamicAttribute) ? dynamicAttribute[0]?.id : dynamicAttribute?.id,
		source: Array.isArray(source) ? source[0]?.id : source?.id,
	};
};
const sendEmailProcessData = (data: any, getState: any, isEdit = false) => {
	const formData = data;
	const recipientsCopy = `${formData.recipients}`;
	recipientsCopy.replaceAll(' ', '');
	formData.recipients = replaceVariablesInMsgArray(
		recipientsCopy.split(','),
		getState().PathBuilder.pathVariables,
		false
	);
	formData.subject = replaceVariablesInMsg(data.subject, getState().PathBuilder.pathVariables);
	const processedAttrs = processChatHistoryAttrs(formData.body, ['chat.text', 'chat.URL', 'chat.file']);
	formData.body = processedAttrs.updatedString;
	formData.request_type = processedAttrs.stringsMatched[0] ? processedAttrs.stringsMatched[0] : null;
	formData.body = replaceVariablesInMsg(data.emailText, getState().PathBuilder.pathVariables);
	formData.body = formData.body.substring(0, 50);

	return JSON.parse(JSON.stringify(formData));
};

const formNodeProcessedData = (data: any, getState: any, isEdit = false) => {
	const formData = JSON.parse(JSON.stringify(data));
	const { attributes, confirmationButton, identificationType, skipButton, skipLogin, subtitle, title, ...rest } =
		formData;

	const getValidationData = (validation: any, pathVariables: any) => {
		const optionType = Array.isArray(validation.validationOptionType)
			? validation.validationOptionType[0]?.id
			: validation.validationOptionType?.id;
		const attrType = Array.isArray(validation.attributeType)
			? validation.attributeType[0]?.id
			: validation.attributeType?.id;

		let restOptions: any = {};
		switch (optionType) {
			case 'LESS_THAN':
				restOptions = {
					lowerBound: null,
					upperBound: replaceVariablesInMsg(validation.upperBound, pathVariables),
					numberValidationType: 'LESS_THAN',
					type: 'NUMBER_VALIDATION',
				};
				break;
			case 'GREATER_THAN':
				restOptions = {
					lowerBound: replaceVariablesInMsg(validation.lowerBound, pathVariables),
					upperBound: null,
					numberValidationType: 'GREATER_THAN',
					type: 'NUMBER_VALIDATION',
				};
				break;
			case 'BETWEEN':
				restOptions =
					attrType === 'number'
						? {
								lowerBound: replaceVariablesInMsg(validation.lowerBound, pathVariables),
								upperBound: replaceVariablesInMsg(validation.upperBound, pathVariables),
								numberValidationType: 'BETWEEN',
								type: 'NUMBER_VALIDATION',
							}
						: {
								afterDate: validation.afterDate && new Date(validation.afterDate).toLocaleDateString(),
								beforeDate:
									validation.beforeDate && new Date(validation.beforeDate).toLocaleDateString(),
								dateValidationType: 'BETWEEN',
								type: 'DATE_VALIDATION',
							};
				break;
			case 'REGEX_VALIDATION':
				restOptions = {
					regex: replaceVariablesInMsg(validation.regex, pathVariables),
					type: optionType,
				};
				break;
			case 'ALLOW':
				const domainValues =
					validation.allowDomains && replaceVariablesInMsg(validation.allowDomains, pathVariables);
				restOptions = {
					domains: domainValues && domainValues?.split(',').map((s: any) => s.trim()),
					emailValidationType: 'ALLOW',
					type: 'EMAIL_VALIDATION',
				};
				break;
			case 'RESTRICT':
				const resDomainValues =
					validation.restrictDomains && replaceVariablesInMsg(validation.restrictDomains, pathVariables);
				restOptions = {
					domains: resDomainValues && resDomainValues?.split(',').map((s: any) => s.trim()),
					emailValidationType: 'RESTRICT',
					type: 'EMAIL_VALIDATION',
				};
				break;
			case 'MOBILE_VALIDATION':
				const startingValues = validation.startingDigits
					? validation.startingDigits.indexOf('{{') > -1
						? replaceVariablesInMsg(validation.startingDigits, pathVariables)
						: validation.startingDigits?.split(',').map((s: any) => s.trim())
					: '';
				restOptions = {
					startingDigits: startingValues,
					validMobileNumberLengths:
						validation.validMobileNumberLengths &&
						validation.validMobileNumberLengths.map((num: any) => num.id),
					type: 'MOBILE_VALIDATION',
				};
				break;
			case 'BEFORE':
				restOptions = {
					afterDate: '',
					beforeDate: validation.beforeDate && new Date(validation.beforeDate).toLocaleDateString(),
					dateValidationType: 'BEFORE',
					type: 'DATE_VALIDATION',
				};
				break;
			case 'AFTER':
				restOptions = {
					afterDate: validation.afterDate && new Date(validation.afterDate).toLocaleDateString(),
					beforeDate: '',
					dateValidationType: 'AFTER',
					type: 'DATE_VALIDATION',
				};
				break;
			case 'DEFAULT':
				restOptions = null;
				break;
			default:
				restOptions = null;
				break;
		}

		return restOptions ? { ...restOptions } : restOptions;
	};

	const processedAttributes =
		attributes?.map((attribute: any) => {
			const optionType = Array.isArray(attribute.validationOptionType)
				? attribute.validationOptionType[0]?.id
				: attribute.validationOptionType?.id;

			return {
				attributeName: attribute.attributeName,
				attributeType: Array.isArray(attribute.attributeType)
					? attribute.attributeType[0]?.id
					: attribute.attributeType?.id,
				displayName: attribute.displayName,
				isMandatory: attribute.isMandatory,
				showCountryCode: attribute.showCountryCode,
				isMultiselectEnabled: attribute.isMultiselectEnabled,
				showDatePicker: attribute.showDatePicker,
				validationType: optionType && optionType !== 'DEFAULT' ? 'CUSTOM' : 'DEFAULT',
				uniqueIdentifier: attribute.uniqueIdentifier,
				validationMessage: replaceVariablesInMsg(
					attribute.validationMessage,
					getState().PathBuilder.pathVariables
				),
				validation: getValidationData(attribute, getState().PathBuilder.pathVariables),
				singleSelectData: attribute?.singleSelectData,
				options: attribute.options?.map((option: any) => ({
					text: option.text,
					postback: option.postback,
					node: '',
					id: '',
				})),
			};
		}) || [];

	return {
		...rest,
		attributes: processedAttributes,
		confirmation_button: confirmationButton,
		identification_type: identificationType,
		skip_button: skipLogin ? skipButton : null,
		skip_login: skipLogin,
		subtitle: processQuillContent(subtitle, isEdit),
		title,
	};
};

const whatsAppPaymentProcessedData = (formData: IObjProps, pathVariables: any) => {
	const {
		paymentConfigName,
		bodyText,
		catalogId,
		items,
		expiryBy,
		expiryDescription,
		taxOption,
		taxAmount,
		taxAttribute,
		taxDescription,
		discountOption,
		discountAmount,
		discountAttribute,
		discountDescription,
		shippingOption,
		shippingAmount,
		shippingAttribute,
		shippingDescription,
		footerText,
		successPath,
		failurePath,
		errorMsg,
		continueOnError,
		errorHandlingType,
		errorOptions,
		orderDetailType,
	} = formData;

	let taxValue;
	let discountValue;
	let shippingValue;
	taxValue = taxOption === CUSTOMS_OPTIONS.ATTRIBUTE ? taxAttribute?.[0]?.id : taxAmount;
	discountValue = discountOption === CUSTOMS_OPTIONS.ATTRIBUTE ? discountAttribute?.[0]?.id : discountAmount;
	shippingValue = shippingOption === CUSTOMS_OPTIONS.ATTRIBUTE ? shippingAttribute?.[0]?.id : shippingAmount;

	const processedErrorOptions = errorOptions.map((optionData: any, index: number) => ({
		text: optionData.text || '',
		id: `choice${index + 1}`,
		postback: optionData?.node?.[0]?.id || '',
	}));

	return {
		payment_config_name: paymentConfigName?.[0]?.label,
		payment_config_id: paymentConfigName?.[0]?.id,
		body_text: replaceVariablesInMsg(bodyText, pathVariables),
		order_type: ORDER_TYPE,
		catalog_id: replaceVariablesInMsg(catalogId, pathVariables),
		items: replaceVariablesInMsg(items, pathVariables),
		expiry_by: expiryBy,
		expiry_description: replaceVariablesInMsg(expiryDescription, pathVariables),
		currency: CURRENCY_MAP['INR'],
		tax_option: taxOption,
		tax_value: taxValue,
		tax_description: taxDescription,
		discount_option: discountOption,
		discount_value: discountValue,
		discount_description: discountDescription,
		shipping_option: shippingOption,
		shipping_value: shippingValue,
		shipping_description: shippingDescription,
		footer_text: replaceVariablesInMsg(footerText, pathVariables),
		success_path: successPath?.[0]?.id,
		failure_path: failurePath?.[0]?.id,
		error_msg: errorMsg,
		continue_on_error: continueOnError === FLOW.CONTINUE,
		error_handling_type: continueOnError === FLOW.CONTINUE ? null : errorHandlingType,
		error_options:
			continueOnError === FLOW.STOP && errorHandlingType === FLOW_OPTIONS.SEND_MESSAGE_WITH_OPTIONS
				? processedErrorOptions
				: null,
		order_detail_type: orderDetailType,
		payment_type: paymentConfigName?.[0]?.paymentType,
	};
};

const whatsAppFlowProcessedData = (formData: IObjProps, pathVariables: any) => {
	const { flowName, bodyText, buttonLabel, screenId, flowActionData, status, successPath } = formData;
	flowActionData.map((attribute: IObjProps, index: number) => {
		flowActionData[index]['value'] = replaceVariablesInMsg(attribute?.value, pathVariables);
	});

	return {
		body_text: replaceVariablesInMsg(bodyText, pathVariables),
		button_label: buttonLabel,
		flow_id: flowName?.[0]?.id,
		screen_id: screenId,
		flow_status: status,
		success_path: successPath?.[0]?.id,
		flow_action_data: flowActionData,
	};
};

const whatsAppCommerceProcessedData = (formData: IObjProps, pathVariables: any, isEdit = false) => {
	const { action, errorMsg, paramMap, type, continueOnError, errorHandlingType, errorOptions, ...rest } = formData;

	const newParamMap: IObjProps = {};

	const processedErrorMsg = replaceVariablesInMsg(processQuillContent(errorMsg, isEdit), pathVariables);

	const processedErrorOptions = errorOptions.map((optionData: any, index: number) => ({
		text: optionData.text || '',
		id: `choice${index + 1}`,
		postback: optionData?.node?.[0]?.id || '',
	}));

	Object.keys(WHATSAPP_COMMERCE_WORK_FLOW_TAB_MAP[action]).forEach((index) => {
		Object.keys(paramMap).forEach((field) => {
			newParamMap[toSnakeCase(field)] = {
				value:
					action === WA_COMMERCE_ACTIONS.DISPLAY_COLLECTIONS && field === 'displayProducts'
						? paramMap[field].value
						: replaceVariablesInMsg(paramMap[field].value, pathVariables),
			};
		});
	});

	return {
		...rest,
		action,
		error_msg: processedErrorMsg || '',
		param_map: newParamMap,
		continue_on_error: continueOnError !== FLOW.STOP,
		error_handling_type: continueOnError === FLOW.CONTINUE ? null : errorHandlingType,
		error_options:
			continueOnError === FLOW.STOP && errorHandlingType === FLOW_OPTIONS.SEND_MESSAGE_WITH_OPTIONS
				? processedErrorOptions
				: null,
	};
};
const recurringNotificationProcessedData = (formData: IObjProps, pathVariables: any, isEdit = false) => {
	const { topic, frequency, image_url } = formData;

	return {
		topic: replaceVariablesInMsg(processQuillContent(topic, isEdit), pathVariables),
		image_url: validateImageUrl(image_url) ? image_url : replaceVariablesInMsg(image_url, pathVariables),
		frequency,
	};
};
const shopifyProcessedData = (data: any, pathVariables: any, isEdit = false) => {
	const formData = JSON.parse(JSON.stringify(data));
	const { action, errorMsg, paramMap, authType, type, continueOnError, errorHandlingType, errorOptions, ...rest } =
		formData;

	const newParamMap: any = {};

	const processedErrorMsg = replaceVariablesInMsg(processQuillContent(errorMsg, isEdit), pathVariables);

	const processedErrorOptions = errorOptions.map((optionData: any, index: number) => ({
		text: optionData.text || '',
		id: `choice${index + 1}`,
		postback: optionData?.node?.[0]?.id || '',
	}));

	Object.keys(WORK_FLOW_TAB_MAP[action]).forEach((index) => {
		Object.keys(paramMap).forEach((field) => {
			if (field === WORK_FLOW_TAB_MAP[action][index]) {
				if (field === 'status') {
					newParamMap[field] = {
						dataType: 'STRING',
						inputType: 'TEXT',
						description: 'Please specify an attribute name',
						value: 'any',
					};
				} else if (field === 'productSearchType') {
					const value = paramMap[field]['value'];
					if (value) {
						let id;
						Object.keys(value).forEach((index) => {
							id = value[index].id;
						});
						newParamMap[field] = {
							dataType: 'STRING',
							inputType: 'TEXT',
							description: 'Please specify an attribute name',
							value: id,
						};
					}
				} else if (field === 'currency' || field === 'discountType') {
					const value = paramMap[field]['value'];
					if (value && value.length > 0) {
						let id;
						Object.keys(value).forEach((index) => {
							id = value[index].id;
						});
						newParamMap[field] = {
							dataType: 'STRING',
							inputType: 'TEXT',
							description: 'Please specify an attribute name',
							value: id,
						};
					}
				} else {
					newParamMap[field] = {
						dataType: 'STRING',
						inputType: 'TEXT',
						description: 'Please specify an attribute name',
						value: replaceVariablesInMsg(paramMap[field].value, pathVariables),
					};
				}
			}
		});
	});

	return {
		...rest,
		action,
		error_msg: processedErrorMsg || '',
		auth_type: authType,
		param_map: newParamMap,
		continue_on_error: continueOnError !== FLOW.STOP,
		error_handling_type: continueOnError === FLOW.CONTINUE ? null : errorHandlingType,
		error_options:
			continueOnError === FLOW.STOP && errorHandlingType === FLOW_OPTIONS.SEND_MESSAGE_WITH_OPTIONS
				? processedErrorOptions
				: null,
	};
};

const jsonApiProcessData = (data: any, getState: any) => {
	const isC2eEnabled = getState.User.botAdmin.data.is_c2e_enabled;
	const { pathVariables } = getState.PathBuilder;

	const apiData: IObjProps = {};
	const authentication: IObjProps = {};
	const dictionaryParams = Object.assign(
		{},
		...data.params
			.filter((param: any) => param?.key && param?.value)
			.map((x: any) => ({ [x.key]: replaceVariablesInMsg(x.value, pathVariables) }))
	);
	const dictionaryHeaders = Object.assign(
		{},
		...data.headers
			.filter((header: any) => header?.key)
			.map((x: any) => ({ [x.key]: replaceVariablesInMsg(x.value, pathVariables) }))
	);
	delete data.authenticationFallBackFlow;
	if (data.authenticationType[0].id !== 'BASIC') {
		delete data.username;
		delete data.password;
	}
	apiData.params = data.params[0].key === '' ? {} : dictionaryParams;
	apiData.headers = data.headers[0].key === '' ? {} : dictionaryHeaders;
	apiData.authenticationType = data.authenticationType[0].id === 'NONE' ? null : data.authenticationType[0].id;
	apiData.body = replaceVariablesInMsg(
		data.headers[0].key !== ''
			? content.CONTENT_TYPE in apiData.headers &&
					getBodyValue(
						apiData.headers[content.CONTENT_TYPE],
						data.bodyParam,
						data.body,
						bodyType.APPLICATION_URL_ENCODED
					)
			: data.body,
		pathVariables
	);
	apiData.body_type = data.bodyType?.[0]?.id;
	apiData.request_url = data.method?.[0]?.id === 'C2E' ? '' : replaceVariablesInMsg(data.request_url, pathVariables);
	apiData.module = data.module || null;
	if (isC2eEnabled) {
		apiData.module =
			data.method?.[0]?.id === 'C2E'
				? {
						name: data.moduleName,
						version: data.moduleVersion,
					}
				: null;
	}
	apiData.error_msg = 'Something went wrong. Please try again or get in touch with the administrator.';
	apiData.method = isC2eEnabled ? data.method?.[0].id : data.method;
	apiData.continue_on_error = data.continue_on_error;
	apiData.validation_option = data.continue_on_error ? 'Continue flow' : 'Stop flow';
	apiData.success_status_code = data.success_status_code;
	apiData.response_map = data.response_map;
	apiData.error_msg = replaceVariablesInMsg(data.error_msg, pathVariables);
	apiData.username = data.username ? replaceVariablesInMsg(data.username, pathVariables) : null;
	apiData.password = data.password ? data.password : null;
	apiData.authenticationPathKey = data.authenticationPathKey[0].id;
	if (
		(data.authenticationType[0].id === 'BASIC' || data.authenticationType[0].id === 'API_KEY',
		data.authenticationType[0].id === 'TWO_STEP')
	) {
		authentication.authType = apiData.authenticationType;
		authentication.username = data.username ? replaceVariablesInMsg(data.username, pathVariables) : null;
		authentication.password = data.password ? data.password : null;
		authentication.authenticationFallBackFlow = apiData.authenticationPathKey
			? apiData.authenticationPathKey
			: null;
		apiData.authentication = authentication;
	} else {
		apiData.authentication = null;
	}
	if (apiData.body) {
		const processedAttrs = processChatHistoryAttrs(apiData.body, ['chat.text', 'chat.URL', 'chat.file']);
		apiData.body = processedAttrs.updatedString;
		apiData.request_type = processedAttrs.stringsMatched[0] ? processedAttrs.stringsMatched[0] : null;
		data.params &&
			data.params.forEach((param: any) => {
				const processedAttrs = processChatHistoryAttrs(param, ['chat.URL']);
				if (processedAttrs.stringsMatched[0]) {
					param = processedAttrs.updatedString;
					apiData.request_type = processedAttrs.stringsMatched[0];
				}
			});
		data.headers &&
			data.headers.forEach((header: any) => {
				const processedAttrs = processChatHistoryAttrs(header, ['chat.URL']);
				if (processedAttrs.stringsMatched[0]) {
					header = processedAttrs.updatedString;
					apiData.request_type = processedAttrs.stringsMatched[0];
				}
			});
	} else {
		apiData.request_type = null;
	}
	// module
	return JSON.parse(JSON.stringify(apiData));
};

const fileUploadApiProcessData = (data: any, getState: any) => {
	const fileUpload: IObjProps = {};
	const request_model: IObjProps = {};

	const dictionaryParams = Object.assign(
		{},
		...data.params
			.filter((param: any) => param.key)
			.map((param: any) => ({ [param.key]: replaceVariablesInMsg(param.value, getState) }))
	);
	const dictionaryHeaders = Object.assign(
		{},
		...data.headers
			.filter((header: any) => header.key)
			.map((header: any) => ({ [header.key]: replaceVariablesInMsg(header.value, getState) }))
	);
	data.body[0].value = 'ENGATI_FILE_UPLOAD_KEY';

	const dictionaryBody = Object.assign(
		{},
		...data.body
			.filter((body: any) => body.key)
			.map((body: any) => ({ [body.key]: replaceVariablesInMsg(body.value, getState) }))
	);

	request_model.params = data.params?.length > 0 ? dictionaryParams : {};
	request_model.headers = data.headers?.length > 0 ? dictionaryHeaders : {};
	request_model.body = data.body?.length > 0 ? dictionaryBody : {};
	request_model.method = data.method;
	request_model.request_url = replaceVariablesInMsg(data.requestUrl || '', getState);

	fileUpload.message = replaceVariablesInMsg(data.messages, getState);
	fileUpload.description = replaceVariablesInMsg(data.descriptions, getState);
	fileUpload.customer_id = data.customerId;
	fileUpload.error_msg = data.errorMessage;
	fileUpload.public_upload = !data.publicUpload;
	fileUpload.whitelisted_domains = data.whitelistedDomains;
	fileUpload.save_attributes = !data.saveAttributes;
	fileUpload.request_model = request_model;
	fileUpload.enable_skip = data?.enable_skip;

	return JSON.parse(JSON.stringify(fileUpload));
};

const identityNodeProcessData = (data: any, pathVariables: any, isEdit = false) => {
	const apiData: IObjProps = {};
	const attributeData = data?.attributes;

	const attributesArray = attributeData?.map((attribute: any) => {
		const type = attribute?.regexType ? 'REGEX_VALIDATION' : '';
		if (!attribute.regexType) {
			delete attribute?.validation;
			delete attribute.validationMessage;
		}
		let passwordRegexData: any = {};
		if (attribute?.regexType) {
			passwordRegexData = {
				validation: {
					regex: attribute?.regexType ? attribute?.validation?.regex : '',
					type,
				},
				validationMessage: attribute.validationMessage || null,
			};
		}
		delete attribute.regexType;

		return {
			...attribute,
			attributeName: attribute.attributeName[0].label,
			attributeType: attribute.attributeType[0].id.toLowerCase(),
			displayName: attribute.displayName,
			fieldDisabled: false,
			...passwordRegexData,
		};
	});
	apiData.attributes = attributesArray;
	const dictionaryParams = Object.assign(
		{},
		...data?.params.map((x: any) => ({ [x.key]: replaceVariablesInMsg(x.value, pathVariables) }))
	);
	const dictionaryHeaders = Object.assign(
		{},
		...data?.headers.map((x: any) => ({ [x.key]: replaceVariablesInMsg(x.value, pathVariables) }))
	);
	apiData.base_url = window.location.origin;
	apiData.identification_type = data.identificationType[0].id;
	apiData.params = data.params[0].key === '' ? {} : dictionaryParams;
	apiData.headers = data.headers[0].key === '' ? {} : dictionaryHeaders;
	apiData.body = replaceVariablesInMsg(data.body, pathVariables);
	apiData.request_url = replaceVariablesInMsg(data.request_url, pathVariables);
	apiData.method = data.method;
	apiData.response_map = data.response_map;
	apiData.login_failed_msg = replaceVariablesInMsg(data.error_msg, pathVariables);
	apiData.skip_login = data.skipLogin;
	apiData.subtitle = processQuillContent(data.subtitle, isEdit);
	apiData.title = data.title;
	if (apiData.body) {
		const processedAttrs = processChatHistoryAttrs(data.body, ['chat.text', 'chat.URL', 'chat.file']);
		apiData.body = processedAttrs.updatedString;
		apiData.request_type = processedAttrs.stringsMatched[0] ? processedAttrs.stringsMatched[0] : null;
		data.params &&
			data.params?.forEach((param: any) => {
				const processedAttrs = processChatHistoryAttrs(param, ['chat.URL']);
				if (processedAttrs.stringsMatched[0]) {
					param = processedAttrs.updatedString;
					apiData.request_type = processedAttrs.stringsMatched[0];
				}
			});
		data.headers &&
			data.headers?.forEach((header: any) => {
				const processedAttrs = processChatHistoryAttrs(header, ['chat.URL']);
				if (processedAttrs.stringsMatched[0]) {
					header = processedAttrs.updatedString;
					apiData.request_type = processedAttrs.stringsMatched[0];
				}
			});
	} else {
		apiData.request_type = null;
	}

	return JSON.parse(JSON.stringify(apiData));
};

export const templateMesaageNodeProcessData = (data: any, pathVariables: any) => {
	const templateComponent =
		typeof data.templateComponent === 'string' ? data.templateComponent.split(',') : data.templateComponent;
	const template: IObjProps = {};
	if (!data.isWhatsAppCloudConfigured) {
		template.namespace = data.templateNamespace;
	}
	template.name = data.templateName[0].id;
	const attributesPayload: any = [];
	const components: Array<any> = [];

	const upsertAttributesToAttributesPayload = (attribute: any) => {
		const getAttributeId = () => {
			const attr =
				attribute?.name?.[0]?.id && attribute?.name?.[0]?.id?.includes('attr_') ? attribute?.name?.[0]?.id : '';

			return attr;
		};
		attributesPayload.push({
			attribute_id: getAttributeId(),
			attribute_name: attribute?.name?.[0]?.label,
			type: 'TEXT',
			value: attribute?.value,
		});
	};

	if (templateComponent.indexOf('TEXT') !== -1 && data.parameters['header']) {
		const headerParameters: Array<any> = [];
		data?.parameters['header']?.map((header: any, index: any) => {
			headerParameters.push({ type: 'text', text: replaceVariablesInMsg(header, pathVariables) });

			return 0;
		});
		if (!isEmpty(headerParameters)) {
			components.push({ type: 'header', parameters: headerParameters });
		}
	} else if (templateComponent.indexOf('IMAGE') !== -1) {
		components.push({
			type: 'header',
			parameters: [{ type: 'image', image: { link: replaceVariablesInMsg(data.fileUrl, pathVariables) } }],
		});
	} else if (templateComponent.indexOf('VIDEO') !== -1) {
		components.push({
			type: 'header',
			parameters: [{ type: 'video', video: { link: data.video } }],
		});
	} else if (templateComponent.indexOf('DOCUMENT') !== -1) {
		components.push({
			type: 'header',
			parameters: [
				{
					type: 'document',
					document: {
						filename:
							data?.mediaDoc?.[0]?.name ||
							data?.fileUrlDoc?.substring(data.fileUrlDoc.lastIndexOf('/') + 1),
						link: data.fileUrlDoc,
					},
				},
			],
		});
	}

	if (templateComponent.includes(templateComponentParameters.carousel)) {
		const cards: Array<any> = [];
		const carouselObject = data?.carousel || [];
		carouselObject.map((carousel: any, index: any) => {
			const cardComponents: Array<any> = [];
			if (templateComponent.includes(`card${index}.IMAGE`)) {
				cardComponents.push({
					type: templateComponentParameters.header,
					parameters: [
						{
							type: templateComponentParameters.image,
							image: { link: replaceVariablesInMsg(carousel.fileUrl, pathVariables) },
						},
					],
				});
			} else if (templateComponent.includes(`card${index}.VIDEO`)) {
				cardComponents.push({
					type: templateComponentParameters.header,
					parameters: [
						{
							type: templateComponentParameters.video,
							video: { link: replaceVariablesInMsg(carousel.video, pathVariables) },
						},
					],
				});
			}
			if (templateComponent.includes(`card${index}.body`)) {
				const bodyParameters: Array<any> = [];
				const carouselCardBodyParamsList = data?.parameters?.carousel[index]?.body || [];
				carouselCardBodyParamsList?.forEach((body: any, index: any) => {
					bodyParameters.push({
						type: templateComponentParameters.text,
						text: replaceVariablesInMsg(body, pathVariables),
					});
				});
				cardComponents.push({ type: templateComponentParameters.body, parameters: bodyParameters });
			}
			if (templateComponent.includes(`card${index}.buttons`)) {
				carousel?.buttons?.forEach((button: any, buttonIndex: number) => {
					const type = templateComponentParameters.button;
					if (button?.url && button?.value) {
						const sub_type = TEMPLATE_BUTTON_TYPES.URL;
						const parameters = [
							{
								type: 'payload',
								payload: replaceVariablesInMsg(button.value, pathVariables),
								url: button.url,
							},
						];
						cardComponents.push({ index: buttonIndex, parameters, sub_type, type });
					} else if (button?.payload && button?.attributes) {
						const sub_type = TEMPLATE_BUTTON_TYPES.QUICK_REPLY;
						const payloadPath = `flow_${button.payload?.[0]?.id}||`;
						const payloadAttr = `data_${button.attributes
							.map((attribute: any) => {
								upsertAttributesToAttributesPayload(attribute);

								return `${attribute.name[0].label}=${attribute.dateValue || attribute.value}`;
							})
							.join('&')}`;
						const parameters = [{ payload: payloadPath + payloadAttr, type: 'payload' }];
						cardComponents.push({ index: buttonIndex, parameters, sub_type, type });
					} else if (button?.payload) {
						const sub_type = TEMPLATE_BUTTON_TYPES.QUICK_REPLY;
						const parameters = [{ payload: `flow_${button.payload?.[0]?.id}`, type: 'payload' }];
						cardComponents.push({ index: buttonIndex, parameters, sub_type, type });
					}
				});
			}
			cards.push({ card_index: index, components: cardComponents });
		});
		components.push({ type: templateComponentParameters.carousel, cards });
	}

	if (templateComponent.indexOf(templateComponentParameters.expiration) !== -1) {
		components.push({
			type: templateComponentParameters.lto,
			parameters: [
				{
					type: templateComponentParameters.lto,
					limited_time_offer: {
						expiration_time_ms: data?.expirationDateTime,
					},
				},
			],
		});
	}

	if (templateComponent.indexOf('body') !== -1 && data.parameters['body']) {
		const bodyParameters: Array<any> = [];
		data.parameters?.['body']?.map((body: any, index: any) => {
			bodyParameters.push({ type: 'text', text: replaceVariablesInMsg(body, pathVariables) });

			return 0;
		});
		components.push({ type: 'body', parameters: bodyParameters });
	}
	if (templateComponent.indexOf('buttons') !== -1 && data.buttons) {
		data.buttons?.map((button: any, buttonIndex: any) => {
			const type = 'button';
			if (button?.url && button?.value) {
				const sub_type = 'url';
				const parameters = [
					{ text: replaceVariablesInMsg(button?.value, pathVariables), type: 'text', url: button.url },
				];
				components.push({ index: buttonIndex, parameters, sub_type, type });
			} else if (button?.payload && button?.attributes) {
				const sub_type = 'quick_reply';
				const payloadPath = `flow_${button.payload[0].id}||`;
				let payloadAttr = 'data_';
				button.attributes.map((attribute: any, index: any) => {
					upsertAttributesToAttributesPayload(attribute);
					payloadAttr += `${attribute.name[0].label}=${attribute.dateValue || attribute.value}`;
					if (button.attributes.length - 1 !== index) {
						payloadAttr += '&';
					}
				});
				const parameters = [{ payload: payloadPath + payloadAttr, type: 'payload' }];
				components.push({ index: buttonIndex, parameters, sub_type, type });
			} else if (button?.payload) {
				const sub_type = 'quick_reply';
				const parameters = [{ payload: `flow_${button.payload[0].id}`, type: 'payload' }];
				components.push({ index: buttonIndex, parameters, sub_type, type });
			} else if (button?.attributes) {
				const sub_type = 'quick_reply';
				let payload = 'data_';
				button.attributes.map((attribute: any, index: any) => {
					upsertAttributesToAttributesPayload(attribute);
					payload += `${attribute.name[0].label}=${attribute.dateValue || attribute.value}`;
					if (button.attributes.length - 1 !== index) {
						payload += '&';
					}
				});
				const parameters = [{ payload, type: 'payload' }];
				components.push({ index: buttonIndex, parameters, sub_type, type });
			} else if (templateComponent.includes(templateComponentParameters.lto) && button?.copy_code) {
				const sub_type = 'copy_code';
				const parameters = [{ coupon_code: data?.offerCode, type: 'coupon_code' }];
				components.push({ index: buttonIndex, parameters, sub_type, type });
			}

			return 0;
		});
	}
	const language: IObjProps = {};
	language.code = data.tempLanguage;
	language.policy = 'deterministic';
	template.components = components;
	template.language = language;
	const templateData: IObjProps = {};
	templateData.payload = template;
	templateData.attributes = attributesPayload;
	templateData.targetWhatsAppNumber = replaceVariablesInMsg(data.targetWhatsAppNumber, pathVariables);
	templateData.trackMsgStatus = data.trackMsgStatus;
	if (data?.trackMsgStatus === true) {
		templateData.msgTrackingDuration = data.msgTrackingDuration[0].id;
		templateData.msgStatusTracked = data.msgStatusTracked[0].id;
		templateData.alternateFlowKey = data.alternateFlowKey[0].id;
	}
	if (data?.templateIsPhoneNumber === 'true') {
		templateData.targetWhatsAppNumber = data.targetWhatsAppNumber.replace(/[^A-Z0-9]/gi, '');
	}

	return templateData;
};

const webviewProcessData = (data: any, getState: any) => {
	const apiData: IObjProps = {};
	const {
		params,
		headers,
		billing_address_collection,
		cancel_url,
		client_secret,
		continue_on_error,
		error_msg,
		conversion_required,
		currency,
		name,
		quantity,
		amount,
		desc,
		oauth_type,
		payment_method_types,
		provider,
		publisher_key,
		redirect,
		redirect_url,
		refresh_url,
		request_url,
		response_map,
		token_url,
		url_prefix_message,
		web_view_type,
		editResponse,
		sample_response,
		secret_key,
		scope,
		client_id,
		tenant_id,
		razorpay_key_id,
		expire_by,
		is_test_mode,
		merchant_id,
		merchant_salt,
		success_path,
		failure_path,
	} = data;
	const dictionaryParams = Object.assign(
		{},
		...params.map((x: any) => ({ [x.key]: replaceVariablesInMsg(x.value, getState) }))
	);
	const dictionaryHeaders = Object.assign(
		{},
		...headers.map((x: any) => ({ [x.key]: replaceVariablesInMsg(x.value, getState) }))
	);
	const webviewType = web_view_type[0]?.id;
	apiData.params = params[0]?.key === '' ? {} : dictionaryParams;
	apiData.headers = headers[0]?.key === '' ? {} : dictionaryHeaders;
	apiData.base_url = window.location.origin;
	apiData.billing_address_collection = billing_address_collection;
	apiData.cancel_url = replaceVariablesInMsg(cancel_url, getState);
	apiData.client_id = replaceVariablesInMsg(client_id, getState);
	apiData.client_secret = client_secret === '*****' ? '' : client_secret;
	apiData.continue_on_error = continue_on_error;
	apiData.error_msg = replaceVariablesInMsg(error_msg, getState);
	apiData.conversion_required = conversion_required;
	const lineItems: IObjProps = {};
	lineItems.currency = currency[0]?.id;
	if (currency[0]?.zeroDecimal) {
		apiData.conversion_required = false;
	}
	lineItems.name = replaceVariablesInMsg(name, getState);
	lineItems.quantity = replaceVariablesInMsg(quantity, getState);
	lineItems.amount = replaceVariablesInMsg(amount, getState);
	if (webviewType && webviewType !== 'STRIPE') {
		lineItems.desc = replaceVariablesInMsg(desc, getState);
	}
	apiData.line_items = lineItems;
	apiData.oauth_type = oauth_type[0]?.id;
	apiData.payment_method_types = payment_method_types;
	apiData.provider = provider;
	apiData.publisher_key = replaceVariablesInMsg(publisher_key, getState);
	apiData.redirect = redirect === 'true';
	apiData.redirect_url = replaceVariablesInMsg(redirect_url, getState);
	apiData.refresh_url = replaceVariablesInMsg(refresh_url, getState);
	apiData.request_url = replaceVariablesInMsg(request_url, getState);
	apiData.response_map = response_map;
	apiData.scope = replaceVariablesInMsg(scope, getState);
	apiData.secret_key = secret_key === '*****' ? '' : secret_key;
	apiData.selectedTab = 'params';
	apiData.tenant_id = replaceVariablesInMsg(tenant_id, getState);
	apiData.token_url = replaceVariablesInMsg(token_url, getState);
	apiData.url_prefix_message = replaceVariablesInMsg(url_prefix_message, getState);
	apiData.web_view_type = webviewType;
	apiData.sample_response = sample_response;
	apiData.editResponse = editResponse;
	apiData.razorpay_key_id = replaceVariablesInMsg(razorpay_key_id, getState);
	apiData.expire_by = replaceVariablesInMsg(expire_by, getState);
	apiData.is_test_mode = is_test_mode;
	apiData.merchant_id = replaceVariablesInMsg(merchant_id, getState);
	apiData.merchant_salt = merchant_salt === '*****' ? '' : merchant_salt;
	apiData.success_path = replaceVariablesInMsg(success_path, getState);
	apiData.failure_path = replaceVariablesInMsg(failure_path, getState);

	return apiData;
};

const addEvent = (data: any, processedData: any, pathvariables: any) => {
	if (data?.enableEvent) {
		processedData.event_name = replaceVariablesInMsg(data.eventName[0]?.id, pathvariables);
		processedData.user_defined_event_category = replaceVariablesInMsg(
			data.user_defined_event_category,
			pathvariables
		);
		processedData.user_defined_event_action = replaceVariablesInMsg(data.user_defined_event_action, pathvariables);
	} else {
		processedData.event_name = '';
		processedData.user_defined_event_category = '';
		processedData.user_defined_event_action = '';
	}
};

export const prepareDataForNodeSubmit = (formData: any) => (dispatch: any, getState: () => RootState) => {
	const pathKey = getState().PathBuilder.flowData.data.path_key;
	const nodeData = getState().PathBuilder.selectedNode;
	const nodeKey = nodeData.node_key;

	const flowData = getState().PathBuilder.flowData.data.nodes || [];
	const nodeList = flowData && flowData.length && flowData.filter((curNode: any) => curNode.node_type !== 'START');

	const nextNodeKey = getNodeKey(nodeData.next_node, nodeList);
	const updatedNodeData = { ...nodeData, next_node: nextNodeKey };

	let url;
	if (nodeKey === 'rootNode' || nodeKey === 'hangingNode' || nodeKey === 'triggerNode' || nodeData.isEdit) {
		url = `/api/v1/path/${pathKey}/node/${nodeKey}`;
	} else {
		url = `/api/v1/path/${pathKey}/node/nextTo${nodeKey}`;
	}

	dispatch(
		processNodeDataAndSubmit(formData, pathKey, nodeKey, updatedNodeData, url, flowData, updatedNodeData.iterator)
	);
};

export const processNodeDataAndSubmit =
	(
		data: IObjProps,
		pathKey: string,
		nodeKey: string,
		nodeData: IObjProps,
		url: string,
		flowData: any,
		iterator: number
	) =>
	(dispatch: any, getState: () => RootState) => {
		let { node_type, node_data, ...restNodeData } = nodeData;

		if (data.entities && Object.keys(data.entities[0]).length && node_type === NODE_TYPE.RCV_INP) {
			node_type = NODE_TYPE.RCV_INP_ENTITY;
		} else if (node_type === NODE_TYPE.RCV_INP) {
			delete data.entities;
		}
		if (nodeData.selectedFlowKey) {
			delete nodeData.selectedFlowKey;
		}
		const { isEdit } = nodeData;
		const { carouselCardIndex } = nodeData;
		let processedData = JSON.parse(JSON.stringify(nodeData));
		if (data?.enableEvent) {
			processedData.event_name = replaceVariablesInMsg(
				data.eventName[0]?.id,
				getState().PathBuilder.pathVariables
			);
			processedData.user_defined_event_category = replaceVariablesInMsg(
				data.user_defined_event_category,
				getState().PathBuilder.pathVariables
			);
			processedData.user_defined_event_action = replaceVariablesInMsg(
				data.user_defined_event_action,
				getState().PathBuilder.pathVariables
			);
		} else {
			processedData.event_name = '';
			processedData.user_defined_event_category = '';
			processedData.user_defined_event_action = '';
		}
		switch (node_type) {
			case NODE_TYPE.FAQ_FILTER: {
				const { categories } = data;
				const processedCategory =
					categories
						?.filter((opt: any) => opt.value && opt.value[0] && opt.value[0].id !== 'RESET_FAQS')
						.map((opt: any) => opt.value[0].id) || [];
				processedData.node_data = {
					categories: processedCategory,
				};

				return dispatch(
					addEditNodeAction(
						pathKey,
						processedData,
						url,
						isEdit,
						nodeKey,
						nodeData.sourceIndex,
						flowData,
						iterator,
						carouselCardIndex
					)
				);
			}
			case NODE_TYPE.SEND_MSG_OPTNS: {
				processedData = {
					node_type,
					node_data: JSON.parse(
						JSON.stringify(smoProcessedData(data, getState().PathBuilder.pathVariables, isEdit))
					),
					...restNodeData,
				};
				addEvent(data, processedData, getState().PathBuilder.pathVariables);

				return dispatch(
					addEditNodeAction(
						pathKey,
						processedData,
						url,
						isEdit,
						nodeKey,
						nodeData.sourceIndex,
						flowData,
						iterator,
						carouselCardIndex
					)
				);
			}
			case NODE_TYPE.HTTP_REQUEST: {
				processedData = {
					node_type,
					node_data: JSON.parse(JSON.stringify(jsonApiProcessData(data, getState()))),
					...restNodeData,
				};
				addEvent(data, processedData, getState().PathBuilder.pathVariables);

				return dispatch(
					addEditNodeAction(
						pathKey,
						processedData,
						url,
						isEdit,
						nodeKey,
						nodeData.sourceIndex,
						flowData,
						iterator,
						carouselCardIndex
					)
				);
			}
			case NODE_TYPE.FILE_UPLOAD: {
				processedData = {
					node_type,
					node_data: JSON.parse(
						JSON.stringify(fileUploadApiProcessData(data, getState().PathBuilder.pathVariables))
					),
					...restNodeData,
				};
				addEvent(data, processedData, getState().PathBuilder.pathVariables);

				return dispatch(
					addEditNodeAction(
						pathKey,
						processedData,
						url,
						isEdit,
						nodeKey,
						nodeData.sourceIndex,
						flowData,
						iterator,
						carouselCardIndex
					)
				);
			}
			case NODE_TYPE.SCRIPT_NODE: {
				processedData = {
					node_type,
					node_data: JSON.parse(
						JSON.stringify(scriptNodeProcessData(data, getState().PathBuilder.pathVariables))
					),
					...restNodeData,
				};
				addEvent(data, processedData, getState().PathBuilder.pathVariables);

				return dispatch(
					addEditNodeAction(
						pathKey,
						processedData,
						url,
						isEdit,
						nodeKey,
						nodeData.sourceIndex,
						flowData,
						iterator,
						carouselCardIndex
					)
				);
			}
			case NODE_TYPE.PYTHON_SCRIPT_NODE: {
				processedData = {
					node_type,
					node_data: JSON.parse(
						JSON.stringify(pythonScriptNodeProcessData(data, getState().PathBuilder.pathVariables))
					),
					...restNodeData,
				};
				addEvent(data, processedData, getState().PathBuilder.pathVariables);

				return dispatch(
					addEditNodeAction(
						pathKey,
						processedData,
						url,
						isEdit,
						nodeKey,
						nodeData.sourceIndex,
						flowData,
						iterator
					)
				);
			}
			case NODE_TYPE.LIVE_CHAT_CATEGORY: {
				processedData = {
					node_type,
					node_data: JSON.parse(JSON.stringify(transferAgentProcessData(data))),
					...restNodeData,
				};
				addEvent(data, processedData, getState().PathBuilder.pathVariables);

				return dispatch(
					addEditNodeAction(
						pathKey,
						processedData,
						url,
						isEdit,
						nodeKey,
						nodeData.sourceIndex,
						flowData,
						iterator,
						carouselCardIndex
					)
				);
			}
			case NODE_TYPE.SUBSCRIBE_CAMPAIGN: {
				processedData.node_data = JSON.parse(JSON.stringify(campaignProcessedData(data)));
				processedData.node_data.node_type = 'SUBSCRIBE_CAMPAIGN';
				processedData.node_data.rcv_inp_type_flag = '';
				processedData.node_type = 'CAMPAIGN_NODE';

				return dispatch(
					addEditNodeAction(
						pathKey,
						processedData,
						url,
						isEdit,
						nodeKey,
						nodeData.sourceIndex,
						flowData,
						iterator,
						carouselCardIndex
					)
				);
			}
			case NODE_TYPE.UNSUBSCRIBE_CAMPAIGN: {
				processedData.node_data = JSON.parse(JSON.stringify(campaignProcessedData(data)));
				processedData.node_data.node_type = NODE_TYPE.UNSUBSCRIBE_CAMPAIGN;
				processedData.node_data.rcv_inp_type_flag = '';
				processedData.node_type = NODE_TYPE.CAMPAIGN_NODE;

				return dispatch(
					addEditNodeAction(
						pathKey,
						processedData,
						url,
						isEdit,
						nodeKey,
						nodeData.sourceIndex,
						flowData,
						iterator,
						carouselCardIndex
					)
				);
			}
			case NODE_TYPE.SEND_MSG: {
				processedData.node_data = JSON.parse(JSON.stringify(SendMessageProcessedData(data, getState, isEdit)));

				return dispatch(
					addEditNodeAction(
						pathKey,
						processedData,
						url,
						isEdit,
						nodeKey,
						nodeData.sourceIndex,
						flowData,
						iterator,
						carouselCardIndex
					)
				);
			}
			case NODE_TYPE.ACTN_NODE: {
				switch (nodeData.action_type) {
					case 'email': {
						processedData.node_data = sendEmailProcessData(data, getState, isEdit);
						processedData.node_data.content_template_id = nodeData.node_data.content_template_id;
						processedData.node_data.content_type = nodeData.node_data.content_type;
						processedData.node_data.email_mode = nodeData.node_data.email_mode;
						processedData.node_data.rcv_inp_type_flag = nodeData.node_data.rcv_inp_type_flag;
						processedData.node_data.request_type = nodeData.node_data.request_type;
						processedData.node_data.sender = nodeData.node_data.sender.replaceAll(' ', '');
						processedData.node_data.sender_name = nodeData.node_data.sender_name;
						processedData.node_data.template_id = nodeData.node_data.template_id;
						processedData.node_data.template_id = nodeData.template_id;
						processedData.node_data.type = nodeData.node_data.type;

						return dispatch(
							addEditNodeAction(
								pathKey,
								processedData,
								url,
								isEdit,
								nodeKey,
								nodeData.sourceIndex,
								flowData,
								iterator,
								carouselCardIndex
							)
						);
					}

					default: {
						processedData.node_data = JSON.parse(JSON.stringify(triggerPathProcessedData(data)));
						processedData.node_key = nodeData.node_key;
						processedData.node_name = nodeData.node_name;
						processedData.next_node = nodeData.next_node;
						processedData.node_data.type = nodeData.action_type;
						processedData.node_data.rcv_inp_type_flag = '';
						processedData.node_data.type = 'start_a_flow';

						return dispatch(
							addEditNodeAction(
								pathKey,
								processedData,
								url,
								isEdit,
								nodeKey,
								nodeData.sourceIndex,
								flowData,
								iterator,
								carouselCardIndex
							)
						);
					}
				}
			}
			case NODE_TYPE.FEEDBACK_NODE: {
				processedData.node_data = JSON.parse(
					JSON.stringify(feedbackNodeProcessData(data, getState().PathBuilder.pathVariables))
				);

				return dispatch(
					addEditNodeAction(
						pathKey,
						processedData,
						url,
						isEdit,
						nodeKey,
						nodeData.sourceIndex,
						flowData,
						iterator,
						carouselCardIndex
					)
				);
			}
			case NODE_TYPE.RCV_INP: {
				processedData.node_data = JSON.parse(
					JSON.stringify(requestUserDataProcessedData(data, getState().PathBuilder.pathVariables, isEdit))
				);

				return dispatch(
					addEditNodeAction(
						pathKey,
						processedData,
						url,
						isEdit,
						nodeKey,
						nodeData.sourceIndex,
						flowData,
						iterator,
						carouselCardIndex
					)
				);
			}
			case NODE_TYPE.RCV_INP_ENTITY: {
				processedData.node_data = JSON.parse(
					JSON.stringify(requestUserDataEntity(data, getState().PathBuilder.pathVariables)),
					(processedData.node_type = node_type)
				);

				return dispatch(
					addEditNodeAction(
						pathKey,
						processedData,
						url,
						isEdit,
						nodeKey,
						nodeData.sourceIndex,
						flowData,
						iterator,
						carouselCardIndex
					)
				);
			}

			case NODE_TYPE.SET_USER_ATTRIBUTES: {
				processedData.node_data = JSON.parse(
					JSON.stringify(
						setAttributeUserAttributeProcessData(data, getState().PathBuilder.pathVariables, url)
					)
				);

				return dispatch(
					addEditNodeAction(
						pathKey,
						processedData,
						url,
						isEdit,
						nodeKey,
						nodeData.sourceIndex,
						flowData,
						iterator,
						carouselCardIndex
					)
				);
			}
			case NODE_TYPE.SELECT_LANGUAGE: {
				processedData.node_data = JSON.parse(JSON.stringify(selectLanguageProcessedData(data)));

				return dispatch(
					addEditNodeAction(
						pathKey,
						processedData,
						url,
						isEdit,
						nodeKey,
						nodeData.sourceIndex,
						flowData,
						iterator,
						carouselCardIndex
					)
				);
			}
			case NODE_TYPE.SEND_IMAGE: {
				data.image_url = validateImageUrl(data.image_url)
					? data.image_url
					: replaceVariablesInMsg(data.image_url, getState().PathBuilder.pathVariables);

				processedData.node_data = JSON.parse(JSON.stringify(data));

				return dispatch(
					addEditNodeAction(
						pathKey,
						processedData,
						url,
						isEdit,
						nodeKey,
						nodeData.sourceIndex,
						flowData,
						iterator,
						carouselCardIndex
					)
				);
			}
			case NODE_TYPE.SEND_DOCUMENT: {
				data.document_url = validateDocumentUrl(data.document_url)
					? data.document_url
					: replaceVariablesInMsg(data.document_url, getState().PathBuilder.pathVariables);
				processedData.node_data = JSON.parse(JSON.stringify(data));

				return dispatch(
					addEditNodeAction(
						pathKey,
						processedData,
						url,
						isEdit,
						nodeKey,
						nodeData.sourceIndex,
						flowData,
						iterator,
						carouselCardIndex
					)
				);
			}
			case NODE_TYPE.SEND_AUDIO: {
				data.audio_url = validateAudioUrl(data.audio_url)
					? data.audio_url
					: replaceVariablesInMsg(data.audio_url, getState().PathBuilder.pathVariables);
				data.audio_caption = validateAudioUrl(data.audio_caption)
					? data.audio_caption
					: replaceVariablesInMsg(data.audio_caption, getState().PathBuilder.pathVariables);
				processedData.node_data = JSON.parse(JSON.stringify(data));

				return dispatch(
					addEditNodeAction(
						pathKey,
						processedData,
						url,
						isEdit,
						nodeKey,
						nodeData.sourceIndex,
						flowData,
						iterator,
						carouselCardIndex
					)
				);
			}
			case NODE_TYPE.SEND_VIDEO: {
				data.video_url = validateAudioUrl(data.video_url)
					? data.video_url
					: replaceVariablesInMsg(data.video_url, getState().PathBuilder.pathVariables);
				data.video_caption = validateAudioUrl(data.video_caption)
					? data.video_caption
					: replaceVariablesInMsg(data.video_caption, getState().PathBuilder.pathVariables);
				processedData.node_data = JSON.parse(JSON.stringify(data));

				return dispatch(
					addEditNodeAction(
						pathKey,
						processedData,
						url,
						isEdit,
						nodeKey,
						nodeData.sourceIndex,
						flowData,
						iterator,
						carouselCardIndex
					)
				);
			}
			case NODE_TYPE.RNDM_MSG: {
				processedData.node_data = JSON.parse(
					JSON.stringify(RandomizeMessageProcessedData(data, getState, isEdit))
				);

				return dispatch(
					addEditNodeAction(
						pathKey,
						processedData,
						url,
						isEdit,
						nodeKey,
						nodeData.sourceIndex,
						flowData,
						iterator,
						carouselCardIndex
					)
				);
			}
			case NODE_TYPE.PAUSE_NODE: {
				processedData.node_data = JSON.parse(JSON.stringify(pauseProcessedData(data)));

				return dispatch(
					addEditNodeAction(
						pathKey,
						processedData,
						url,
						isEdit,
						nodeKey,
						nodeData.sourceIndex,
						flowData,
						iterator,
						carouselCardIndex
					)
				);
			}
			case NODE_TYPE.SEND_CAROUSEL: {
				processedData.node_data = JSON.parse(
					JSON.stringify(carouselProcessedData(data, getState().PathBuilder.pathVariables, isEdit))
				);

				return dispatch(
					addEditNodeAction(
						pathKey,
						processedData,
						url,
						isEdit,
						nodeKey,
						nodeData.sourceIndex,
						flowData,
						iterator,
						carouselCardIndex
					)
				);
			}

			case NODE_TYPE.EVAL_LOGIC: {
				processedData.node_data = JSON.parse(JSON.stringify(decisionProcessData(data, getState)));

				return dispatch(
					addEditNodeAction(
						pathKey,
						processedData,
						url,
						isEdit,
						nodeKey,
						nodeData.sourceIndex,
						flowData,
						iterator,
						carouselCardIndex
					)
				);
			}
			case NODE_TYPE.SHOPIFY_NODE: {
				processedData.node_data = JSON.parse(
					JSON.stringify(shopifyProcessedData(data, getState().PathBuilder.pathVariables, isEdit))
				);

				return dispatch(
					addEditNodeAction(
						pathKey,
						processedData,
						url,
						isEdit,
						nodeKey,
						nodeData.sourceIndex,
						flowData,
						iterator,
						carouselCardIndex
					)
				);
			}

			case NODE_TYPE.FORM_NODE: {
				processedData.node_data = JSON.parse(JSON.stringify(formNodeProcessedData(data, getState, isEdit)));

				return dispatch(
					addEditNodeAction(
						pathKey,
						processedData,
						url,
						isEdit,
						nodeKey,
						nodeData.sourceIndex,
						flowData,
						iterator,
						carouselCardIndex
					)
				);
			}

			case NODE_TYPE.SLIDER_NODE: {
				processedData.node_data = JSON.parse(JSON.stringify(sliderNodeProcessData(data, getState, isEdit)));

				return dispatch(
					addEditNodeAction(
						pathKey,
						processedData,
						url,
						isEdit,
						nodeKey,
						nodeData.sourceIndex,
						flowData,
						iterator,
						carouselCardIndex
					)
				);
			}
			case NODE_TYPE.CARD_NODE: {
				processedData.node_data = JSON.parse(JSON.stringify(cardNodeProcessData(data, getState)));

				return dispatch(
					addEditNodeAction(
						pathKey,
						processedData,
						url,
						isEdit,
						nodeKey,
						nodeData.sourceIndex,
						flowData,
						iterator,
						carouselCardIndex
					)
				);
			}

			case NODE_TYPE.WEB_VIEW_NODE: {
				processedData.node_data = JSON.parse(
					JSON.stringify(webviewProcessData(data, getState().PathBuilder.pathVariables))
				);

				return dispatch(
					addEditNodeAction(
						pathKey,
						processedData,
						url,
						isEdit,
						nodeKey,
						nodeData.sourceIndex,
						flowData,
						iterator,
						carouselCardIndex
					)
				);
			}

			case NODE_TYPE.INTEGRATION: {
				processedData.node_data = JSON.parse(JSON.stringify(processIntegrationNodesData(data, getState)));

				return dispatch(
					addEditNodeAction(
						pathKey,
						processedData,
						url,
						isEdit,
						nodeKey,
						nodeData.sourceIndex,
						flowData,
						iterator,
						carouselCardIndex
					)
				);
			}

			case NODE_TYPE.ZAPIER: {
				processedData = {
					node_type,
					node_data: { ...nodeData.node_data, ...JSON.parse(JSON.stringify(data)) },
					...restNodeData,
				};
				addEvent(data, processedData, getState().PathBuilder.pathVariables);

				return dispatch(
					addEditNodeAction(
						pathKey,
						processedData,
						url,
						isEdit,
						nodeKey,
						nodeData.sourceIndex,
						flowData,
						iterator,
						carouselCardIndex
					)
				);
			}

			case NODE_TYPE.IDENTITY_NODE: {
				processedData.node_data = JSON.parse(
					JSON.stringify(identityNodeProcessData(data, getState().PathBuilder.pathVariables, isEdit))
				);

				return dispatch(
					addEditNodeAction(
						pathKey,
						processedData,
						url,
						isEdit,
						nodeKey,
						nodeData.sourceIndex,
						flowData,
						iterator,
						carouselCardIndex
					)
				);
			}

			case NODE_TYPE.TEMPLATE_MESSAGE: {
				processedData.node_data = JSON.parse(
					JSON.stringify(templateMesaageNodeProcessData(data, getState().PathBuilder.pathVariables))
				);

				return dispatch(
					addEditNodeAction(
						pathKey,
						processedData,
						url,
						isEdit,
						nodeKey,
						nodeData.sourceIndex,
						flowData,
						iterator,
						carouselCardIndex
					)
				);
			}

			case NODE_TYPE.RECURRING_NOTIFICATION_NODE: {
				processedData.node_data = recurringNotificationProcessedData(
					data,
					getState().PathBuilder.pathVariables,
					isEdit
				);

				return dispatch(
					addEditNodeAction(
						pathKey,
						processedData,
						url,
						isEdit,
						nodeKey,
						nodeData.sourceIndex,
						flowData,
						iterator,
						carouselCardIndex
					)
				);
			}

			case NODE_TYPE.CONVERSATION_STATUS_NODE: {
				const conversationData = {
					conversation_status: data.conversation_status[0]?.id,
					is_status_updated: true,
				};
				processedData.node_data = JSON.parse(JSON.stringify(conversationData));

				return dispatch(
					addEditNodeAction(
						pathKey,
						processedData,
						url,
						isEdit,
						nodeKey,
						nodeData.sourceIndex,
						flowData,
						iterator,
						carouselCardIndex
					)
				);
			}

			case NODE_TYPE.WHATSAPP_COMMERCE_NODE: {
				processedData.node_data = whatsAppCommerceProcessedData(
					data,
					getState().PathBuilder.pathVariables,
					isEdit
				);

				return dispatch(
					addEditNodeAction(
						pathKey,
						processedData,
						url,
						isEdit,
						nodeKey,
						nodeData.sourceIndex,
						flowData,
						iterator,
						carouselCardIndex
					)
				);
			}

			case NODE_TYPE.WA_PAYMENT_NODE: {
				processedData.node_data = whatsAppPaymentProcessedData(data, getState().PathBuilder.pathVariables);

				return dispatch(
					addEditNodeAction(
						pathKey,
						processedData,
						url,
						isEdit,
						nodeKey,
						nodeData.sourceIndex,
						flowData,
						iterator,
						carouselCardIndex
					)
				);
			}
			case NODE_TYPE.EXTERNAL_INTEGRATION_NODE: {
				processedData.node_data = externalIntegrationNodeProcessData(
					data,
					getState().PathBuilder.pathVariables
				);

				return dispatch(
					addEditNodeAction(
						pathKey,
						processedData,
						url,
						isEdit,
						nodeKey,
						nodeData.sourceIndex,
						flowData,
						iterator,
						carouselCardIndex
					)
				);
			}
			case NODE_TYPE.WA_FLOW_NODE: {
				processedData.node_data = whatsAppFlowProcessedData(data, getState().PathBuilder.pathVariables);

				return dispatch(
					addEditNodeAction(
						pathKey,
						processedData,
						url,
						isEdit,
						nodeKey,
						nodeData.sourceIndex,
						flowData,
						iterator,
						carouselCardIndex
					)
				);
			}
			default:
				return dispatch(
					addEditNodeAction(
						pathKey,
						processedData,
						url,
						isEdit,
						nodeKey,
						nodeData.sourceIndex,
						flowData,
						iterator,
						carouselCardIndex
					)
				);
		}
	};
export const processNodeDataAndSubmitForEmailNode =
	(data: IObjProps, url: string, id: any, flowData: any) => (dispatch: any, getState: () => RootState) => {
		const pathKey = getState().PathBuilder.flowData.data.path_key;
		const nodeData = getState().PathBuilder.selectedNode;
		const nodeKey = nodeData.node_key;
		const { node_type, node_data, ...restNodeData } = nodeData;
		const { isEdit } = nodeData;
		const { carouselCardIndex } = nodeData;
		if (!restNodeData.isEdit) {
			delete restNodeData.node_key;
		}
		delete restNodeData.isEdit;
		delete restNodeData.coordinates;
		const {
			content_template_id,
			content_type,
			email_mode,
			rcv_inp_type_flag,
			sender,
			sender_name,
			type,
			recipients,
			node_key,
		} = node_data;
		const processedData = JSON.parse(JSON.stringify(nodeData));
		if (data?.enableEvent) {
			processedData.event_name = replaceVariablesInMsg(
				data.eventName[0]?.id,
				getState().PathBuilder.pathVariables
			);
			processedData.user_defined_event_category = replaceVariablesInMsg(
				data.user_defined_event_category,
				getState().PathBuilder.pathVariables
			);
			processedData.user_defined_event_action = replaceVariablesInMsg(
				data.user_defined_event_action,
				getState().PathBuilder.pathVariables
			);
		} else {
			processedData.event_name = '';
			processedData.user_defined_event_category = '';
			processedData.user_defined_event_action = '';
		}
		processedData.node_data = sendEmailProcessData(data, getState, isEdit);
		processedData.node_data.content_template_id = id || content_template_id;
		processedData.node_data.content_type = content_type || 'text/html';
		processedData.node_data.email_mode =
			(data?.email_mode && data.email_mode[0]?.id) || SEND_EMAIL_MODE.NEW_CONVERSATION;
		processedData.node_data.rcv_inp_type_flag = rcv_inp_type_flag || '';
		processedData.node_data.sender =
			replaceVariablesInMsg(
				data?.email_mode && data.email_mode[0]?.id === SEND_EMAIL_MODE.EXISTING_CONVERSATION
					? processedData.node_data.sender
					: processedData?.node_data?.sender?.replaceAll(' ', ''),
				getState().PathBuilder.pathVariables
			) || '';
		processedData.node_data.sender_name =
			replaceVariablesInMsg(
				data?.email_mode && data.email_mode[0]?.id === SEND_EMAIL_MODE.EXISTING_CONVERSATION
					? sender_name
					: processedData.node_data.sender_name,
				getState().PathBuilder.pathVariables
			) || '';
		processedData.node_data.template_id = nodeData.template_id || null;
		processedData.node_data.type = type || 'email';
		if (data?.email_mode && data.email_mode[0]?.id === SEND_EMAIL_MODE.EXISTING_CONVERSATION) {
			processedData.node_data.node_key = node_key;
			processedData.node_data.subject = ' ';
		}

		return dispatch(
			addEditNodeAction(
				pathKey,
				processedData,
				url,
				isEdit,
				nodeKey,
				nodeData.sourceIndex,
				flowData,
				0,
				carouselCardIndex
			)
		);
	};

export const renameFlowAction = (flowName: string, flowKey: string) => (dispatch: any) => {
	const data = {
		nodearray: { path_key: flowKey, display_name: flowName, nodes: [] },
		type: 'rename',
	};

	return API.updateFlow(data).then((resp: any) => {
		if (resp.data) {
			dispatch(getAllFlowsAction());
			dispatch(getFlowDataAction(flowKey));
		} else {
			console.log('error creating a flow');
			toaster.negative(
				<ENGTToasterContainer
					title={i18nHelper('common:failed')}
					description={i18nHelper('errors:editPathError')}
				/>,
				{}
			);
		}
	});
};

const convertJSONAPIdata = (data: IObjProps) => {
	let convertedData = JSON.parse(JSON.stringify(data));
	if (data.node_type && data.node_type === 'JSON_API') {
		convertedData = {
			...convertedData,
			node_type: 'HTTP_REQUEST',
			node_data: {
				...data.node_data,
				headers: data.node_data.headers_obj,
			},
		};
	}

	return convertedData;
};

export const setSelectedNodeAction = (data: IObjProps) => (dispatch: any) => {
	const convertedData = convertJSONAPIdata(data);
	dispatch(setSelectedNodeDetails(convertedData));
};

export const updateSelectedNodeNameAction = (data: string) => (dispatch: any, getState: () => RootState) => {
	let flows = getState().PathBuilder.flowData?.data?.nodes || [];
	const currentSelectedNodeKey: IObjProps = getState().PathBuilder.selectedNode?.node_key;

	if (flows.length && currentSelectedNodeKey) {
		flows = flows.map((flow: IObjProps) =>
			flow.node_key === currentSelectedNodeKey
				? {
						...flow,
						node_name: data,
					}
				: flow
		);
	}

	dispatch(updateSelectedNodeName(data));
	dispatch(updateFlowDataNodes(flows));
};

export const setHighlightedNodeAction = (data: IObjProps) => (dispatch: any) => {
	dispatch(setHighlightedNodeDetails(data));
};

export const setIsFormSubmittingAction = (data: boolean) => (dispatch: any) => {
	dispatch(updateIsFormSubmittingState(data));
};

export const setBotRefAction = (data: any) => (dispatch: any) => {
	dispatch(setBotRef(data));
};

const getDefaultCoordinates = (index: number) => ({
	height: 40,
	xCoordinate: 200,
	yCoordinate: 200 * (index + 1),
});

export const getFlowNodeDataCoordinateAction = (pathKey: string) => (dispatch: any, getState: () => RootState) => {
	dispatch(setFlowDataLoader());
	API.getFlowNodeCoordinateData(pathKey).then((resp: any) => {
		if (resp.data?.responseObject) {
			const { nodes, root_node }: any = getState().PathBuilder.flowData.data;
			let newNodes = nodes.map((node: any, index: number) => ({
				...node,
				coordinates: resp.data.responseObject.nodeMap[node.node_key] || getDefaultCoordinates(index),
				isSelected: false,
			}));
			let newPathKey = pathKey;
			Object.keys(resp.data.responseObject.nodeMap).forEach((nodeName) => {
				const brokenNode = nodeName.split('_');
				if (brokenNode[brokenNode.length - 1] === 'startNode') {
					newPathKey = nodeName.replace('_startNode', '');
				}
			});

			const startNodeCoords = resp.data.responseObject.nodeMap[`${newPathKey}_startNode`]
				? resp.data.responseObject.nodeMap[`${newPathKey}_startNode`]
				: {
						height: 40,
						xCoordinate: 200,
						yCoordinate: 35,
					};

			newNodes = [
				...newNodes,
				{
					next_node: nodes.length ? root_node : null,
					node_key: `${newPathKey}_startNode`,
					node_name: 'Start',
					node_type: 'START',
					coordinates: startNodeCoords,
					isSelected: false,
				},
			];

			const newData = {
				...getState().PathBuilder.flowData.data,
				nodes: newNodes,
				coordinates: resp.data.responseObject.nodeMap,
			};
			dispatch(getFlowData(newData));
		} else {
			const { data } = getState().PathBuilder.flowData;
			const responseObject = data?.nodes?.map((node: any, index: number) => ({
				next_node: data.nodes[index + 1] ? data.nodes[index + 1].node_key : null,
				node_data: node.node_data,
				node_key: node.node_key,
				node_name: node.node_name,
				node_type: node.node_type,
				coordinates: {
					height: 40,
					xCoordinate: 150,
					yCoordinate: 200 * (index + 1),
				},
			}));
			const newData = {
				...getState().PathBuilder.flowData.data,
				nodes: [
					{
						next_node: data.nodes.length ? data.root_node : null,
						node_key: `${pathKey}_startNode`,
						node_name: 'Start',
						node_type: 'START',
						coordinates: {
							height: 40,
							xCoordinate: 200,
							yCoordinate: 35,
						},
						isSelected: false,
					},
					...responseObject,
				],
			};
			let sendData: Object = {};
			// eslint-disable-next-line array-callback-return
			data.nodes.map((node: any, index: number) => {
				sendData = {
					...sendData,
					[node.node_key]: {
						height: 40,
						xCoordinate: 150,
						yCoordinate: 200 * (index + 1),
					},
				};
			});
			sendData = {
				...sendData,
				[`${pathKey}_startNode`]: {
					height: 40,
					xCoordinate: 150,
					yCoordinate: 35,
				},
			};
			dispatch(saveFlowNodeDataCoordinateAction(pathKey, sendData));
			dispatch(getFlowData(newData));
		}
	});
};

export const addNodeAction = (data: IObjProps, menuCoordinates: any) => (dispatch: any, getState: () => RootState) => {
	const { nodes }: any = getState().PathBuilder.flowData.data;
	const newNodes = [...nodes];
	const newNode = {
		next_node: null,
		node_name: data.label,
		node_type: data.key,
		node_key: 'tempNode',
		coordinates: {
			height: 140,
			xCoordinate: menuCoordinates.x,
			yCoordinate: menuCoordinates.y + 200,
		},
	};
	newNodes.push(newNode);

	const newData = {
		...getState().PathBuilder.flowData.data,
		nodes: newNodes,
	};
	dispatch(getFlowData(newData));
};

export const deleteUnsavedNode = () => (dispatch: any, getState: () => RootState) => {
	const { nodes }: any = getState().PathBuilder.flowData.data;
	const newNodes: any = [];
	nodes.forEach((element: { node_key: string; next_node: string }) => {
		if (element.node_key !== 'tempNode') {
			if (element.next_node === 'tempNode') {
				newNodes.push({ ...element, next_node: null });
			} else {
				newNodes.push(element);
			}
		}
	});
	const newData = {
		...getState().PathBuilder.flowData.data,
		nodes: newNodes,
	};
	dispatch(getFlowData(newData));
};

export const setLinkNodeAction =
	(sourceNode: number, targetNode: number) => (dispatch: any, getState: () => RootState) => {
		const { nodes }: any = getState().PathBuilder.flowData.data;
		const newNodes = [...nodes];

		const newSourceNode = {
			...newNodes[sourceNode],
			...{ next_node: newNodes[targetNode].node_key },
		};

		const newNodeList: any[] = [];
		newNodes.forEach(function (item, index) {
			if (index === sourceNode) {
				newNodeList.push(newSourceNode);
			} else {
				newNodeList.push(item);
			}
		});

		const newData = {
			...getState().PathBuilder.flowData.data,
			nodes: newNodeList,
		};

		dispatch(getFlowData(newData));
	};

export const deleteNodeFromLayout = (pathKey: string, nodeKey: string) => (dispatch: any) =>
	API.deleteNodeLayout(pathKey, nodeKey).then((resp: any) => {
		if (resp.data) {
			toaster.positive(<ENGTToasterContainer title='Success' description='Node deleted' />, {});
			dispatch(getFlowDataAction(pathKey));
		} else {
			toaster.negative(
				<ENGTToasterContainer
					title={i18nHelper('common:failed')}
					description={i18nHelper('common:wentWrong')}
				/>,
				{}
			);
		}

		return resp;
	});

export const deleteOtherNodeConnectionAction = (pathKey: string, nodeKey: string) => (dispatch: any) =>
	API.deleteNodeConnections(pathKey, nodeKey).then((resp: any) => {
		if (resp.data) {
			return dispatch(deleteNodeFromLayout(pathKey, nodeKey));
		}
		toaster.negative(
			<ENGTToasterContainer title={i18nHelper('common:failed')} description={i18nHelper('common:wentWrong')} />,
			{}
		);

		return resp;
	});

export const deleteNodeAction = (pathKey: string, nodeKey: string) => (dispatch: any) =>
	API.deleteNode(pathKey, nodeKey).then((resp: any) => {
		if (resp.data) {
			return dispatch(deleteOtherNodeConnectionAction(pathKey, nodeKey));
		}
		toaster.negative(
			<ENGTToasterContainer title={i18nHelper('common:failed')} description={i18nHelper('common:wentWrong')} />,
			{}
		);

		return resp;
	});

export const changeRootNodeAndDelete =
	(newRootNodeKey: string, pathKey: string, nodeKeyToDel: string) => (dispatch: any) =>
		API.changeRootNode(newRootNodeKey, pathKey).then((resp: any) => {
			if (resp.data) {
				return dispatch(deleteNodeAction(pathKey, nodeKeyToDel));
			}
			toaster.negative(
				<ENGTToasterContainer
					title={i18nHelper('common:failed')}
					description={i18nHelper('common:wentWrong')}
				/>,
				{}
			);

			return resp;
		});

export const changeRootNode = (newRootNodeKey: string, pathKey: string) => (dispatch: any) => {
	API.changeRootNode(newRootNodeKey, pathKey).then((resp: any) => {
		if (resp.data) {
			dispatch(getFlowDataAction(pathKey));
		} else {
			toaster.negative(
				<ENGTToasterContainer
					title={i18nHelper('common:failed')}
					description={i18nHelper('common:wentWrong')}
				/>,
				{}
			);
		}
	});
};

export const deleteConnection = (nodeKey: string, pathKey: string) => (dispatch: any) =>
	API.deleteConnection(nodeKey, pathKey).then((resp: any) => {
		if (resp.data) {
			dispatch(getFlowDataAction(pathKey));
		} else {
			console.log('error Deleting a flow');
		}
	});

export const setConnection = (nodeKey: string, nextNodeKey: string, pathKey: string) => (dispatch: any) =>
	API.setConnection(nodeKey, nextNodeKey, pathKey).then((resp: any) => {
		if (resp.data) {
			dispatch(getFlowDataAction(pathKey));
		} else {
			console.log('error creating a connection');
		}
	});

export const setFlowDataNodeCoordinatesAction = (dataForStore: any) => (dispatch: any) => {
	dispatch(setFlowDataNodeCoordinates(dataForStore));
};

export const resetAllPathBuilderDataAction = () => (dispatch: any) => {
	dispatch(resetAllPathBuilderData());
};
export const getHashIdAction = (successCB?: Function) => (dispatch: any, getState: () => RootState) => {
	const brandingKey = getState().User.botAdmin.data.branding_key;
	const { cid, email } = getState().User.botAdmin.data;
	const uniqueId = `${getEnvironment()}_${email}_${brandingKey}`;
	const params = {
		targetApi: 'getHashId',
		integrationType: EXTERNAL_INTEGRATION_PROVIDER,
		brandingKey,
		uniqueId,
	};
	CloudIntegrationsApi.getHashId(params).then((response: any) => {
		if (isValidResponseObject(response)) {
			const { responseObject } = response.data;
			dispatch(setExternalIntegrationKeysAction(responseObject));
			successCB && successCB();
		}
	});
};

export const setExternalIntegrationKeysAction = (externalIntegrationKeys: any) => (dispatch: any) => {
	dispatch(setExternalIntegrationKeys(externalIntegrationKeys));
};
export const setExternalIntegrationsAction = (externalIntegrations: any) => (dispatch: any) => {
	dispatch(setExternalIntegrations(externalIntegrations));
};
export const getExternalIntegrationsAction = (successCB?: Function) => (dispatch: any, getState: () => RootState) => {
	const brandingKey = getState().User.botAdmin.data.branding_key;
	const isEngatiBrand = getState().User.botAdmin.data.is_engati_brand;
	const { RESELLER } = ACCOUNT_TYPES;
	const isReseller = localStorage.getItem('loggedInUserAccountType') === RESELLER;
	const isWhiteLabelReseller = isReseller || !isEngatiBrand;

	const { cid } = getState().User.botAdmin.data;
	const params = {
		targetApi: 'getExternalIntegrations',
		integrationType: 'integry',
		brandingKey,
		customerId: cid,
	};
	CloudIntegrationsApi.getExternalIntegrations(params).then((response: any) => {
		const integrations = { ...response.data.responseObject };
		if (isWhiteLabelReseller) {
			delete integrations.Mailchimp;
		}
		dispatch(setExternalIntegrations(integrations));
		const externalIntegrationIds = Object.values(integrations)
			.map((value: any) => value.id)
			.join(',');
		dispatch(getConnectedUsersAction(externalIntegrationIds));

		successCB && successCB();
	});
};
export const getExternalCloudIntegrationsAction =
	(successCB?: Function) => (dispatch: any, getState: () => RootState) => {
		const brandingKey = getState().User.botAdmin.data.branding_key;
		const isEngatiBrand = getState().User.botAdmin.data.is_engati_brand;
		const { RESELLER } = ACCOUNT_TYPES;
		const isReseller = localStorage.getItem('loggedInUserAccountType') === RESELLER;
		const isWhiteLabelReseller = isReseller || !isEngatiBrand;

		const { cid } = getState().User.botAdmin.data;
		const params = {
			targetApi: 'getExternalIntegrations',
			integrationType: 'integry',
			brandingKey,
			customerId: cid,
		};
		CloudIntegrationsApi.getExternalIntegrations(params).then((response: any) => {
			const integrations = { ...response.data.responseObject };
			if (isWhiteLabelReseller) {
				delete integrations.Mailchimp;
			}
			dispatch(setExternalIntegrations(integrations));
			const externalIntegrationIds = Object.values(integrations)
				.map((value: any) => value.id)
				.join(',');

			dispatch(getConnectedUsersAction(externalIntegrationIds));
			successCB && successCB(externalIntegrationIds);
		});
	};
export const getConnectedUsersAction =
	(externalIntegrationIds: string) => (dispatch: any, getState: () => RootState) => {
		const { cid } = getState().User.botAdmin.data;
		const params = {
			targetApi: 'getConnectedUsers',
			externalIntegrationIds,
			customerId: cid,
		};
		CloudIntegrationsApi.getConnectedUsers(params).then(
			(response: any) => {
				if (isValidResponseObject(response)) {
					const { responseObject } = response.data;
					dispatch(setConnectedUsers(responseObject));
				}
			},
			() => {
				dispatch(openErrorSavingModalAction());
			}
		);
	};

export const setZoomScaleAction = (zoomScale: globalZoomScaleInterface) => (dispatch: any) => {
	dispatch(setZoomScale(zoomScale));
};
export const resetZoomScaleAction = () => (dispatch: any) => {
	dispatch(setZoomScale({ scaleX: 1, scaleY: 1, translateX: 0, translateY: 0, skewX: 0, skewY: 0 }));
};
