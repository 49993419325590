import React from 'react';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';

import SkeletonCircleView from 'components/UI/ENGTSkeleton/ENGTSkeletonCircleView';
import SkeletonSquareView from 'components/UI/ENGTSkeleton/ENGTSkeletonSquareView';

import Wrappper, { MESSAGE_ALIGN } from '../MessageOverview/components/Wrapper';

function MessageOverviewSkeletonView({ count }: { count: number }) {
	const [css]: any = useStyletron();

	const verticalAlignBottom = {
		display: 'flex',
		alignItems: 'flex-end',
	};
	const isOddCount = !!(count % 2);
	const randomNumber = (Math.random() * (7 - 3) + 3) * 10;

	const squareOverrideCss = {
		height: '2.0rem',
		width: `${randomNumber}%`,
		override: {
			Root: {
				style: {
					...(!isOddCount && { marginLeft: 'auto' }),
					opacity: 0.09,
				},
			},
		},
	};

	return (
		<Wrappper align={isOddCount ? MESSAGE_ALIGN.LEFT : MESSAGE_ALIGN.RIGHT}>
			<Block className={css(verticalAlignBottom)}>
				{isOddCount && <SkeletonCircleView diameter='1.5rem' />}
				<Block
					width='calc(100% - 2rem)'
					display='inline-block'
					paddingRight={isOddCount ? '' : '0.5rem'}
					paddingLeft={isOddCount ? '0.5rem' : ''}
				>
					<SkeletonSquareView
						height={squareOverrideCss.height}
						width={squareOverrideCss.width}
						override={squareOverrideCss.override}
					/>
				</Block>
				{!isOddCount && <SkeletonCircleView diameter='1.5rem' />}
			</Block>
		</Wrappper>
	);
}
export default React.memo(MessageOverviewSkeletonView);
