import { createSlice } from '@reduxjs/toolkit';

import { userXpReducer } from './reducers';
import { userXpState } from './state';

export const userXpSlice = createSlice({
	name: 'UserXp',
	initialState: userXpState,
	reducers: userXpReducer,
});

export const { actions, reducer } = userXpSlice;
