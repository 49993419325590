import React from 'react';
import { useStyletron } from 'baseui';
import { Avatar } from 'baseui/avatar';

import { REACT_APP_BASE_URL } from 'shared/config/config';

interface IENGTMessageAvatar {
	backgroundColor: string;
	color: string;
	name: string;
	avatar?: string;
}

function MessageAvatar(props: IENGTMessageAvatar) {
	const { name, backgroundColor, color, avatar } = props;
	const [css, theme]: [any, any] = useStyletron();

	return (
		<>
			{name && (
				<Avatar
					src={
						avatar
							? avatar.indexOf('https://') === -1
								? `${REACT_APP_BASE_URL}/portal/api/v1/download/profilePic?token=${avatar}`
								: avatar
							: ''
					}
					name={name || ''}
					size='1.5rem'
					overrides={{
						Root: {
							style: {
								backgroundColor,
							},
						},
						Initials: {
							style: {
								height: 'calc(100% + 0.156rem)',
								color: color || theme.colors.primaryB,
								fontSize: '0.563rem',
								fontStyle: 'normal',
								fontWeight: 500,
								lineHeight: '0.688rem',
							},
						},
					}}
				/>
			)}
		</>
	);
}

export default React.memo(MessageAvatar);
