import React, { useMemo, useState } from 'react';
import { useStyletron } from 'baseui';

import ApiTriggerMessageTag from 'components/shared/ApiTriggerMessageTag/ApiTriggerMessageTag';

import { MESSAGE_SENDER_MAP } from 'shared/consts/consts';
import { IObjProps } from 'shared/consts/types';
import { decodeAndSanitizeInput } from 'shared/helpers';
import { linkify, regExp } from 'shared/helpers/quillHelper';
import Train from 'shared/icons/navigation/Train';

import { getIsValidLocationMessage, getLocationMessageHTML } from '../helpers/locationMessageHelper';

import MessageActionsPopup from './MessageActionsPopup';
import ToggleLocationLink from './ToggleLocationLink';

export interface IENGTText {
	backgroundColor: string;
	menuItems?: Array<any>;
	message: string;
	trainIconBgColor: string;
	trainIconFill: string;
	messageData?: IObjProps;
	openMenu?: Function;
	closeMenu?: Function;
	appMessage?: any;
	messageStatus?: JSX.Element | null;
	showApiTriggerMessageTag?: boolean;
}

const { bot: SENDER_BOT, user: SENDER_USER } = MESSAGE_SENDER_MAP;

function Text(props: IENGTText) {
	const {
		message,
		backgroundColor,
		openMenu,
		closeMenu,
		menuItems = [],
		trainIconBgColor,
		trainIconFill,
		appMessage = <></>,
		messageStatus,
		messageData = {},
		showApiTriggerMessageTag = false,
	} = props;
	const [css]: [any, any] = useStyletron();
	const { sender, messageId, marked_by: markedBy } = messageData;
	const [showLinkForLocation, setShowLinkForLocation] = useState<boolean>(true);

	const textCss = {
		paddingLeft: '1rem',
		paddingRight: messageStatus ? '0.5rem' : '1rem',
		paddingTop: '0.875rem',
		paddingBottom: '0.875rem',
		display: 'inline-block',
		borderTopLeftRadius: '0.5rem',
		borderTopRightRadius: '0.5rem',
		borderBottomLeftRadius: '0.5rem',
		borderBottomRightRadius: '0.5rem',
		textAlign: 'left',
		wordBreak: 'break-word',
		position: 'relative',
	};

	const onMenuHover = (event: any) => {
		event.stopPropagation();
		openMenu && openMenu(event);
	};

	const onMenuClose = (event: any) => {
		closeMenu && closeMenu(event);
	};

	const textMessage = useMemo(() => {
		const msg = linkify(message);
		try {
			if (msg.indexOf('<a href=') > -1) {
				return msg.replace(regExp, `<a class="clickableLinksA" id="external-link" target="_blank" href=`);
			}
		} catch (e) {
			console.error('error parsing the message');

			return msg;
		}

		return msg;
	}, [message]);

	const locationMessage: string = useMemo(() => getLocationMessageHTML(message, sender), [message]);

	const isValidLocationMessage: boolean = getIsValidLocationMessage(locationMessage);

	const messageHTML = showLinkForLocation && isValidLocationMessage ? locationMessage : '' || textMessage || '';

	return (
		<span
			className={css({
				display: 'flex',
				alignItems: 'center',
				...(isValidLocationMessage && { marginBottom: '1rem' }),
				...(sender === SENDER_BOT && {
					flexDirection: 'row-reverse',
					justifyContent: 'flex-start',
				}),
			})}
			onMouseEnter={onMenuHover}
			onMouseLeave={onMenuClose}
		>
			<span
				className={css({
					...textCss,
					backgroundColor,
					cursor: menuItems.length ? 'pointer' : '',
				})}
				id={messageId}
			>
				{showApiTriggerMessageTag && <ApiTriggerMessageTag />}
				<div
					className={css({
						position: 'relative',
						display: 'flex',
						flexDirection: 'row',
					})}
				>
					<span
						className={css({ 'white-space': 'pre-wrap' })}
						dangerouslySetInnerHTML={{ __html: decodeAndSanitizeInput(messageHTML) }}
					/>
					{markedBy && (
						<div
							className={css({
								position: 'absolute',
								top: '-1rem',
								right: '-1.75rem',
								borderTopLeftRadius: '100%',
								borderTopRightRadius: '100%',
								borderBottomLeftRadius: '100%',
								borderBottomRightRadius: '100%',
								height: '1.5rem',
								width: '1.5rem',
								backgroundColor: trainIconBgColor,
							})}
						>
							<Train size={24} fillColor={trainIconFill} />
						</div>
					)}
					{messageStatus}
				</div>
				{appMessage}
				{isValidLocationMessage && (
					<ToggleLocationLink showLink={showLinkForLocation} setShowLink={setShowLinkForLocation} />
				)}
			</span>
			<MessageActionsPopup {...props} />
		</span>
	);
}

export default React.memo(Text);
