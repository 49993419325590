import React from 'react';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';

import ApiTriggerMessageTag from 'components/shared/ApiTriggerMessageTag/ApiTriggerMessageTag';

interface IENGTAudio {
	backgroundColor: string;
	src: any;
	showApiTriggerMessageTag?: boolean;
}

function Audio(props: IENGTAudio) {
	const { backgroundColor, src, showApiTriggerMessageTag = false } = props;
	const [css]: [any, any] = useStyletron();

	return (
		<Block
			className={css({
				display: 'inline-block',
				paddingLeft: '1rem',
				paddingRight: '1rem',
				paddingTop: '0.875rem',
				paddingBottom: '0.875rem',
				borderTopLeftRadius: '0.5rem',
				borderTopRightRadius: '0.5rem',
				borderBottomLeftRadius: '0.5rem',
				borderBottomRightRadius: '0.5rem',
				backgroundColor,
			})}
		>
			{showApiTriggerMessageTag && <ApiTriggerMessageTag />}
			<audio controls>
				<source src={src} type='audio/mpeg' />
			</audio>
		</Block>
	);
}

export default React.memo(Audio);
