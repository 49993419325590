import React from 'react';
import { useStyletron } from 'baseui';
import { Avatar } from 'baseui/avatar';

import ENGTImage from 'components/UI/ENGTImage/ENGTImage';

import { REACT_APP_BASE_URL } from 'shared/config/config';
import { CHANNEL_ICON_MAPPING } from 'shared/consts/consts';

export interface IUserProfileAvatarProps {
	avatar?: string;
	avatarSize: string;
	channelName: string;
	color?: string;
	initialsFontSize?: string;
	inPendingState?: boolean;
	name: string;
	onHover?: boolean;
}

const avatarOverrideCss = ({
	color,
	initialsFontSize,
	theme,
}: {
	color: string;
	initialsFontSize: string;
	theme: any;
}) => ({
	Root: {
		style: {
			backgroundColor: color,
			display: 'flex',
			justifyContent: 'center',
		},
	},
	Initials: {
		style: {
			fontSize: initialsFontSize,
			fontWeight: 500,
			color: theme.colors.primaryB,
			marginTop: '0.0625rem',
		},
	},
});

const userProfileAvatarCss = () => ({
	display: 'flex',
	fontFamily: 'Hind',
	width: '3rem',
	height: '2rem',
	position: 'relative',
	paddingBottom: '0.7rem',
});

export const UserProfileAvatar: React.FC<React.PropsWithChildren<IUserProfileAvatarProps>> = ({
	avatar,
	avatarSize,
	channelName,
	color = '#AE1536',
	initialsFontSize = '1rem',
	inPendingState,
	name,
	onHover,
	...props
}) => {
	const [css, theme]: [any, any] = useStyletron();

	const userChannelCss = (theme: any) => ({
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '1rem',
		height: '1rem',
		position: 'absolute',
		top: '1.5625rem',
		left: '1.5625rem',
		borderTopRightRadius: '50%',
		borderTopLeftRadius: '50%',
		borderBottomRightRadius: '50%',
		borderBottomLeftRadius: '50%',
		borderWidth: '0.188rem',
		borderStyle: 'solid',
		backgroundColor: onHover ? theme.primaryNavBackground : theme.colors.backgroundPrimary,
		borderColor: onHover ? theme.primaryNavBackground : theme.colors.backgroundPrimary,
	});

	return (
		<div className={css(userProfileAvatarCss())}>
			<span className={css({ height: avatarSize })}>
				<span
					className={css({
						display: inPendingState ? 'inline-block' : 'none',
						width: '0.5rem',
						height: '0.5rem',
						position: 'absolute',
						borderTopRightRadius: '50%',
						borderTopLeftRadius: '50%',
						borderBottomRightRadius: '50%',
						borderBottomLeftRadius: '50%',
						borderWidth: '0.188rem',
						borderStyle: 'solid',
						backgroundColor: theme.colors.dotPendingFill,
						marginLeft: '0.063rem',
						marginTop: '0.063rem',
					})}
					style={{
						borderColor: onHover ? theme.primaryNavBackground : theme.colors.backgroundPrimary,
					}}
				/>
				<Avatar
					overrides={avatarOverrideCss({
						initialsFontSize,
						theme,
						color,
					})}
					src={
						avatar
							? avatar.indexOf('https://') === -1
								? `${REACT_APP_BASE_URL}/portal/api/v1/download/profilePic?token=${avatar}`
								: avatar
							: ''
					}
					name={name || ''}
					size={avatarSize}
				/>
				<div className={css(userChannelCss(theme))}>
					<ENGTImage
						lazy
						lazyProps={{
							height: '5rem',
							width: channelName === 'email' ? '85%' : '100%',
						}}
						src={CHANNEL_ICON_MAPPING[channelName]}
						alt='channel-img'
						className={css({
							width: channelName === 'email' ? '85%' : '100%',
							height: '100%',
							objectFit: 'contain',
						})}
					/>
				</div>
			</span>
		</div>
	);
};
