import React from 'react';
import { useStyletron } from 'baseui';

function Level7({
	size,
	fillColor,
	height,
	width,
}: {
	size?: number;
	fillColor?: string;
	height: number;
	width: number;
}) {
	const [, theme] = useStyletron();

	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			enableBackground='new 0 0 90 90'
			viewBox='0 0 90 90'
			id='telescope'
			height={height}
			width={width}
		>
			<path
				fill='#c9eaf9'
				d='M62.406,14.586c-3.826,1.106-7.475,2.768-11.304,3.865c-7.499,2.15-15.43,2.074-23.164,3.094
		s-15.775,3.427-20.919,9.292c-6.684,7.621-6.615,19.678-1.495,28.428s14.385,14.45,24.094,17.363
		c8.026,2.408,16.637,3.127,24.841,1.421s15.971-5.923,21.39-12.315c3.786-4.467,6.344-9.84,8.463-15.299
		c2.704-6.966,4.784-14.507,3.439-21.857C85.421,15.845,74.016,11.228,62.406,14.586z'
			/>
			<path
				fill='#fff'
				d='M48.626,54.871l-5.44,1.42l-4.41,1.14l-7.68,2c-0.66,0.18-1.34-0.22-1.51-0.88l-2.89-11.079
						c-0.17-0.66,0.23-1.33,0.89-1.51l15.599-4.06l1.92-0.5h0.01l1.22,4.68L48.626,54.871z'
			/>
			<polygon
				fill='#e6e6ef'
				points='48.626 54.871 43.186 56.291 43.186 41.902 45.106 41.402 45.116 41.402 46.336 46.082'
			/>
			<path
				fill='#e6e6ef'
				d='M48.626,54.871l-5.44,1.42l-4.41,1.14c-0.19-0.73-0.29-1.49-0.29-2.27c0-3.43,1.9-6.43,4.7-8.01
						c0.96-0.54,2.02-0.91,3.14-1.07c0.01,0,0.01,0,0.01,0L48.626,54.871z'
			/>
			<path
				fill='#5c9efe'
				d='M68.485,52.071l-11.629,3.03l-6.06,1.58c-0.88,0.23-1.78-0.3-2.01-1.18l-0.16-0.63l-2.29-8.79
						c0,0,0,0-0.01,0l-1.21-4.68h-0.01l-0.16-0.63c-0.23-0.88,0.3-1.78,1.18-2.01l17.689-4.61L68.485,52.071z'
			/>
			<path
				fill='#3f8fef'
				d='M56.855,55.101l-6.06,1.58c-0.88,0.23-1.78-0.3-2.01-1.18l-0.16-0.63l-2.29-8.79
						c0.44-0.07,0.88-0.1,1.33-0.1C52.715,45.982,56.825,50.071,56.855,55.101z'
			/>
			<polygon fill='#3f8fef' points='65.255 52.909 68.487 52.066 63.818 34.149 60.637 34.978' />
			<rect
				width='9.831'
				height='9.374'
				x='18.472'
				y='49.565'
				fill='#ff564c'
				transform='rotate(-14.602 23.392 54.248)'
			/>
			<rect
				width='3.171'
				height='9.374'
				x='25.024'
				y='48.726'
				fill='#f73934'
				transform='rotate(-14.602 26.614 53.408)'
			/>
			<path
				fill='#fff'
				d='M73.017 53.223l-3.294.858c-.367.096-.743-.124-.838-.492l-5.464-20.965c-.096-.367.124-.743.492-.838l3.294-.858c.367-.096.743.124.838.492l5.464 20.965C73.604 52.752 73.384 53.128 73.017 53.223zM19.328 61.986l-2.17.566c-.515.134-1.041-.174-1.175-.689l-2.772-10.638c-.134-.515.174-1.041.689-1.175l2.17-.566c.515-.134 1.041.174 1.175.689l2.772 10.638C20.152 61.326 19.843 61.852 19.328 61.986z'
			/>
			<path
				fill='#fff'
				d='M68.834,34.448c4.121-1.074,8.329,1.394,9.401,5.507c1.074,4.121-1.394,8.329-5.515,9.403L68.834,34.448z'
			/>
			<path
				fill='#e6e6ef'
				d='M72.649,34.443c-1.213-0.309-2.516-0.334-3.816,0.004l3.886,14.91c0.561-0.146,1.076-0.371,1.57-0.628
						L72.649,34.443z'
			/>
			<path
				fill='#febc00'
				d='M47.416 61.837l-5.51 11.909c-.36.77-1.13 1.27-1.98 1.27-1.26 0-2.19-1.04-2.19-2.18 0-.31.07-.62.21-.92l5.4-11.669C44.446 61.197 45.866 61.787 47.416 61.837zM57.595 72.827c0 1.15-.92 2.19-2.18 2.19-.85 0-1.62-.5-1.98-1.27l-5.51-11.909c1.55-.06 2.97-.64 4.07-1.59l5.4 11.669C57.535 72.217 57.595 72.527 57.595 72.827z'
			/>
			<circle cx='47.669' cy='55.162' r='6.685' fill='#fff' transform='rotate(-76.714 47.674 55.165)' />
			<circle cx='47.669' cy='55.162' r='2.496' fill='#febc00' transform='rotate(-76.714 47.674 55.165)' />
			<path
				fill='#84d189'
				d='M39.249,25.011c1.222,4.102-1.113,8.418-5.224,9.643c-4.102,1.222-8.418-1.113-9.64-5.214
							c-1.225-4.111,1.11-8.427,5.211-9.649C33.708,18.565,38.024,20.9,39.249,25.011z'
			/>
			<path
				fill='#525987'
				d='M78.96,39.766c-1.11-4.263-5.311-6.913-9.58-6.196l-0.61-2.339c-0.097-0.371-0.332-0.683-0.663-0.877
					c-0.332-0.195-0.718-0.247-1.09-0.151l-3.294,0.858c-0.767,0.2-1.229,0.986-1.028,1.754l0.208,0.798l-16.968,4.422
					c-1.246,0.325-2,1.579-1.729,2.827L27.39,45.238c-1.053,0.286-1.689,1.372-1.419,2.423l0.073,0.281l-8.059,2.1l-0.015-0.058
					c-0.238-0.915-1.175-1.465-2.089-1.225l-2.169,0.565c-0.917,0.238-1.465,1.172-1.227,2.09l2.772,10.638
					c0.235,0.902,1.161,1.465,2.09,1.226l2.169-0.566c0.918-0.239,1.465-1.174,1.226-2.09l-0.015-0.057l8.061-2.1l0.071,0.274
					c0.281,1.091,1.396,1.699,2.425,1.419l9.408-2.447c0.379,1.034,0.973,1.963,1.74,2.725L37.267,71.6
					c-0.923,1.972,0.542,4.167,2.659,4.167c1.132,0,2.176-0.668,2.661-1.706l5.084-10.989l5.085,10.992
					c0.483,1.034,1.527,1.703,2.66,1.703c2.16,0,3.548-2.266,2.66-4.164l-5.168-11.17c1.088-1.081,1.835-2.497,2.092-4.076
					l12.952-3.375l0.208,0.798c0.198,0.76,0.975,1.23,1.753,1.028l3.294-0.858c0,0,0,0,0,0c0.767-0.2,1.228-0.986,1.028-1.754
					l-0.609-2.337C77.72,48.4,80.075,44.043,78.96,39.766z M19.14,61.26C19.14,61.26,19.14,61.26,19.14,61.26l-2.17,0.566
					c-0.115,0.023-0.23-0.04-0.261-0.153l-2.772-10.637c-0.029-0.113,0.036-0.23,0.152-0.261l2.171-0.565
					c0.117-0.029,0.23,0.04,0.259,0.152c1.119,4.294,1.656,6.356,2.772,10.638C19.322,61.116,19.252,61.23,19.14,61.26z
					 M20.35,59.112l-1.986-7.619l8.059-2.1l1.987,7.619L20.35,59.112z M30.9,58.707c-0.264,0.069-0.522-0.091-0.587-0.346
					c-0.509-1.955-3.047-11.685-2.889-11.076c-0.067-0.259,0.095-0.527,0.353-0.598l16.799-4.373l1.459,5.6
					c-3.314,0.748-5.799,3.71-5.799,7.247c0,0.373,0.036,0.736,0.09,1.094L30.9,58.707z M41.227,73.429
					c-0.238,0.509-0.749,0.838-1.301,0.838c-1.044,0-1.749-1.074-1.299-2.034l5.011-10.829c0.727,0.473,1.689,0.883,2.66,1.064
					L41.227,73.429z M51.703,61.402l5.012,10.833c0.419,0.899-0.225,2.032-1.3,2.032c-0.552,0-1.063-0.329-1.299-0.835
					l-5.074-10.967C50.007,62.284,50.941,61.897,51.703,61.402z M47.669,61.097c-3.258,0-5.935-2.665-5.935-5.935
					c0-3.272,2.662-5.935,5.935-5.935s5.935,2.662,5.935,5.935C53.604,58.465,50.894,61.097,47.669,61.097z M67.573,51.529
					l-12.487,3.254c-0.199-3.923-3.444-7.056-7.416-7.056c-0.043,0-0.085,0.006-0.129,0.007c-0.026-0.101-1.895-7.254-1.868-7.154
					c-0.125-0.479,0.164-0.97,0.642-1.095l16.968-4.422C64.176,38.495,66.971,49.22,67.573,51.529z M69.61,53.401
					c0,0-4.022-15.26-5.509-20.889l3.218-0.902l5.448,20.905L69.61,53.401z M73.246,48.404l-3.488-13.382
					c3.471-0.514,6.848,1.658,7.75,5.122C78.432,43.687,76.464,47.189,73.246,48.404z'
			/>
			<path
				fill='#525987'
				d='M47.669 51.916c-1.79 0-3.246 1.456-3.246 3.246 0 1.789 1.456 3.245 3.246 3.245s3.246-1.456 3.246-3.245C50.915 53.372 49.459 51.916 47.669 51.916zM47.669 56.907c-.962 0-1.746-.783-1.746-1.745 0-.963.783-1.746 1.746-1.746s1.746.783 1.746 1.746C49.415 56.124 48.632 56.907 47.669 56.907zM24.997 32.297c2.084 2.805 5.733 4.122 9.242 3.076 3.498-1.042 5.852-4.123 6.062-7.64 3.627-1.501 7.076-3.38 6.603-4.969-.163-.549-.611-1.979-8.225-.555-2.135-2.928-5.843-4.166-9.296-3.137-3.559 1.06-5.877 4.211-6.054 7.711-7.152 2.972-6.743 4.416-6.58 4.965C17.338 33.726 22.965 32.677 24.997 32.297zM33.811 33.935c-2.63.783-5.363-.035-7.149-1.974 3.8-.82 8.01-2.052 12.062-3.602C38.285 31.012 36.403 33.163 33.811 33.935zM45.356 23.227c-.434.546-2.119 1.621-5.112 2.909-.128-.975-.342-1.686-.751-2.554C43.157 22.935 44.815 23.057 45.356 23.227zM29.811 20.509c4.247-1.268 8.663 1.679 8.99 6.225-3.875 1.536-8.937 3.051-13.098 3.902-.246-.443-.45-.913-.598-1.41C23.994 25.499 26.112 21.611 29.811 20.509zM23.399 28.378c.06.428.142.854.267 1.276.132.442.305.863.501 1.271-3.209.56-5.208.582-5.87.365C18.658 30.851 19.98 29.841 23.399 28.378z'
			/>
			<g>
				<path
					fill='#ff564c'
					d='M56.281,15.576c0,0.42-0.34,0.75-0.75,0.75c-1.69,0-3.07,1.38-3.07,3.07c0,0.41-0.33,0.75-0.75,0.75
							c-0.41,0-0.75-0.34-0.75-0.75c0-0.82-0.31-1.59-0.89-2.18c-0.58-0.57-1.35-0.89-2.17-0.89c-0.41,0-0.75-0.33-0.75-0.75
							c0-0.41,0.34-0.75,0.75-0.75c0.81,0,1.59-0.31,2.17-0.89c0.58-0.58,0.89-1.36,0.89-2.17c0-0.41,0.34-0.75,0.75-0.75
							c0.42,0,0.75,0.34,0.75,0.75c0,0.81,0.32,1.59,0.9,2.17c0.58,0.58,1.35,0.89,2.17,0.89
							C55.941,14.827,56.281,15.167,56.281,15.576z'
				/>
			</g>
			<g>
				<path
					fill='#5c9efe'
					d='M67.992,22.571c0,0.331-0.268,0.591-0.591,0.591c-1.332,0-2.42,1.088-2.42,2.42
							c0,0.323-0.26,0.591-0.591,0.591c-0.323,0-0.591-0.268-0.591-0.591c0-0.646-0.244-1.254-0.702-1.719
							c-0.457-0.449-1.064-0.702-1.711-0.702c-0.323,0-0.591-0.26-0.591-0.591c0-0.323,0.268-0.591,0.591-0.591
							c0.639,0,1.254-0.244,1.711-0.702c0.457-0.457,0.702-1.072,0.702-1.711c0-0.323,0.268-0.591,0.591-0.591
							c0.331,0,0.591,0.268,0.591,0.591c0,0.639,0.252,1.254,0.71,1.711c0.457,0.457,1.064,0.702,1.711,0.702
							C67.724,21.979,67.992,22.247,67.992,22.571z'
				/>
			</g>
		</svg>
	);
}

export default Level7;
