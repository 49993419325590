/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { toaster } from 'baseui/toast';
import { ParagraphMedium } from 'baseui/typography';
import { differenceInHours } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import VisibilitySensor from 'react-visibility-sensor';

import EditQuickReplyModal from 'components/shared/EditQuickReply/EditQuickReplyModal';
import TemplatePreviewCards from 'components/shared/MessageTemplatesRevamp/TemplatePreviewCards/TemplatePreviewCards';
import TemplatePreview from 'components/shared/Template/Template';
import ENGTImage from 'components/UI/ENGTImage/ENGTImage';
import ENGTToasterContainer from 'components/UI/ENGTToaster/ENGTToasterContainer';
import ENGTTooltip from 'components/UI/ENGTTooltip/ENGTTooltip';
import NoDataTemplate from 'components/UI/NoDataTemplate/NoDataTemplate';

import {
	BOT_AUTOMATION_PAUSE_LIMIT,
	CHANNEL_TITLE_MAPPING,
	MESSAGE_SENDER_MAP,
	MESSAGE_STATE_MAP,
	MESSAGE_STATES,
	MESSAGE_TYPE_MAP,
	NEW_MESSAGE_INDICATOR_ELEMENT_ID,
	THUMB_GESTURE_DOWN_ICON,
	THUMB_GESTURE_UP_ICON,
	TRANSLATED_LAST_MESSAGE_STATE,
} from 'shared/consts/consts';
import { TIME_STAMP } from 'shared/consts/QAConsts';
import { IApiDataProps, IObjProps } from 'shared/consts/types';
import { CHANNELS, FLOW_CONVERSATION } from 'shared/enum';
import {
	copyToClipboard,
	decodeAndSanitizeInput,
	getConversationMessageDuration,
	getProfileColor,
	hexToRgb,
	isValidJson,
	setTemplateDataForD360,
	toSpaceSeperatedPascalCase,
	WHATSAPP_PROVIDERS,
} from 'shared/helpers';
import { linkify } from 'shared/helpers/quillHelper';
import BotIconDefaultLight from 'shared/icons/BotIconDefaultLight.svg';
import DownArrow from 'shared/icons/DownArrow';
import awaitedIcon from 'shared/icons/Messages/awaitedIcon.svg';
import doubleTickReadIcon from 'shared/icons/Messages/doubleTickRead.svg';
import doubleTickUnreadIcon from 'shared/icons/Messages/doubleTickUnread.svg';
import errorIcon from 'shared/icons/Messages/error.png';
import singleTickIcon from 'shared/icons/Messages/singleTick.svg';
import NoMessagesListIcon from 'shared/icons/NoMessagesListIcon.svg';
import TypingIndicatorIcon from 'shared/icons/TypingIndicatorIcon.gif';
import { getCarouselPayload } from 'shared/whatsappTemplateHelpers';

import {
	fontStyleSelector,
	isCreateMessageTemplatesV2EnabledSelector,
	isTemplateCacheEntitlementEnabled,
	isWhatsappAcceleratorTrialEnabled,
} from 'store/App/User/selectors';
import { API as template_api } from 'store/Broadcast/api';
import { messageTemplatesSelector } from 'store/Broadcast/selectors';
import {
	actions,
	getUserMessagesAction,
	markToTrainAction,
	setSelectedUserAction,
	updateLastTranslatedMessageStateAction,
	updateUserDetailsAction,
} from 'store/Message';
import { IUserDetailsProps } from 'store/Message/messageDetails/actions';
import { openErrorSavingModalAction } from 'store/Modal/actions';
import { RootState } from 'store/rootReducer';

import MessageSkeletonList from 'pages/Messages/components/MessageSkeletonList';

import { getNotReadMessageCount } from '../helpers/helper';
import { getLocationPacketDetails } from '../helpers/locationMessageHelper';

import Audio from './Audio';
import Card from './Card';
import Carousel from './Carousel';
import Cart from './Cart';
import ContactCard from './ContactCard';
import FeedBack from './FeedBack';
import File from './File';
import FileReceived from './FileReceived';
import Image from './Image';
import MediaResponse from './MediaResponse';
import MessageAvatar from './MessageAvatar';
import NewMessageIndicator from './NewMessageIndicator';
import PaymentMessage from './PaymentMessage';
import ProductMessage from './ProductMessage';
import QuotedMessage from './QuotedMessage';
import RecurringNotificationTemplate from './RecurringNotificationTemplate';
import Smo from './Smo';
import Text from './Text';
import Video from './Video';
import WhatsAppFlowMessage from './WhatsAppFlowMessage';
import Wrappper, { MESSAGE_ALIGN } from './Wrapper';

const { bot: SENDER_BOT, user: SENDER_USER } = MESSAGE_SENDER_MAP;

export const {
	text: TEXT,
	messageReceived: MESSAGE_RECEIVED,
	options: OPTIONS,
	audio: AUDIO,
	image: IMAGE,
	video: VIDEO,
	carousel: CAROUSEL,
	fileReceived: FILE_RECEIVED,
	feedBack: FEEDBACK,
	postBack: POSTBACK,
	file: FILE,
	card: CARD,
	slider: SLIDER,
	identity: IDENTITY,
	identityNode: IDENTITY_NODE,
	formNode: FORM_NODE,
	webView: WEB_VIEW,
	agentParticipation: AGENT_PARTICIPATION_STATUS,
	storyReceived: STORY_RECEIVED,
	template: TEMPLATE,
	COMMENT_RECEIVED,
	COMMENT,
	PRIVATE_REPLY,
	WA_COMMERCE_PRODUCT,
	CART_RECEIVED,
	PRODUCT_MESSAGE_RECEIVED,
	AD_REFERRAL_RECEIVED,
	WA_PAYMENT,
	WA_CONTACT,
	WA_FLOW_RECEIVED,
	WA_FLOW,
} = MESSAGE_TYPE_MAP;
const { setBringToView } = actions;

interface messageWapperParams {
	align?: string;
	children: React.ReactNode;
	index: number;
	isActiveTab: boolean;
	message: IObjProps;

	translationAction: Function;
}

const messageStatusMapper: IObjProps = {
	READ: doubleTickReadIcon,
	DELIVERED: doubleTickUnreadIcon,
	SENT: singleTickIcon,
	FAILED: errorIcon,
	AWAITED: awaitedIcon,
	SENDING: awaitedIcon,
};

const isUserMessage = (message: IObjProps) => message.sender === SENDER_USER;

const messageCss = (theme: any, message: IObjProps, flow: FLOW_CONVERSATION[keyof FLOW_CONVERSATION]) => {
	const { inbox, recentConvAgentMsgBg, recentConvBotMsgBg } = theme;
	const { backgroundPrimary, backgroundSecondary } = inbox;

	if (flow !== FLOW_CONVERSATION.CONVERSATIONS) {
		return { backgroundColor: isUserMessage(message) ? recentConvAgentMsgBg : recentConvBotMsgBg };
	}

	return {
		backgroundColor: isUserMessage(message) ? backgroundSecondary : backgroundPrimary,
	};
};

const verticalAlignBottom = {
	display: 'flex',
	alignItems: 'flex-end',
};

const thumbsIconCss = {
	height: '1.875rem',
	width: '1.875rem',
	paddingLeft: '1rem',
	paddingRight: '1rem',
	paddingTop: '0.875rem',
	paddingBottom: '0.875rem',
	borderTopLeftRadius: '0.5rem',
	borderTopRightRadius: '0.5rem',
	borderBottomLeftRadius: '0.5rem',
	borderBottomRightRadius: '0.5rem',
	...verticalAlignBottom,
};

const AUTOMATIC_HANDLED = 'AUTOMATIC_HANDLED';

const quotedMessageBgColor = (theme: any, message: IObjProps, flow: FLOW_CONVERSATION[keyof FLOW_CONVERSATION]) => {
	const { backgroundPrimary } = theme.inbox;
	const { quotedUserMessageBgColor, quotedAgentMessageBgColor } = theme;

	if (flow !== FLOW_CONVERSATION.CONVERSATIONS) {
		return isUserMessage(message) ? quotedAgentMessageBgColor : quotedUserMessageBgColor;
	}

	return isUserMessage(message) ? backgroundPrimary : quotedAgentMessageBgColor;
};

const textColor = (theme: any, flow: FLOW_CONVERSATION[keyof FLOW_CONVERSATION]) => {
	const { primaryA } = theme.colors;
	const { modalTextColor } = theme;

	return flow === FLOW_CONVERSATION.CONVERSATIONS ? primaryA : modalTextColor;
};

const recentConvIconFill = (theme: any, flow: FLOW_CONVERSATION[keyof FLOW_CONVERSATION]) => {
	const { primaryA } = theme.colors;
	const { recentConvIconFill } = theme;

	return flow === FLOW_CONVERSATION.CONVERSATIONS ? primaryA : recentConvIconFill;
};

const quotedMessageFocusColor = (theme: any, flow: FLOW_CONVERSATION[keyof FLOW_CONVERSATION]) => {
	const { quotedMessageFocusColor, recentConvMsgFocus } = theme;

	return flow === FLOW_CONVERSATION.CONVERSATIONS ? quotedMessageFocusColor : recentConvMsgFocus;
};

const trainIconBgColor = (theme: any, flow: FLOW_CONVERSATION[keyof FLOW_CONVERSATION]) => {
	const { messageListBgColor, modalPrimaryBackground } = theme;

	return flow === FLOW_CONVERSATION.CONVERSATIONS ? messageListBgColor : modalPrimaryBackground;
};

const showMarkTotrain = (message: IObjProps) =>
	!message.marked_by && (MESSAGE_RECEIVED === message.messagetype || TEXT === message.messagetype);

const showQuoteMessage = (message: IObjProps, selectedUser: IObjProps) =>
	message.messageId &&
	selectedUser.is_chat_window_active &&
	(selectedUser.channel === CHANNEL_TITLE_MAPPING.web || selectedUser.platform === CHANNELS.WEB);

const showGetSmartResponse = (selectedUser: IObjProps) => selectedUser.is_chat_window_active;

const getAgentObject = (participantDetails: Array<IObjProps>, email: string, agentAvatarColorMap: IObjProps = {}) => {
	const details: Array<IObjProps> = Array.isArray(participantDetails) ? participantDetails : [];
	for (let i = 0; i < details.length; i++) {
		if (details[i].actorEmail === email) {
			return {
				...details[i],
				color: agentAvatarColorMap[details[i].actorEmail] || getProfileColor(details[i].actorName),
			};
		}
	}

	return null;
};

const checkIfLoginMessage = (message: IObjProps) => message.hasOwnProperty('login_success');

const MESSAGE_INDEX_ID = 'message-index-';

const MessageWrappper = (params: messageWapperParams) => {
	const { align, children, index, isActiveTab, message, translationAction } = params;

	return (
		<Block
			width='calc(100% - 2rem)'
			display='inline-block'
			paddingRight={message.sender === SENDER_BOT ? '0.5rem' : ''}
			paddingLeft={message.sender === SENDER_BOT ? '' : '0.5rem'}
		>
			{children}
			{isActiveTab && index >= 0 && (
				<RenderSwitchingTextButton
					message={message}
					align={align}
					index={index}
					translationAction={translationAction}
				/>
			)}
		</Block>
	);
};

const RenderSwitchingTextButton = (props: {
	message: IObjProps;
	align: string | undefined;
	index: number;
	translationAction: Function;
}) => {
	const { message, align, index, translationAction } = props;
	const [css, theme]: any = useStyletron();
	const { t } = useTranslation(['components']);

	const { isLocationPacket } = getLocationPacketDetails(message?.message, message?.sender);

	if (message?.isTranslated && !isLocationPacket) {
		return (
			<div
				id={`translation-action-id-${message.event_id}`}
				className={css({
					marginTop: '0.125rem',
					background: 'transparent',
					cursor: 'pointer',
					fontSize: '0.625rem',
					lineHeight: '0.75rem',
					color: message?.showTranslated
						? `${theme.primaryA}!important`
						: `${theme.colors.inputBorderError}!important`,
					display: 'flex',
					justifyContent: MESSAGE_ALIGN.LEFT === align ? 'flex-start' : 'flex-end',
				})}
				onMouseEnter={(e: any) => {
					e.target.style.color = theme.colors.inputBorderError;
				}}
				onMouseLeave={(e: any) => {
					e.target.style.color = theme.colors.primaryA;
				}}
				onClick={() => translationAction(message, index)}
			>
				{message?.showTranslated
					? t('components:autoTranslationMessages.seeOriginal')
					: t('components:autoTranslationMessages.seeTranslated')}
			</div>
		);
	}

	return <></>;
};

interface IMessages {
	isActiveTab?: boolean;
	flow?: FLOW_CONVERSATION[keyof FLOW_CONVERSATION];
	messageData: IApiDataProps<Array<IObjProps>>;
	paddingLeft?: string;
	paddingRight?: string;
	quoting?: [IObjProps | undefined, any];
	selectedUser: IObjProps;
	agentAvatarColorMap: IObjProps;
	setSmartResponsePayload?: any;
	messageFocusIndex?: number;
	fetchWhatsAppTemplates?: boolean;
	isChatPopupOpen?: boolean;
}

function Messages(props: IMessages) {
	const {
		isActiveTab = false,
		flow = FLOW_CONVERSATION.CONVERSATIONS,
		messageData,
		paddingLeft = '1rem',
		paddingRight = '1rem',
		quoting = [],
		selectedUser,
		agentAvatarColorMap = {},
		setSmartResponsePayload,
		messageFocusIndex = -1,
		fetchWhatsAppTemplates = true,
		isChatPopupOpen = false,
	} = props;

	const [, setQuotedMessage]: any = quoting;
	const [showScrollToBottom, toggleScrollToBottom] = useState(false);
	const [messages, setMessages]: [Array<IObjProps>, any] = useState([]);
	const [css, theme]: [any, any] = useStyletron();
	const messageContainer: any = useRef(null);
	const params: IObjProps = useParams();
	const { tab: activeTab } = params;

	const scrollToLastMessage: any = useRef(false);
	const currentPlatformRef = useRef<string>('');

	const [menuListIndex, setIndex] = useState(-1);
	const dispatch = useDispatch<any>();
	const { t } = useTranslation(['components', 'common', 'pages']);
	const [isAddQuickReplyOpen, toggleAddQuickReplyModal] = useState<boolean>(false);
	const [quickReplyText, setQuickReplyText] = useState('');
	const [isScroll, setScroll] = useState(true);
	const [scrollType, setScrollType] = useState<string>('');
	const [showAgentInteractionIndicator, toggleAgentInteractionIndicator] = useState<boolean>(false);

	const isMessageSearchEnabled: any = useSelector(
		(state: RootState) => state.User.botAdmin.data.is_message_search_enabled
	);

	const conversationMessageDuration: any = useSelector(
		(state: RootState) => state.User.botAdmin.data.conversation_message_duration
	);

	const bringToView = useSelector((state: RootState) => state.Message.bringToView);

	const [templates, setTemplates] = useState<Array<{ name: any; components: any; namespace: any; language: any }>>(
		[]
	);

	const [sensorStatus, setSensorStatus] = useState(false);

	const profileEmail = useSelector((state: RootState) => state.User.profile.data.email);

	const profileName = useSelector((state: RootState) => state.User.profile.data.name);

	const botRef = useSelector((state: RootState) => state.Bot.data.bot_ref);

	const provider = useSelector((state: RootState) => state.Bot.data.provider_configured);

	const lastVisitedRow = useSelector((state: RootState) => state.Message.messagesPagination.lastVisitedRow);

	const isUserMessagesDataAppended = useSelector((state: RootState) => state.Message.messagePosition.append);

	const isUserMessagesDataPrepended = useSelector((state: RootState) => state.Message.messagePosition.prepend);

	const lastTranslatedMessageState = useSelector(
		(state: RootState) => state.Message.autoTranslationConfigs.lastTranslatedMessageState
	);

	const restrictSwitchToBot: boolean = useSelector(
		(state: RootState) => state.User.botAdmin.data.restrict_switch_to_bot || false
	);

	const isSimplifiedOneViewEnabled = useSelector(
		(state: RootState) => state.User.botAdmin.data.is_simplified_one_view_enabled
	);

	const scrollToNewMessageIndicator = () => {
		const newMessageIndicator: any = document.getElementById(NEW_MESSAGE_INDICATOR_ELEMENT_ID);

		newMessageIndicator?.scrollIntoView({ block: 'start' });
	};
	const fontStyle: string = useSelector(fontStyleSelector);
	const conversationData = useSelector((state: RootState) => state.Message.conversationData);
	const isNewBannerEnabled = useSelector(
		(state: RootState) => state.User.botAdmin.data?.enable_start_conversation_from_backend || false
	);

	const isTemplateCacheEnabled = useSelector(isTemplateCacheEntitlementEnabled);
	const whatsappAcceleratorTrialEnabled = useSelector(isWhatsappAcceleratorTrialEnabled);
	const messageTemplates = useSelector(messageTemplatesSelector);
	const isCreateMessageTemplatesV2Enabled = useSelector(isCreateMessageTemplatesV2EnabledSelector);
	const isNotActiveState =
		selectedUser.current_state !== MESSAGE_STATE_MAP.ENGAGING &&
		selectedUser.current_state !== MESSAGE_STATE_MAP.PENDING_RESOLUTION;

	useEffect(() => {
		renderMessageTemplate();
	}, [messageData]);

	useEffect(() => {
		const countOfUnreadMessages = getNotReadMessageCount();

		if (menuListIndex >= 0 || !isScroll) {
			return;
		}

		if (messages?.length === countOfUnreadMessages && scrollToLastMessage.current) {
			scrollToLastMessage.current = false;
			scrollToNewMessageIndicator();

			return;
		}

		if (messageContainer && messageContainer.current) {
			if (scrollToLastMessage.current) {
				scrollToLastMessage.current = false;
				scrollToBottom();
			}

			if (messageFocusIndex > -1) {
				messageContainer.current
					.getElementsByClassName(`${MESSAGE_INDEX_ID}${messageFocusIndex}`)?.[0]
					?.scrollIntoView(true);
			}

			setSensorStatus(!!messages.length);
		}
	}, [messages]);

	useEffect(() => {
		scrollToLastMessage.current = true;
	}, [messageData]);

	useEffect(() => {
		if (!fetchWhatsAppTemplates) {
			return;
		}

		if (!isChatPopupOpen) {
			if (provider === '360Dialog') {
				if (whatsappAcceleratorTrialEnabled) {
					setTemplateDataForD360(setTemplates, messageTemplates);
				} else if (isTemplateCacheEnabled) {
					template_api.fetchCachedApprovedTemplates(botRef, 'dialog360').then((resp: any) => {
						if (resp?.data?.responseObject?.waba_templates) {
							setTemplateDataForD360(setTemplates, resp.data.responseObject.waba_templates);
						}
					});
				} else {
					template_api.fetchBroadCastTemplates(botRef, 'dialog360').then((resp: any) => {
						if (resp?.data?.waba_templates) {
							setTemplateDataForD360(setTemplates, resp.data.waba_templates);
						}
					});
				}
			} else if (provider === WHATSAPP_PROVIDERS.whatsAppCloud) {
				template_api.fetchBroadCastTemplates(botRef, 'whatsapp').then((resp: any) => {
					if (resp?.data?.data) {
						setTemplates(
							resp.data.data.map((key: any) => ({
								name: key.name as string,
								components: key.components,
								language: key.language,
							}))
						);
					}
				});
			}
		}
	}, []);

	useEffect(() => {
		toggleAgentInteractionIndicator(false);
	}, [selectedUser?.user_id]);

	useLayoutEffect(() => {
		setSensorStatus(true);
	}, [messageData]);

	const closeMessageOptionsMenu = useCallback(() => {
		if (menuListIndex >= 0) {
			const messagesClone: Array<IObjProps> = messages.map((el) => ({
				...el,
			}));
			if (messagesClone[menuListIndex]) {
				messagesClone[menuListIndex].isOpen = false;
			}
			setMessages(messagesClone);
			setIndex(-1);
		}
	}, [menuListIndex, messages]);

	const resetLastHoveredMessage = useCallback(
		(event: any) => {
			const targetElementId = event?.target?.id;
			if (targetElementId.indexOf('translation-action-id') >= 0) {
				return;
			}
			closeMessageOptionsMenu();
		},
		[closeMessageOptionsMenu]
	);

	const showButtons =
		conversationData.assignConversation ||
		conversationData.resolveConversation ||
		conversationData.startConversation ||
		conversationData.templateButton;

	const getBottomCssForJumpToLatest = () => {
		let bottomCss = '1rem';
		if (showAgentInteractionIndicator) {
			bottomCss = showButtons ? '2.4875rem' : '4.6875rem';
		}

		return bottomCss;
	};

	const jumpToLatestCss = css({
		display: 'flex',
		justifyContent: 'space-between',
		minWidth: '6.5rem',
		alignItems: 'middle',
		position: 'absolute',
		bottom: getBottomCssForJumpToLatest(),
		left: '50%',
		transform: 'translate(-50%, 20%)',
		backgroundColor: theme.colors.primaryA,
		color: theme.colors.primaryB,
		cursor: 'pointer',
		fontSize: '0.875rem',
		lineHeight: '1.25rem',
		paddingLeft: '1.5rem',
		paddingRight: '1.5rem',
		paddingTop: '0.5rem',
		paddingBottom: '0.5rem',
		borderTopRightRadius: '5rem',
		borderTopLeftRadius: '5rem',
		borderBottomRightRadius: '5rem',
		borderBottomLeftRadius: '5rem',
	});

	const tagCss = css({
		fontWeight: 400,
		fontSize: '0.625rem',
		float: 'left',
		marginTop: '0.25rem',
		lineHeight: '0.75rem',
		color: theme.colors.accent50,
	});

	const onScrollFunc = (e: any) => {
		if (!sensorStatus && e.target.scrollTop < 10) {
			e.target.scrollTop = 10;
		}
		if (e.target.scrollTop < e.target.scrollHeight - e.target.offsetHeight - 150) {
			!showScrollToBottom && toggleScrollToBottom(true);
		} else {
			showScrollToBottom && toggleScrollToBottom(false);
		}
	};

	const scrollToBottom = () => {
		if (messageContainer.current) {
			messageContainer.current.scrollTop = messageContainer.current.scrollHeight;
		}
	};

	const renderMessageTemplate = () => {
		if (messages) {
			let messagesClone: Array<IObjProps> = [];
			if (Array.isArray(messageData.data)) {
				messagesClone = messageData.data.map((el) => ({
					...el,
					showTranslated: el.isTranslated
						? !(
								(!el.event_id && el.sender === 'bot') ||
								(el.messagetype === TEMPLATE && el.platform === CHANNELS.DIALOG360)
							)
						: false,
				}));

				const lastMessage: IObjProps = messageData.data.slice(-1);
				const isLastMessageTranslated = !!(lastMessage && lastMessage[0]?.isTranslated);
				if (
					(isLastMessageTranslated &&
						TRANSLATED_LAST_MESSAGE_STATE.PROCESSED === lastTranslatedMessageState) ||
					isUserMessagesDataAppended ||
					isUserMessagesDataPrepended
				) {
					// If last message is translated message and processed, then do not scroll to bottom
					// If Messages data is appended due to polling of fetchUserMessages API then do not scroll to bottom
					// If Messages data is prepended due to calling a paginated fetchUserMessages API
					setScroll(false);
				} else {
					// Scroll to bottom  when fetching messages, new message from agent/ user and for all messages without translation
					setScroll(true);
					isLastMessageTranslated &&
						dispatch(updateLastTranslatedMessageStateAction(TRANSLATED_LAST_MESSAGE_STATE.PROCESSED));
				}
			}
			setMessages(messagesClone);
		}
	};

	window.addEventListener('wheel', function (event) {
		if (event.deltaY < 0) {
			setScrollType('TOP');
		} else if (event.deltaY > 0) {
			setScrollType('BOTTOM');
		}
	});

	const translationAction = (message: IObjProps, index: number) => {
		const messagesClone: Array<IObjProps> = messages.map((el: any) => {
			if (el.event_id ? el.event_id === message.event_id : el.messageId === message.messageId) {
				el.showTranslated = !el.showTranslated;
			}

			return el;
		});

		setMessages(messagesClone);
		setIndex(index);
	};

	const messageActions = (message: IObjProps, index: number) => {
		const items = [];
		if (
			(isNewBannerEnabled && isNotActiveState) ||
			(!isActiveTab && !isSimplifiedOneViewEnabled && !isNewBannerEnabled) ||
			(isSimplifiedOneViewEnabled && isNotActiveState)
		) {
			return [];
		}

		const markToTrain = {
			isDisabled: restrictSwitchToBot,
			label: t('components:messagesPreview.markToTrain'),
			onClick: () => {
				dispatch(
					markToTrainAction(message, index, () => {
						dispatch(openErrorSavingModalAction());
					})
				);
			},
		};
		const quoteMessage = {
			label: t('components:messagesPreview.quoteMessage'),
			onClick: () => {
				setQuotedMessage({ ...message, sender: selectedUser.username });
				const messagesClone: Array<IObjProps> = messages.map((el) => ({
					...el,
				}));
				messagesClone[menuListIndex].isOpen = false;
				setMessages(messagesClone);
				setIndex(-1);
			},
		};
		const addQuickReply = {
			label: t('components:messagesPreview.newQuickReply'),
			onClick: () => {
				const div = document.createElement('div');
				div.innerHTML = message.message;
				setQuickReplyText(div.innerText);
				toggleAddQuickReplyModal(true);
			},
		};
		const getSmartResponse = {
			isDisabled: restrictSwitchToBot,
			label: t('components:messagesPreview.getSmartResponse'),
			onClick: () => {
				setSmartResponsePayload &&
					setSmartResponsePayload({
						isOpen: true,
						query: message.message,
					});
			},
		};

		const copyMessage = {
			label: t('components:messagesPreview.copyMessage'),
			onClick: () => {
				copyToClipboard(message.message);
				closeMessageOptionsMenu();
				toaster.positive(
					<ENGTToasterContainer
						title={t('common:success')}
						description={t('components:messagesPreview.messageCopied')}
					/>,
					{}
				);
			},
		};

		if (message.sender === SENDER_USER) {
			if (showMarkTotrain(message)) {
				items.push(markToTrain);
			}
			if (showQuoteMessage(message, selectedUser)) {
				items.push(quoteMessage);
			}
			if (showGetSmartResponse(selectedUser)) {
				items.push(getSmartResponse);
			}
			if (message.messagetype === MESSAGE_RECEIVED) {
				items.push(copyMessage);
			}
		}

		if (message.sender === SENDER_BOT) {
			if (message.messagetype === TEXT) {
				items.push(addQuickReply);
			}
		}

		return items;
	};

	const showMessageActions = (index: number, actions: Array<any>) => {
		if (!actions.length) {
			return;
		}
		const messagesClone: Array<IObjProps> = messages.map((el) => ({
			...el,
		}));
		if (menuListIndex >= 0) {
			messagesClone[menuListIndex].isOpen = false;
		}
		messagesClone[index].isOpen = true;
		setIndex(index);
		setMessages(messagesClone);
	};

	const getAgentDetails = (participantDetails: Array<IObjProps>, email: string) => {
		let agentDetails: IObjProps | null = getAgentObject(participantDetails, email, agentAvatarColorMap);
		if (!agentDetails) {
			if (email && email !== profileEmail) {
				agentDetails = {
					actorEmail: email,
					actorId: 0,
					actorName: email,
					state: '',
					color: getProfileColor(email),
				};
			} else {
				agentDetails = {
					actorEmail: profileEmail,
					actorId: 0,
					actorName: profileName,
					state: '',
					color: theme.userProfileColor,
				};
			}
		}

		return agentDetails;
	};

	const getQuotedMessage = (quotedMessageType: string, quotedMessage: any) => {
		const parsedMessage = isValidJson(quotedMessage) && JSON.parse(quotedMessage);
		if (parsedMessage) {
			if (quotedMessageType === COMMENT_RECEIVED) {
				return parsedMessage?.comment;
			}
			if (quotedMessageType === WA_COMMERCE_PRODUCT) {
				return parsedMessage?.product_details?.name || 'Product';
			}
			if (quotedMessageType === CAROUSEL) {
				return parsedMessage?.[0]?.title;
			}
			if (quotedMessageType === WA_FLOW) {
				return parsedMessage?.body?.text;
			}
		}

		return quotedMessage;
	};

	const getKey = (message: IObjProps, index: number) =>
		`${message?.event_id || message?.messageId}-${message?.index !== undefined ? message?.index : index}`;

	const messageAvatar = (message: IObjProps, agentObject: IObjProps) => {
		if (message.sender === SENDER_USER) {
			return (
				<MessageAvatar
					backgroundColor={selectedUser.profileColor || getProfileColor(selectedUser?.id || '0')}
					name={selectedUser.username}
					color={theme.colors.primaryB}
					avatar={selectedUser?.profile_pic_url || ''}
				/>
			);
		}

		return message.is_agent_reply ? (
			<MessageAvatar
				backgroundColor={agentObject.color || getProfileColor(selectedUser?.id || '0')}
				color={theme.colors.primaryBtnTextColor}
				name={agentObject.actorName}
			/>
		) : (
			<ENGTImage
				lazy
				src={BotIconDefaultLight}
				alt='Bot'
				className={css({
					width: '1.5rem',
					height: '1.5rem',
					borderTopRightRadius: '100%',
					borderTopLeftRadius: '100%',
					borderBottomRightRadius: '100%',
					borderBottomLeftRadius: '100%',
				})}
			/>
		);
	};

	const TypingLoaderCss = {
		paddingLeft: '1rem',
		paddingRight: '1rem',
		paddingTop: '0.8rem',
		paddingBottom: '0.8rem',
		display: 'inline-block',
		borderTopLeftRadius: '0.5rem',
		borderTopRightRadius: '0.5rem',
		borderBottomLeftRadius: '0.5rem',
		borderBottomRightRadius: '0.5rem',
		textAlign: 'left',
		wordBreak: 'break-word',
		position: 'relative',
		backgroundColor: theme.colors.backgroundSecondary,
	};

	const messageTypingOn = () =>
		isActiveTab && (
			<div
				className={css({
					display: 'none',
				})}
				id='message-typing-loader'
			>
				<Block className={css(verticalAlignBottom)}>
					<MessageAvatar
						backgroundColor={selectedUser.profileColor}
						color={theme.colors.primaryB}
						name={selectedUser.username}
						avatar={selectedUser?.profile_pic_url || ''}
					/>
					<Block paddingLeft='0.5rem'>
						<Wrappper align={MESSAGE_ALIGN.LEFT}>
							<span className={css(TypingLoaderCss)}>
								<ENGTImage lazy src={TypingIndicatorIcon} alt='typing-indicator-gif' />
							</span>
						</Wrappper>
					</Block>
				</Block>
			</div>
		);

	const showStatusIcon = (status: string, message_failed_reason: string) => (
		<div
			className={css({
				display: 'flex',
				flexDirection: 'column-reverse',
				alignContent: 'flex-end',
				marginLeft: '0.25rem',
				marginBottom: '0.125rem',
				cursor: 'pointer',
			})}
		>
			{message_failed_reason ? (
				<ENGTTooltip content={message_failed_reason} ignoreBoundary={false} placement='top'>
					<div className={css({ display: 'flex' })}>
						<ENGTImage lazy src={messageStatusMapper[status]} width={13} height={13} alt='message-status' />
					</div>
				</ENGTTooltip>
			) : (
				<ENGTImage lazy src={messageStatusMapper[status]} width={13} height={13} alt='message-status' />
			)}
		</div>
	);

	const messageStatus = (message: IObjProps) => {
		let messageStatusComponent = null;
		const { messageStatus, platform, sender, message_failed_reason } = message;
		if (CHANNELS.DIALOG360 === platform || CHANNELS.WHATSAPP === platform) {
			if (sender === SENDER_BOT && messageStatus) {
				messageStatusComponent =
					messageStatus === MESSAGE_STATES.FAILED
						? message_failed_reason
							? showStatusIcon(messageStatus, message_failed_reason)
							: showStatusIcon(messageStatus, '')
						: showStatusIcon(messageStatus, '');
			}
		}

		return messageStatusComponent;
	};

	const renderAgentInteractionIndicator = (platform: string) => {
		const enableAutomation = () => {
			const customVariable: IObjProps = {
				last_app_msg_timestamp: null,
			};

			if (platform === CHANNELS.INSTAGRAM) {
				customVariable['channel_user_handle'] = selectedUser.custom_variable.channel_user_handle;
			}

			const userDetails: IUserDetailsProps = {
				selectedUser,
				sendEmailTranscript: false,
				emailTranscriptStartDate: null,
				emailTranscriptEndDate: null,
				editKey: 'custom_variable',
				editValue: JSON.stringify(customVariable),
			};

			const successCb = () => {
				toaster.positive(
					<ENGTToasterContainer
						title={t('common:success')}
						description={t('components:messages.messageAutomationEnabledSuccessfully')}
					/>,
					{}
				);
				toggleAgentInteractionIndicator(false);
				const updatedSelectedUser = { ...selectedUser, custom_variable: customVariable };
				dispatch(setSelectedUserAction(updatedSelectedUser));
			};

			const errorCb = () => {
				toaster.negative(
					<ENGTToasterContainer title={t('common:error')} description={t('common:somethingWentWrong')} />,
					{}
				);
			};

			dispatch(updateUserDetailsAction(userDetails, successCb, errorCb));
		};

		const agentInteractionIndicatorCss = {
			backgroundColor: hexToRgb(theme.colors.engatiLavender, 0.3),
			borderRadius: '7.375rem',
			lineHeight: '1rem',
			paddingLeft: '0.75rem',
			paddingTop: '0.3125rem',
			paddingRight: '0.75rem',
			paddingBottom: '0.1875rem',
		};

		const textCss = {
			fontSize: '0.75rem',
			color: theme.colors.primaryA,
			fontWeight: 400,
		};

		const enableAutomationLinkCss = {
			...textCss,
			fontWeight: 600,
			textDecoration: 'underline',
			cursor: 'pointer',
		};

		const automatedMessagesDisabledText = (
			<span className={css(textCss)}>
				{t('components:messages.automatedMessagesDisabled', {
					platform: toSpaceSeperatedPascalCase(platform),
				})}
			</span>
		);

		const enableAutomationText = (
			<span className={css(enableAutomationLinkCss)} onClick={enableAutomation}>
				{t('components:messages.enableAutomation')}
			</span>
		);

		const agentInteractionIndicator = (
			<div className={css(agentInteractionIndicatorCss)}>
				{automatedMessagesDisabledText} {enableAutomationText}
			</div>
		);

		const areMessagesInsideModal = () => flow !== FLOW_CONVERSATION.CONVERSATIONS;

		const getPaddingBottomForIndicator = () => (areMessagesInsideModal() || showButtons ? '0rem' : '2.1875rem');

		return (
			<div
				className={css({
					position: 'absolute',
					bottom: '0rem',
					display: 'flex',
					justifyContent: 'center',
					width: '100%',
					paddingTop: '0.4375rem',
					paddingBottom: getPaddingBottomForIndicator(),
					backgroundColor: areMessagesInsideModal()
						? theme.modalPrimaryBackground
						: theme.inbox.backgroundPrimary60,
				})}
			>
				{agentInteractionIndicator}
			</div>
		);
	};

	const lastAppMessageIsLessThan24HoursAgo = () => {
		const lastAppMessageDate = selectedUser?.custom_variable?.last_app_msg_timestamp ?? new Date('1970');

		return differenceInHours(new Date(), new Date(lastAppMessageDate)) < BOT_AUTOMATION_PAUSE_LIMIT;
	};

	const messageToParseValue = (message: IObjProps) =>
		message?.showTranslated ? message.translatedMessage : message.message;

	const renderCarouselPreview = (data: any, message: any) => {
		const { cardComponents, bodyText, language } = getCarouselPayload(data);

		return (
			<>
				{bodyText !== '' && (
					<Text
						message={bodyText}
						backgroundColor={messageCss(theme, message, flow).backgroundColor}
						messageData={message}
						trainIconBgColor={trainIconBgColor(theme, flow)}
						trainIconFill={recentConvIconFill(theme, flow)}
						showApiTriggerMessageTag={message.sent_via_api_trigger}
						messageStatus={messageStatus(message)}
					/>
				)}
				<div
					className={css({
						display: 'flex',
						alignItems: 'center',
						flexDirection: 'row-reverse',
						justifyContent: 'flex-start',
						overflowY: 'scroll',
						paddingBottom: '2rem',
					})}
				>
					<TemplatePreviewCards carouselPreviewCardsData={cardComponents} language={language} />
				</div>
			</>
		);
	};

	const renderMessageTemplatePreview = (data: any, message: any) => {
		const template = data?.template
			? data?.template
			: templates.find(({ name, language }) => name === data?.name && language === data?.language?.code);
		if (
			isCreateMessageTemplatesV2Enabled &&
			template &&
			data?.components.some((component: any) => component?.type === 'carousel')
		) {
			data.template = template;

			return renderCarouselPreview(data, message);
		}

		return (
			<TemplatePreview
				messageId={message.messageId}
				components={data?.components}
				redirectUrl={data?.redirect_url}
				template={template}
				templateName={data?.name}
				targetWhatsAppNumber={data?.targetWhatsAppNumber}
				showTranslatedTemplate={message.showTranslated}
				messageStatus={messageStatus(message)}
				showApiTriggerMessageTag={message.sent_via_api_trigger}
			/>
		);
	};

	const renderMessages = () => {
		let timestamp: string = new Date('1970').toISOString();
		const messageDetailsMap: IObjProps = {};

		return messages.map((message: IObjProps, index: number) => {
			if (message.messagetype !== OPTIONS) {
				messageDetailsMap[message.messageId] = {
					message: messageToParseValue(message),
					messagetype: message.messagetype,
				};
			}
			if (message.multipleImage) {
				return <></>;
			}
			if (message['isNewMessagesIndicator']) {
				return (
					<NewMessageIndicator
						key={`${message.timestamp}-${message.index}`}
						totalMessagesWhenLoaded={message?.totalMessagesWhenLoaded}
					/>
				);
			}

			let appMessage = <></>;

			if (
				message.is_app_message &&
				(message.platform === CHANNELS.INSTAGRAM || message.platform === CHANNELS.FACEBOOK)
			) {
				if (message.messagetype === AGENT_PARTICIPATION_STATUS) {
					if (!showAgentInteractionIndicator && isNotActiveState && lastAppMessageIsLessThan24HoursAgo()) {
						toggleAgentInteractionIndicator(true);
						currentPlatformRef.current = message.platform;
					}

					return <></>;
				}

				appMessage = (
					<div className={tagCss}>
						{message.platform === CHANNELS.INSTAGRAM
							? t('pages:configure.deployment.instagramDeployment.repliedViaInstagram')
							: t('pages:configure.deployment.facebookDeployment.repliedViaFacebook')}
					</div>
				);
			}

			const align = message.sender === SENDER_BOT ? MESSAGE_ALIGN.RIGHT : MESSAGE_ALIGN.LEFT;
			let timestampTemplate = <></>;
			const current = t('common:standardDateTimeFormatter', {
				date: message.timestamp,
			});
			const previous = t('common:standardDateTimeFormatter', {
				date: timestamp,
			});

			if (current.substring(0, current.length - 3) !== previous.substring(0, previous.length - 3)) {
				timestampTemplate = (
					<p
						key={current}
						className={css({
							textAlign: 'center',
						})}
						data-qa={TIME_STAMP}
					>
						{current}
					</p>
				);
			}
			timestamp = message.timestamp;
			message.quotedMessage = null;
			if (message.replyId && messageDetailsMap[message.replyId]) {
				const quotedMessageType = messageDetailsMap[message.replyId]?.messagetype;
				message.quotedMessage = getQuotedMessage(quotedMessageType, messageDetailsMap[message.replyId].message);

				message.quotedMessageId = message.replyId;
				message.quotedMessageType = quotedMessageType;
			} else if (message.linkedEventId) {
				message.quotedMessage = messageDetailsMap[message.linkedEventId]?.message;
				message.quotedMessageId = message.linkedEventId;
				message.quotedMessageType = messageDetailsMap[message.linkedEventId]?.messagetype;
			}

			const agentObject = getAgentDetails(selectedUser.participant_details, message.agent_email);
			if (
				message.messagetype === TEXT ||
				message.messagetype === MESSAGE_RECEIVED ||
				message.messagetype === POSTBACK ||
				message.messagetype === COMMENT ||
				message.messagetype === PRIVATE_REPLY ||
				message.messagetype === WA_FLOW_RECEIVED
			) {
				let textTemplate = <></>;
				const menuItems =
					message.messagetype !== COMMENT && message.messagetype !== PRIVATE_REPLY
						? messageActions(message, index)
						: [];
				if (message.message?.indexOf('THUMB_GESTURE') !== -1) {
					const src = message.message?.indexOf('UP') !== -1 ? THUMB_GESTURE_UP_ICON : THUMB_GESTURE_DOWN_ICON;

					return (
						<div key={getKey(message, index)}>
							{timestampTemplate}
							<div className={css(verticalAlignBottom)}>
								{messageAvatar(message, agentObject)}
								<MessageWrappper
									key={getKey(message, index)}
									message={message}
									index={-1}
									translationAction={translationAction}
									isActiveTab={isActiveTab}
								>
									<Wrappper align={MESSAGE_ALIGN.LEFT}>
										<ENGTImage
											lazy
											src={src}
											alt='thumbsIcon'
											className={css({
												...thumbsIconCss,
												backgroundColor: messageCss(theme, message, flow).backgroundColor,
											})}
										/>
									</Wrappper>
								</MessageWrappper>
							</div>
						</div>
					);
				}
				if (!message.quotedMessageId) {
					textTemplate = (
						<Text
							message={messageToParseValue(message)}
							backgroundColor={messageCss(theme, message, flow).backgroundColor}
							messageData={message}
							openMenu={() => {
								showMessageActions(index, menuItems);
							}}
							closeMenu={resetLastHoveredMessage}
							menuItems={menuItems}
							trainIconBgColor={trainIconBgColor(theme, flow)}
							trainIconFill={recentConvIconFill(theme, flow)}
							appMessage={appMessage}
							messageStatus={messageStatus(message)}
							showApiTriggerMessageTag={message.sent_via_api_trigger}
						/>
					);
				} else {
					textTemplate = (
						<QuotedMessage
							messageData={message}
							backgroundColor={messageCss(theme, message, flow).backgroundColor}
							quotedMessageBg={quotedMessageBgColor(theme, message, flow)}
							menuItems={menuItems}
							openMenu={() => {
								showMessageActions(index, menuItems);
							}}
							closeMenu={resetLastHoveredMessage}
							quotedMessageFocusColor={quotedMessageFocusColor(theme, flow)}
							trainIconBgColor={trainIconBgColor(theme, flow)}
							trainIconFill={recentConvIconFill(theme, flow)}
						/>
					);
				}
				if (message.sender === SENDER_USER) {
					textTemplate = (
						<Block className={css(verticalAlignBottom)}>
							{messageAvatar(message, agentObject)}
							<MessageWrappper
								message={message}
								index={index}
								align={align}
								translationAction={translationAction}
								isActiveTab={isActiveTab}
							>
								{textTemplate}
							</MessageWrappper>
						</Block>
					);
				}
				if (message.sender === SENDER_BOT) {
					textTemplate = (
						<Block className={css(verticalAlignBottom)}>
							<MessageWrappper
								message={message}
								index={index}
								align={align}
								translationAction={translationAction}
								isActiveTab={isActiveTab}
							>
								{textTemplate}
							</MessageWrappper>
							{messageAvatar(message, agentObject)}
						</Block>
					);
				}

				return (
					<div key={getKey(message, index)} className={`${MESSAGE_INDEX_ID}${index}`}>
						{timestampTemplate}
						<Wrappper align={align} key={getKey(message, index)}>
							{textTemplate}
						</Wrappper>
					</div>
				);
			}
			if (message.messagetype === OPTIONS) {
				const messageToParse = messageToParseValue(message);
				let data = [];
				try {
					data = JSON.parse(messageToParse);
				} catch (e) {
					data =
						typeof messageToParse === 'string'
							? [
									{
										id: Math.random(),
										text: messageToParse,
									},
								]
							: [];
				}

				return (
					<div key={getKey(message, index)}>
						{timestampTemplate}
						<div className={css(verticalAlignBottom)}>
							<MessageWrappper
								message={message}
								index={index}
								align={align}
								translationAction={translationAction}
								isActiveTab={isActiveTab}
								key={getKey(message, index)}
							>
								<Wrappper align={MESSAGE_ALIGN.RIGHT}>
									<Smo
										options={data}
										backgroundColor={messageCss(theme, message, flow).backgroundColor}
									/>
								</Wrappper>
							</MessageWrappper>
							<div>{messageAvatar(message, agentObject)}</div>
						</div>
					</div>
				);
			}
			if (message.messagetype === IMAGE) {
				const multipleUploadedData = [];
				const newMessage = () => {
					multipleUploadedData.push(...message.liveChatRespond);
				};

				if (message.isNewMessage) {
					newMessage();
				} else {
					multipleUploadedData.push(message);
					for (let i = index + 1; i < messages.length; i++) {
						const data = messages[i];

						if (
							data.messagetype === IMAGE &&
							!data.isNewMessage &&
							data?.group_id &&
							data?.group_id === message?.group_id
						) {
							multipleUploadedData.push(data);
							messages[i].multipleImage = true;
						}
					}
				}
				const messageText = multipleUploadedData[0].message_text;

				return (
					<div key={getKey(message, index)}>
						{timestampTemplate}
						<div className={css(verticalAlignBottom)}>
							<MessageWrappper
								message={message}
								index={-1}
								translationAction={translationAction}
								isActiveTab={isActiveTab}
								key={getKey(message, index)}
							>
								<Wrappper align={MESSAGE_ALIGN.RIGHT}>
									<Image
										messageText={messageText || message.message_text}
										backgroundColor={messageCss(theme, message, flow).backgroundColor}
										multipleUploadedData={multipleUploadedData}
										messageId={message.messageId}
										messageStatus={messageStatus(message)}
										showApiTriggerMessageTag={message.sent_via_api_trigger}
									/>
								</Wrappper>
							</MessageWrappper>
							{messageAvatar(message, agentObject)}
						</div>
					</div>
				);
			}
			if (message.messagetype === VIDEO) {
				return (
					<div key={getKey(message, index)}>
						{timestampTemplate}
						<div className={css(verticalAlignBottom)}>
							<MessageWrappper
								message={message}
								index={-1}
								translationAction={translationAction}
								isActiveTab={isActiveTab}
								key={getKey(message, index)}
							>
								<Wrappper align={MESSAGE_ALIGN.RIGHT}>
									<Video
										backgroundColor={messageCss(theme, message, flow).backgroundColor}
										src={message.message}
										showApiTriggerMessageTag={message.sent_via_api_trigger}
									/>
								</Wrappper>
							</MessageWrappper>
							{messageAvatar(message, agentObject)}
						</div>
					</div>
				);
			}
			if (message.messagetype === AUDIO) {
				return (
					<div key={getKey(message, index)}>
						{timestampTemplate}
						<div className={css(verticalAlignBottom)}>
							<MessageWrappper
								message={message}
								index={-1}
								translationAction={translationAction}
								isActiveTab={isActiveTab}
								key={getKey(message, index)}
							>
								<Wrappper align={MESSAGE_ALIGN.RIGHT}>
									<Audio
										backgroundColor={messageCss(theme, message, flow).backgroundColor}
										src={message.message}
										showApiTriggerMessageTag={message.sent_via_api_trigger}
									/>
								</Wrappper>
							</MessageWrappper>
							{messageAvatar(message, agentObject)}
						</div>
					</div>
				);
			}
			if (message.messagetype === CAROUSEL) {
				const messageToParse = messageToParseValue(message);
				const carousel = messageToParse.startsWith('http') ? messageToParse : JSON.parse(messageToParse);

				return (
					<div key={getKey(message, index)}>
						{timestampTemplate}
						<div className={css(verticalAlignBottom)}>
							<MessageWrappper
								message={message}
								index={index}
								align={align}
								translationAction={translationAction}
								isActiveTab={isActiveTab}
								key={getKey(message, index)}
							>
								<Wrappper align={MESSAGE_ALIGN.RIGHT} colSpan={12}>
									{messageToParse.startsWith('http') ? (
										<Text
											message={carousel}
											backgroundColor={messageCss(theme, message, flow).backgroundColor}
											messageData={message}
											openMenu={() => {
												showMessageActions(index, []);
											}}
											closeMenu={resetLastHoveredMessage}
											menuItems={[]}
											trainIconBgColor={trainIconBgColor(theme, flow)}
											trainIconFill={recentConvIconFill(theme, flow)}
											showApiTriggerMessageTag={message.sent_via_api_trigger}
										/>
									) : (
										<Carousel
											carousel={carousel}
											backgroundColor={messageCss(theme, message, flow).backgroundColor}
											fillColor={recentConvIconFill(theme, flow)}
											showApiTriggerMessageTag={message.sent_via_api_trigger}
										/>
									)}
								</Wrappper>
							</MessageWrappper>
							<div
								className={css({
									paddingBottom: '1.625rem',
								})}
							>
								{messageAvatar(message, agentObject)}
							</div>
						</div>
					</div>
				);
			}
			if (message.messagetype === FILE_RECEIVED) {
				const file = JSON.parse(message.message);

				return (
					<div key={getKey(message, index)}>
						{timestampTemplate}
						<Wrappper align={MESSAGE_ALIGN.LEFT} key={getKey(message, index)}>
							<div className={css(verticalAlignBottom)}>
								{messageAvatar(message, agentObject)}
								<MessageWrappper
									message={message}
									index={-1}
									translationAction={translationAction}
									isActiveTab={isActiveTab}
								>
									{!message.quotedMessageId ? (
										<FileReceived
											file={file}
											timestamp={message.timestamp}
											backgroundColor={messageCss(theme, message, flow).backgroundColor}
											textColor={textColor(theme, flow)}
											messageId={message.messageId}
											isPublic={message?.is_public}
										/>
									) : (
										<QuotedMessage
											messageData={message}
											backgroundColor={messageCss(theme, message, flow).backgroundColor}
											quotedMessageBg={quotedMessageBgColor(theme, message, flow)}
											menuItems={[]}
											openMenu={() => {
												showMessageActions(index, []);
											}}
											closeMenu={resetLastHoveredMessage}
											quotedMessageFocusColor={quotedMessageFocusColor(theme, flow)}
											trainIconBgColor={trainIconBgColor(theme, flow)}
											trainIconFill={recentConvIconFill(theme, flow)}
										>
											<FileReceived
												file={file}
												timestamp={message.timestamp}
												backgroundColor={messageCss(theme, message, flow).backgroundColor}
												textColor={textColor(theme, flow)}
												messageId={message.messageId}
												isPublic={message?.is_public}
											/>
										</QuotedMessage>
									)}
								</MessageWrappper>
							</div>
						</Wrappper>
					</div>
				);
			}
			if (message.messagetype === FEEDBACK) {
				return (
					<div key={getKey(message, index)}>
						{timestampTemplate}
						<div className={css(verticalAlignBottom)}>
							<MessageWrappper
								message={message}
								index={index}
								align={align}
								translationAction={translationAction}
								isActiveTab={isActiveTab}
								key={getKey(message, index)}
							>
								<Wrappper align={MESSAGE_ALIGN.RIGHT}>
									<FeedBack
										feedBack={JSON.parse(messageToParseValue(message))}
										backgroundColor={messageCss(theme, message, flow).backgroundColor}
										showApiTriggerMessageTag={message.sent_via_api_trigger}
									/>
								</Wrappper>
							</MessageWrappper>
							{messageAvatar(message, agentObject)}
						</div>
					</div>
				);
			}
			if (message.messagetype === FILE) {
				return (
					<div key={getKey(message, index)}>
						{timestampTemplate}
						<div className={css(verticalAlignBottom)}>
							<MessageWrappper
								message={message}
								index={-1}
								translationAction={translationAction}
								isActiveTab={isActiveTab}
								key={getKey(message, index)}
							>
								<Wrappper align={MESSAGE_ALIGN.RIGHT}>
									<File
										url={message.message}
										backgroundColor={messageCss(theme, message, flow).backgroundColor}
										textColor={textColor(theme, flow)}
										messageText={message.message_text || message.messageText}
										messageId={message.messageId}
										appMessage={appMessage}
										messageStatus={messageStatus(message)}
										showApiTriggerMessageTag={message.sent_via_api_trigger}
									/>
								</Wrappper>
							</MessageWrappper>
							{messageAvatar(message, agentObject)}
						</div>
					</div>
				);
			}
			if (message.messagetype === CARD) {
				return (
					<div key={getKey(message, index)}>
						{timestampTemplate}
						<div className={css(verticalAlignBottom)}>
							<MessageWrappper
								message={message}
								index={index}
								align={align}
								translationAction={translationAction}
								isActiveTab={isActiveTab}
								key={getKey(message, index)}
							>
								<Wrappper align={MESSAGE_ALIGN.RIGHT}>
									<Card
										card={JSON.parse(messageToParseValue(message))}
										backgroundColor={messageCss(theme, message, flow).backgroundColor}
									/>
								</Wrappper>
							</MessageWrappper>
							{messageAvatar(message, agentObject)}
						</div>
					</div>
				);
			}
			if (message.messagetype === WA_CONTACT) {
				const packet = JSON.parse(message.message);
				const packetMessage = packet.contactCardDTOS;
				const contactCard = packetMessage?.map((item: any, index: any) => (
					<div key={index}>
						<div
							className={css({
								marginTop: '5px',
							})}
						>
							<ContactCard
								name={item.name}
								phoneNumbers={item.contactCardDetails.map((detail: any) => detail.waId)}
							/>
						</div>
					</div>
				));

				return (
					<div key={getKey(message, index)}>
						{timestampTemplate}
						<div className={css(verticalAlignBottom)}>
							{messageAvatar(message, agentObject)}
							<MessageWrappper
								key={getKey(message, index)}
								message={message}
								index={-1}
								translationAction={translationAction}
								isActiveTab={isActiveTab}
							>
								<Wrappper align={MESSAGE_ALIGN.LEFT}>{contactCard}</Wrappper>
							</MessageWrappper>
						</div>
					</div>
				);
			}
			if (message.messagetype === SLIDER) {
				const slider = JSON.parse(messageToParseValue(message));

				return (
					<div key={getKey(message, index)}>
						{timestampTemplate}
						<div className={css(verticalAlignBottom)}>
							<MessageWrappper
								message={message}
								index={index}
								align={align}
								translationAction={translationAction}
								isActiveTab={isActiveTab}
								key={getKey(message, index)}
							>
								<Wrappper align={align}>
									<Text
										message={`Slider Node [${slider.min_label.value} - ${slider.max_label.value}]`}
										backgroundColor={messageCss(theme, message, flow).backgroundColor}
										messageData={message}
										openMenu={() => {
											showMessageActions(index, []);
										}}
										closeMenu={resetLastHoveredMessage}
										menuItems={[]}
										trainIconBgColor={trainIconBgColor(theme, flow)}
										trainIconFill={recentConvIconFill(theme, flow)}
										showApiTriggerMessageTag={message.sent_via_api_trigger}
									/>
								</Wrappper>
							</MessageWrappper>
							{messageAvatar(message, agentObject)}
						</div>
					</div>
				);
			}
			if (message.messagetype === IDENTITY) {
				const messageToParse = messageToParseValue(message);
				const identity =
					message.platform === 'web'
						? JSON.parse(messageToParse)
						: { node_type: IDENTITY_NODE, title: messageToParse };
				if (!checkIfLoginMessage(identity)) {
					let title = '';
					if (identity.node_type === IDENTITY_NODE) {
						title = `Identity - ${identity.title}`;
					} else if (identity.node_type === FORM_NODE) {
						title = `Form - ${identity.title}`;
					}

					return (
						<div key={getKey(message, index)}>
							{timestampTemplate}
							<div className={css(verticalAlignBottom)}>
								<MessageWrappper
									message={message}
									index={index}
									align={align}
									translationAction={translationAction}
									isActiveTab={isActiveTab}
									key={getKey(message, index)}
								>
									<Wrappper align={align}>
										<Text
											message={title}
											backgroundColor={messageCss(theme, message, flow).backgroundColor}
											messageData={message}
											openMenu={() => {
												showMessageActions(index, []);
											}}
											closeMenu={resetLastHoveredMessage}
											menuItems={[]}
											trainIconBgColor={trainIconBgColor(theme, flow)}
											trainIconFill={recentConvIconFill(theme, flow)}
											showApiTriggerMessageTag={message.sent_via_api_trigger}
										/>
									</Wrappper>
								</MessageWrappper>
								{messageAvatar(message, agentObject)}
							</div>
						</div>
					);
				}
			}
			if (message.messagetype === WEB_VIEW) {
				const webView = JSON.parse(messageToParseValue(message));

				return (
					<div key={getKey(message, index)}>
						{timestampTemplate}
						<div className={css(verticalAlignBottom)}>
							<MessageWrappper
								message={message}
								index={index}
								align={align}
								translationAction={translationAction}
								isActiveTab={isActiveTab}
								key={getKey(message, index)}
							>
								<Wrappper align={MESSAGE_ALIGN.RIGHT}>
									<File
										url={webView.url}
										backgroundColor={messageCss(theme, message, flow).backgroundColor}
										textColor={textColor(theme, flow)}
										fileName={webView.url_prefix_msg}
										messageText={message.message_text || message.messageText}
									/>
								</Wrappper>
							</MessageWrappper>
							{messageAvatar(message, agentObject)}
						</div>
					</div>
				);
			}
			if (message.messagetype === AGENT_PARTICIPATION_STATUS) {
				const data = JSON.parse(messageToParseValue(message));
				if (data?.TARGET_STATUS === AUTOMATIC_HANDLED) {
					return (
						<>
							<p
								key={current}
								className={css({
									textAlign: 'center',
								})}
								data-qa={TIME_STAMP}
							>
								{current}
							</p>
							<p
								className={css({
									textAlign: 'center',
									color: 'gray',
								})}
								key={getKey(message, index)}
								dangerouslySetInnerHTML={{ __html: decodeAndSanitizeInput(data.PARTICIPATION_MESSAGE) }}
							/>
						</>
					);
				}

				return (
					<p
						className={css({
							textAlign: 'center',
							color: 'gray',
						})}
						key={getKey(message, index)}
						dangerouslySetInnerHTML={{ __html: decodeAndSanitizeInput(data.PARTICIPATION_MESSAGE) }}
					/>
				);
			}
			if (
				message.messagetype === STORY_RECEIVED ||
				message.messagetype === COMMENT_RECEIVED ||
				message.messagetype === PRODUCT_MESSAGE_RECEIVED ||
				message.messagetype === AD_REFERRAL_RECEIVED
			) {
				const messageToParse = messageToParseValue(message);
				const data = isValidJson(messageToParse) ? JSON.parse(messageToParse) : messageToParse;

				let url = '';
				let messageText = '';
				let messageDescription = '';
				let messageUnavailableText = '';
				let captionText = '';
				if (message.messagetype === COMMENT_RECEIVED) {
					url = data.mediaUrl;
					messageText = linkify(data?.comment ?? message.message.comment);
					messageDescription = data.description;
					captionText = data.caption;
					messageUnavailableText = t('components:messagesPreview.postUnavailable');
				} else if (message.messagetype === STORY_RECEIVED) {
					url = data.storyUrl;
					messageText = data.message ?? message.message;
					messageDescription = data.storyReplyText;
					captionText = data.caption;
					messageUnavailableText = t('components:messagesPreview.storyUnavailable');
				} else if (message.messagetype === PRODUCT_MESSAGE_RECEIVED) {
					url = data.productImage || '';
					messageText = data.message ?? message.message;
					messageDescription = data?.description;
					captionText = data?.productName;
					messageUnavailableText = t('components:messagesPreview.previewUnavaiable');
				} else if (message.messagetype === AD_REFERRAL_RECEIVED) {
					url = data.sourceUrl;
					messageText = data.message ?? message.message;
					messageDescription = data?.headline;
					captionText = data?.caption;
					messageUnavailableText = t('components:messagesPreview.adReferralPreviewUnavailable');
				}

				return (
					<div key={getKey(message, index)}>
						{timestampTemplate}
						<div className={css(verticalAlignBottom)}>
							{messageAvatar(message, agentObject)}
							<MessageWrappper
								message={message}
								index={index}
								align={align}
								translationAction={translationAction}
								isActiveTab={isActiveTab}
								key={getKey(message, index)}
							>
								<Wrappper align={MESSAGE_ALIGN.LEFT}>
									<MediaResponse
										backgroundColor={messageCss(theme, message, flow).backgroundColor}
										messageMediaUrl={url}
										message={messageText}
										caption={captionText}
										messageDescription={messageDescription}
										messageUnavailableText={messageUnavailableText}
										messageType={message.messagetype}
									/>
								</Wrappper>
							</MessageWrappper>
						</div>
					</div>
				);
			}

			if (message.messagetype === TEMPLATE) {
				let data =
					message.platform === CHANNELS.DIALOG360 ||
					message.platform === CHANNELS.WHATSAPP ||
					message.platform === CHANNELS.FACEBOOK
						? JSON.parse(message.message)
						: message.message;
				if (data?.payload) {
					data = data?.payload;
				}
				const menuItems = messageActions(message, index);

				return (
					<div key={getKey(message, index)}>
						{timestampTemplate}
						<div className={css(verticalAlignBottom)}>
							{message.platform === CHANNELS.DIALOG360 ||
							message.platform === CHANNELS.WHATSAPP ||
							message.platform === CHANNELS.FACEBOOK ? (
								<MessageWrappper
									message={message}
									index={index}
									align={align}
									translationAction={translationAction}
									isActiveTab={isActiveTab}
									key={getKey(message, index)}
								>
									{message.platform === CHANNELS.FACEBOOK ? (
										<Wrappper align={MESSAGE_ALIGN.RIGHT}>
											<RecurringNotificationTemplate
												imageUrl={data.image_url}
												notificationMessagesFrequency={data.notification_messages_frequency}
												title={data.title}
											/>
										</Wrappper>
									) : (
										<Wrappper align={MESSAGE_ALIGN.RIGHT}>
											{renderMessageTemplatePreview(data, message)}
										</Wrappper>
									)}
								</MessageWrappper>
							) : (
								<MessageWrappper
									message={message}
									index={index}
									align={align}
									translationAction={translationAction}
									isActiveTab={isActiveTab}
								>
									<Wrappper align={MESSAGE_ALIGN.RIGHT}>
										<Text
											message={data}
											backgroundColor={messageCss(theme, message, flow).backgroundColor}
											messageData={message}
											openMenu={() => {
												showMessageActions(index, menuItems);
											}}
											closeMenu={resetLastHoveredMessage}
											menuItems={menuItems}
											trainIconBgColor={trainIconBgColor(theme, flow)}
											trainIconFill={recentConvIconFill(theme, flow)}
											showApiTriggerMessageTag={message.sent_via_api_trigger}
										/>
									</Wrappper>
								</MessageWrappper>
							)}
							{messageAvatar(message, agentObject)}
						</div>
					</div>
				);
			}

			if (message.messagetype === WA_COMMERCE_PRODUCT) {
				const data = JSON.parse(messageToParseValue(message));

				return (
					<div key={getKey(message, index)}>
						{timestampTemplate}
						<div className={css(verticalAlignBottom)}>
							<MessageWrappper
								message={message}
								index={index}
								align={align}
								translationAction={translationAction}
								isActiveTab={isActiveTab}
								key={getKey(message, index)}
							>
								<Wrappper align={MESSAGE_ALIGN.RIGHT}>
									<ProductMessage data={data} />
								</Wrappper>
							</MessageWrappper>
							{messageAvatar(message, agentObject)}
						</div>
					</div>
				);
			}
			if (message.messagetype === CART_RECEIVED) {
				const data = JSON.parse(messageToParseValue(message));

				return (
					<div key={getKey(message, index)}>
						{timestampTemplate}
						<div className={css(verticalAlignBottom)}>
							{messageAvatar(message, agentObject)}
							<MessageWrappper
								message={message}
								index={index}
								align={align}
								translationAction={translationAction}
								isActiveTab={isActiveTab}
								key={getKey(message, index)}
							>
								<Wrappper align={MESSAGE_ALIGN.LEFT}>
									<Cart
										backgroundColor={messageCss(theme, message, flow).backgroundColor}
										data={data}
									/>
								</Wrappper>
							</MessageWrappper>
						</div>
					</div>
				);
			}

			if (message.messagetype === WA_PAYMENT) {
				return (
					<div key={getKey(message, index)}>
						{timestampTemplate}
						<div className={css(verticalAlignBottom)}>
							<MessageWrappper
								message={message}
								index={index}
								align={align}
								translationAction={translationAction}
								isActiveTab={isActiveTab}
								key={getKey(message, index)}
							>
								<Wrappper align={MESSAGE_ALIGN.RIGHT}>
									<PaymentMessage
										paymentData={JSON.parse(messageToParseValue(message))}
										messageStatus={messageStatus(message)}
									/>
								</Wrappper>
							</MessageWrappper>
							{messageAvatar(message, agentObject)}
						</div>
					</div>
				);
			}

			if (message.messagetype === WA_FLOW) {
				return (
					<div key={getKey(message, index)}>
						{timestampTemplate}
						<div className={css(verticalAlignBottom)}>
							<MessageWrappper
								message={message}
								index={index}
								align={align}
								translationAction={translationAction}
								isActiveTab={isActiveTab}
								key={getKey(message, index)}
							>
								<Wrappper align={MESSAGE_ALIGN.RIGHT}>
									<WhatsAppFlowMessage
										flowData={JSON.parse(messageToParseValue(message))}
										messageStatus={messageStatus(message)}
										messageId={message?.messageId}
									/>
								</Wrappper>
							</MessageWrappper>
							{messageAvatar(message, agentObject)}
						</div>
					</div>
				);
			}

			return <div key={getKey(message, index)} />;
		});
	};

	const renderMessageUnavailableBanner = () => (
		<NoDataTemplate
			bannerImage={{
				altText: t('components:noMessagesAvailableIconAltText'),
				imageUrl: NoMessagesListIcon,
			}}
			className={css({ width: '100% !important', height: '80%', fontFamily: fontStyle })}
			heading={t('pages:messages.banner.conversationNotAvailable.title')}
		>
			<ParagraphMedium className={css({ fontSize: '1rem', width: '18.813rem' })}>
				{t('pages:messages.banner.conversationNotAvailable.description', {
					conversationDuration: getConversationMessageDuration(conversationMessageDuration, t),
				})}
			</ParagraphMedium>
		</NoDataTemplate>
	);

	useEffect(() => {
		if (isMessageSearchEnabled && bringToView) {
			const setToView = document.getElementById(bringToView);
			if (setToView) {
				setToView.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
				dispatch(setBringToView(null));
			}
		}
	});

	return (
		<>
			<div
				className={css({
					color: textColor(theme, flow),
					fontSize: '0.875rem',
					height: '100%',
					overflow: 'auto',
					paddingTop: '0rem',
					paddingRight,
					paddingBottom: '0rem',
					paddingLeft,
					lineHeight: '1.25rem',
					fontWeight: 'normal',
				})}
				ref={messageContainer}
				onScroll={onScrollFunc}
				id='messages-container'
			>
				{messageData.loading ? (
					<div
						className={css({
							background: 'transparent',
							width: '100%',
							height: '100%',
						})}
					>
						<MessageSkeletonList usage='OVERVIEW' count={20} />
					</div>
				) : messageData.error ? (
					<div>
						<span>{t('components:errorFetchingMessage.description')}</span>
					</div>
				) : (
					<>
						<VisibilitySensor
							key='visibility-sesnor'
							offset={{ top: -300 }}
							active={sensorStatus}
							onChange={(isVisible: boolean) => {
								if (selectedUser.message_digest && scrollType !== '') {
									dispatch(
										getUserMessagesAction({
											usersData: selectedUser,
											isUserMessagesDataAppended: false,
											isUserMessagesDataPrepended: true,
											isActiveTab,
											scrollType,
										})
									);
								} else if (isVisible && lastVisitedRow !== null && messages.length > 1) {
									setSensorStatus(false);
									messageContainer.current.scrollTop += 10;
									dispatch(
										getUserMessagesAction({
											usersData: selectedUser,
											isUserMessagesDataAppended: false,
											isUserMessagesDataPrepended: true,
											isActiveTab,
										})
									);
								}
							}}
						>
							<div
								id='visibility-sensor'
								className={css({
									height: '0.5rem',
								})}
							/>
						</VisibilitySensor>
						{conversationData.topBanner !== 'CONVERSATION_EXPIRED_NINETY_DAYS'
							? renderMessages()
							: renderMessageUnavailableBanner()}
						{messageTypingOn()}
						<div
							key='padding-element'
							className={css({
								paddingBottom: '1rem',
							})}
						/>
					</>
				)}
			</div>
			{isAddQuickReplyOpen && (
				<EditQuickReplyModal
					isModalOpen={isAddQuickReplyOpen}
					payload={{ display_name: quickReplyText }}
					setModelState={toggleAddQuickReplyModal}
					getAllQuickReplies={() => {}}
					isAddModal
				/>
			)}
			{showScrollToBottom && (
				<div className={jumpToLatestCss} onClick={scrollToBottom}>
					<DownArrow fillColor={theme.colors.primaryB} />
					<span>{t('components:messagesPreview.JumpTolatest')}</span>
				</div>
			)}
			{showAgentInteractionIndicator && renderAgentInteractionIndicator(currentPlatformRef.current)}
		</>
	);
}

export default Messages;
