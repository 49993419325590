import { useStyletron } from 'baseui';
import { SIZE } from 'baseui/input';
import { useTranslation } from 'react-i18next';

import ENGTInfoBadge from 'components/UI/ENGTInfoBadge/ENGTInfoBadge';

import { IObjProps } from 'shared/consts/types';

function ApiTriggerMessageTag({ overrides }: { overrides?: IObjProps }) {
	const [css, theme]: any = useStyletron();
	const { t } = useTranslation(['common', 'pages']);

	return (
		<ENGTInfoBadge
			className={{
				backgroundColor: theme.instagramReplyMode.privateReplyColor,
				width: 'fit-content',
				paddingTop: ' 0.23rem',
				marginBottom: '0.5rem',
				...overrides,
			}}
			size={SIZE.mini}
		>
			{t('pages:integrations.apiLogs.applications.apiTrigger')}
		</ENGTInfoBadge>
	);
}

export default ApiTriggerMessageTag;
