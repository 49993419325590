export const ORDER_LIST_STATUS = (t: any) => [
	{
		id: 'any',
		label: t('shopify:node.allOrders'),
	},
	{
		id: 'open',
		label: t('shopify:node.openOrders'),
	},
	{
		id: 'closed',
		label: t('shopify:node.closedOrders'),
	},
	{
		id: 'cancelled',
		label: t('shopify:node.cancelledOrders'),
	},
];

export const PRODUCT_SEARCH_TYPES = (t: any) => [
	{
		id: 'tag',
		label: t('shopify:node.tag'),
	},
];

export const CURRENCIES = [
	{ id: 'AED', label: 'AED' },
	{ id: 'ALL', label: 'ALL' },
	{ id: 'AMD', label: 'AMD' },
	{ id: 'ARS', label: 'ARS' },
	{ id: 'AUD', label: 'AUD' },
	{ id: 'AWG', label: 'AWG' },
	{ id: 'BBD', label: 'BBD' },
	{ id: 'BDT', label: 'BDT' },
	{ id: 'BMD', label: 'BMD' },
	{ id: 'BND', label: 'BND' },
	{ id: 'BOB', label: 'BOB' },
	{ id: 'BSD', label: 'BSD' },
	{ id: 'BWP', label: 'BWP' },
	{ id: 'BZD', label: 'BZD' },
	{ id: 'CAD', label: 'CAD' },
	{ id: 'CHF', label: 'CHF' },
	{ id: 'CNY', label: 'CNY' },
	{ id: 'COP', label: 'COP' },
	{ id: 'CRC', label: 'CRC' },
	{ id: 'CUP', label: 'CUP' },
	{ id: 'CZK', label: 'CZK' },
	{ id: 'DKK', label: 'DKK' },
	{ id: 'DOP', label: 'DOP' },
	{ id: 'DZD', label: 'DZD' },
	{ id: 'EGP', label: 'EGP' },
	{ id: 'ETB', label: 'ETB' },
	{ id: 'EUR', label: 'EUR' },
	{ id: 'FJD', label: 'FJD' },
	{ id: 'GBP', label: 'GBP' },
	{ id: 'GEL', label: 'GEL' },
	{ id: 'GGP', label: 'GGP' },
	{ id: 'GHS', label: 'GHS' },
	{ id: 'GIP', label: 'GIP' },
	{ id: 'GMD', label: 'GMD' },
	{ id: 'GTQ', label: 'GTQ' },
	{ id: 'GYD', label: 'GYD' },
	{ id: 'HKD', label: 'HKD' },
	{ id: 'HNL', label: 'HNL' },
	{ id: 'HRK', label: 'HRK' },
	{ id: 'HTG', label: 'HTG' },
	{ id: 'HUF', label: 'HUF' },
	{ id: 'IDR', label: 'IDR' },
	{ id: 'ILS', label: 'ILS' },
	{ id: 'INR', label: 'INR' },
	{ id: 'JMD', label: 'JMD' },
	{ id: 'KES', label: 'KES' },
	{ id: 'KGS', label: 'KGS' },
	{ id: 'KHR', label: 'KHR' },
	{ id: 'KYD', label: 'KYD' },
	{ id: 'KZT', label: 'KZT' },
	{ id: 'LAK', label: 'LAK' },
	{ id: 'LKR', label: 'LKR' },
	{ id: 'LRD', label: 'LRD' },
	{ id: 'LSL', label: 'LSL' },
	{ id: 'MAD', label: 'MAD' },
	{ id: 'MDL', label: 'MDL' },
	{ id: 'MKD', label: 'MKD' },
	{ id: 'MMK', label: 'MMK' },
	{ id: 'MNT', label: 'MNT' },
	{ id: 'MOP', label: 'MOP' },
	{ id: 'MUR', label: 'MUR' },
	{ id: 'MVR', label: 'MVR' },
	{ id: 'MWK', label: 'MWK' },
	{ id: 'MXN', label: 'MXN' },
	{ id: 'MYR', label: 'MYR' },
	{ id: 'NAD', label: 'NAD' },
	{ id: 'NGN', label: 'NGN' },
	{ id: 'NIO', label: 'NIO' },
	{ id: 'NOK', label: 'NOK' },
	{ id: 'NPR', label: 'NPR' },
	{ id: 'NZD', label: 'NZD' },
	{ id: 'PEN', label: 'PEN' },
	{ id: 'PGK', label: 'PGK' },
	{ id: 'PHP', label: 'PHP' },
	{ id: 'PKR', label: 'PKR' },
	{ id: 'QAR', label: 'QAR' },
	{ id: 'RON', label: 'RON' },
	{ id: 'RUB', label: 'RUB' },
	{ id: 'SAR', label: 'SAR' },
	{ id: 'SCR', label: 'SCR' },
	{ id: 'SDG', label: 'SDG' },
	{ id: 'SGD', label: 'SGD' },
	{ id: 'SLL', label: 'SLL' },
	{ id: 'SOS', label: 'SOS' },
	{ id: 'SSP', label: 'SSP' },
	{ id: 'SZL', label: 'SZL' },
	{ id: 'TTD', label: 'TTD' },
	{ id: 'TWD', label: 'TWD' },
	{ id: 'TZS', label: 'TZS' },
	{ id: 'USD', label: 'USD' },
	{ id: 'UYU', label: 'UYU' },
	{ id: 'UZS', label: 'UZS' },
	{ id: 'YER', label: 'YER' },
	{ id: 'ZAR', label: 'ZAR' },
];

export const DISCOUNT_TYPES = (t: any) => [
	{
		id: 'FIXED_AMOUNT',
		label: t('shopify:node.fixedValue'),
	},
	{
		id: 'PERCENTAGE',
		label: t('shopify:node.percentage'),
	},
];

export const WORK_FLOW_TAB_MAP: any = {
	VERIFY_CUSTOMER: ['contact'],
	GET_STORE_DETAILS: [],
	GET_CUSTOMER_PROFILE_INFORMATION: [],
	UPDATE_CUSTOMER_PHONE_NUMBER: ['phone'],
	GET_ORDER_LIST: ['status'],
	GET_ORDER_DETAILS: ['name'],
	GET_SHIPPING_ADDRESS: ['name'],
	UPDATE_SHIPPING_ADDRESS: [
		'name',
		'firstName',
		'lastName',
		'line1',
		'line2',
		'city',
		'country',
		'zip',
		'province',
		'phone',
	],
	GET_SHIPMENTS: ['name'],
	GET_REFUNDS: ['name'],
	GET_SHIPMENT_DETAILS: ['shipmentId'],
	GET_REFUND_DETAILS: ['refundId', 'name'],
	COPY_TO_CLIPBOARD: ['text'],
	APPLY_DISCOUNT_CODE: ['text'],
	ADD_SUBSCRIBER: ['subscriberEmail', 'firstName', 'lastName', 'phone'],
	ADD_ORDER_TAG: ['name', 'tags'],
	GET_PRODUCT_DETAILS: ['productSearchType', 'productSearchValue'],
	PRODUCT_DISCOVERY: [],
	CANCEL_ORDER_REST_API: ['orderId'],
	SHOPIFY_CHECKOUT_LINK: ['waCommerceCart', 'phone'],
	GET_PRODUCTS_INVENTORY_QUANTITY: ['waCommerceCart'],
	CALCULATE_DRAFT_ORDER_VALUE: ['shippingAddress', 'inventoryLineItems'],
	CREATE_DRAFT_ORDER: [
		'email',
		'currency',
		'expireBy',
		'selectedShippingRate',
		'shippingAddress',
		'inventoryLineItems',
		'phone',
		'discountType',
		'discountValue',
		'tag',
	],
	CREATE_DRAFT_ORDER_FROM_ORDER: [
		'orderId',
		'orderName',
		'currency',
		'phone',
		'expireBy',
		'discountType',
		'discountValue',
		'tag',
	],
	GENERATE_RAZORPAY_PAYMENT_LINK: [
		'draftOrderId',
		'paymentLinkExpireBy',
		'paymentLinkAmount',
		'paymentLinkDescription',
		'phone',
	],
};
