import { useStyletron } from 'baseui';
import { Skeleton } from 'baseui/skeleton';

function ENGTSkeletonCircleView({ diameter }: { diameter: string }) {
	const [css, theme]: any = useStyletron();

	return (
		<Skeleton
			width={diameter}
			height={diameter}
			overrides={{
				Root: {
					style: {
						borderRadius: '50%',
						backgroundImage: `${theme.skeletonBackgroundImage}`,
						opacity: 0.09,
					},
				},
			}}
			animation
		/>
	);
}

export default ENGTSkeletonCircleView;
