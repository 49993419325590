import React, { Suspense, useEffect, useRef, useState } from 'react';
import { useStyletron } from 'baseui';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Loader from 'components/UI/Loader/Loader';
import QuillEditor from 'components/UI/Quill/QuillEditor';

import { MESSAGE_TYPE_MAP } from 'shared/consts/consts';
import { IApiDataProps, IObjProps } from 'shared/consts/types';
import { FLOW_CONVERSATION } from 'shared/enum';
import CloseIcon from 'shared/icons/CloseIcon';
import RedirectionIcon from 'shared/icons/Messages/SocialInbox/RedirectionIcon';

import { updateFirstMessageJourneyData } from 'store/App/User/actions';
import { isWhatsappAcceleratorEnabled } from 'store/App/User/selectors';
import { setSelectedUserAction } from 'store/Message/actions';
import { messageOverviewActions } from 'store/Message/messageOverview/actions';
import { RootState } from 'store/rootReducer';
import { usersAPI } from 'store/Users/api';

import Messages from 'pages/Messages/MessageOverview/components/Messages';

const UserMessagesContainer: React.FC<{ isOpen: boolean; selectedUser: any; onClose: () => void; gap: number }> = ({
	isOpen,
	selectedUser,
	onClose,
	gap,
}) => {
	const [css, theme]: any = useStyletron();
	const navigate = useNavigate();
	const { t } = useTranslation(['common', 'components', 'errors']);
	const dispatch = useDispatch<any>();
	const reference = useRef<any>(null);

	const [chatOpen, setIsChatOpen] = useState<boolean>(isOpen);

	const isWhatsappAccelerator = useSelector(isWhatsappAcceleratorEnabled);
	const botAdminData = useSelector((state: RootState) => state.User.botAdmin.data);

	const methods = useForm<any>({
		shouldUnregister: false,
	});

	const { control } = methods;

	const [messageData, setMessageData]: [IApiDataProps<Array<IObjProps>> & IObjProps, Function] = useState({
		data: [],
		error: false,
		loading: true,
		conversation: {},
	});

	const UserMessagesContainerComponent = styled.div<{ isOpen: boolean; gap: number }>`
		position: fixed;
		bottom: 0;
		right: ${({ gap }) => `${gap}px`};
		width: 350px;
		height: ${(props) => (props.isOpen ? '400px' : '50px')};
		background-color: ${theme.modalPrimaryBackground};
		box-shadow: 0px 2px 14px rgba(25, 0, 0, 0.5);
		border-top-right-radius: 0.625rem;
		border-top-left-radius: 0.625rem;
		overflow: hidden;
		z-index: 1000;
		transition:
			height 0.3s ease,
			transform 0.3s ease;
		display: flex;
		flex-direction: column;
		cursor: pointer;
	`;

	const Header = styled.div<{ isOpen: boolean }>`
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0.625rem;
		background: linear-gradient(135deg, #ae1536 0%, #fad0c4 100%);
		border-bottom: 1px solid #ddd;
	`;

	const UserDetails = styled.div`
		display: flex;
		flex-direction: column;
		gap: 0.2rem;
	`;

	const Username = styled.span`
		font-weight: 600;
		font-size: 0.875rem;
		color: white;
	`;

	const LastActive = styled.span`
		font-size: 0.8rem;
		color: white;
	`;

	const Content = styled.div`
		flex: 1;
		overflow-y: auto;
		display: flex;
		flex-direction: column;
	`;

	const Footer = styled.div`
		background-color: ${theme.modalPrimaryBackground};
		padding-bottom: 1rem;
	`;

	const EditorWrapper = styled.div`
		position: relative;
		width: 100%;
		height: 4rem;
		background-color: transparent;
	`;

	const getEditorData = () => {
		const editor = reference?.current?.getEditor();
		const message = editor?.getText()?.trim();

		return { editor, message };
	};

	const appendReplyMessage = (message: string) => ({
		message,
		sender: 'bot',
		messagetype: MESSAGE_TYPE_MAP.text,
		timestamp: new Date().toISOString(),
		is_agent_reply: true,
		messageId: 'dummy_message_id',
		agent_email: botAdminData.email,
		failed_to_send: false,
		messageStatus: 'SENT',
	});

	const onEditorChange = (event: any) => {
		const { editor, message } = getEditorData();

		if (event.keyCode === 13 && !event.shiftKey) {
			event.preventDefault();
			if (message) {
				dispatch(messageOverviewActions.checkForTranslationAndSendMessageAction(message, {}, selectedUser));
				editor.setContents('');

				if (isWhatsappAccelerator) {
					dispatch(updateFirstMessageJourneyData());
				}

				setMessageData({
					data: [...messageData.data, appendReplyMessage(message)],
					loading: false,
					error: false,
				});
			}
		}
	};

	const getUserMessages = () => {
		const userConversationId: IObjProps = {};

		userConversationId[selectedUser['user_id']] = selectedUser['conversation_id'];
		const params = {
			targetApi: 'fetchUserMessages',
		};
		const data = {
			user_conversation_id: userConversationId,
			time_stamp: null,
			page: null,
			page_no: 1,
			page_size: 20,
		};

		usersAPI.getUserMessages(params, data).then((response: any) => {
			if (
				response.data &&
				response.data.status &&
				response.data.status.code === 1000 &&
				response.data.responseObject
			) {
				const userId = Object.keys(response.data.responseObject.all_msg_data)[0];
				const messages = Array.isArray(response.data.responseObject.all_msg_data[userId])
					? response.data.responseObject.all_msg_data[userId]
					: [];
				setMessageData({
					data: messages,
					loading: false,
					error: false,
				});
			} else {
				setMessageData({
					data: [],
					loading: false,
					error: true,
					conversation: {},
				});
			}
		});
	};

	const handleRedirectClick = () => navigate(`/messages/active/${selectedUser.conversation_id}`);

	const toggleChat = () => {
		setIsChatOpen((prev) => !prev);
	};

	useEffect(() => {
		getUserMessages();
	}, [isOpen]);

	useEffect(() => {
		dispatch(setSelectedUserAction(selectedUser));
	}, [isOpen]);

	return (
		<UserMessagesContainerComponent isOpen={chatOpen} gap={gap}>
			<Header isOpen={chatOpen} onClick={toggleChat}>
				<UserDetails style={{ paddingLeft: '0.5rem' }}>
					<Username>{selectedUser?.username || 'No User Selected'}</Username>
					<LastActive>
						{t('common:lastActive')}
						{': '}
						{selectedUser?.last_active_at
							? t('common:messageHeaderLastActiveDateFormatter', {
									date: new Date(selectedUser?.last_active_at),
								})
							: '-'}
					</LastActive>
				</UserDetails>
				<div style={{ display: ' flex', gap: '0.75rem' }}>
					<div onClick={handleRedirectClick} style={{ cursor: 'pointer' }}>
						<RedirectionIcon fillColor={theme.colors.primaryA} />
					</div>

					<div onClick={onClose} style={{ cursor: 'pointer', paddingRight: '0.5rem' }}>
						<CloseIcon width={15} height={16} viewX={14} viewY={14} fillColor={theme.colors.primaryA} />
					</div>
				</div>
			</Header>
			<Content>
				<Messages
					selectedUser={selectedUser}
					messageData={messageData}
					flow={FLOW_CONVERSATION.RECENT_CONVERSATIONS}
					agentAvatarColorMap={{}}
					isChatPopupOpen
				/>
			</Content>
			{!selectedUser?.is_enable && selectedUser?.is_live_chat_enable && selectedUser?.is_chat_window_active && (
				<Footer>
					<EditorWrapper>
						<Controller
							control={control}
							name='message'
							defaultValue={reference.current}
							render={({ value, onChange }) => (
								<Suspense fallback={<Loader />}>
									<QuillEditor
										name='message'
										width='100%'
										value={value}
										inputRef={reference}
										editorHeight='5.625rem'
										placeholder='Type your message...'
										height='4.7rem'
										toolbarHeight='0px'
										onKeyDown={onEditorChange}
										onChange={(event: any) => {
											onChange(event);
										}}
									/>
								</Suspense>
							)}
						/>
					</EditorWrapper>
				</Footer>
			)}
		</UserMessagesContainerComponent>
	);
};

export default UserMessagesContainer;
