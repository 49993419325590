import React from 'react';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';

interface ProductProps {
	data: any;
}

const productViewCss = (padding: string) => ({
	display: 'flex',
	alignItems: 'flex-start',
	paddingRight: padding,
	paddingLeft: padding,
	justifyContent: 'flex-end',
	flexDirection: 'column',
	paddingTop: padding,
	paddingBottom: padding,
});

const imageHolderCss = (theme: any) => ({
	borderTopLeftRadius: '0.4375rem',
	borderTopRightRadius: '0.4375rem',
	borderBottomLeftRadius: '0.4375rem',
	borderBottomRightRadius: '0.4375rem',
	borderStyle: 'solid',
	borderColor: theme.colors.backgroundSecondary,
	borderWidth: '0.05rem',
});

function ProductMessage({ data }: ProductProps) {
	const {
		items: itemsCount,
		image_link: imageUrl,
		name: productName,
		price: productPrice,
	} = data?.product_details || {};

	const [css, theme]: [any, any] = useStyletron();

	const renderSingleProductView = () => (
		<div className={css(productViewCss('1rem'))}>
			<div className={css(imageHolderCss(theme))}>
				<a
					href={imageUrl}
					target='_blank'
					className={css({
						display: 'flex',
						justifyContent: 'flex-start',
						float: 'right',
					})}
					rel='noreferrer'
				>
					<img
						src={imageUrl}
						className={css({
							height: '7.5rem',
							width: '7.5rem',
						})}
					/>
				</a>
			</div>

			<div
				className={css({
					color: theme.colors.primaryA,
					fontSize: '0.75rem',
					fontWeight: '600',
					paddingTop: '0.375rem',
				})}
			>
				{productName}
			</div>
			<div
				className={css({
					color: theme.colors.accent50,
					fontSize: '0.75rem',
					fontWeight: '400',
					paddingTop: '0.375rem',
				})}
			>
				{productPrice}
			</div>
		</div>
	);

	const renderMultiProductView = () => (
		<div className={css(productViewCss('1rem'))}>
			<div
				className={css({
					display: 'inline-flex',
				})}
			>
				<div className={css(imageHolderCss(theme))}>
					<a
						href={imageUrl}
						target='_blank'
						className={css({
							display: 'flex',
							justifyContent: 'flex-start',
						})}
						rel='noreferrer'
					>
						<img
							src={imageUrl}
							className={css({
								height: '7.5rem',
								width: '7.5rem',
							})}
						/>
					</a>
				</div>
				<div className={css(productViewCss('0.5rem'))}>
					<div
						className={css({
							display: 'inline-block',
							fontWeight: '600',
							fontSize: '0.75rem',
							color: theme.colors.primaryA,
						})}
					>
						{data?.header?.text}
					</div>

					<div
						className={css({
							display: 'inline-block',
							fontWeight: '400',
							fontSize: '0.75rem',
							color: theme.colors.accent50,
						})}
					>
						{`${itemsCount} ` + `items`}
					</div>
				</div>
			</div>
			<div
				className={css({
					color: theme.colors.primaryA,
					fontSize: '0.75rem',
					fontWeight: '400',
				})}
			>
				{data?.body?.text}
			</div>
		</div>
	);

	return (
		<Block
			className={css({
				display: 'inline-block',
				background: theme.colors.primaryB,
				borderTopLeftRadius: '0.5rem',
				borderTopRightRadius: '0.5rem',
				borderBottomLeftRadius: '0.5rem',
				borderBottomRightRadius: '0.5rem',
			})}
		>
			{itemsCount <= 1 ? renderSingleProductView() : renderMultiProductView()}
		</Block>
	);
}

export default React.memo(ProductMessage);
