import { useStyletron } from 'baseui';
import { useTranslation } from 'react-i18next';

function ToggleLocationLink(props: any) {
	const { showLink, setShowLink } = props;
	const [css, theme]: [any, any] = useStyletron();
	const { t } = useTranslation(['pages']);

	return (
		<span
			className={css({
				position: 'absolute',
				marginTop: '1rem',
				left: 0,
			})}
		>
			<span
				className={css({
					color: !showLink
						? `${theme.colors.inputBorderError} !important`
						: `${theme.colors.primaryA} !important`,
					cursor: 'pointer',
					fontSize: '0.625rem',
				})}
				onClick={() => {
					setShowLink(!showLink);
				}}
			>
				{showLink ? t('pages:messages.showCode') : t('pages:messages.showLink')}
			</span>
		</span>
	);
}

export default ToggleLocationLink;
