function RedirectIcon({ fillColor }: { fillColor?: string }) {
	return (
		<svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M6.1675 2.58268H4.5175C3.49073 2.58268 2.97697 2.58268 2.5848 2.78251C2.23983 2.95827 1.95957 3.23853 1.78381 3.5835C1.58398 3.97567 1.58398 4.48943 1.58398 5.5162V12.4829C1.58398 13.5096 1.58398 14.0228 1.78381 14.4149C1.95957 14.7599 2.23983 15.0406 2.5848 15.2164C2.97658 15.416 3.48973 15.416 4.51448 15.416H11.4868C12.5116 15.416 13.024 15.416 13.4158 15.2164C13.7607 15.0406 14.0419 14.7596 14.2177 14.4146C14.4173 14.0229 14.4173 13.5103 14.4173 12.4855V10.8327M15.334 6.24935V1.66602M15.334 1.66602H10.7507M15.334 1.66602L8.91732 8.08268'
				stroke={fillColor || 'black'}
				strokeWidth='1.77604'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}
export default RedirectIcon;
