import { FLOW } from 'shared/enum';

export const MAX_BODY_TEXT_LIMIT = 1024;
export const MAX_FOOTER_TEXT_LIMIT = 60;
export const MAX_EXPIRY_DESCRIPTION_LIMIT = 120;
export const MIN_CUSTOMS_VALUE = 0;
export const MAX_CUSTOMS_VALUE = 100;
export const MIN_PAYMENT_LINK_EXPIRY_TIME = 6;
export const MAX_PAYMENT_LINK_EXPIRY_TIME = 1440;
export const ORDER_TYPE = 'digital-goods';

export enum CUSTOMS_OPTIONS {
	AMOUNT = 'AMOUNT',
	ATTRIBUTE = 'ATTRIBUTE',
}

export enum CUSTOMS_NAME {
	TAX = 'tax',
	DISCOUNT = 'discount',
	SHIPPING = 'shipping',
}

export enum ORDER_DETAIL_OPTIONS {
	CATALOG = 'catalog',
	CUSTOM = 'custom',
}

export const customsOptions = (t: any) => [
	{
		name: t('components:node.whatsAppPayment.input.amount'),
		value: CUSTOMS_OPTIONS.AMOUNT,
	},
	{
		name: t('components:node.whatsAppPayment.input.attribute'),
		value: CUSTOMS_OPTIONS.ATTRIBUTE,
	},
];

export const paymentOption = (t: any) => [
	{
		name: t('components:node.whatsAppPayment.input.catalogOptionText'),
		value: ORDER_DETAIL_OPTIONS.CATALOG,
	},
	{
		name: t('components:node.whatsAppPayment.input.customOptionText'),
		value: ORDER_DETAIL_OPTIONS.CUSTOM,
	},
];

export const flowBtns = (t: any) => [
	{
		name: t('components:node.whatsAppCommerce.errorHandling.stopFlow'),
		value: FLOW.STOP,
	},
	{
		name: t('components:node.whatsAppCommerce.errorHandling.continueFlow'),
		value: FLOW.CONTINUE,
	},
];

export const paymentTypeOptions = [
	{
		id: 'payu',
		label: 'PayU',
	},
	{
		id: 'razorpay',
		label: 'Razorpay',
	},
	{
		id: 'upi',
		label: 'WhatsApp UPI',
	},
];
