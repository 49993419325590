import React from 'react';

function VideoIcon({
	width = 32,
	height = 32,
	fillColor = '#403F42',
	fill = '#E8E6E7',
}: {
	width?: any;
	height?: any;
	fillColor?: string;
	fill?: string;
}) {
	return (
		<svg width={width} height={height} viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<circle cx='20' cy='20' r='20' fill={fill} />
			<path
				d='M21.4561 16.1822H14.2323C13.2795 16.1822 12.5 16.9617 12.5 17.9145V23.7524C12.5 24.7052 13.2795 25.4847 14.2323 25.4847H21.4561C22.4088 25.4847 23.1884 24.7052 23.1884 23.7524V17.9145C23.1884 16.9444 22.4088 16.1822 21.4561 16.1822Z'
				fill={fillColor}
			/>
			<path
				d='M27.0516 17.135C26.9476 17.1523 26.8437 17.2043 26.7571 17.2562L24.0547 18.8153V22.8343L26.7744 24.3933C27.2768 24.6878 27.9004 24.5146 28.1949 24.0122C28.2815 23.8563 28.3335 23.6831 28.3335 23.4925V18.1397C28.3335 17.4987 27.7272 16.9791 27.0516 17.135Z'
				fill={fillColor}
			/>
		</svg>
	);
}

export default VideoIcon;
