import { REACT_APP_API_GATEWAY_BASE_URL } from 'shared/config/config';
import { serialize } from 'shared/helpers';

import BaseService from 'services/baseService';

const accountSettings = '/v2/genericProxyRequest/';
const accountSettingsAPI = new BaseService(accountSettings);
const getBotAndInteractions = '/getBotAndInteractions';
const getBotAndInteractionsAPI = new BaseService(getBotAndInteractions);
const profileApiUrl = '/admin/profile/';
const profileApi = new BaseService(profileApiUrl);
const apiGatetWayGeneralProxyApi = new BaseService(REACT_APP_API_GATEWAY_BASE_URL);

export const API = {
	getMyAchievements(data) {
		const params = {
			targetApi: 'getMyAchievements',
			userId: '{{userId}}',
		};

		return apiGatetWayGeneralProxyApi.getRequest(serialize(params, '?'));
	},
	getUserXp() {
		const params = {
			targetApi: 'getUserXp',
		};

		return apiGatetWayGeneralProxyApi.getRequest(serialize(params, '?'));
	},
	increaseUserXp(category) {
		const params = {
			targetApi: 'increaseUserXp',
		};
		const body = {
			xp: 100,
			category,
		};

		return apiGatetWayGeneralProxyApi.postRequest(body, serialize(params, '?'));
	},
};
