import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { useTranslation } from 'react-i18next';

import { NOTIFICATION_MESSAGES_FREQUENCY } from 'shared/consts/consts';

interface IENGTRecurringNotification {
	imageUrl: string;
	title: string;
	notificationMessagesFrequency: string;
}

function RecurringNotificationTemplate(props: IENGTRecurringNotification) {
	const { imageUrl, title, notificationMessagesFrequency } = props;
	const [css, theme]: [any, any] = useStyletron();
	const { t } = useTranslation(['components']);

	const getNotificationText = () => {
		let text = t('components:recurringNotificationText.daily');
		if (NOTIFICATION_MESSAGES_FREQUENCY.weekly === notificationMessagesFrequency) {
			text = t('components:recurringNotificationText.weekly');
		} else if (NOTIFICATION_MESSAGES_FREQUENCY.monthly === notificationMessagesFrequency) {
			text = t('components:recurringNotificationText.monthly');
		}

		return text;
	};

	const getFrequencyText = () => {
		let frequency = notificationMessagesFrequency?.toLocaleLowerCase() || '';
		frequency = frequency.charAt(0).toUpperCase() + frequency.slice(1);

		return `${'Get ' + ' '}${frequency} ` + `Messages`;
	};

	return (
		<Block
			className={css({
				display: 'inline-block',
				background: theme.colors.primaryB,
				borderTopLeftRadius: '0.5rem',
				borderTopRightRadius: '0.5rem',
				borderBottomLeftRadius: '0.5rem',
				borderBottomRightRadius: '0.5rem',
			})}
		>
			<div
				className={css({
					display: 'flex',
					alignItems: 'flex-start',
					paddingRight: '1rem',
					paddingLeft: '1rem',
					justifyContent: 'flex-end',
					flexDirection: 'column',
					paddingTop: '1rem',
					paddingBottom: '1rem',
					width: 'min-content',
				})}
			>
				<div
					className={css({
						borderTopLeftRadius: '0.4375rem',
						borderTopRightRadius: '0.4375rem',
						borderBottomLeftRadius: '0.4375rem',
						borderBottomRightRadius: '0.4375rem',
						borderStyle: 'solid',
						borderColor: theme.colors.backgroundSecondary,
						borderWidth: '0.05rem',
					})}
				>
					<a
						href={imageUrl}
						target='_blank'
						className={css({
							display: 'flex',
							justifyContent: 'flex-start',
							float: 'right',
						})}
						rel='noreferrer'
					>
						<img
							src={imageUrl}
							alt='Image'
							className={css({
								height: '7.5rem',
								width: 'fit-content',
							})}
						/>
					</a>
				</div>

				<div
					className={css({
						color: theme.colors.primaryA,
						fontSize: '0.75rem',
						fontWeight: '600',
						paddingTop: '0.375rem',
					})}
				>
					{title}
				</div>
				<div
					className={css({
						color: theme.colors.accent50,
						fontSize: '0.75rem',
						fontWeight: '400',
						paddingTop: '0.375rem',
						textAlign: 'left',
					})}
				>
					{getNotificationText()}
				</div>
				<div
					className={css({
						borderTopLeftRadius: '0.5rem',
						borderTopRightRadius: '0.5rem',
						borderBottomLeftRadius: '0.5rem',
						borderBottomRightRadius: '0.5rem',
						backgroundColor: theme.colors.accent100,
						marginTop: '1rem',
						paddingTop: '0.5rem',
						paddingBottom: '0.5rem',
						paddingLeft: '0.5rem',
						paddingRight: '0.5rem',
						width: '-webkit-fill-available',
						textAlign: 'center',
					})}
				>
					{getFrequencyText()}
				</div>
			</div>
		</Block>
	);
}

export default RecurringNotificationTemplate;
