import React from 'react';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';

import { IObjProps } from 'shared/consts/types';

interface IENGTSmo {
	backgroundColor: string;
	options: Array<IObjProps>;
}

const smoCss = {
	paddingTop: '0.875rem',
	paddingBottom: '0.875rem',
	paddingRight: '1rem',
	paddingLeft: '1rem',
	display: 'inline-block',
	marginTop: '0.25rem',
	marginRight: '0rem',
	marginBottom: '0.375rem',
	marginLeft: '0.375rem',
	borderTopLeftRadius: '3.5rem',
	borderTopRightRadius: '3.5rem',
	borderBottomLeftRadius: '3.5rem',
	borderBottomRightRadius: '3.5rem',
};

function Smo(props: IENGTSmo) {
	const { options, backgroundColor } = props;
	const [css]: [any, any] = useStyletron();

	return (
		<>
			{options.map((option, index) => (
				<Block
					className={css({
						...smoCss,
						backgroundColor,
					})}
					key={`${option.id}-${index}`}
				>
					<span>{option.text}</span>
				</Block>
			))}
		</>
	);
}

export default React.memo(Smo);
