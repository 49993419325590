import React from 'react';
import { useStyletron } from 'baseui';

import { IObjProps } from 'shared/consts/types';
import { hexToRgb } from 'shared/helpers';

import ENGTInfoBadge from '../ENGTInfoBadge/ENGTInfoBadge';

export interface IInfoDividerProps {
	children: React.ReactNode | string;
	color?: string;
	width?: string;
	wrapperCss?: IObjProps;
}

function InfoDivider({ children, color, width, wrapperCss }: IInfoDividerProps) {
	const [css, theme]: any = useStyletron();

	return (
		<div
			className={css({
				...wrapperCss,
			})}
		>
			<div
				className={css({
					width: width || '100%',
					borderBottomWidth: '0.0625rem',
					borderBottomStyle: 'solid',
					borderBottomColor: hexToRgb(color || theme.engatiOrange, 0.6),
					textAlign: 'center',
					position: 'relative',
					height: '1rem',
				})}
			>
				<ENGTInfoBadge
					size='mini'
					className={{
						position: 'absolute',
						left: '50%',
						top: '50%',
						transform: 'translate(-50%, 20%)',
					}}
				>
					{children}
				</ENGTInfoBadge>
			</div>
		</div>
	);
}

export default InfoDivider;
