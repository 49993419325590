import React from 'react';

import MessageListSkeletonView from './MessageListSkeletonView';
import MessageOverviewSkeletonView from './MessgaeOverviewSkeletonView';

function MessageSkeletonList({ usage = 'LIST', count }: { usage?: 'LIST' | 'OVERVIEW'; count: number }) {
	const skeletons = Array.from({ length: count }, (_, i) => {
		const key = i + 1;

		return (
			<>
				{usage === 'LIST' ? (
					<MessageListSkeletonView key={`${usage}-skeleton-${key}`} />
				) : (
					<MessageOverviewSkeletonView count={key} key={`${usage}-skeleton-${key}`} />
				)}
			</>
		);
	});

	return <>{skeletons}</>;
}

export default React.memo(MessageSkeletonList);
