import { useStyletron } from 'baseui';
import { StatefulPopover, TRIGGER_TYPE } from 'baseui/popover';

import ENGTMenuList from 'components/UI/ENGTMenuList/ENGTMenuList';

import { MESSAGE_SENDER_MAP } from 'shared/consts/consts';
import { IObjProps } from 'shared/consts/types';
import useResponsiveSize from 'shared/hooks/useResponsiveSize';

import { IENGTText } from './Text';

const { bot: SENDER_BOT } = MESSAGE_SENDER_MAP;

function MessageActionsPopup(props: IENGTText | IObjProps) {
	const [css, theme]: [any, any] = useStyletron();
	const { messageData = {}, menuItems = [], message } = props;
	const { isOpen, sender, messageId } = messageData;

	const { isSmallDesktopScreen } = useResponsiveSize();

	return isOpen || (isSmallDesktopScreen && menuItems?.length) ? (
		<StatefulPopover
			overrides={{
				Inner: {
					style: {
						backgroundColor: 'transparent',
					},
				},
				Body: {
					style: ({ $theme }: any) => ({
						paddingTop: '0',
						paddingBottom: '0',
						paddingLeft: '0',
						paddingRight: '0',
						marginTop: '0',
						marginBottom: '0',
						marginRight: '0',
						marginLeft: '0',
						left:
							sender === SENDER_BOT
								? message?.length > 15
									? '4rem'
									: '-5rem'
								: message?.length > 15
									? '-4.5rem'
									: '5rem',
						top: '0.5rem',
						borderTopLeftRadius: '0.5rem',
						borderTopRightRadius: '0.5rem',
						borderBottomLeftRadius: '0.5rem',
						borderBottomRightRadius: '0.5rem',
						zIndex: $theme.zIndex300,
					}),
				},
			}}
			accessibilityType='menu'
			popoverMargin={10}
			placement='bottom'
			triggerType={isSmallDesktopScreen ? TRIGGER_TYPE.click : TRIGGER_TYPE.hover}
			ignoreBoundary
			content={() =>
				menuItems?.length && (
					<ENGTMenuList
						Items={menuItems}
						isOpen
						top='0'
						marginTop='0'
						marginBottom='0'
						position='relative'
						key={`menu-list${messageId}`}
						right='0'
						width='100%'
						listItemCss={{
							minWidth: '7rem',
						}}
					/>
				)
			}
		>
			<div
				className={css({
					display: 'flex',
					alignItems: 'center',
					width: '1.625rem',
					height: '1.625rem',
					borderTopRightRadius: '4.5rem',
					borderTopLeftRadius: '4.5rem',
					borderBottomRightRadius: '4.5rem',
					borderBottomLeftRadius: '4.5rem',
					backgroundColor: theme.colors.backgroundSecondary,
					marginLeft: '0.5rem',
					marginRight: '0.5rem',
					cursor: 'pointer',
				})}
			>
				<div
					className={css({
						width: '1rem',
						height: '0.825rem',
						backgroundImage: `radial-gradient(circle, ${theme.colors.primaryA} 0.75px, transparent 1.5px)`,
						backgroundSize: '100% 33.33%',
						marginLeft: '0.5rem',
						marginRight: '0.5rem',
					})}
				/>
			</div>
		</StatefulPopover>
	) : (
		<div
			className={css({
				minWidth: '2.625rem',
			})}
		/>
	);
}

export default MessageActionsPopup;
