import React, { useCallback, useMemo, useState } from 'react';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { PLACEMENT } from 'baseui/tooltip';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ENGTImage from 'components/UI/ENGTImage/ENGTImage';
import { LabelWithTooltip } from 'components/UI/LabelWithToolTip/LabelWithTooltip';

import {
	MESSAGE_LIST_MAXIMUM_CHARACTERS,
	MESSAGE_LIST_USER_NAME_MAXIMUM_CHARACTERS,
	SEARCH_TAGS,
	SENTIMENT_COLOR_MAP,
	SENTIMENT_MAP,
	WHATSAPP_PLATFORMS,
} from 'shared/consts/consts';
import { MESSAGE_COUNT } from 'shared/consts/QAConsts';
import { highlightSearchedMessage, renderWindowClosingTime } from 'shared/helpers/messagesHelper';
import useResponsiveSize from 'shared/hooks/useResponsiveSize';
import VideoCallIndicator from 'shared/icons/VideoCallIndicator.svg';

import { RootState } from 'store/rootReducer';

import ENGTInfoBadge from '../ENGTInfoBadge/ENGTInfoBadge';
import ENGTTooltip from '../ENGTTooltip/ENGTTooltip';

import { IUserProfileAvatarProps, UserProfileAvatar } from './UserProfileAvatar';

export interface IUserCardItemProps extends IUserProfileAvatarProps {
	index: number;
	isActive?: boolean;
	isInstagramPost?: boolean;
	inVideoConversation?: boolean;
	highlightName?: boolean;
	isTagged?: boolean;
	lastActiveAt: string;
	message: string;
	status: boolean;
	avatar: string;
	sentiment: string;
	tagsSearchQuery?: string;
	section?: string;
	windowclosingTime?: string;

	setSelectedUser?: (...args: any) => any;
}

const enhancerComponentCss = (theme: any, isTagged: boolean) => ({
	color: theme.colors.accent50,
	fontSize: '0.625rem',
	lineHeight: '0.75rem',
	marginBottom: isTagged ? '0.25rem' : '1.25rem',
	textAlign: 'right',
	display: 'flex',
	flexDirection: 'row',
});

const UserCardListItem: React.FC<React.PropsWithChildren<IUserCardItemProps>> = ({
	channelName,
	color,
	index,
	inPendingState,
	isActive,
	isInstagramPost = false,
	inVideoConversation,
	isTagged = false,
	lastActiveAt,
	message,
	name,
	status,
	highlightName = false,
	setSelectedUser,
	avatar = '',
	tagsSearchQuery = '',
	sentiment,
	section = '',
	windowclosingTime,
}) => {
	const [css, theme]: [any, any] = useStyletron();
	const { t } = useTranslation();
	const [onHover, setOnHover] = useState(false);
	const navigate = useNavigate();
	const { isSmallDesktopScreen } = useResponsiveSize();

	const botRef = useSelector((state: RootState) => state.Bot.data.bot_ref);
	const uid = useSelector((state: RootState) => state.User.botAdmin.data.uid);
	const enableSentimentTagging: any = useSelector(
		(state: RootState) => state.User.botAdmin.data.enable_sentiment_tagging
	);
	const isShopifyEnabled = useSelector(
		(state: RootState) => state.User.botAdmin.data.enable_shopify_shopper_assitance
	);
	const isMessageSearchEnabled: any = useSelector(
		(state: RootState) => state.User.botAdmin.data.is_message_search_enabled
	);
	const isWAChannel = WHATSAPP_PLATFORMS.includes(channelName);

	const activeCss = (isActive: boolean) =>
		isActive
			? {
					backgroundColor:
						isMessageSearchEnabled && section === 'messages' ? 'transparent' : theme.primaryNavBackground,
					':hover': {
						backgroundColor: theme.primaryNavBackground,
					},
					borderTopRightRadius: '0.75rem',
					borderTopLeftRadius: '0.75rem',
					borderBottomRightRadius: '0.75rem',
					borderBottomLeftRadius: '0.75rem',
					cursor: 'pointer',
				}
			: {};

	const taggedCss = {
		backgroundColor: color,
		color: theme.colors.primaryB,
		width: '0.75rem',
		alignSelf: 'center',
		marginLeft: '0.75rem',
		fontSize: '0.625rem',
		lineHeight: '0.5rem',
		paddingRight: '0.125rem',
		paddingLeft: '0.125rem',
		paddingBottom: '0.275rem',
		paddingTop: '0.19rem',
		borderBottomLeftRadius: '0.375rem',
		borderBottomRightRadius: '0.375rem',
		borderTopLeftRadius: '0.375rem',
		borderTopRightRadius: '0.375rem',
	};

	const renderLastMessage = useCallback(
		(messageHTML = '') => {
			const div = document.createElement('div');
			div.innerHTML = messageHTML;

			return div.innerText;
		},
		[message]
	);

	const showMessageSearchHighlight = () => {
		const messageType = typeof message;

		return (
			section === SEARCH_TAGS.MESSAGES &&
			(messageType === 'string'
				? message?.toLowerCase().includes(tagsSearchQuery.toLowerCase())
				: message?.includes(tagsSearchQuery))
		);
	};

	const showUserSearchHighlight = () =>
		section === SEARCH_TAGS.USERS && name?.toLowerCase().includes(tagsSearchQuery.toLowerCase());

	const onMouseEnter = () => {
		setOnHover(true);
	};

	const onMouseLeave = () => {
		setOnHover(false);
	};

	const userProfileAvatar = useMemo(
		() => (
			<UserProfileAvatar
				avatarSize='2.5rem'
				color={color}
				channelName={channelName}
				inPendingState={inPendingState}
				name={name || ''}
				onHover={onHover}
				avatar={avatar || ''}
			/>
		),
		[avatar, channelName, color, inPendingState, name, onHover, isSmallDesktopScreen]
	);

	const navigateToNotes = (e: any) => {
		if (isActive) {
			navigate({
				search: '?open=notes',
			});
		} else {
			setSelectedUser?.(index, true);
		}

		e.stopPropagation();
	};

	const renderTag = () => (
		<span className={css(taggedCss)} onClick={navigateToNotes}>
			@
		</span>
	);

	const renderInstagramPost = () => (
		<ENGTInfoBadge
			className={{
				alignSelf: 'center',
				justifySelf: 'flex-end',
				marginLeft: '0.5rem',
				backgroundColor: `${theme.colors.accent} !important`,
			}}
			size='mini'
		>
			{t('common:post').toUpperCase()}
		</ENGTInfoBadge>
	);

	const renderSentiment = () => (
		<ENGTTooltip
			content={`${t('pages:messages.sentiment')}: ${SENTIMENT_MAP(t)[sentiment]}`}
			ignoreBoundary
			placement={PLACEMENT.top}
		>
			<div
				className={css({
					width: '0.625rem',
					height: '0.625rem',
					borderRadius: '0.1875rem',
					backgroundColor: SENTIMENT_COLOR_MAP(theme)[sentiment],
					marginLeft: '0.5rem',
					alignSelf: 'center',
				})}
			/>
		</ENGTTooltip>
	);

	const lastActiveTimeOfUser = lastActiveAt
		? t('common:messageListLastActiveDateFormatter', {
				date: new Date(lastActiveAt),
			})
		: '-';

	return (
		<div
			className={css({
				marginBottom: '0.5rem',
				paddingBottom: '0.25rem',
				paddingTop: '0.25rem',
				paddingLeft: '0.5rem',
				paddingRight: '0.5rem',
				':hover': {
					...activeCss(true),
				},
				...activeCss(isActive || false),
			})}
			data-qa={MESSAGE_COUNT}
			{...(!isSmallDesktopScreen && { onMouseEnter, onMouseLeave })}
		>
			<Block
				display='flex'
				justifyContent={['center', 'center', 'left']}
				height={['100%', '100%', '3.25rem']}
				className={css({
					color: theme.colors.primaryA,
					backgroundColor: 'transparent',
					cursor: 'pointer',
					alignItems: 'center',
					width: '100%',
					flexWrap: isSmallDesktopScreen ? 'wrap' : 'nowrap',
				})}
			>
				{userProfileAvatar}
				<Block marginLeft='0.5rem'>
					<span
						className={css({
							display: 'flex',
						})}
					>
						<LabelWithTooltip
							fontSize='0.875rem !important'
							lineHeight='1.25rem !important'
							color={theme.colors.primaryA}
							characterLimit={
								isMessageSearchEnabled && showUserSearchHighlight()
									? MESSAGE_LIST_USER_NAME_MAXIMUM_CHARACTERS
									: 20
							}
							fontWeight={highlightName ? '700' : '400'}
							className={css({ textAlign: 'left' })}
							dangerouslySetHTML={isMessageSearchEnabled && showUserSearchHighlight()}
							placement='topLeft'
							appendToBody
							style={{
								maxWidth: '20rem',
							}}
							tooltipColor={theme.colors.primaryB}
							backgroundColor={theme.colors.primaryA}
						>
							{isMessageSearchEnabled && showUserSearchHighlight() && section === SEARCH_TAGS.USERS
								? highlightSearchedMessage(
										name,
										localStorage.getItem(`message_search_query_${botRef}_${uid}`) || '',
										theme.colors.primaryA
									)
								: name}
						</LabelWithTooltip>

						<span
							className={css({
								display: status ? 'inline-block' : 'none',
								width: '0.5rem',
								height: '0.5rem',
								borderTopRightRadius: '50%',
								borderTopLeftRadius: '50%',
								borderBottomRightRadius: '50%',
								borderBottomLeftRadius: '50%',
								backgroundColor: theme.colors.dotStatusFill,
								marginLeft: '0.5rem',
								marginTop: '0.25rem',
							})}
						/>
						{inVideoConversation && (
							<span
								className={css({
									paddingLeft: '0.313rem',
								})}
							>
								{' '}
								<ENGTImage
									lazy
									src={VideoCallIndicator}
									alt={t('components:videoButtonAlternateTexts.videoIcon')}
									className={css({
										paddingBottom: '0.09rem',
									})}
								/>{' '}
							</span>
						)}
					</span>
					<LabelWithTooltip
						fontSize='0.75rem !important'
						fontWeight='normal !important'
						lineHeight='1rem !important'
						color={theme.primaryNavText}
						characterLimit={
							isMessageSearchEnabled && showMessageSearchHighlight()
								? MESSAGE_LIST_MAXIMUM_CHARACTERS
								: 22
						}
						style={{
							maxWidth: '25rem',
						}}
						className={css({ textAlign: 'left' })}
					>
						{isMessageSearchEnabled && showMessageSearchHighlight()
							? highlightSearchedMessage(
									message,
									localStorage.getItem(`message_search_query_${botRef}_${uid}`) || '',
									theme.colors.primaryA
								)
							: renderLastMessage(message)}
					</LabelWithTooltip>
				</Block>
				<Block
					display='flex'
					flexDirection='column'
					justifySelf='flex-end'
					alignItems='flex-end'
					marginLeft='auto'
				>
					<span className={css(enhancerComponentCss(theme, isTagged))}>
						{windowclosingTime && isWAChannel && (
							<ENGTTooltip
								content={t('components:messageFilter.filters.conversationClosingTimeTooltip')}
								placement={PLACEMENT.top}
							>
								<div
									className={css({
										width: 'fit-content',
										height: '0.625rem',
										fontSize: '0.625rem',
										fontWeight: 400,
										color: theme.colors.dotPendingFill,
										marginRight: '0.5rem',
									})}
								>
									{renderWindowClosingTime(windowclosingTime)}
								</div>
							</ENGTTooltip>
						)}
						{lastActiveTimeOfUser}
					</span>
					<Block display='flex' flexDirection='row' justifyContent='space-between'>
						{isTagged && renderTag()}
						{isInstagramPost && renderInstagramPost()}
						{enableSentimentTagging && isShopifyEnabled && sentiment && renderSentiment()}
					</Block>
				</Block>
			</Block>
		</div>
	);
};

export default React.memo(UserCardListItem);
