export interface IUserXpState {
	userXp: number;
	noOfAchievements: number;
	isLoading: boolean;
	isError: boolean;
}

export const userXpState: IUserXpState = {
	userXp: 0,
	noOfAchievements: 0,
	isLoading: false,
	isError: false,
};
