import { IUserXpState } from './state';

export const userXpReducer = {
	setUserXp: (state: IUserXpState, action: { payload: any }) => {
		state.userXp = action.payload.user_xp;
		state.noOfAchievements = action.payload.no_of_achievements;
	},
	setLoading: (state: IUserXpState, action: { payload: boolean }) => {
		state.isLoading = action.payload;
	},
	setError: (state: IUserXpState, action: { payload: boolean }) => {
		state.isError = action.payload;
	},
	increaseUserXp: (state: IUserXpState) => {
		state.userXp += 100;
	},
};
