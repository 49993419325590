import { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { Button, KIND, SHAPE, SIZE } from 'baseui/button';
import { TriangleDown, TriangleUp } from 'baseui/icon';
import { LabelXSmall, ParagraphMedium } from 'baseui/typography';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import DefaultDatePicker from 'components/UI/ENGTDatePicker/DefaultDatePicker';
import ActionModal from 'components/UI/Modal/ActionModal/ActionModal';

import { RootState } from 'store/rootReducer';

interface IRenderDateTimePickerLocalProps {
	toggelDateTime: Function;
	setDateValue: Function;
	openDateTime: boolean;
	dateValue: Date | undefined;
}

const MERIDIEM = {
	am: 'am',
	pm: 'pm',
};

const RenderDateTimePicker = ({
	openDateTime,
	toggelDateTime,
	dateValue,
	setDateValue,
}: IRenderDateTimePickerLocalProps) => {
	const [css, theme]: any = useStyletron();
	const { t }: any = useTranslation(['common', 'components']);

	const [hours, setHours] = useState<number>(1);
	const [paddedHours, setPaddedHours] = useState('');
	const [paddedMinutes, setPaddedMinutes] = useState('');
	const [minutes, setMinutes] = useState<number>(0);
	const [meridiem, setMeridiem] = useState(dateValue && dateValue.getHours() < 12 ? MERIDIEM.am : MERIDIEM.pm);
	const [timeValue, setTime] = useState<any>();

	const timeZoneStore = useSelector((state: RootState) => state.User.profile.data.timezone);

	const currentDateInTimeZone = new Date().toLocaleString('en-US', { timeZone: timeZoneStore });
	const minDate = new Date(currentDateInTimeZone);
	const styles = {
		overrideHeader: {
			color: theme.colors.modalTextColor,
			fontWeight: '600 !important',
			fontSize: '1.25rem !important',
			lineHeight: '1.25rem !important',
			marginLeft: '0rem !important',
			marginRight: '0rem!important',
			marginTop: '0rem !important',
			marginBottom: '0rem !important',
		},
		wrapper: {
			display: 'flex',
			flexDirection: 'column',
			backgroundColor: theme.colors.primaryB,
			paddingLeft: '0.5rem',
			paddingRight: '0.9375rem',
			borderBottomLeftRadius: '0.5rem',
			borderBottomRightRadius: '0.5rem',
			borderTopRightRadius: '0.5rem',
			borderTopLeftRadius: '0.5rem',
		},
		para: {
			fontWeight: '600',
			textAlign: 'center',
		},
	};

	const decrementHours = () => {
		if (hours === 1) {
			setHours(12);
		} else {
			setHours(hours - 1);
		}
	};

	const incrementHours = () => {
		if (hours === 12) {
			setHours(1);
		} else {
			setHours(hours + 1);
		}
	};

	const decrementMinutes = () => {
		if (minutes === 0) {
			setMinutes(59);
		} else {
			setMinutes(minutes - 1);
		}
	};

	const incrementMinutes = () => {
		if (minutes === 59) {
			setMinutes(0);
		} else {
			setMinutes(minutes + 1);
		}
	};

	const getButtonStyle = (meridiem: string) => ({
		BaseButton: {
			style: {
				marginTop: '0.5rem',
				backgroundColor: getButtonBackgroundColor(meridiem),
				':hover': {
					backgroundColor: getButtonBackgroundColor(meridiem),
				},
			},
		},
	});

	const getDateObjectTime = () => {
		const dateObjectTime = dateValue ? new Date(dateValue) : new Date();

		const adjustedHours = meridiem === MERIDIEM.pm ? hours + 12 : hours;

		dateObjectTime.setHours(adjustedHours);
		dateObjectTime.setMinutes(minutes);

		return dateObjectTime;
	};

	const getButtonBackgroundColor = (currentMeridiem: string) =>
		meridiem === currentMeridiem ? theme.colors.accent100 : 'transparent';

	useEffect(() => {
		setPaddedHours(hours < 10 ? `0${hours}` : hours.toString());
	}, [hours]);

	useEffect(() => {
		setDateValue(() => {
			const currentDate = new Date();
			const options = { timeZone: timeZoneStore };

			return new Date(currentDate.toLocaleString('en-US', options));
		});
	}, []);

	useEffect(() => {
		setPaddedMinutes(minutes < 10 ? `0${minutes}` : minutes.toString());
	}, [minutes]);

	useEffect(() => {
		const displayTime = `${paddedHours}:${paddedMinutes} ${meridiem}`;
		setTime(displayTime);
	}, [paddedHours, paddedMinutes, meridiem]);

	useEffect(() => {
		const dateObjectTime = getDateObjectTime();

		if (typeof dateValue === 'object' && dateValue.getTime() !== dateObjectTime.getTime()) {
			const date = new Date(dateValue);
			const hrs = date.getHours() % 12 || 12;

			const updatedMeridiem = meridiem;

			setHours(hrs);
			setMinutes(date.getMinutes());
			setMeridiem(updatedMeridiem);
		}
	}, [dateValue, meridiem]);

	return (
		<ActionModal
			isOpen={openDateTime}
			onClose={() => {
				toggelDateTime(false);
				setDateValue();
			}}
			onConfirm={() => {
				const dateObjectTime = getDateObjectTime();
				setDateValue(dateObjectTime);
				toggelDateTime(false);
			}}
			heading={t('pages:broadcast.messageTemplates.createTemplate.lto.OfferExpiryText2')}
			overridHeaderCss={styles.overrideHeader}
		>
			<LabelXSmall>
				{t('pages:configure.liveChat.setup.timeZoneDescription', {
					zone: timeZoneStore,
				})}
				<NavLink
					to='/account-settings/region'
					className={css({
						textDecoration: 'underline',
						color: `${theme.colors.accent50} !important`,
						display: 'inline-flex',
						alignItems: 'center',
						justifyContent: 'center',
						fontWeight: 400,
						fontSize: '0.875rem',
					})}
				>
					{t('common:locations.navigation.regionSettings')}
				</NavLink>
			</LabelXSmall>
			<div
				className={css({
					display: 'flex',
					width: '100%',
				})}
			>
				<DefaultDatePicker
					value={dateValue}
					onChange={(newDate) => {
						if (newDate instanceof Date && !isNaN(newDate.getTime())) {
							const updatedDate = new Date(newDate);
							if (timeValue) {
								const [hours, minutes] = timeValue.split(':');
								updatedDate.setHours(parseInt(hours), parseInt(minutes), 0, 0);
							} else {
								updatedDate.setHours(5, 30, 0, 0);
							}
							setDateValue(updatedDate);
						}
					}}
					minDate={minDate}
					isNewFilter
					isPickerRanged={false}
				/>

				<div
					className={css({
						display: 'flex',
						alignSelf: 'center',
					})}
				>
					<div className={css(styles.wrapper)}>
						<div className={css({ display: 'flex' })}>
							<div>
								<Button onClick={() => incrementHours()} kind={KIND.tertiary} size={SIZE.mini}>
									<TriangleUp size={24} />
								</Button>
								<ParagraphMedium className={css(styles.para)} marginTop={0} marginBottom={0}>
									{paddedHours}
								</ParagraphMedium>
								<Button onClick={() => decrementHours()} kind={KIND.tertiary} size={SIZE.mini}>
									<TriangleDown size={24} />
								</Button>
							</div>
							<div>
								<Button onClick={() => incrementMinutes()} kind={KIND.tertiary} size={SIZE.mini}>
									<TriangleUp size={24} />
								</Button>
								<ParagraphMedium className={css(styles.para)} marginTop={0} marginBottom={0}>
									{paddedMinutes}
								</ParagraphMedium>
								<Button onClick={() => decrementMinutes()} kind={KIND.tertiary} size={SIZE.mini}>
									<TriangleDown size={24} />
								</Button>
							</div>
						</div>
						<div
							className={css({
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'center',
								borderTopWidth: '0.063rem',
								borderTopStyle: 'solid',
								borderTopColor: theme.colors.accent100,
							})}
						>
							<Button
								onClick={() => setMeridiem(MERIDIEM.am)}
								shape={SHAPE.circle}
								overrides={getButtonStyle(MERIDIEM.am)}
								kind={KIND.tertiary}
								size={SIZE.compact}
							>
								{t('components:timepicker.am')}
							</Button>
							<Button
								onClick={() => setMeridiem(MERIDIEM.pm)}
								shape={SHAPE.circle}
								overrides={getButtonStyle(MERIDIEM.pm)}
								kind={KIND.tertiary}
								size={SIZE.compact}
							>
								{t('components:timepicker.pm')}
							</Button>
						</div>
					</div>
				</div>
			</div>
		</ActionModal>
	);
};

export default RenderDateTimePicker;
