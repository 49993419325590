import axios from 'axios';

import {
	ENGATI_RESPONSE_CODE,
	ENGATI_ROLES,
	GROUP_CONVERSATION_STATES,
	MESSAGE_STATE_MAP,
	MESSAGE_TAB_HEADERS_MAP,
	ROLE_SCOPE,
	SENTIMENT_ATTRIBUTE_LABEL,
	SMS_SOLR_QUERY,
	WHATSAPP_PLATFORMS,
	WHATSAPP_PLATFORMS_CAPS,
	WHATSAPP_SOLR_QUERY,
} from 'shared/consts/consts';
import { deleteFromLs, getUniqueTabLSKey, LS_KEYS, readFromLs, writeToLs } from 'shared/consts/localStorageConsts';
import { IMessageFilters, IObjProps } from 'shared/consts/types';
import { CHANNELS, messageSort, messageSortAllScreen } from 'shared/enum';
import { getDateRangedMorningTillNight, getProfileColor, isEmpty, isValidResponseObject } from 'shared/helpers';

import { setAgentOnlineStatusAction } from 'store/App/Bot';
import { jitsiActions } from 'store/App/Jitsi';
import { removeNotificationAction } from 'store/App/Notifications';
import { API } from 'store/Broadcast/api';
import { openErrorSavingModalAction } from 'store/Modal';
import { API as pathBuilderApi } from 'store/PathBuilder/api';
import { RootState } from 'store/rootReducer';
import { currentTab } from 'store/Socket';

import {
	fetchAllUsersCountApiAction,
	getAllUsersCountAction,
	getFacetsSortFromLs,
	getLiteQuickFiltersFromLS,
	getUserMessagesAction,
	setConversationReadStatusAction,
	setFacetsSortInLs,
} from '../actions';
import { actions } from '../store';

import { messageListAPI } from './api';

const {
	getAvailableAgents,
	getAllTabAvailableAgents,
	getAgentsByCategory,
	getAvailableAgentsLoading,
	getLiveChatUsers,
	getLiveChatUsersError,
	getLiveChatUsersLoading,
	setAllUsersCount,
	setMessageCount,
	setConversationReadStatus,
	getUsersSolarQuery,
	updateAllUsersCount,
	setSelectedUser,
	setUserList,
	setPathList,
	setBotLevelTagsList,
	setSentimentData,
	setSentimentAttributeData,
	setAgentData,
	setUserLevelTags,
} = actions;

const getCurrentStatus = (tab: string, statusSelected: Array<any>) => {
	const status = statusSelected.map((status: any) => status.id);

	if (MESSAGE_TAB_HEADERS_MAP.new === tab) {
		return ['ASSIGNED'];
	}
	if (MESSAGE_TAB_HEADERS_MAP.unassigned === tab) {
		return ['REQUESTED'];
	}

	return status.length > 0 ? status : ['ENGAGING', 'PENDING_RESOLUTION'];
};

const getParticipantState = (tab: string) => {
	if (MESSAGE_TAB_HEADERS_MAP.new === tab) {
		return ['INVITED'];
	}
	if (MESSAGE_TAB_HEADERS_MAP.active === tab) {
		return ['JOINED'];
	}

	return null;
};
const isConversationActiveInLiveChat = (state: string) =>
	state === MESSAGE_STATE_MAP.ASSIGNED ||
	state === MESSAGE_STATE_MAP.ENGAGING ||
	state === MESSAGE_STATE_MAP.PENDING_RESOLUTION;

const setStartConversation = (user: any, isSupervisor: boolean, state: RootState) => {
	const { uid } = state.User.botAdmin.data;
	const { actor_id, current_state, is_group_conversation, is_tagged, show_start_conversation } = user;
	let showStartConversation = show_start_conversation;
	const isConversationOngoing = ['ENGAGING', 'PENDING_RESOLUTION', 'ASSIGNED'].indexOf(current_state) > -1;

	if (isSupervisor) {
		if (actor_id) {
			if (isConversationOngoing && actor_id === uid) {
				showStartConversation = false;
			}
		}
	} else if (isConversationOngoing) {
		showStartConversation = is_tagged && !is_group_conversation;
	}

	return showStartConversation;
};
const setStartConversationForWAChannel = (user: any) => {
	let showStartConversation = false;
	if (user.last_active_at) {
		const currentTime: any = new Date();
		const lastActiveTime: any = new Date(user.last_active_at);
		const diffTime: any = Math.abs(currentTime - lastActiveTime);
		const diffDays = diffTime / (1000 * 60 * 60 * 24);
		if (diffDays < 1) {
			showStartConversation = true;
		}
	}

	return showStartConversation;
};

const hasAgentJoinedConversation = (participants: Array<IObjProps>, uid: number) => {
	const { joined } = GROUP_CONVERSATION_STATES;
	for (let i = 0; i < participants.length; i++) {
		if (participants[i].state === joined && participants[i].actorId === uid) {
			return true;
		}
	}

	return false;
};

const setJoinConversation = (user: any, isSupervisor: boolean, state: RootState) => {
	const { uid } = state.User.botAdmin.data;
	const { ENGAGING, PENDING_RESOLUTION } = MESSAGE_STATE_MAP;
	const isLivechatAdvancedFeatureEnabled = state.Bot.configData.enable_livechat_advanced_features;
	const {
		is_tagged: isTagged,
		platform,
		current_state: currentState,
		actor_id: actorId,
		participant_details: participantDetails,
	} = user;
	const isActiveConversation = [ENGAGING, PENDING_RESOLUTION].indexOf(currentState) > -1;
	const participantLimitReached = Array.isArray(participantDetails) && participantDetails.length >= 3;
	const hasAgentJoined = hasAgentJoinedConversation(Array.isArray(participantDetails) ? participantDetails : [], uid);
	if (
		(isSupervisor || isTagged) &&
		(platform === 'web' || platform === 'mobile') &&
		isActiveConversation &&
		actorId !== uid &&
		isLivechatAdvancedFeatureEnabled &&
		!participantLimitReached &&
		!hasAgentJoined
	) {
		return true;
	}

	return false;
};

export const sortUsersByUnreadCount = (usersToSort: Array<any>, unreadMap: IObjProps) => {
	const users = [...usersToSort];

	return users.sort((userA, userB) => {
		const userACount = unreadMap[userA.conversation_id] || 0;
		const userBCount = unreadMap[userB.conversation_id] || 0;

		return userBCount - userACount;
	});
};

export const sortUsersByRecentFirst = (usersToSort: Array<any>, conversationId: string, timestamp: string) => {
	const users = [...usersToSort];
	let index = -1;

	const filteredUsers = users.filter((user, i: number) => {
		if (user.conversation_id === conversationId) {
			index = i;
		}

		return user.conversation_id !== conversationId;
	});
	if (index > -1) {
		filteredUsers.unshift(users[index]);
	}

	return filteredUsers;
};

export const updateLastActiveAt = (users: Array<IObjProps>, conversationId: string, timestamp: string) => {
	const updatedUsers = [...users];
	for (let i = 0; i < updatedUsers.length; i++) {
		if (updatedUsers[i].conversation_id === conversationId && timestamp) {
			updatedUsers[i] = { ...updatedUsers[i] };
			updatedUsers[i].last_active_at = timestamp;
			updatedUsers[i].last_message_time = timestamp;
			break;
		}
	}

	return updatedUsers;
};

const allUserCountAction = (data: any, dispatch: any, isSimplifiedOneViewEnabled?: boolean, cancelToken?: any) => {
	const usersData = data;
	usersData.content.map((user: IObjProps) => {
		user.profileColor = getProfileColor(user.id || user.global_user_id || '0');
	});
	if (usersData.content.length > 0) {
		dispatch(
			getUserMessagesAction({
				usersData,
				isUserMessagesDataAppended: false,
				isUserMessagesDataPrepended: false,
				fetchOnlyLastMessage: true,
				isSolrQuery: true,
				cancelToken,
			})
		);
	} else {
		dispatch(getUsersSolarQuery(usersData));
	}
	!isSimplifiedOneViewEnabled &&
		dispatch(
			getAllUsersCountAction({
				all: usersData?.totalElements,
			})
		);
};

let agentCategories: Array<any> = [];
let agentChannels: Array<any> = [];

export const messageListActions = {
	toggleOnlineStatus: (isOnline: boolean) => (dispatch: any, getState: () => RootState) => {
		const params = {
			targetApi: 'toggleStatus',
		};
		const body = {
			bot_ref: '{{botRef}}',
			agent_id: '{{userId}}',
			is_online: isOnline,
			module: getState().User.botAdmin.data.active_module,
			cid: '{{customerId}}',
		};
		messageListAPI.setAgentOnlineStatus(params, body).then((resp: any) => {
			if (isValidResponseObject(resp)) {
				dispatch(setAgentOnlineStatusAction(isOnline));
			} else {
				dispatch(openErrorSavingModalAction());
			}
		});
	},

	toggleAgentStatus: (isOnline: boolean) => (dispatch: any, getState: () => RootState) => {
		dispatch(setAgentOnlineStatusAction(isOnline));
	},

	setAllAvailableAgentsAction: (data: any) => (dispatch: any) => {
		dispatch(getAllTabAvailableAgents(data));
	},

	getAvailableAgentsAction:
		(isAssignPath: any, categoryId?: any, channel?: any, successCB?: Function) => (dispatch: any) => {
			dispatch(getAvailableAgentsLoading());

			const body = {
				categoryId: categoryId || null,
				channel: channel || null,
				isAssignPath,
			};

			messageListAPI.getAvailableAgents(body).then((response: any) => {
				if (response.data && response.data.status && response.data.status.code === 1000) {
					isAssignPath
						? dispatch(getAllTabAvailableAgents(response.data.responseObject))
						: dispatch(getAvailableAgents(response.data.responseObject));
					successCB && successCB(response.data.responseObject);
				}
			});
		},
	getAgentsOnlineByCategoryAction: (categoryId: number, channel: any) => (dispatch: any) => {
		dispatch(getAvailableAgentsLoading());

		const body = {
			categoryId: categoryId || null,
			channel: channel || null,
			isAssignPath: false,
		};

		messageListAPI.getAvailableAgents(body).then((response: any) => {
			if (isValidResponseObject(response)) {
				const agentsList = response.data.responseObject;
				const autoAssignAgentObject = {
					email: '',
					is_online: false,
					profile_image: null,
					uid: null,
					user_name: 'Auto Assign',
				};
				agentsList.unshift(autoAssignAgentObject);

				dispatch(getAgentsByCategory(agentsList));
			}
		});
	},
	setAvailableAgentsAction: (data: any) => (dispatch: any) => {
		dispatch(getAvailableAgents(data));
	},
	getFacetsSortFromLs: (tab: string) => (dispatch: any, getState: () => RootState) =>
		JSON.parse(
			localStorage.getItem(`${tab}_${getState().Bot.data.bot_ref}_${getState().User.botAdmin.data.uid}`) || '{}'
		),
	getMessageSearchTagFromLs: () => (dispatch: any, getState: () => RootState) =>
		JSON.parse(
			localStorage.getItem(
				`message_search_tag_${getState().Bot.data.bot_ref}_${getState().User.botAdmin.data.uid}`
			) || '{}'
		),
	getMessageSearchQueryFromLs: () => (dispatch: any, getState: () => RootState) =>
		localStorage.getItem(
			`message_search_query_${getState().Bot.data.bot_ref}_${getState().User.botAdmin.data.uid}`
		) || '',

	setMessageSearchQueryToLs: (data: any) => (dispatch: any, getState: () => RootState) =>
		localStorage.setItem(
			`message_search_query_${getState().Bot.data.bot_ref}_${getState().User.botAdmin.data.uid}`,
			data
		),
	setShortcutQuickFilterToLs: (tab: string, data: any) => (dispatch: any, getState: () => RootState) =>
		writeToLs(
			getUniqueTabLSKey(
				LS_KEYS.SHORTCUT_QUICK_FILTERS,
				tab,
				getState().Bot.data.bot_ref,
				getState().User.botAdmin.data.uid
			),
			data
		),
	getShortcutQuickFilterFromLs: (tab: string) => (dispatch: any, getState: () => RootState) =>
		readFromLs(
			getUniqueTabLSKey(
				LS_KEYS.SHORTCUT_QUICK_FILTERS,
				tab,
				getState().Bot.data.bot_ref,
				getState().User.botAdmin.data.uid
			)
		),
	removeShortcutQuickFilterFromLs: (tab: string) => (dispatch: any, getState: () => RootState) =>
		deleteFromLs(
			getUniqueTabLSKey(
				LS_KEYS.SHORTCUT_QUICK_FILTERS,
				tab,
				getState().Bot.data.bot_ref,
				getState().User.botAdmin.data.uid
			)
		),
	fetchAllUsersCountApiAction: (countFromParentApi: IObjProps) => (dispatch: any, getState: () => RootState) => {
		const { all } = MESSAGE_TAB_HEADERS_MAP;
		const { facets }: any = dispatch(getFacetsSortFromLs(all));

		const { query } = dispatch(messageListActions.getGeneratedSolrQuery(facets));

		messageListAPI.getAllUsersCount(query).then((response: any) => {
			if (response.data && response.data.responseObject) {
				const {
					allConversationCount,
					newConversationCount,
					unassignedConversationCount,
					activeConversationCount,
				} = response.data.responseObject;

				const data = {
					...countFromParentApi,
					active: activeConversationCount,
					all: allConversationCount,
					new: newConversationCount,
					unassigned: unassignedConversationCount,
				};

				dispatch(messageListActions.setAllUserCountAction(data));
			} else {
				console.error('Users count api');
			}
		});
	},
	setAllUserCountAction: (data: IObjProps) => (dispatch: any, getState: () => RootState) => {
		dispatch(setAllUsersCount(data));
	},
	getAllUsersCountAction: (countFromParentApi: IObjProps) => (dispatch: any, getState: () => RootState) => {
		const oldCount = getState().Message.usersCount;

		Object.keys(oldCount).length < 3
			? dispatch(fetchAllUsersCountApiAction(countFromParentApi))
			: dispatch(
					messageListActions.setAllUserCountAction({
						...oldCount,
						...countFromParentApi,
					})
				);
	},
	getMessagesSearchData:
		(
			searchQuery: string,
			selectedtags: any,
			loadingToChange: Function,
			successCB: Function,
			errorCb: Function,
			pageNumber: number
		) =>
		() => {
			if (searchQuery.length <= 1) {
				loadingToChange();

				return;
			}

			const params: any = {
				botRef: '{{botRef}}',
				searchQuery,
				size: 25,
				page: pageNumber - 1,
				userId: '{{userId}}',
				searchUsers: selectedtags === 'Users' || !selectedtags,
				searchMessages: selectedtags === 'Messages' || !selectedtags,
				targetApi: 'searchConversations',
			};

			messageListAPI.getSearchUsersAndMessages(params).then((response: any) => {
				loadingToChange();
				if (response?.data?.status?.code === ENGATI_RESPONSE_CODE.STATUS_1000) {
					successCB(response.data.responseObject);
				} else if (response?.data?.status?.code !== 10006) {
					errorCb();
				}
			});
		},

	getLiveChatUsersAction:
		({ facets = {}, sort }: IMessageFilters, pageNo = 0, tab = '', cancelSignalToken: IObjProps = {}) =>
		(dispatch: any, getState: () => RootState) => {
			dispatch(getLiveChatUsersLoading());

			const quickFilters = dispatch(getLiteQuickFiltersFromLS());

			const params = {
				page: pageNo - 1,
				size: getState().Bot.data.conversationLoadCount,
				sort: sort === messageSort.unreadDesc ? messageSort.createdAtAsc : sort,
				targetApi: 'getLiveChatUsers',
			};

			const body = {
				...dispatch(messageListActions.getFacetsData(facets, tab)),
				filter_on_unread_messages: !!quickFilters?.unreadMessages,
			};
			if (getState().Message.showPriorityQuickFilter) {
				Object.assign(body, { filter_on_priority: quickFilters?.priority });
			}

			try {
				messageListAPI
					.getLiveChatUsers(params, body, { cancelToken: cancelSignalToken?.users })
					.then((response: any) => {
						if (isValidResponseObject(response)) {
							const usersData = response.data.responseObject;
							const conversationReadStatusMap: IObjProps = {};
							if (
								sort === messageSort.unreadDesc &&
								Array.isArray(usersData.content) &&
								usersData.content.length
							) {
								usersData.content = sortUsersByUnreadCount(
									usersData.content,
									getState().Message.messageCountMap
								);
							}
							usersData.content.map((user: IObjProps) => {
								user.profileColor = getProfileColor(user.id || user.global_user_id || '0');
								user.showChatWindowLimitClosed = false;
								user.showNoDirectMessageBanner = false;
								if (isConversationActiveInLiveChat(user.current_state)) {
									conversationReadStatusMap[user.conversation_id] = user.is_conversation_read;
								}
							});
							if (usersData.content.length > 0) {
								dispatch(
									getUserMessagesAction({
										usersData,
										isUserMessagesDataAppended: false,
										fetchOnlyLastMessage: true,
										isUserMessagesDataPrepended: false,
										cancelToken: cancelSignalToken?.messages,
									})
								);
							} else {
								dispatch(getLiveChatUsers(usersData));
							}
							if (tab) {
								usersData.tab = tab;
								dispatch(
									getAllUsersCountAction({
										[tab]: usersData?.totalElements,
									})
								);
							}
							dispatch(setConversationReadStatusAction(conversationReadStatusMap));
						} else {
							dispatch(getLiveChatUsersError());
						}
					});
			} catch (err: any) {
				if (axios.isCancel(err)) {
					console.log('Cancelled users list API since tab changed: ', err.message); // => prints: Api is being canceled
				} else {
					dispatch(getLiveChatUsersError());
				}
			}
		},
	getNotificationClickUserAction:
		(conversationId: any, tab: any, cancelSignalToken: IObjProps = {}) =>
		(dispatch: any, getState: () => RootState) => {
			const filters: any = dispatch(getFacetsSortFromLs(tab));
			const MessageFilters = {
				facets: {
					username: null,
					email: null,
					conversation_id: conversationId,
				},
				sort: filters.sort,
			};
			dispatch(getLiveChatUsersLoading());

			const quickFilters = dispatch(getLiteQuickFiltersFromLS());

			const body = {
				...dispatch(messageListActions.getFacetsData(MessageFilters.facets, tab)),
				filter_on_unread_messages: !!quickFilters?.unreadMessages,
			};
			if (getState().Message.showPriorityQuickFilter) {
				Object.assign(body, { filter_on_priority: quickFilters?.priority });
			}

			const processResponse = (response: any) => {
				if (isValidResponseObject(response)) {
					const usersData = response.data.responseObject;
					const conversationReadStatusMap: IObjProps = {};
					if (
						MessageFilters.sort === messageSort.unreadDesc &&
						Array.isArray(usersData.content) &&
						usersData.content.length
					) {
						usersData.content = sortUsersByUnreadCount(
							usersData.content,
							getState().Message.messageCountMap
						);
					}
					usersData.content.map((user: IObjProps) => {
						user.profileColor = getProfileColor(user.id || user.global_user_id || '0');
						user.showChatWindowLimitClosed = false;
						user.showNoDirectMessageBanner = false;
						if (isConversationActiveInLiveChat(user.current_state)) {
							conversationReadStatusMap[user.conversation_id] = user.is_conversation_read;
						}
					});
					if (usersData.content.length > 0) {
						dispatch(
							getUserMessagesAction({
								usersData,
								isUserMessagesDataAppended: false,
								fetchOnlyLastMessage: true,
								isUserMessagesDataPrepended: false,
								cancelToken: cancelSignalToken?.messages,
							})
						);
					} else {
						dispatch(getLiveChatUsers(usersData));
					}
					if (tab) {
						usersData.tab = tab;
						dispatch(
							getAllUsersCountAction({
								[tab]: usersData?.totalElements,
							})
						);
					}
					dispatch(setConversationReadStatusAction(conversationReadStatusMap));
				}
			};

			let params = {
				page: 0,
				size: 10,
				sort: MessageFilters.sort,
				targetApi: 'getLiveChatUsers',
			};

			if ('solrQuery' in body && tab === 'all') {
				params = { ...params, targetApi: 'getUsersBySolrQuery', ...body };
				API.getUsersBySolrQuery(params).then((response: any) => {
					processResponse(response);
				});
			} else {
				messageListAPI
					.getLiveChatUsers(params, body, { cancelToken: cancelSignalToken?.users })
					.then((response: any) => {
						processResponse(response);
					});
			}
		},
	subSetSelectedUserAction:
		(object: IObjProps, removeNotification = true) =>
		(dispatch: any) => {
			if (object.conversation_id && removeNotification) {
				dispatch(removeNotificationAction('CONVERSATION_ID', object.conversation_id));
			}
		},
	setSelectedUserAction:
		(object: IObjProps, removeNotification = true) =>
		(dispatch: any) => {
			if (object.conversation_id && removeNotification) {
				dispatch(removeNotificationAction('CONVERSATION_ID', object.conversation_id));
			}
			dispatch(setSelectedUser(object));
		},
	getFacetsData: (facets: IMessageFilters['facets'], tab: string) => (dispatch: any, getState: () => RootState) => {
		const {
			channelsSelected = [],
			dateSelected = [],
			statusSelected = [],
			username,
			email,
			conversation_id,
			slaFilterSelected,
			conversationClosingTime,
		} = facets;
		let facetsDTO: IObjProps = {};
		const quickFilters: any = dispatch(getLiteQuickFiltersFromLS());
		const { showPriorityQuickFilter } = getState().Message;

		const { roleScope, isAgent, isSupervisor } = dispatch(messageListActions.getAgentRoleInformation());

		const isAgentAndCategoryConversation =
			(isAgent || isSupervisor) && roleScope === ROLE_SCOPE.CATEGORY_CONVERSATIONS;

		facetsDTO = {
			agent_id: '{{userId}}',
			bot_ref: '{{botRef}}',
			participant_state: tab ? getParticipantState(tab) : ['INVITED', 'JOINED'] || null,
			filter_on_agent_id: tab
				? MESSAGE_TAB_HEADERS_MAP.unassigned !== tab
				: !(showPriorityQuickFilter && quickFilters?.priority && !quickFilters.myConversations),
			platform: channelsSelected.length ? channelsSelected.map((channel: any) => channel.id) : null,
			from_date: dateSelected[0] || null,
			to_date: new Date(dateSelected[1]).setHours(23, 59, 59) || null,
			current_state: tab
				? getCurrentStatus(tab, statusSelected)
				: showPriorityQuickFilter && quickFilters?.priority && !quickFilters.myConversations
					? ['ASSIGNED', 'ENGAGING', 'PENDING_RESOLUTION', 'REQUESTED'] || null
					: ['ASSIGNED', 'ENGAGING', 'PENDING_RESOLUTION'] || null,
			username: username || null,
			email: email || null,
			conversation_id: conversation_id || null,
			window_duration_left: conversationClosingTime
				? parseInt(conversationClosingTime[0]) * 60 * 60 * 1000
				: null,
		};
		if (slaFilterSelected && slaFilterSelected?.length > 0) {
			const sla_filter: string[] = [];
			slaFilterSelected.forEach((item: any) => {
				if (item.label === 'First Response') {
					sla_filter.push('SLA_FIRST_RESPONSE');
				} else if (item.label === 'Resolution') {
					sla_filter.push('SLA_RESOLUTION');
				}
			});
			facetsDTO = {
				...facetsDTO,
				...{
					filter_on_sla: sla_filter,
				},
			};
		}

		if (isAgentAndCategoryConversation) {
			const exisingPlatformsInFacetsDTO = facetsDTO?.platform;
			const agentPlatforms = getState().Message.agent.channels;
			const configuredPlatforms = exisingPlatformsInFacetsDTO || agentPlatforms;

			facetsDTO = {
				...facetsDTO,
				...{
					platform: configuredPlatforms,
					catergory_id: getState().Message.agent.categories,
				},
			};
		}

		if (MESSAGE_TAB_HEADERS_MAP.all === tab) {
			const { query, isTagged } = dispatch(messageListActions.getGeneratedSolrQuery(facets));

			return {
				solrQuery: query,
				isTaggedUsers: isTagged,
			};
		}

		return facetsDTO;
	},
	getGeneratedSolrQuery:
		(facets: IObjProps = {}) =>
		(dispatch: any, getState: () => RootState) => {
			const {
				channelsSelected = [],
				dateSelected = [],
				agentConversationsFilter = [],
				lastMessageFilter = [],
				managedByFilter = [],
				slaFilterSelected = [],
				statusSelected = [],
				intentsSelected = [],
				tagsSelected = [],
				sentimentsSelected = [],
				customFilterSelected = {},
				username,
				email,
				sentimentAttributeId,
				conversationClosingTime,
			} = facets;
			const queryArray: string[] = [];
			let slaArray: string[] = [];
			const channelsArray: string[] = [];
			let statusArray: string[] = [];
			let intentsArray: string[] = [];
			let tagsArray: string[] = [];
			let sentimentsArray: string[] = [];
			const agentConversationsArray: string[] = [];
			let lastMessageArray: string[] = [];
			let managedByArray: string[] = [];
			let isTaggedUsers = true;
			const state = getState();

			const isAgent = state.User.profile.data.roleName === ENGATI_ROLES.ROLE_CONNECT_AGENT;
			const isCustomFilterApplied = customFilterSelected?.isChecked;
			const isLiveChatUser = state.User.botAdmin.data?.customerEngagePlan === 'ENGATI_LIVECHAT';
			const isViewAllBotconvAllowed = state.User.security?.data?.is_view_all_bot_conversations_allowed;
			const uid = state.User.botAdmin.data?.uid;
			const botRef = state.Bot.data?.bot_ref;

			const isLowercaseSearchEnabled = state.User.botAdmin.data.is_lowercase_search_enabled;
			const { showPriorityQuickFilter } = state.Message;

			const quickFilters: any = dispatch(getLiteQuickFiltersFromLS());
			if (isCustomFilterApplied) {
				queryArray.push(customFilterSelected.solrQuery);
			}
			if (username) {
				let userName = facets.username?.trim()?.replaceAll(' ', ' AND ');
				userName = isLowercaseSearchEnabled ? userName.toLowerCase() : userName;
				let solrQuery: string;
				if (!isNaN(userName)) {
					solrQuery = `(phone_no_ci:*${userName}*)`;
				} else {
					userName = `(${userName}*)`;
					solrQuery = `((username:${userName}) OR (first_name:${userName}) OR (last_name:${userName}) OR (email:${userName.replaceAll(
						'@',
						' AND '
					)}))`;
				}
				queryArray.push(solrQuery);
			}
			if (email) {
				let escapedEmail = email?.trim()?.replaceAll(' ', '\\ ');
				escapedEmail = isLowercaseSearchEnabled ? escapedEmail.toLowerCase() : escapedEmail;
				queryArray.push(
					`((username:${escapedEmail}*) OR (first_name:${escapedEmail}*) OR (last_name:${escapedEmail}*) OR (email:(${escapedEmail.replaceAll(
						'@',
						' AND '
					)}*)))`
				);
			} else {
				if (channelsSelected.length) {
					channelsSelected.map((channel: any) => {
						channel.id === 'whatsapp'
							? channelsArray.push(WHATSAPP_SOLR_QUERY)
							: channelsArray.push(`platform:${channel.id.toUpperCase()}`);

						return 0;
					});
					channelsSelected.map((channel: any) => {
						channel.id === 'sms'
							? channelsArray.push(SMS_SOLR_QUERY)
							: channelsArray.push(`platform:${channel.id.toUpperCase()}`);

						return 0;
					});
					channelsArray.length && queryArray.push(`(${channelsArray.join(' OR ')})`);
				}
				if (slaFilterSelected?.length) {
					slaArray = slaFilterSelected.map((sla: any) => sla.id);
					slaArray.length && queryArray.push(`(${slaArray.join(' OR ')})`);
				}
				if (statusSelected?.length) {
					statusArray = statusSelected.map((status: any) => status.id);
					statusArray.length && queryArray.push(`(${statusArray.join(' OR ')})`);
				}
				if (intentsSelected?.length) {
					intentsArray = intentsSelected.map((intent: any) => `intents:${intent.id}`);
					intentsArray.length && queryArray.push(`(${intentsArray.join(' OR ')})`);
				}
				if (tagsSelected?.length) {
					tagsArray = tagsSelected.map((tag: any) => `tags:"${tag.label}"`);
					tagsArray.length && queryArray.push(`(${tagsArray.join(' OR ')})`);
				}
				if (sentimentsSelected?.length && sentimentAttributeId) {
					sentimentsArray = sentimentsSelected.map(
						(sentiment: any) => `attributes_text_${sentimentAttributeId}:${sentiment.id}`
					);
					sentimentsArray.length && queryArray.push(`(${sentimentsArray.join(' OR ')})`);
				}
				if (agentConversationsFilter?.length) {
					const assignedToArray = agentConversationsFilter.map((agent: any) => `assigned_to:${agent.id}`);
					const livechatEngagedByArray = agentConversationsFilter.map(
						(agent: any) => `livechat_engaged_by:${agent.id}`
					);

					agentConversationsArray.push(
						`(${assignedToArray.join(' OR ')})`,
						`(${livechatEngagedByArray.join(' OR ')})`
					);
					agentConversationsArray.length && queryArray.push(`(${agentConversationsArray.join(' OR ')})`);
				}
				if (managedByFilter?.length) {
					managedByArray = managedByFilter.map((managedBy: any) => `managed_by:${managedBy.id}`);
					managedByArray.length && queryArray.push(`(${managedByArray.join(' OR ')})`);
				}
				if (lastMessageFilter?.length) {
					lastMessageArray = lastMessageFilter.map(
						(lastMessage: any) => `last_message_type:${lastMessage.id}`
					);
					lastMessageArray.length && queryArray.push(`(${lastMessageArray.join(' OR ')})`);
				}
				if (quickFilters?.myConversations && quickFilters?.starredConversations) {
					queryArray.push(`((assigned_to:${uid}) OR (livechat_engaged_by:${uid})) AND (starred_by:${uid})`);
				} else if (quickFilters?.starredConversations) {
					queryArray.push(`(starred_by:${uid})`);
				}
				if (dateSelected[0] && dateSelected[1]) {
					try {
						const { from, to } = getDateRangedMorningTillNight(dateSelected[0], dateSelected[1]);

						queryArray.push(`created_at:[${from}/HOUR TO ${to}/HOUR}`);
					} catch (e) {
						console.error(`Error in parsing message filter facets${e}`);
					}
				}

				if (isAgent && isLiveChatUser && !isViewAllBotconvAllowed) {
					queryArray.unshift(`(bot_ref:${botRef} AND managed_by:${uid})`);
				}
			}
			if (showPriorityQuickFilter && quickFilters.priority) {
				queryArray.push(`((cs_request_priority:10) OR (cs_request_priority:20) OR (cs_request_priority:30))`);
			}
			if (conversationClosingTime) {
				const startDate = new Date();
				const endDate = new Date(
					new Date().setHours(startDate.getHours() + parseInt(conversationClosingTime[0]))
				);
				const formattedStartDate = `${startDate.toISOString().slice(0, -1)}Z`;
				const formattedEndDate = `${endDate.toISOString().slice(0, -1)}Z`;
				try {
					queryArray.push(`window_closing_time:[${formattedStartDate} TO ${formattedEndDate}]`);
				} catch (e) {
					console.error(`Error in parsing message filter facets${e}`);
				}
			}
			if (queryArray.length) {
				isTaggedUsers = false;
			}

			if (isAgent && isLiveChatUser && !isViewAllBotconvAllowed) {
				isTaggedUsers = true;
			}

			return {
				query: dispatch(messageListActions.getQueryFromSecurityConfig(queryArray.join(' AND ') || '')),
				isTagged: isTaggedUsers,
			};
		},
	getQueryFromSecurityConfig: (solrQuery: string) => (dispatch: any, state: () => RootState) => {
		const { roleScope, isAgent, isSupervisor } = dispatch(messageListActions.getAgentRoleInformation());
		const categoryChannelQuery = [];

		agentCategories =
			!agentCategories || agentCategories.length === 0 ? state().Message.agent.categories : agentCategories;

		agentChannels =
			!agentChannels || agentChannels.length === 0
				? state().Message.agent.channels?.map((item: any) => item.toString().toUpperCase())
				: agentChannels;

		if (agentCategories?.length) {
			categoryChannelQuery.push(`category_id:(${agentCategories?.join(' OR ')})`);
		}
		if (agentChannels?.length) {
			let channels: Array<any> = [];
			if (agentChannels.indexOf(CHANNELS.WHATSAPP_ALL_CAPS) > -1) {
				channels = agentChannels.filter((channel) => channel != 'WHATSAPP');
				channels = [...channels, ...WHATSAPP_PLATFORMS_CAPS];
			} else {
				channels = agentChannels;
			}
			categoryChannelQuery.push(`platform:(${channels?.join(' OR ')})`);
		}

		if (isAgent) {
			const { uid } = state().User.botAdmin.data;
			const agentQuery = `((is_enable:true AND is_live_chat_enable:false) OR livechat_resolved_by:${uid} OR livechat_engaged_by:${uid} OR managed_by:${uid}`;
			const agentHandledQuery = `(livechat_resolved_by:${uid} OR livechat_engaged_by:${uid} OR managed_by:${uid}`;
			if (roleScope === 'HANDLED_CONVERSATIONS') {
				solrQuery = solrQuery?.length ? `${solrQuery} AND ${agentHandledQuery})` : `${agentHandledQuery})`;
			}
			if (roleScope === 'CATEGORY_CONVERSATIONS') {
				solrQuery =
					solrQuery?.length && categoryChannelQuery.length
						? `${solrQuery} AND ${categoryChannelQuery.join(' AND ')}`
						: categoryChannelQuery.join(' AND ');
			}
		}
		if (roleScope === 'CATEGORY_CONVERSATIONS' && isSupervisor) {
			solrQuery =
				solrQuery?.length && categoryChannelQuery.length
					? `${solrQuery} AND ${categoryChannelQuery.join(' AND ')}`
					: categoryChannelQuery.join(' AND ');
		}

		return solrQuery;
	},
	getAgentRoleInformation: () => (dispatch: any, getState: () => RootState) => {
		const isAgent = getState().User.profile.data.roleName === ENGATI_ROLES.ROLE_CONNECT_AGENT;
		const isSupervisor = getState().User.profile.data.roleName === ENGATI_ROLES.ROLE_CONNECT_SUPERVISOR;
		let roleScope = '';

		if (isAgent) {
			roleScope = getState().User?.security?.data?.agent_conversation_scope;
		} else if (isSupervisor) {
			roleScope = getState().User?.security?.data?.supervisor_conversation_scope;
		}

		return {
			roleScope,
			isAgent,
			isSupervisor,
		};
	},
	getActiveCSRequestsByConversationIdsAction:
		(conversationIds: Array<string>, successCB?: any) => (dispatch: any, getState: () => RootState) => {
			const botRef = getState().Bot.data?.bot_ref;
			const { uid } = getState().User.botAdmin.data;
			const { isAgent } = dispatch(messageListActions.getAgentRoleInformation());

			const params = {
				botRef,
				conversationIds,
			};

			messageListAPI.getActiveCSRequestsByConversationIds(params).then((response: any) => {
				if (isValidResponseObject(response) && Array.isArray(response.data.responseObject)) {
					const users = response.data.responseObject;
					const conversationReadStatusMap: IObjProps = {};
					users.map((user: IObjProps) => {
						if ((isAgent && uid === user.actorId) || !isAgent) {
							conversationReadStatusMap[user.conversationId] = user.is_conversation_read;
						}
					});

					dispatch(setConversationReadStatusAction(conversationReadStatusMap));
					successCB && successCB();
				} else {
					dispatch(getLiveChatUsersError());
				}
			});
		},
	getUsersSolarQueryAction:
		(
			filters: IMessageFilters,
			pageNo = 0,
			autorefresh: boolean,
			sentimentAttributeId: string,
			cancelTokens: { users: any; messages: any } = { users: undefined, messages: undefined },
			successCB?: any,
			errorCB?: any,
			functionForNoResult?: any
		) =>
		(dispatch: any, getState: () => RootState) => {
			let { facets, sort } = filters;
			if (!autorefresh) {
				dispatch(getLiveChatUsersLoading());
			}

			const isSimplifiedOneViewEnabled = getState().User.botAdmin.data.is_simplified_one_view_enabled;
			const { users: usersCancelToken, messages: messagesCancelToken } = cancelTokens;

			const facetDetails = dispatch(messageListActions.getFacetsData(facets, MESSAGE_TAB_HEADERS_MAP.all));
			const { solrQuery = '', isTaggedUsers = true } = facetDetails;

			if (sort === messageSort.createdAtAsc) {
				sort = messageSortAllScreen.createdAtAsc;
			} else if (sort === messageSort.createdAtDesc) {
				sort = messageSortAllScreen.createdAtDesc;
			} else if (sort === messageSort.recentFirst) {
				sort = messageSortAllScreen.recentFirst;
			} else if (sort === messageSort.recentUserFirst) {
				sort = messageSortAllScreen.recentUserFirst;
			} else if (sort === messageSortAllScreen.conversationClosingTimeLTH) {
				sort = 'window_closing_time,asc';
			} else if (sort === messageSortAllScreen.conversationClosingTimeHTL) {
				sort = 'window_closing_time,desc';
			}

			const { roleScope, isAgent, isSupervisor } = dispatch(messageListActions.getAgentRoleInformation());

			const params: any = {
				page: pageNo - 1,
				size: getState().Bot.data.conversationLoadCount,
				solrQuery,
				targetApi: 'getUsersBySolrQuery',
				sort,
				userId: getState().User.botAdmin.data.uid,
				sentimentAttributeId: sentimentAttributeId || undefined,
				checkIsUserBanned: getState().User.botAdmin.data.enable_user_ban,
			};

			if (facets.startNewUserCreation) {
				params.preferLeader = true;
			}
			try {
				messageListAPI
					.getUsersSolarQuery(params, {
						cancelToken: usersCancelToken,
					})
					.then((response: any) => {
						if (isValidResponseObject(response) && Array.isArray(response.data.responseObject.content)) {
							const usersData = response.data.responseObject;
							const conversationsIds = usersData.content.reduce(
								(acc: string, user: any, index: number) =>
									index === usersData.content.length - 1
										? acc + user.conversation_id
										: `${acc + user.conversation_id},`,
								''
							);

							!usersData.content.length &&
								solrQuery?.length &&
								Object.keys(facets)?.length > 2 &&
								(Object?.keys(facets)?.includes('username') ||
									Object?.keys(facets)?.includes('email')) &&
								functionForNoResult &&
								functionForNoResult(true);

							usersData.tab = MESSAGE_TAB_HEADERS_MAP.all;
							if (pageNo == 1 && !autorefresh) {
								localStorage.setItem('allOldConv', JSON.stringify(usersData.content));
							}
							dispatch(
								messageListActions.getActiveCSRequestsByConversationIdsAction(conversationsIds, () => {
									if (autorefresh) {
										dispatch(
											messageListActions.getAutoRefreshAction(usersData, successCB(usersData))
										);
									} else {
										allUserCountAction(usersData, dispatch, messagesCancelToken);
									}
								})
							);
							if (!isSimplifiedOneViewEnabled) {
								if (autorefresh) {
									dispatch(messageListActions.getAutoRefreshAction(usersData, successCB(usersData)));
								} else {
									allUserCountAction(
										usersData,
										dispatch,
										getState().User.botAdmin.data.is_simplified_one_view_enabled,
										messagesCancelToken
									);
								}
								delete filters['facets']['startNewUserCreation'];
								dispatch(setFacetsSortInLs('all', filters));
							}
						} else {
							dispatch(getLiveChatUsersError());
							errorCB && errorCB();
						}
					});
			} catch (err: any) {
				if (axios.isCancel(err)) {
					console.log('Cancelled users list API since tab changed: ', err.message); // => prints: Api is being canceled
				} else {
					dispatch(getLiveChatUsersError());
					errorCB && errorCB();
				}
			}
		},
	getUsersSolarQueryActionForNewUserCreation:
		(
			params: any,
			successCB?: any,
			errorCB?: any,
			cancelTokens: { users: any; messages: any } = { users: undefined, messages: undefined }
		) =>
		(dispatch: any, getState: () => RootState) => {
			dispatch(getLiveChatUsersLoading());
			const { users: usersCancelToken, messages: messagesCancelToken } = cancelTokens;
			const isSimplifiedOneViewEnabled = getState().User.botAdmin.data.is_simplified_one_view_enabled;
			try {
				messageListAPI
					.getUsersSolarQuery(params, {
						cancelToken: usersCancelToken,
					})
					.then((response: any) => {
						if (isValidResponseObject(response) && Array.isArray(response.data.responseObject.content)) {
							const usersData = response.data.responseObject;
							const conversationsIds = usersData.content.reduce(
								(acc: string, user: any, index: number) =>
									index === usersData.content.length - 1
										? acc + user.conversation_id
										: `${acc + user.conversation_id},`,
								''
							);
							if (isSimplifiedOneViewEnabled) {
								dispatch(
									messageListActions.getActiveCSRequestsByConversationIdsAction(
										conversationsIds,
										() => {
											allUserCountAction(
												usersData,
												dispatch,
												getState().User.botAdmin.data.is_simplified_one_view_enabled,
												messagesCancelToken
											);
											successCB && successCB();
										}
									)
								);
							} else {
								allUserCountAction(
									usersData,
									dispatch,
									getState().User.botAdmin.data.is_simplified_one_view_enabled,
									messagesCancelToken
								);
								successCB && successCB();
							}
						} else {
							dispatch(getLiveChatUsersError());
							errorCB && errorCB();
						}
					});
			} catch (err: any) {
				if (axios.isCancel(err)) {
					console.log('Cancelled users list API ', err.message);
				} else {
					dispatch(getLiveChatUsersError());
					errorCB && errorCB();
				}
			}
		},
	setAgentChannelAndCategory: (user: any) => (dispatch: any, getState: () => RootState) => {
		agentChannels = user?.channels.map((item: any) => item.toString().toUpperCase());
		agentCategories = user?.categories;
	},
	setAgentDataAction: (content: any) => (dispatch: any) => {
		dispatch(setAgentData(content));
	},
	setPathListAction: (content: any) => (dispatch: any) => {
		dispatch(setPathList(content));
	},
	getPathListAction: (successCB?: any) => (dispatch: any) => {
		messageListAPI
			.getAllSwitchToBotFlows({
				filterOnAvailableForLivechat: 'True',
			})
			.then((resp: any) => {
				if (resp && resp.data) {
					let agentList = [];
					agentList = resp.data.map((agent: any) => ({
						id: agent.path_key,
						label: agent.display_name,
					}));
					dispatch(messageListActions.setPathListAction(agentList));
					successCB && successCB();
				}
			});
	},
	setBotLevelTags: (content: any) => (dispatch: any) => {
		dispatch(setBotLevelTagsList(content));
	},
	getBotLevelTags: () => (dispatch: any) => {
		messageListAPI.getTags().then((resp: any) => {
			if (isValidResponseObject(resp)) {
				dispatch(messageListActions.setBotLevelTags(resp.data.responseObject.tagsList));
			} else {
				dispatch(messageListActions.setBotLevelTags([]));
			}
		});
	},
	setUserLevelTagsAction: (content: any) => (dispatch: any) => {
		dispatch(setUserLevelTags(content));
	},
	setFacetsSortInLs: (tab: string, filters: IObjProps) => (dispatch: any, getState: () => RootState) => {
		localStorage.setItem(
			`${tab}_${getState().Bot.data.bot_ref}_${getState().User.botAdmin.data.uid}`,
			JSON.stringify({ ...filters })
		);
	},
	setUserListAction: (data: Array<IObjProps>) => (dispatch: any, getState: () => RootState) => {
		if (!getState().Message.userList.loading) {
			dispatch(setUserList(data));
		}
	},

	setUserListLoadingAction: () => (dispatch: any) => {
		dispatch(getLiveChatUsersLoading());
	},
	getCsRequestAction:
		(selectedUser: IObjProps, successCB?: Function) => (dispatch: any, getState: () => RootState) => {
			const isOwnerOrSupervisor =
				getState().User.botAdmin.data.admin_mode_access.role === 'ROLE_CONNECT_SUPERVISOR' ||
				getState().User.botAdmin.data.admin_mode_access.role === 'ROLE_CONNECT_OWNER';
			const isLivechatInputAreaDisabled = getState().User.botAdmin.data.disable_livechat_input_area;
			const params = {
				user_id: selectedUser?.conversation_id,
				user_bot_ref: selectedUser?.bot_ref,
				targetApi: 'getCsRequestAllConversation',
			};

			messageListAPI.getCsRequest(params).then((response: any) => {
				if (response.data && response.data.status && response.data.status.code) {
					const user = { ...selectedUser };
					user.csDataResponseCode = response.data.status.code;
					user.show_start_conversation = false;
					if (response.data.status.code === 1000 && response.data.responseObject) {
						const csData = response.data.responseObject;
						user.cs_request_id = csData.id;
						user.actor_email = csData.actorEmail;
						user.actor_id = csData.actorId;
						user.actor_name = csData.actorName;
						user.category_id = csData.categoryId;
						user.created_at = csData.createdAt;
						user.current_state = csData.currentState;
						user.event_data = csData.eventData;
						user.initiated_by = csData.initiatedBy;
						user.is_group_conversation = csData.is_group_conversation;
						user.participant_details = csData.participant_details;
						user.priority = csData.priority;
						user.user_id = csData.userId;
						user.show_start_conversation = true;

						user.show_start_conversation = setStartConversation(user, isOwnerOrSupervisor, getState());
						if (WHATSAPP_PLATFORMS.includes(user.platform)) {
							user.show_start_conversation = setStartConversationForWAChannel(user);
						}
						user.showJoinConversation = setJoinConversation(user, isOwnerOrSupervisor, getState());
						dispatch(setSelectedUser(user));
						successCB && successCB();
					} else if (response.data.status.code === 1004) {
						if (WHATSAPP_PLATFORMS.includes(user.platform)) {
							user.show_start_conversation = setStartConversationForWAChannel(user);
						} else if (isOwnerOrSupervisor) {
							if (isLivechatInputAreaDisabled === false) {
								user.show_start_conversation = true;
							} else {
								const currentTime: any = new Date();
								const lastActiveTime: any = new Date(user.last_active_at);
								const diffTime: any = Math.abs(currentTime - lastActiveTime);

								const diffDays = diffTime / (1000 * 60 * 60 * 24);
								if (diffDays < 2) {
									user.show_start_conversation = true;
								} else {
									const currentTime: any = new Date();
									const lastActiveTime: any = new Date(user.last_active_at);
									const diffTime: any = Math.abs(currentTime - lastActiveTime);

									const diffDays = diffTime / (1000 * 60 * 60 * 24);
									if (diffDays < 2 || WHATSAPP_PLATFORMS.includes(user.platform)) {
										user.show_start_conversation = true;
									}
								}
							}
						} else {
							user.show_start_conversation = true;
						}

						user.show_start_conversation = setStartConversation(user, isOwnerOrSupervisor, getState());
						user.showJoinConversation = false;
						dispatch(setSelectedUser(user));
						successCB && successCB();
					}
				}
			});
		},
	removeSelectedUserFromListAction: (conversationData: IObjProps) => (dispatch: any, getState: () => RootState) => {
		const usersListData: IObjProps = getState().Message.userList;
		const isNeutralScreenEnabled = getState().User.botAdmin.data.enable_neutral_screen;
		const usersList = [...usersListData.data];
		const userToRemove = conversationData.length ? conversationData : [conversationData];

		let newSelectedUser = {};
		userToRemove?.forEach((data: any) => {
			usersList.map((user: { conversation_id: any }, index: number) => {
				if (user.conversation_id === data.conversation_id) {
					if (usersList.length > 1) {
						if (index === usersList.length - 1) {
							newSelectedUser = usersList[index - 1];
						} else {
							newSelectedUser = usersList[index + 1];
						}
					} else {
						newSelectedUser = {};
					}
					usersList.splice(index, 1);
				}
			});
			dispatch(messageListActions.updateAllUsersCountAction('remove'));
		});
		!isNeutralScreenEnabled && dispatch(setSelectedUser(newSelectedUser));
		dispatch(messageListActions.setUserListAction(usersList));
	},
	removeUserFromListAction: (count: number, tab?: string) => (dispatch: any, getState: () => RootState) => {
		const tabToUpdate = tab || currentTab();
		const { usersCount } = getState().Message;

		if (tabToUpdate) {
			const data = {
				...usersCount,
				[tabToUpdate]: usersCount[tabToUpdate] - count > 0 ? usersCount[tabToUpdate] - count : 0,
			};
			dispatch(updateAllUsersCount(data));
		}
	},
	updateAllUsersCountAction:
		(operation: 'add' | 'remove' = 'remove', data?: IObjProps, tab?: string) =>
		(dispatch: any, getState: () => RootState) => {
			const { usersCount } = getState().Message;
			const oldNotifications: IObjProps = getState().Notifications.data;
			const tabToUpdate = tab || currentTab();

			if (operation === 'add' && !isEmpty(data)) {
				oldNotifications.forEach((notification: any) => {
					if (
						notification.conversationId === data?.conversation_id ||
						notification.userId === data?.user_id
					) {
					}
				});
			}
			if (tabToUpdate) {
				const data = {
					...usersCount,
					[tabToUpdate]:
						operation === 'add'
							? usersCount[tabToUpdate] + 1
							: usersCount[tabToUpdate] > 0
								? usersCount[tabToUpdate] - 1
								: 0,
				};
				dispatch(updateAllUsersCount(data));
			}
		},
	setMessageCountAction: (messageCountMap: IObjProps) => (dispatch: any) => {
		dispatch(setMessageCount(messageCountMap));
	},
	setConversationReadStatusAction: (conversationReadStatusMap: IObjProps) => (dispatch: any) => {
		dispatch(setConversationReadStatus(conversationReadStatusMap));
	},
	getSentimentData: (userIds: Array<string>, attributeName: string, errorCB?: any) => (dispatch: any) => {
		messageListAPI
			.getAttributeValueForUsers(userIds, attributeName)
			.then((resp: any) => {
				if (resp?.data?.code === ENGATI_RESPONSE_CODE.STATUS_1000) {
					!isEmpty(resp.data.data) && dispatch(setSentimentData(resp.data.data));
				} else {
					errorCB && errorCB();
					dispatch(setSentimentData({}));
				}
			})
			.catch(() => {
				errorCB && errorCB();
				dispatch(setSentimentData({}));
			});
	},
	getSentimentAttributeIdFromGetFlowAttributes: (errorCB?: any, successCB?: any) => (dispatch: any) => {
		const param = {
			user_type: 'GLOBAL',
		};
		pathBuilderApi.getFlowAttributes(param).then((resp: any) => {
			if (resp.data) {
				if (Array.isArray(resp.data)) {
					const sentimentAttribute = resp.data.find(
						(attribute: any) => attribute.name === SENTIMENT_ATTRIBUTE_LABEL
					);
					if (sentimentAttribute?.id?.length > 5) {
						const sentimentAttributeId = sentimentAttribute.id.slice(5);
						dispatch(setSentimentAttributeData(sentimentAttributeId));
						successCB && successCB(sentimentAttributeId);
					} else {
						errorCB && errorCB();
					}
				}
			} else {
				errorCB && errorCB();
			}
		});
	},
	setSentimentAttributeDataAction: (id: any) => (dispatch: any) => {
		dispatch(setSentimentAttributeData(id));
	},
	removeMessageCountForIdAction: (id: string) => (dispatch: any, getState: () => RootState) => {
		const messageCountMap = getState().Message.messageCountMap || {};

		if (messageCountMap.hasOwnProperty(id)) {
			const countObject = { ...messageCountMap };

			delete countObject[id];

			dispatch(setMessageCount(countObject));
		}
	},
	updateUserInUserListAction: (userData: IObjProps) => (dispatch: any, getState: () => RootState) => {
		const usersListData: IObjProps = getState().Message.userList;
		const usersList = [...usersListData.data];

		usersList.every((user, index) => {
			if (user.conversation_id === userData.conversation_id) {
				usersList[index] = { ...userData };

				return false;
			}

			return true;
		});
		usersList?.length > 0 && dispatch(messageListActions.setUserListAction(usersList));
	},
	updateSelectedUserInUserListAction: (selectedUser: IObjProps) => (dispatch: any, getState: () => RootState) => {
		const usersListData: IObjProps = getState().Message.userList;
		const usersList = [...usersListData.data];

		usersList.every((user, index) => {
			if (user.conversation_id === selectedUser.conversation_id) {
				usersList[index] = { ...selectedUser };
				dispatch(setSelectedUser(selectedUser));

				return false;
			}

			return true;
		});
		usersList?.length > 0 && dispatch(messageListActions.setUserListAction(usersList));
	},
	updateUserListAndSelectedUserAction:
		(users: Array<IObjProps>, user: IObjProps) => (dispatch: any, getState: any) => {
			const { selectedUser } = getState().Message;
			const { selectedVideoUser } = getState().Jitsi;
			if (selectedUser && selectedUser.conversation_id === user.conversation_id) {
				dispatch(setSelectedUser(user));
				if (selectedVideoUser && selectedVideoUser.conversation_id === user.conversation_id) {
					dispatch(jitsiActions.setSelectedVideoUserAction(user));
				}
			}
			dispatch(messageListActions.setUserListAction(users));
		},
	getAutoRefreshAction: (usersData: any, successCB?: any) => (dispatch: any, getState: () => RootState) => {
		// we compare lastactive value to know whether we have new bot messages or not
		const isSimplifiedOneViewEnabled = getState().User.botAdmin.data.is_simplified_one_view_enabled;
		const newUsersData = usersData.content;
		const oldUsersData = JSON.parse(localStorage.getItem('allOldConv') || '') || [];

		newUsersData.map((user: IObjProps) => {
			user.profileColor = getProfileColor(user.id || user.global_user_id || '0');
		});

		if (!isSimplifiedOneViewEnabled && newUsersData.length) {
			let messageCountMap: IObjProps = {};
			if (getState().Message.messageCountMap) {
				messageCountMap = {
					...getState().Message.messageCountMap,
				};
			}
			for (let i = 0; i < newUsersData.length; i++) {
				const users = oldUsersData.filter(
					(user: any) => user.conversation_id === newUsersData[i].conversation_id
				);
				if (!users.length || (users.length && newUsersData[i].last_active_at !== users[0].last_active_at)) {
					messageCountMap[newUsersData[i].conversation_id] = true;
				}
			}
			dispatch(setMessageCount(messageCountMap));
		}
		dispatch(getUsersSolarQuery(usersData));
		localStorage.setItem('allOldConv', JSON.stringify(newUsersData));
		!getState().User.botAdmin.data.is_simplified_one_view_enabled &&
			dispatch(
				getAllUsersCountAction({
					all: usersData?.totalElements,
				})
			);
		successCB && successCB();
	},
	markConversationAsReadOrUnread:
		(conversationId: string, status: boolean) => (dispatch: any, getState: () => RootState) => {
			const { conversationReadStatusMap } = getState().Message;
			const updatedConversationReadStatusMap = { ...conversationReadStatusMap };

			if (updatedConversationReadStatusMap.hasOwnProperty(conversationId)) {
				updatedConversationReadStatusMap[conversationId] = status;
			}

			dispatch(setConversationReadStatusAction(updatedConversationReadStatusMap));
		},
};
