import React from 'react';

function EmoticonIcon({ fillColor, height, width }: { fillColor?: string; height?: number; width?: number }) {
	return (
		<svg
			width={width || '24'}
			height={height || '24'}
			viewBox='0 0 20 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M8.75369 10.9291C8.60819 10.7006 8.30502 10.6332 8.07645 10.7787C7.84784 10.9241 7.78045 11.2274 7.92593 11.456L8.01029 11.4023H11.9506C12.0665 11.2203 12.0128 10.9789 11.8308 10.863L11.8845 10.7787C11.8845 10.7787 11.8845 10.7787 11.8845 10.7787C11.6559 10.6332 11.3526 10.7006 11.2072 10.9292L11.2915 10.9829L11.2072 10.9291L11.2072 10.9291C11.2048 10.9328 11.0887 11.1105 10.8775 11.2874C10.6634 11.4667 10.3589 11.6395 9.98047 11.6395C9.60208 11.6395 9.29754 11.4667 9.08341 11.2874C8.87224 11.1105 8.75614 10.9328 8.75372 10.9291L8.75369 10.9291ZM8.75369 10.9291C8.75372 10.9291 8.75374 10.9292 8.75376 10.9292L8.66939 10.9829L8.75369 10.9291L8.75369 10.9291ZM6.39374 6.39374L6.39374 6.39374C5.43063 7.35689 4.9 8.63793 4.9 10C4.9 11.3621 5.43063 12.6431 6.39374 13.6063L6.46445 13.5355L6.39374 13.6063C7.35689 14.5694 8.63793 15.1 10 15.1C11.3621 15.1 12.6431 14.5694 13.6063 13.6063L13.5355 13.5355L13.6063 13.6063C14.5694 12.6431 15.1 11.3621 15.1 10C15.1 8.63793 14.5694 7.35689 13.6063 6.39374L13.5355 6.46445L13.6063 6.39374C12.6431 5.43063 11.3621 4.9 10 4.9C8.63793 4.9 7.35689 5.43063 6.39374 6.39374ZM5.88125 10C5.88125 7.729 7.729 5.88125 10 5.88125C12.271 5.88125 14.1187 7.729 14.1187 10C14.1187 12.271 12.271 14.1187 10 14.1187C7.729 14.1187 5.88125 12.271 5.88125 10ZM8.86953 8.71094C8.86953 8.38604 8.60615 8.12266 8.28125 8.12266C7.95635 8.12266 7.69297 8.38604 7.69297 8.71094C7.69297 9.03583 7.95635 9.29922 8.28125 9.29922C8.60615 9.29922 8.86953 9.03583 8.86953 8.71094ZM11.1109 8.71094C11.1109 9.03583 11.3743 9.29922 11.6992 9.29922C12.0241 9.29922 12.2875 9.03583 12.2875 8.71094C12.2875 8.38604 12.0241 8.12266 11.6992 8.12266C11.3743 8.12266 11.1109 8.38604 11.1109 8.71094Z'
				fill={fillColor || '#403F42'}
				stroke={fillColor || '#403F42'}
				strokeWidth='0.2'
			/>
		</svg>
	);
}

export default EmoticonIcon;
