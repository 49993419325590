import React, { useEffect, useMemo, useState } from 'react';
import { useStyletron } from 'baseui';

import ApiTriggerMessageTag from 'components/shared/ApiTriggerMessageTag/ApiTriggerMessageTag';
import ENGTImage from 'components/UI/ENGTImage/ENGTImage';

import { decodeAndSanitizeInput } from 'shared/helpers';
import { linkify, regExp } from 'shared/helpers/quillHelper';

import CarouselPreview from './OneViewRespondArea/CarouselPreview';

interface IENGTImage {
	backgroundColor: string;
	messageText: string;
	multipleUploadedData: any;
	messageId?: string;
	messageStatus?: JSX.Element | null;
	showApiTriggerMessageTag?: boolean;
}

function Image(props: IENGTImage) {
	const {
		backgroundColor,
		messageText,
		multipleUploadedData,
		messageId,
		messageStatus,
		showApiTriggerMessageTag = false,
	} = props;
	const [css, theme]: [any, any] = useStyletron();

	const [showCarouselPreview, setCarouselPreview] = useState(false);
	const [multipleImageUrls, setMultipleImageUrls] = useState([]);
	const [previewIndex, setPreviewIndex] = useState(0);
	const [multipleImageClassName, setMultipleImageClassName] = useState('');

	const imageCss = {
		paddingLeft: '1rem',
		paddingRight: messageStatus ? '0.5rem' : '1rem',
		paddingTop: '0.875rem',
		paddingBottom: '0.875rem',
		display: 'inline-flex',
		flexDirection: 'column',
		borderTopLeftRadius: '0.5rem',
		borderTopRightRadius: '0.5rem',
		borderBottomLeftRadius: '0.5rem',
		borderBottomRightRadius: '0.5rem',
	};

	const width = multipleUploadedData.length === 1 ? '9.375rem' : '4.5rem';
	const height = multipleUploadedData.length === 1 ? '7.5rem' : '3.375rem';

	useEffect(() => {
		const urls: any = [];
		multipleUploadedData?.length &&
			multipleUploadedData.map((message: any) => {
				urls.push(message.message);
			});
		setMultipleImageUrls(urls);
		let classNameBasedOnMessageId = '';
		multipleUploadedData?.length &&
			multipleUploadedData.forEach((message: any) => {
				classNameBasedOnMessageId = `${message.messageId ?? messageId},${classNameBasedOnMessageId}`;
			});
		setMultipleImageClassName(classNameBasedOnMessageId);
	}, [multipleUploadedData]);

	const onClick = (index: number) => {
		setCarouselPreview(true);
		setPreviewIndex(index);
	};

	const onClose = () => {
		setCarouselPreview(false);
	};

	const textMessage = useMemo(() => {
		let msg = messageText;
		if (messageText) {
			msg = linkify(messageText);
			try {
				if (msg.indexOf('<a href=') > -1) {
					return msg.replace(regExp, '<a class="clickableLinksA" target="_blank" href=');
				}
			} catch (e) {
				console.error('error parsing the message');

				return msg;
			}
		}

		return msg;
	}, [messageText]);

	return (
		<>
			<div
				className={css({
					...imageCss,
					backgroundColor,
				})}
				id={multipleImageClassName}
			>
				{showApiTriggerMessageTag && <ApiTriggerMessageTag />}
				<div
					className={css({
						display: 'flex',
					})}
				>
					<div>
						<div
							className={css({
								width: '9.375rem',
								display: 'flex',
								flexWrap: 'wrap',
								justifyContent: 'space-around',
							})}
						>
							{multipleUploadedData.map((message: any, index: number) => {
								if (index > 3) {
								} else {
									return (
										<div
											key={`clubbed-image-${index}`}
											className={css({
												flex: '0 1 33%',
												display: 'flex',
												paddingBottom: '0.125rem',
												...(index == 3 &&
													multipleUploadedData.length > 4 && {
														position: 'relative',
														justifyContent: 'center',
														alignItems: 'center',
														backgroundColor: theme.colors.primaryA,
														opacity: 0.6,
													}),
											})}
											onClick={() => onClick(index < 3 ? index : 0)}
										>
											<ENGTImage
												lazy
												src={message.message}
												alt='image'
												className={css({
													width,
													maxHeight: height,
													cursor: 'pointer',
												})}
												lazyProps={{
													offset: 200,
												}}
											/>
											{index === 3 && multipleUploadedData.length > 4 && (
												<div
													className={css({
														position: 'absolute',
														color: theme.colors.primaryB,
														fontWeight: 700,
														fontSize: '0.75rem',
														lineHeight: '1rem',
													})}
												>
													+ {multipleUploadedData.length - 3}
												</div>
											)}
										</div>
									);
								}
							})}
						</div>
						{messageText && (
							<div
								className={css({
									textAlign: 'left',
									marginTop: '0.5rem',
									fontSize: '0.875rem',
									fontWeight: 'normal',
									lineHeight: '1.25rem',
								})}
							>
								<span
									className={css({ 'white-space': 'pre-wrap' })}
									dangerouslySetInnerHTML={{ __html: decodeAndSanitizeInput(textMessage || '') }}
								/>
							</div>
						)}
					</div>
					{messageStatus}
				</div>
			</div>
			{showCarouselPreview && (
				<CarouselPreview
					isOpen={showCarouselPreview}
					multipleImageUrls={multipleImageUrls}
					onClose={onClose}
					previewIndex={previewIndex}
					setCarouselPreview={setCarouselPreview}
				/>
			)}
		</>
	);
}

export default React.memo(Image);
