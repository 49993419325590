import { NEW_MESSAGE_INDICATOR_ELEMENT_ID } from 'shared/consts/consts';

export const getNotReadMessageCount = () => {
	const newMessageIndicator: any = document.getElementById(NEW_MESSAGE_INDICATOR_ELEMENT_ID);

	let countOfUnreadMessages = newMessageIndicator?.getAttribute('data-messages-count');

	countOfUnreadMessages =
		countOfUnreadMessages && typeof countOfUnreadMessages === 'string'
			? parseInt(countOfUnreadMessages)
			: countOfUnreadMessages;

	return countOfUnreadMessages || 0;
};

export const iconHolderCss = {
	height: '2rem',
	width: '2rem',
	cursor: 'pointer',
	marginRight: '0.5rem',
};

export const dataNotFoundCss = (theme: any) => ({
	paddingTop: '1rem',
	paddingBottom: '1rem',
	borderTopWidth: '0.063rem',
	borderTopStyle: 'solid',
	borderTopColor: theme.colors.horizantalDividerColor,
	borderBottomWidth: '0.063rem',
	borderBottomStyle: 'solid',
	borderBottomColor: theme.colors.horizantalDividerColor,
	marginTop: '1rem',
	marginRight: '1rem',
	marginLeft: '1rem',
	fontSize: '0.875rem',
	fontWeight: '500',
	lineHeight: '1.25rem',
});
