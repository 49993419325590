import { useCallback, useState } from 'react';
import { useStyletron } from 'baseui';
import { KIND, SIZE } from 'baseui/button';
import { PLACEMENT } from 'baseui/popover';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { HorizontalDivider } from 'components/UI/Dividers/HorizontalDivider';
import Button from 'components/UI/ENGTButton/ENGTButton';
import ENGTTooltip from 'components/UI/ENGTTooltip/ENGTTooltip';

import { CHANNEL_TITLE_MAPPING, ENGATI_ROLES, WHATSAPP_PLATFORMS } from 'shared/consts/consts';
import { WHATSAPP_PROVIDERS } from 'shared/helpers';

import { RootState } from 'store/withReducer';

import TemplateMessage from './OneViewRespondArea/TemplateMessage';

interface IENGTContactCardProps {
	name: string;
	phoneNumbers: string[];
}

function ContactCard(props: IENGTContactCardProps) {
	const { name, phoneNumbers } = props;
	const [css, theme]: [any, any] = useStyletron();
	const { tab }: any = useParams();
	const { t } = useTranslation(['pages', 'components']);
	const agentData = useSelector((state: RootState) => state.Message.agent);
	const conversationPlatform: string = useSelector((state: RootState) => state.Message.selectedUser.platform);
	const agentTemplateMessageScope = useSelector(
		(state: RootState) => state.User.security.data.template_message_agent
	);
	const isAgentAccount =
		useSelector((state: RootState) => state.User.profile.data.roleName === ENGATI_ROLES.ROLE_CONNECT_AGENT) ||
		false;
	const isOwnerAccount =
		useSelector((state: RootState) => state.User.profile.data.roleName === ENGATI_ROLES.ROLE_CONNECT_OWNER) ||
		false;
	const isSuperVisorAccount =
		useSelector((state: RootState) => state.User.profile.data.roleName === ENGATI_ROLES.ROLE_CONNECT_SUPERVISOR) ||
		false;
	const conversationCategoryId: any = useSelector((state: RootState) => state.Message.selectedUser.category_id);

	const isDialog360Configured =
		useSelector((state: RootState) => state.Bot.data.provider_configured) === WHATSAPP_PROVIDERS.dialog360;
	const isWhatsAppCloudConfigured =
		useSelector((state: RootState) => state.Bot.data.provider_configured) === WHATSAPP_PROVIDERS.whatsAppCloud;
	const isWhatsAppUser = WHATSAPP_PLATFORMS.includes(conversationPlatform);
	const isSendingTemplateMessageAllowed =
		isOwnerAccount || isSuperVisorAccount || (isAgentAccount && agentTemplateMessageScope);

	const [openDownloadModal, setOpenDownloadModal] = useState<boolean>(false);
	const [waNumber, setWaNumber] = useState<string>('');
	const onTemplateModalclose = useCallback(() => {
		setOpenDownloadModal(false);
	}, [setOpenDownloadModal]);

	const isSendTemplateAllowed =
		(isDialog360Configured || isWhatsAppCloudConfigured) &&
		isWhatsAppUser &&
		isSendingTemplateMessageAllowed &&
		conversationCategoryId !== undefined &&
		agentData?.channels?.includes(CHANNEL_TITLE_MAPPING.whatsapp.toLowerCase());

	const buttonCss = {
		width: '9.375rem',
		height: '1.25rem',
		background: theme.colors.backgroundPrimary,
		borderRadius: '0.25rem',
		marginLeft: '0.625rem',
		color: theme.colors.primaryA,
		cursor: isSendTemplateAllowed ? 'pointer' : 'not-allowed',
	};

	const sendTemplateButton = (contactNo: any) => (
		<Button
			label={t('pages:messages.sendTemplateMessage')}
			size={SIZE.mini}
			kind={KIND.secondary}
			overrides={{ BaseButton: { style: buttonCss } }}
			onClick={() => {
				if (isSendTemplateAllowed) {
					setOpenDownloadModal(true);
					setWaNumber(contactNo);
				} else {
					setOpenDownloadModal(false);
				}
			}}
		/>
	);

	return (
		<div
			className={css({
				background: theme.recentConvAgentMsgBg,
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-around',
				color: theme.colors.primaryA,
				width: 'fit-content',
				borderRadius: '0.5rem',
			})}
		>
			<div
				className={css({
					fontSize: '0.85rem',
					fontWeight: 'bold',
					margin: '0.8rem',
				})}
			>
				{name}
			</div>
			<div
				className={css({
					width: '90%',
					margin: 'auto',
				})}
			>
				<HorizontalDivider height='0.031rem' color={theme.colors.accent400} />
			</div>
			<div
				className={css({
					display: 'flex',
					flexDirection: 'column',
				})}
			>
				{phoneNumbers
					.filter((contactNo) => contactNo)
					.map((contactNo, index) => (
						<div
							key={index}
							className={css({
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
								margin: '0.8rem',
							})}
						>
							<div
								className={css({
									margin: '0.5 rem',
								})}
							>
								{`+${contactNo}`}
							</div>
							<div
								className={css({
									display: 'flex',
									flexDirection: 'row',
									justifyItems: 'end',
								})}
							>
								{isSendTemplateAllowed ? (
									<span>{sendTemplateButton(contactNo)}</span>
								) : (
									<ENGTTooltip
										placement={PLACEMENT.right}
										content={t('pages:messages.sendTemplateMessageTooltip')}
									>
										<span>{sendTemplateButton(contactNo)}</span>
									</ENGTTooltip>
								)}
							</div>
						</div>
					))}
			</div>

			<TemplateMessage
				isOpen={openDownloadModal}
				onClose={onTemplateModalclose}
				isAllTab
				tab={tab}
				waNumber={waNumber}
			/>
		</div>
	);
}

export default ContactCard;
