import React from 'react';
import { useStyletron } from 'baseui';

function Level8({
	size,
	fillColor,
	height,
	width,
}: {
	size?: number;
	fillColor?: string;
	height: number;
	width: number;
}) {
	const [, theme] = useStyletron();

	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' id='flag' height={height} width={width}>
			<path fill='#e6e5e1' d='M30.29 41.87V7.72h3.42v34.15Z' />
			<path fill='#6cc5ef' d='M33.71 7.72v12.22h10V7.72Z' />
			<path fill='#3db3ea' d='M43.72 11.81h10V24h-10Z' />
			<path fill='#fe8657' d='M53.73 20h-10v-4.12h10Z' />
			<path fill='#ffa883' d='M33.71 11.8h10v4.07h-10Z' />
			<path fill='#cac7c1' d='M31.78 41.87V7.72h1.93v34.15Z' />
			<path fill='#767689' d='M32 41.83c-15.46 0-28 7.75-28 17.31h56c0-9.56-12.54-17.31-28-17.31Z' />
			<path
				fill='#5f5f70'
				d='M32 41.83c-1.07 0-2.13.05-3.17.12 14 1 24.83 8.29 24.83 17.19H60c0-9.56-12.54-17.31-28-17.31Z'
			/>
			<path
				fill='#e6e5e1'
				d='M30.47 48.78A2.26 2.26 0 1 0 32.75 51a2.26 2.26 0 0 0-2.28-2.26zm14.92-4.84-.08.06a6.36 6.36 0 0 0 10.81 6.49l.07-.06a26.41 26.41 0 0 0-10.8-6.49zm-22.64-1.12c-6.44 1.39-11.81 4.18-15.13 7.8v.16a8.66 8.66 0 0 0 15.16-7.91z'
			/>
			<path
				fill='#cac7c1'
				d='m45.39 43.94-.08.06a6.37 6.37 0 0 0-.52 2.51 19.77 19.77 0 0 1 7 6.32 6.36 6.36 0 0 0 4.38-2.36l.07-.06a26.41 26.41 0 0 0-10.85-6.47Z'
			/>
			<path
				fill='#16244d'
				d='M34.71 40.92v-20h8V24a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V11.81a1 1 0 0 0-1-1h-9V7.72a1 1 0 0 0-1-1H30.29a1 1 0 0 0-1 1v33.2C14.57 41.78 3 49.62 3 59.14a1 1 0 0 0 1 1h56a1 1 0 0 0 1-1c0-9.52-11.57-17.36-26.29-18.22ZM46 45.2a25.62 25.62 0 0 1 8.84 5.23 5.36 5.36 0 0 1-9-3.92A5.74 5.74 0 0 1 46 45.2ZM52.73 19h-8v-2.12h8Zm-10-4.08h-8V12.8h8Zm-8 2h8v2.07h-8Zm10 6.16V21h8v2Zm8-8.15h-8v-2.12h8Zm-10-4.08h-8V8.72h8ZM31.29 8.72h1.42v32.13h-1.42ZM22.23 45.6a7.65 7.65 0 0 1-13.34 5.12c3-3 7.65-5.41 13.15-6.73a7.87 7.87 0 0 1 .19 1.61ZM5.05 58.14a11.49 11.49 0 0 1 2.5-5.91 9.66 9.66 0 0 0 16.68-6.63 9.78 9.78 0 0 0-.22-2 42.84 42.84 0 0 1 8-.74 41.82 41.82 0 0 1 12.06 1.73 7.52 7.52 0 0 0-.27 1.95 7.38 7.38 0 0 0 7.36 7.37 7.3 7.3 0 0 0 5-2A11.7 11.7 0 0 1 59 58.14Z'
			/>
			<path
				fill='#16244d'
				d='M30.49 47.78a3.26 3.26 0 1 0 2.32.95 3.24 3.24 0 0 0-2.32-.95zm0 4.52a1.26 1.26 0 1 1 0-2.52 1.26 1.26 0 1 1 0 2.52zm-9.03-18.04a1 1 0 0 0 1-1V33h.3a1 1 0 0 0 0-2h-.3v-.31a1 1 0 0 0-2 0V31h-.31a1 1 0 0 0 0 2h.31v.3a1 1 0 0 0 1 .96zm18.84 2.43a1 1 0 0 0 1-1v-.31h.31a1 1 0 0 0 0-2h-.31v-.31a1 1 0 0 0-2 0v.31H39a1 1 0 0 0 0 2h.3v.31a1 1 0 0 0 1 1zM18.93 19a1 1 0 0 0 1-1v-.3h.3a1 1 0 0 0 0-2h-.3v-.31a1 1 0 0 0-2 0v.31h-.31a1 1 0 0 0 0 2h.31v.3a1 1 0 0 0 1 1zM9.05 40.75a1 1 0 0 0 1-1v-.31h.3a1 1 0 1 0 0-2h-.3v-.3a1 1 0 0 0-2 0v.3h-.31a1 1 0 0 0 0 2h.31v.31a1 1 0 0 0 1 1zM49 30.19a1 1 0 0 0 1-1v-.3h.31a1 1 0 0 0 0-2H50v-.31a1 1 0 1 0-2 0v.31h-.3a1 1 0 0 0 0 2h.3v.3a1 1 0 0 0 1 1zm-.29-21.72a1 1 0 0 0 1-1v-.3H50a1 1 0 0 0 0-2h-.31v-.31a1 1 0 1 0-2 0v.31h-.3a1 1 0 0 0 0 2h.3v.3a1 1 0 0 0 1.02 1zM7.37 13.9a1 1 0 0 0 1-1v-.31h.3a1 1 0 0 0 0-2h-.3v-.3a1 1 0 1 0-2 0v.3h-.31a1 1 0 1 0 0 2h.31v.31a1 1 0 0 0 1 1z'
			/>
		</svg>
	);
}

export default Level8;
