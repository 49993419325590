import React, { useMemo, useState } from 'react';
import { useStyletron } from 'baseui';

import ApiTriggerMessageTag from 'components/shared/ApiTriggerMessageTag/ApiTriggerMessageTag';

import { decodeAndSanitizeInput } from 'shared/helpers';
import { linkify, regExp } from 'shared/helpers/quillHelper';

interface IENGTFile {
	backgroundColor: string;
	fileName?: string;
	messageText: string;
	textColor: string;
	url: string;
	messageId?: string;
	appMessage?: any;
	messageStatus?: JSX.Element | null;
	showApiTriggerMessageTag?: boolean;
}

const getFileNameFromUrl = (fileUrl: string) => {
	const completeFileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);

	return decodeURIComponent(completeFileName);
};

function File(props: IENGTFile) {
	const {
		backgroundColor,
		fileName,
		textColor,
		url,
		messageText,
		appMessage = <></>,
		messageStatus,
		showApiTriggerMessageTag = false,
	} = props;
	const [css, theme]: [any, any] = useStyletron();

	const fileNameToDisplay = fileName || getFileNameFromUrl(url);

	const [isVideoMessage, setIsVideoMessage] = useState(true);
	const [isImageMessage, setIsImageMessage] = useState(true);

	const textMessage = useMemo(() => {
		let msg = messageText;
		if (messageText) {
			msg = linkify(messageText);
			try {
				if (msg.indexOf('<a href=') > -1) {
					return msg.replace(regExp, '<a class="clickableLinksA" target="_blank" href=');
				}
			} catch (e) {
				console.error('error parsing the message');

				return msg;
			}
		}

		return msg;
	}, [messageText]);

	return (
		<div
			className={css({
				paddingLeft: '1rem',
				paddingRight: messageStatus ? '0.5rem' : '1rem',
				paddingTop: '0.875rem',
				paddingBottom: '0.875rem',
				borderRadius: '0.5rem',
				backgroundColor,
				display: messageStatus ? 'flex' : 'inline-block',
				float: 'right',
				flexDirection: showApiTriggerMessageTag ? 'column' : 'row',
			})}
			id={props.messageId}
		>
			{showApiTriggerMessageTag && (
				<ApiTriggerMessageTag
					overrides={css({
						marginRight: '10px',
					})}
				/>
			)}
			<div
				className={css({
					display: messageStatus ? 'flex' : 'inline-block',
				})}
			>
				{isImageMessage && (
					<a
						href={url}
						target='_blank'
						rel='noreferrer'
						className={css({
							display: 'flex',
							justifyContent: 'flex-start',
						})}
					>
						<img
							src={url}
							alt={fileNameToDisplay}
							onLoad={() => setIsVideoMessage(false)}
							onError={() => setIsImageMessage(false)}
							className={css({
								height: '7.5rem',
								width: '9.375rem',
								borderRadius: '0.5rem',
							})}
						/>
					</a>
				)}
				{isVideoMessage && (
					<a
						href={url}
						target='_blank'
						rel='noreferrer'
						className={css({
							display: 'flex',
							justifyContent: 'flex-start',
						})}
					>
						<video
							src={url}
							controls
							disablePictureInPicture
							controlsList='noplaybackrate nofullscreen nodownload'
							onLoadedData={() => setIsImageMessage(false)}
							onError={() => setIsVideoMessage(false)}
							className={css({
								maxHeight: '8rem',
								width: '14rem',
								borderRadius: '0.5rem',
							})}
						/>
					</a>
				)}
				{!isImageMessage && !isVideoMessage && (
					<div>
						<a
							href={url}
							target='_blank'
							rel='noreferrer'
							className={css({
								color: textColor,
							})}
						>
							{fileNameToDisplay}
						</a>
					</div>
				)}
				{messageText && (
					<div
						className={css({
							textAlign: 'left',
							marginTop: '0.5rem',
							fontSize: '0.875rem',
							fontWeight: 'normal',
							lineHeight: '1.25rem',
						})}
					>
						<span
							className={css({ 'white-space': 'pre-wrap' })}
							dangerouslySetInnerHTML={{ __html: decodeAndSanitizeInput(textMessage || '') }}
						/>
					</div>
				)}
				{appMessage}
				{messageStatus}
			</div>
		</div>
	);
}

export default React.memo(File);
