import BaseService from 'services/baseService';
import { API_GATEWAY_URL } from 'shared/consts/consts';
import { serialize } from 'shared/helpers';

const genericProxyUrl = '/v2/genericProxyRequest/';
const genericProxyAPI = new BaseService(genericProxyUrl);
const flowAttributesUrl = '/api/v1/user/getFlowAttributes';
const flowAttributesApi = new BaseService(flowAttributesUrl);
const genericProxyHeadersAPI = new BaseService(genericProxyUrl, {
	headers: {
		'Content-Type': 'application/json',
		'audit-metadata': '{{audit-metadata}}',
	},
});
const getPathVariablesUrl = 'api/v1/getPathVariables';
const getPathVariablesApi = new BaseService(getPathVariablesUrl);
const getPrioritisationRulesApi = new BaseService(API_GATEWAY_URL);

export const API = {
	getTeamSettings(channels) {
		let params = '?targetApi=getTeamSettingsV2';
		for (const channel of channels) {
			params += `&platforms=${channel}`;
		}

		return genericProxyAPI.getRequest(params);
	},
	saveConnectConfig(data) {
		const params = {
			platforms: ['web', 'mobile'],
			targetApi: 'connectBotConfig',
		};

		return genericProxyAPI.putRequest(data, serialize(params, '?'));
	},
	updateTeamSettings(data) {
		const params = {
			targetApi: 'upsertTeamSettingsV2',
		};

		return genericProxyAPI.putRequest(data, serialize(params, '?'));
	},
	updateVideoSettings(data) {
		const params = {
			targetApi: 'videoConversations',
		};

		return genericProxyAPI.postRequest(data, serialize(params, '?'));
	},
	getCategories(page, size = 100) {
		const params = {
			targetApi: 'getCategory',
			sort: 'updatedOn,desc',
			page,
			size,
		};

		return genericProxyAPI.getRequest(serialize(params, '?'));
	},
	getImportHistory(page, size = 10) {
		const params = {
			targetApi: 'workflowImportHistory',
			importHistoryWorkflow: 'IMPORT_AGENT_PRODUCTIVITY_DATA',
			page,
			size,
		};

		return genericProxyAPI.getRequestWithParams(params);
	},

	deleteFile(fileId) {
		const params = {
			targetApi: 'deleteAgentProductivityImport',
			file_upload_id: fileId,
		};

		return genericProxyAPI.deleteRequest(serialize(params, '?'));
	},
	deleteCategory(categoryId) {
		const params = {
			targetApi: 'deleteCategory',
			categoryId,
		};

		return genericProxyAPI.deleteRequest(serialize(params, '?'));
	},
	getRoutingConfig(data) {
		const params = {
			...data,
			targetApi: 'ruleSet',
		};

		return genericProxyAPI.getRequest(serialize(params, '?'));
	},
	getPrioritisationConfig(data) {
		const params = {
			...data,
			targetApi: 'prioritisationRules',
		};

		return getPrioritisationRulesApi.getRequest(serialize(params, '?'));
	},
	addCategory(data) {
		const params = {
			targetApi: 'addCategory',
		};

		return genericProxyAPI.postRequest(data, serialize(params, '?'));
	},
	updateCategory(data) {
		const params = {
			targetApi: 'updateCategory',
		};

		return genericProxyAPI.putRequest(data, serialize(params, '?'));
	},
	addQuickReply(data) {
		return genericProxyAPI.postRequest(
			data,
			serialize(
				{
					targetApi: 'quickReply',
				},
				'?'
			)
		);
	},
	teamsQuickReply(params) {
		return genericProxyAPI.getRequest(serialize(params, '?'));
	},
	updateQuickReply(data) {
		return genericProxyHeadersAPI.putRequest(
			data,
			serialize(
				{
					targetApi: 'quickReply',
				},
				'?'
			)
		);
	},
	deleteQuickReply(params) {
		return genericProxyHeadersAPI.deleteRequest(serialize(params, '?'));
	},
	getPathVariables(params) {
		return getPathVariablesApi.getRequest(serialize(params, '?'));
	},
	saveOrUpdateBotMailTemplate(data, headers) {
		const saveOrUpdateBotMailTemplateApi = new BaseService(
			`${genericProxyUrl}?targetApi=saveOrUpdateBotMailTemplate`,
			{ headers }
		);

		return saveOrUpdateBotMailTemplateApi.putRequest(data);
	},
	getAllCannedResponses(params, headers) {
		const getAllCannedResponsesApi = new BaseService(genericProxyUrl, { headers });

		return getAllCannedResponsesApi.getRequest(serialize(params, '?'));
	},
	getBotMailTemplate(params) {
		return genericProxyAPI.getRequest(serialize(params, '?'));
	},
	deleteCannedResponse(params, headers) {
		const deleteCannedResponseApi = new BaseService(genericProxyUrl, { headers });

		return deleteCannedResponseApi.deleteRequest(serialize(params, '?'));
	},
	getGloabalFlowAttributes() {
		const params = {
			is_settable: false,
			user_type: 'GLOBAL',
		};

		return flowAttributesApi.getRequest(serialize(params, '?'));
	},
	saveRuleSet: (payload) => {
		const params = {
			targetApi: 'ruleSet',
		};

		return genericProxyAPI.postRequest(payload, serialize(params, '?'));
	},
	savePrioritisationConfig: (payload) => {
		const params = {
			targetApi: 'prioritisationRules',
		};

		return getPrioritisationRulesApi.postRequest(payload, serialize(params, '?'));
	},

	updateRuleSet: (ruleSets, preferredLastAgent) => {
		const body = {
			preferredLastAgent,
			ruleSets,
		};
		const params = {
			targetApi: 'ruleSet',
		};

		return genericProxyAPI.putRequest(body, serialize(params, '?'));
	},
	updatePriorityRuleSet: (data) =>
		getPrioritisationRulesApi.putRequest(
			data,
			serialize(
				{ targetApi: 'prioritisationRules' },

				'?'
			)
		),
	deleteRuleSet: (ruleSetId) => {
		const params = {
			targetApi: 'ruleSet',
			ruleSetId,
		};

		return genericProxyAPI.deleteRequest(serialize(params, '?'));
	},
	deletePrioritisationSet: (ruleSetId) => {
		const params = {
			targetApi: 'prioritisationRules',
			ruleSetId,
		};

		return getPrioritisationRulesApi.deleteRequest(serialize(params, '?'));
	},

	saveExternalLivechatConfig: (data) => {
		const body = {
			authCustomerId: '{{customerId}}',
			botKey: data.botKey,
			bot_ref: '{{botRef}}',
			customer_id: '{{customerId}}',
			end_point_details: {
				authToken: data.inboundApiKey,
				host: data.externalWebhookURL,
				port: 0,
				scheme: 'http',
			},
			errorMessage: 'string',
			idleTimeToReset: 0,
			integration_name: 'EXTERNAL',
			platformConfigMap: {},
			resolutionPhrases: ['string'],
			sendAgentAvailableMessage: true,
		};
		const params = {
			targetApi: 'saveExternalLivechatConfig',
		};

		return genericProxyAPI.postRequest(body, serialize(params, '?'));
	},
	fetchLiveChatIntegrationDetails: () => {
		const params = {
			targetApi: 'fetchExternalLivechatConfig',
			live_chat_provider: 'EXTERNAL',
			bot_ref: '{{botRef}}',
			customer_id: '{{customerId}}',
		};

		return genericProxyAPI.getRequestWithParams(params);
	},
	deleteLiveChatIntegrationDetails: () => {
		const params = {
			targetApi: 'deleteExternalLivechatConfig',
			bot_ref: '{{botRef}}',
			customer_id: '{{customerId}}',
			live_chat_provider: 'EXTERNAL',
		};

		return genericProxyAPI.deleteRequest(serialize(params, '?'));
	},
};
