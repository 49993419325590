import React from 'react';
import { useStyletron } from 'baseui';

function Level2({
	size,
	fillColor,
	height,
	width,
}: {
	size?: number;
	fillColor?: string;
	height: number;
	width: number;
}) {
	const [, theme] = useStyletron();

	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 168 168' id='compass' height={height} width={width}>
			<circle cx='2' cy='146.788' r='2' fill='#2d4356' />
			<path
				fill='#2d4356'
				d='M11 144.788H8a2 2 0 0 0 0 4h3a2 2 0 0 0 0-4zm149 0h-3a2 2 0 0 0 0 4h3a2 2 0 0 0 0-4z'
			/>
			<circle cx='166' cy='146.788' r='2' fill='#2d4356' />
			<path
				fill='#0bceb2'
				d='M118.154 152.788h-8.308a2.006 2.006 0 0 0 0 4h8.308a2.006 2.006 0 0 0 0-4zm-60 0h-8.308a2.006 2.006 0 0 0 0 4h8.308a2.006 2.006 0 0 0 0-4zm45.846 0H64a2 2 0 0 0 0 4h15.94v2H72a2 2 0 0 0 0 4h25a2 2 0 0 0 0-4h-8.94v-2H104a2 2 0 0 0 0-4zm6.5-68a26.5 26.5 0 1 0-26.5 26.5 26.53 26.53 0 0 0 26.5-26.5zm-49 0a22.5 22.5 0 1 1 22.5 22.5 22.5 22.5 0 0 1-22.5-22.5z'
			/>
			<path
				fill='#2d4356'
				d='M98.833 84.788A14.833 14.833 0 1 0 84 99.622a14.85 14.85 0 0 0 14.833-14.834zm-25.666 0A10.833 10.833 0 1 1 84 95.622a10.833 10.833 0 0 1-10.833-10.834zm64.976-9.626 4.221 1.01a59.127 59.127 0 0 0-49.747-49.748l1.009 4.221a55.027 55.027 0 0 1 44.517 44.517zM29.857 94.415l-4.221-1.01a59.127 59.127 0 0 0 49.747 49.748l-1.009-4.222a55.027 55.027 0 0 1-44.517-44.516zm62.76 48.738a59.127 59.127 0 0 0 49.747-49.748l-4.221 1.01a55.027 55.027 0 0 1-44.517 44.516zM74.374 30.645l1.01-4.221A59.127 59.127 0 0 0 25.635 76.17l4.221-1.009a55.027 55.027 0 0 1 44.517-44.517z'
			/>
			<path
				fill='#0bceb2'
				d='m67.567 59.115.385-1.611-14.859-9.124a4 4 0 0 0-5.502 5.502l9.124 14.858 1.611-.385q.715-1.113 1.52-2.16L51 51.789l14.407 8.847q1.045-.806 2.16-1.52zm-2.16 49.826L51 117.788l8.847-14.407q-.806-1.044-1.52-2.159l-1.612-.385-9.124 14.858a4 4 0 0 0 5.502 5.502l14.859-9.124-.385-1.61q-1.114-.716-2.16-1.522zM117 121.788a4 4 0 0 0 3.41-6.093l-9.124-14.858-1.611.385q-.715 1.113-1.52 2.16L117 117.787l-14.407-8.847q-1.045.807-2.16 1.521l-.385 1.611 14.859 9.124a3.991 3.991 0 0 0 2.092.591zm-7.325-53.433 1.61.385 9.125-14.858a4 4 0 0 0-5.502-5.502l-14.859 9.124.385 1.61q1.114.716 2.16 1.521L117 51.788l-8.847 14.408q.806 1.044 1.52 2.159z'
			/>
			<path
				fill='#2d4356'
				d='m23.07 88.679 32.778 7.837a30.242 30.242 0 0 1-1.467-4.463L24 84.788l30.381-7.264a30.242 30.242 0 0 1 1.467-4.463L23.07 80.898a4 4 0 0 0 0 7.78zm53.666-33.51L84 24.79l7.264 30.38a30.261 30.261 0 0 1 4.464 1.468L87.89 23.858a4 4 0 0 0-7.78 0l-7.838 32.779a30.248 30.248 0 0 1 4.464-1.467zm36.883 22.355L144 84.788l-30.381 7.265a30.243 30.243 0 0 1-1.467 4.463l32.778-7.837a4 4 0 0 0 0-7.781l-32.778-7.838a30.243 30.243 0 0 1 1.467 4.464zm37.102 67.264H88.113l7.615-31.848a30.261 30.261 0 0 1-4.464 1.467L84 144.788l-7.264-30.38a30.248 30.248 0 0 1-4.464-1.468l7.615 31.848H17.28a2.017 2.017 0 1 0 0 4H150.72a2.017 2.017 0 1 0 0-4zM108 15.788a3 3 0 1 0-3-3 3.003 3.003 0 0 0 3 3zm0-4.5a1.5 1.5 0 1 1-1.5 1.5 1.501 1.501 0 0 1 1.5-1.5zm43 49a2 2 0 1 0 2 2 2.002 2.002 0 0 0-2-2zm0 3a1 1 0 1 1 1-1 1.001 1.001 0 0 1-1 1zM62 5.212a2 2 0 1 0 2 2 2.002 2.002 0 0 0-2-2zm0 3a1 1 0 1 1 1-1 1.001 1.001 0 0 1-1 1zm91-3a2 2 0 1 0 2 2 2.002 2.002 0 0 0-2-2zm0 3a1 1 0 1 1 1-1 1.001 1.001 0 0 1-1 1zM33 28.788a2 2 0 1 0-2 2 2.002 2.002 0 0 0 2-2zm-3 0a1 1 0 1 1 1 1 1.001 1.001 0 0 1-1-1z'
			/>
			<path
				fill='#0bceb2'
				d='m10.888 69.8 1.487-1.956-.939-.532-.955 2.19h-.031l-.97-2.174-.955.547 1.471 1.909v.032l-2.301-.298v1.064l2.316-.297v.031l-1.486 1.909.891.563 1.018-2.206h.031l.939 2.19.986-.563-1.502-1.877V70.3l2.362.282v-1.064l-2.362.313V69.8zM10.207 8.942l-.856 1.099.513.324.587-1.27h.017l.541 1.261.568-.324-.865-1.082v-.018l1.36.163v-.613l-1.36.18v-.018l.856-1.126-.541-.306-.549 1.261h-.019L9.901 7.22l-.55.316.847 1.099v.018l-1.325-.171v.613l1.334-.171v.018zM164.724 35.632v-1.043l-2.317.307v-.031l1.459-1.918-.921-.522-.936 2.148h-.032l-.95-2.133-.938.538 1.444 1.872v.031l-2.257-.292v1.043l2.272-.291v.031l-1.459 1.872.875.553.999-2.165h.029l.921 2.149.968-.552-1.474-1.842v-.031l2.317.276zM130.96 37.585l1.258-1.655-.794-.45-.807 1.853h-.027l-.821-1.84-.808.463 1.245 1.616v.026l-1.947-.252v.901l1.96-.252v.027l-1.258 1.614.754.477.862-1.866h.026l.794 1.853.834-.476-1.271-1.589v-.027l1.999.239v-.901l-1.999.265v-.026z'
			/>
		</svg>
	);
}

export default Level2;
