import React, { CSSProperties } from 'react';
import { useStyletron } from 'baseui';

function Level6({
	size,
	fillColor,
	height,
	width,
}: {
	size?: number;
	fillColor?: string;
	height: number;
	width: number;
}) {
	const [, theme] = useStyletron();
	const style: CSSProperties = {
		lineHeight: 'normal',
		fontVariantLigatures: 'normal',
		fontVariantPosition: 'normal',
		fontVariantCaps: 'normal',
		fontVariantNumeric: 'normal',
		fontVariantAlternates: 'normal',
		fontFeatureSettings: 'normal',
		textIndent: 0,
		textAlign: 'start',
		textDecorationLine: 'none',
		textDecorationStyle: 'solid',
		textDecorationColor: '#000',
		textTransform: 'none',
		textOrientation: 'mixed',
		isolation: 'auto',
		mixBlendMode: 'normal',
	};

	return (
		<svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 16.933 16.933' id='route'>
			<path
				fill='#38668c'
				d='m 2.646985,15.875015 c 5e-7,0.292242 -0.473737,0.529151 -1.0581219,0.529151 -0.5843849,0 -1.0581222,-0.236909 -1.05812175,-0.529151 1.76e-6,-0.292241 0.47373845,-0.529149 1.05812175,-0.529149 0.5843833,0 1.0581201,0.236908 1.0581219,0.529149 z'
			/>
			<path
				fill='#ffca28'
				d='M8.451155.52966934A.26455693.26460143 0 0 0 8.2010912.7932108v1.324425A.26455693.26460143 0 0 0 8.564304 2.3636078L9.888506 1.8344579a.26455693.26460143 0 0 0 .0372-.4728244L8.60357.56687519A.26455693.26460143 0 0 0 8.451155.52966934zM6.6095364 4.7063231C6.0845623 4.6948097 5.659592 4.2759394 5.6404039 3.7511016 5.6212158 3.2262638 6.0144643 2.7774693 6.5371956 2.7276389c.5227313-.04983.9936651.316584 1.0739509.8355975'
			/>
			<path
				fill='#38668c'
				d='m 8.4532217,2.6059626 c -0.068518,0.00353 -0.1329859,0.033546 -0.1797982,0.083713 L 5.1698407,6.0320015 3.208079,4.5510019 C 3.158233,4.5135171 3.0967496,4.4948493 3.0344809,4.4982936 2.9669113,4.5019396 2.9033071,4.531343 2.8567494,4.5804565 L 0.6030739,6.9611144 C 0.44210694,7.1294199 0.5613703,7.4086076 0.7942385,7.408618 c 5.6238376,0.00368 9.7701345,0 15.3427685,0 0.233859,0.00182 0.354893,-0.2784834 0.193229,-0.4475036 L 14.077077,4.5804565 c -0.09746,-0.1026312 -0.25842,-0.1103733 -0.365279,-0.01757 l -1.59235,1.3667978 c -0.0097,-0.013209 -0.02059,-0.02549 -0.03255,-0.036688 L 8.646453,2.6783074 C 8.5944865,2.6290304 8.5247671,2.6029274 8.4532217,2.6059626 Z'
			/>
			<path
				fill='#ecb200'
				d='M 9.8544065,1.3187434 8.564304,1.8344579 A 0.26455693,0.26460143 0 0 1 8.2010912,1.5884859 V 2.1176358 A 0.26455693,0.26460143 0 0 0 8.564304,2.3636078 L 9.888506,1.8344579 A 0.26455693,0.26460143 0 0 0 9.925705,1.3616335 Z'
			/>
			<path
				fill='#38668c'
				d='M 8.4628798,0.52920621 C 8.3167347,0.53079404 8.1995652,0.65060699 8.2012133,0.79677586 V 2.9105834 c 0,0.3528532 0.5291912,0.3528532 0.5291912,0 V 0.79677586 C 8.7320792,0.64831292 8.6113177,0.52753119 8.4628798,0.52920621 Z'
				color='#000'
				fontFamily='sans-serif'
				fontWeight='400'
				overflow='visible'
				style={style}
			/>
			<path
				fill='#e4eff8'
				d='M 8.4532217,2.6059626 A 0.26455693,0.26460143 0 0 0 8.2734235,2.6896756 L 7.0060543,4.0549239 7.6017644,4.7132607 a 0.13227844,0.1323007 0 0 0 0.1622314,0.027388 L 8.3984557,4.3892598 9.054615,5.1158073 a 0.13227844,0.1323007 0 0 0 0.1777315,0.01757 L 10.371068,4.2895283 8.646453,2.6783081 A 0.26455693,0.26460143 0 0 0 8.4532217,2.6059626 Z'
			/>
			<path
				fill='#e4eff8'
				d='m 13.887979,4.4982936 a 0.26455693,0.26460143 0 0 0 -0.176181,0.064594 l -1.007489,0.8645192 0.141565,0.1415889 0.513044,0.5136474 a 0.12077968,0.1208 0 0 0 0.171531,0 l 0.452596,-0.4511209 0.435029,0.217551 a 0.12077968,0.1208 0 0 0 0.109015,0 L 15.037033,5.5948326 14.077077,4.5804565 a 0.26455693,0.26460143 0 0 0 -0.189098,-0.082163 z'
				color='#000'
				fontFamily='sans-serif'
				fontWeight='400'
				overflow='visible'
				style={style}
			/>
			<path
				fill='#38668c'
				d='M7.6730672 13.758416c5e-7.292242-.473737.529151-1.058122.529151-.5843848 0-1.0581222-.236909-1.0581217-.529151.0000018-.292241.4737384-.529149 1.0581217-.529149.5843834 0 1.0581202.236908 1.058122.529149zM12.580863 11.244952c.000002.292242-.473736.529152-1.058122.529152-.584385 0-1.058124-.236909-1.058122-.529152.000003-.292241.47374-.529148 1.058122-.529148.584383 0 1.058119.236907 1.058122.529148zM6.6149383 11.112666c.0000016.292242-.473736.529152-1.0581215.529152-.5843858 0-1.058124-.23691-1.0581224-.529152.0000029-.292241.4737397-.529148 1.0581224-.529148.5843824 0 1.0581186.236907 1.0581215.529148zM11.641021 8.5992054c.000001.2922418-.473737.5291509-1.058121.5291512-.5843859 4e-7-1.0581243-.2369088-1.0581236-.5291512.0000026-.2922412.4737401-.5291486 1.0581236-.5291482.584382 3e-7 1.058118.2369075 1.058121.5291482z'
			/>
			<path
				fill='#ffca28'
				d='M4.774164 14.336003l.1952735.492172.4901364-.195307-.1952735-.492171zm-.9841783.390612l.1952734.492172.4920892-.195305-.1952734-.492173zm-.9822256.392567l.1952736.492171.492089-.19726-.1972261-.490218zM9.7477792 12.101698l.2226117.480454.4803731-.220696-.222611-.480454zM8.7870338 12.545044L9.0096455 13.025498 9.4900182 12.804802 9.2674064 12.324348zM7.8262882 12.988389L8.0489 13.468843 8.5292728 13.248147 8.306661 12.767693zM7.2326569 11.357582l.5291912.0059.00586-.529281-.5291911-.0059zm1.0583822.01367l.5272384.0059.00781-.52928-.529191-.0059zm1.0564294.01367l.5291911.0059.00781-.529281-.529191-.0059zM8.6737751 9.1349956L8.9022451 9.611543 9.3787122 9.3810816 9.1502423 8.9045342zM7.7208405 9.5939655L7.9493106 10.070512 8.4257778 9.8420045 8.1973079 9.3654571zm-.9529344.4609235l.22847.476547.4764671-.230462-.2284699-.476547z'
				color='#000'
				fontFamily='sans-serif'
				fontWeight='400'
				overflow='visible'
				style={style}
			/>
			<path
				fill='#2c5170'
				d='M2.1081075 15.414077c-.068485.263469-.517025.460692-1.0483052.46094-.18192912-.000036-.36076678-.02353-.51924437-.06821-.00621.02261-.009488.04539-.009816.06821.00000176.292241.47373847.529149 1.05812177.529149.5843832 0 1.0581199-.236908 1.0581217-.529149-.0000847-.191026-.2060464-.367199-.5388779-.46094zM6.0858808 13.758417c-.1819291-.000036-.3607668-.02353-.5192444-.06821-.00621.02261-.00949.04539-.00982.06821 7e-7.292242.4737377.52915 1.0581218.52915.5843841 0 1.0581212-.236908 1.0581219-.52915C7.6729747 13.567392 7.4670131 13.391219 7.134184 13.297478 7.0657 13.560946 6.6171598 13.758169 6.0858808 13.758417zM10.993644 11.244955c-.181929-.000036-.360767-.02353-.519244-.06821-.0062.02261-.0095.04539-.0098.06821.000001.292242.473738.52915 1.058121.52915.584384 0 1.058121-.236908 1.058122-.52915-.000085-.191025-.206046-.367198-.538885-.460939-.0685.263468-.517036.460691-1.048315.460939zM5.0277591 11.112667c-.1819292-.000036-.3607669-.02353-.5192445-.06821-.00621.02261-.00949.04539-.00982.06821 7e-7.292242.4737378.52915 1.0581218.52915.5843841 0 1.0581211-.236908 1.0581218-.52915-.0000854-.191025-.2060468-.367198-.5388759-.460939-.068484.263468-.5170243.460691-1.0483032.460939zM10.053837 8.5992053c-.1819288-.0000358-.3607664-.023529-.5192439-.068211-.00621.022607-.00949.045392-.00982.068211 8e-7.2922416.473738.52915 1.0581219.5291499.584383 0 1.058121-.2369084 1.058122-.5291499-.000085-.1910256-.206048-.3671988-.538876-.4609393-.06849.2634684-.517024.4606912-1.048304.4609393z'
			/>
			<path
				fill='#ecb200'
				d='M 6.5374425,2.7273998 C 6.014573,2.777169 5.6212193,3.2261117 5.6405189,3.7510773 5.6549053,4.136796 5.8917518,4.4791219 6.2475957,4.6285155 6.200744,4.5180939 6.1743107,4.4000869 6.1695799,4.2802272 6.1502803,3.7552616 6.5436339,3.3063189 7.0665034,3.2565497 7.2292497,3.2412775 7.3932469,3.2664847 7.5438984,3.3299277 7.3898867,2.9642932 7.0328079,2.7256586 6.6361248,2.7232651 c -0.032943,-2.626e-4 -0.065878,0.00112 -0.098682,0.00413 z'
			/>
			<path
				fill='#c4dcf0'
				d='M9.8213402 3.7758799L8.7032856 4.604227C8.6783613 4.62297 8.6476213 4.632303 8.6164864 4.630581l.4381285.4852262c.045753.051011.122879.058635.1777315.01757L10.371068 4.2895286zM7.8693948 3.8601099L7.287195 4.2690931c-.022244.012218-.047533.017779-.072849.01602l.3874187.4281487c.041132.045746.1083654.057096.1622314.027388L8.3984557 4.3892609z'
			/>
			<path
				fill='#e4eff8'
				d='M 3.0344809,4.4982936 A 0.26455693,0.26460143 0 0 0 2.8567494,4.5804566 L 2.1246406,5.3535116 2.2806723,5.5095695 2.8427994,6.072308 a 0.13227844,0.1323007 0 0 0 0.1875481,0 L 3.5263421,5.5782967 4.0027036,5.8165176 a 0.13227844,0.1323007 0 0 0 0.1193486,0 L 4.5803307,5.5870814 3.208079,4.5510019 A 0.26455693,0.26460143 0 0 0 3.0344809,4.4982936 Z'
				color='#000'
				fontFamily='sans-serif'
				fontWeight='400'
				overflow='visible'
				style={style}
			/>
			<path
				fill='#2c5170'
				d='M5.4250713 5.7570914L5.1698407 6.0320015 4.8960104 5.8253023 6.5157428 7.0747988zM12.33438 5.7452065l-.214932.1844788a.26524174.26528637 0 0 0-.0016-.00206l-.000529.00103c.108731.097244.785866.7212323.937224.860902z'
			/>
			<path
				fill='#c4dcf0'
				d='M14.677437 5.2145062L14.166459 5.469263a.12784188.12786339 0 0 1-.115215 0L13.590899 5.2387933 13.111438 5.7162686a.12784188.12786339 0 0 1-.08112.037206l.328596.3291683a.12077968.1208 0 0 0 .171531 0L13.983041 5.631522 14.41807 5.849073a.12077968.1208 0 0 0 .109015 0l.509944-.2542402zM3.0344809 4.4982936c-.06757.00365-.1311738.033049-.1777315.082163l-.7321088.773055c.2394937.2394913.4787965.4791738.7181588.7187964.051712.051986.1358358.051986.1875481 0L3.2013622 5.9017811C3.0188751 5.718958 2.8363412 5.5361819 2.6537017 5.3535115L3.3279443 4.6414328 3.208079 4.5510019C3.1582329 4.5135172 3.0967495 4.4948493 3.0344809 4.4982936zM3.7030402 5.4020856L3.5263421 5.5782967 4.0027036 5.8165176c.037522.01897.081827.01897.1193486 0L4.3266499 5.7142015z'
				color='#000'
				fontFamily='sans-serif'
				fontWeight='400'
				overflow='visible'
				style={style}
			/>
		</svg>
	);
}

export default Level6;
