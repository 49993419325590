import React, { useEffect, useMemo, useRef } from 'react';
import { useStyletron } from 'baseui';
import { PLACEMENT } from 'baseui/tooltip';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';

import Popover from 'components/UI/ENGTTooltip/ENGTTooltip';

import { IObjProps } from 'shared/consts/types';
import { getErrorMessage } from 'shared/helpers';
import TooltipIcon from 'shared/icons/node/Tooltip.svg';

import FormLabel from '../Form/FormLabel/FormLabel';
import FormatSupport from '../FormatSupport/FormatSupport';

import QuillEmoji from './QuillEmoji';

import 'quill-emoji/dist/quill-emoji.css';
import './QuillEditor.scss';

import 'quill-emoji';

export interface IQuillEditorProps {
	backgroundColor?: string;
	borderless?: boolean;
	marginBottom?: string;
	borderBottom?: string;
	containerCss?: IObjProps;
	name: string;
	id?: string;
	toolbarOptions?: Array<any>;
	formats?: Array<any>;
	formatSupport?: boolean;
	handlers?: IObjProps;
	placeholder: string;
	width?: string;
	height?: string;
	editorHeight?: string;
	toolbarHeight?: string;
	value?: string;
	label?: string;
	labelClassName?: IObjProps;
	tooltip?: string | React.ReactElement;
	inputRef?: any;
	topHeight?: any;
	usage?: 'primary' | 'carousel' | 'email';
	externalLeftPadding?: string;
	externalTopMargin?: string;
	[key: string]: any;
	onChange?: (...args: any) => any;
	onKeyDown?: (...args: any) => any;
	onInputRefBind?: (...args: any) => any;
	onFocus?: (...args: any) => any;
	onBlur?: (...args: any) => any;
	dataQa?: string;
	containerFontSize?: string;
	isDisabled?: boolean;
	isRtlDirection?: boolean;
	showQuillEmoji?: boolean;
	emojiProps?: IObjProps;
}

function QuillEditor(props: IQuillEditorProps) {
	const {
		defaultValue,
		backgroundColor,
		borderless,
		borderWidth,
		borderBottom,
		marginBottom = '0.25rem',
		name,
		id = '',
		toolbarOptions = ['bold', 'italic', 'underline', 'strike', 'link'],
		formats = ['bold', 'italic', 'underline', 'strike', 'link'],
		formatSupport = false,
		label,
		labelClassName = {},
		placeholder,
		width = '20.3rem',
		height = '100%',
		editorHeight = '7.5rem',
		toolbarHeight = '2.5rem',
		tooltip,
		inputRef,
		usage = 'primary',
		externalLeftPadding = null,
		externalTopMargin = null,
		topHeight,
		onChange,
		onKeyDown,
		onInputRefBind,
		onFocus,
		onBlur,
		containerCss = {},
		handlers = {},
		dataQa,
		containerFontSize,
		isDisabled = false,
		isRtlDirection = false,
		showQuillEmoji = false,
		emojiProps = {},
		...rest
	} = props;

	const [css, theme]: any = useStyletron();
	const { t } = useTranslation(['components']);

	const methods = useFormContext();

	const modules = useMemo(
		() => ({
			toolbar: {
				container: toolbarOptions,
				handlers,
			},
			'emoji-toolbar': true,
		}),
		[]
	);

	const borderThickness = borderWidth || (borderless || id == 'notes' ? '0px' : '1px');
	const borderRadius = borderless ? '0px' : '0.375rem';
	const reference = useRef<ReactQuill>(null);
	const wrapperRef: any = useRef<HTMLDivElement>(document.createElement('div'));

	const getBorder = (errorMsg: any) => {
		if (errorMsg?.message?.length > 0) {
			return `${borderThickness} solid ${theme.inputErrorMsgColor}`;
		}

		return `${borderThickness} solid ${theme.colors.inputBorderColor}`;
	};

	const quillContainerCss = (errorMsg: any) => `height: ${editorHeight}; 
				position: relative;
				background-color: ${backgroundColor || theme.colors.inputFillPrimary}; 
				border: ${getBorder(errorMsg)};
				border-bottom: ${borderBottom || 'none'}; 
				overflow-x: hidden; 
				overflow-y: auto;
				border-top-left-radius: ${borderRadius};
				border-top-right-radius: ${borderRadius};
				font-size: ${containerFontSize || '0.8125rem'}`;

	const quillToolbarCss = (errorMsg: any) => `
	top: ${usage === 'email' ? topHeight : editorHeight}; 
	width: 100%;
	background-color: ${backgroundColor || theme.colors.inputFillPrimary};
	border: ${getBorder(errorMsg)};
	border-top: ${usage === 'carousel' ? `1px solid ${theme.colors.accent100}` : ''};
	border-bottom-right-radius: ${usage === 'email' ? '0rem' : borderRadius};
	border-bottom-left-radius: ${usage === 'email' ? '0rem' : borderRadius};
	marging-top: '1px';
	${toolbarHeight === '0px' ? 'display: none; padding: 0px !important;' : 'display: table; padding: 8px !important'}`;

	useEffect(() => {
		const errorMsg = getErrorMessage(name, methods?.errors, true)
			? getErrorMessage(name, methods?.errors, true)
			: methods?.errors[name];

		const quillList = document.querySelectorAll('.quill');
		for (let i = 0; i < quillList.length; i++) {
			const quillElement = quillList[i] as HTMLElement;
			// Code needs refactoring changes to make sure it scales @Ajay @Utkarsh
			const quillCss = `width: ${width}; position: relative; overflow: visible;`;
			if (quillElement.id === 'canned-response' && width !== '0%') {
				quillElement.style.cssText += quillCss;
			} else if (quillElement.id === 'canned-response') {
				return;
			} else if (quillElement.id !== 'canned-response') {
				quillElement.style.cssText += quillCss;
			}
			for (let i = 0; i < quillElement.childNodes.length; i++) {
				const quillChildNode = quillElement.childNodes[i];
				const quillChildElement = quillChildNode as HTMLElement;
				if (quillChildElement.classList.contains('ql-toolbar')) {
					if (quillElement.id === id) {
						quillChildElement.classList.add(`engt-quill-toolbar-css-${id}`);
					}
					quillChildElement.style.cssText += quillToolbarCss(errorMsg);
				} else if (quillChildElement.classList.contains('ql-container')) {
					if (quillElement.id === id) {
						quillChildElement.classList.add(`engt-quill-container-css-${id}`);
					}
					quillChildElement.style.cssText += quillContainerCss(errorMsg);
				}
			}
		}

		/* added these changes for quill dark mode, the hover color is not getting picked
		 * up here everything else looks good.
		 */
		const toolbarStrokeFormats = document.querySelectorAll('.ql-stroke');
		const toolbarFillFormats = document.querySelectorAll('.ql-fill');
		const toolbarOptionsFormats = document.getElementsByClassName('ql-picker-options') || [];
		for (let i = 0; i < toolbarStrokeFormats.length; i++) {
			const toolBarElement = toolbarStrokeFormats[i] as HTMLElement;
			toolBarElement.style.cssText += `fill: none; stroke: ${theme.colors.primaryA}; :hover: {
				color: red;
			}`;
		}
		for (let i = 0; i < toolbarFillFormats.length; i++) {
			const toolBarElement = toolbarFillFormats[i] as HTMLElement;
			toolBarElement.style.cssText += `fill: ${theme.colors.primaryA} `;
		}
		for (let i = 0; i < toolbarOptionsFormats.length; i++) {
			const options: any = toolbarOptionsFormats[i];
			if (options?.style) {
				options.style.backgroundColor = theme.colors.backgroundPrimary;
				options.style.color = theme.colors.primaryA;
			}
		}
	});

	useEffect(() => {
		const editor = reference.current && reference.current.getEditor();
		if (inputRef) {
			inputRef.current = reference.current;
		}
		if (onInputRefBind) {
			onInputRefBind(editor);
		}
		applyAccessibilityHacks(editor);
	}, [inputRef]);

	useEffect(() => {
		const editor = reference?.current?.getEditor();
		if (isRtlDirection) {
			editor?.root.classList.add('ql-editor-rtl');
		} else {
			editor?.root.classList.remove('ql-editor-rtl');
		}
	}, [isRtlDirection]);

	useEffect(() => {
		const editor = reference.current;
		if (editor) {
			editor.getEditor().root.dataset.placeholder = placeholder;
		}
	}, [placeholder]);

	const isTextAfterCursor = () => {
		const editor = reference?.current?.getEditor();
		const range = editor?.getSelection(true);
		const contents = editor?.getContents(Number(range?.index), editor?.getLength());
		let textAfterCursor = '';
		if (contents?.ops) {
			textAfterCursor = contents?.ops?.map((op) => op.insert).join('');
		}

		return textAfterCursor.trim().length > 0;
	};
	const onKeyDownFunction = (e: any) => {
		onKeyDown && onKeyDown(e);
		if (e.keyCode === 88 && e.shiftKey && e.metaKey) {
			const strikeBtn: any = document.getElementsByClassName('ql-strike')[0];
			strikeBtn && strikeBtn.click();
			e.stopPropagation();
			e.preventDefault();

			return false;
		}
		if (e.keyCode === 13 && !isTextAfterCursor()) {
			document.execCommand('insertHTML', false, '<br>');
			e.preventDefault();
		}
	};
	const applyAccessibilityHacks = (editor: any) => {
		// Get ref to the toolbar, its not available through the quill api ughh
		const query = editor.container.parentElement.getElementsByClassName('ql-toolbar');
		if (query.length !== 1) {
			// No toolbars found OR multiple which is not what we expect either
			return;
		}

		const toolBar = query[0];
		// apply aria labels to base buttons
		const buttons = toolBar.getElementsByTagName('button');
		for (let i = 0; i < buttons.length; i++) {
			const button = buttons[i];
			const className = button.getAttribute('class').toLowerCase();

			if (className.indexOf('bold') >= 0) {
				button.setAttribute('aria-label', 'Toggle bold text');
			} else if (className.indexOf('italic') >= 0) {
				button.setAttribute('aria-label', 'Toggle italic text');
			} else if (className.indexOf('underline') >= 0) {
				button.setAttribute('aria-label', 'Toggle underline text');
			} else if (className.indexOf('blockquote') >= 0) {
				button.setAttribute('aria-label', 'Toggle blockquote text');
			} else if (className.indexOf('list') >= 0 && button.value === 'ordered') {
				button.setAttribute('aria-label', 'Toggle ordered list');
			} else if (className.indexOf('list') >= 0 && button.value === 'bullet') {
				button.setAttribute('aria-label', 'Toggle bulleted list');
			} else if (className.indexOf('strike') >= 0) {
				button.setAttribute('aria-label', 'Toggle text with strike');
			} else if (className.indexOf('link') >= 0) {
				button.setAttribute('aria-label', 'Toggle to get link');
			} else if (className.indexOf('image') >= 0) {
				button.setAttribute('aria-label', 'Toggle to add image');
			} else if (className.indexOf('code-block') >= 0) {
				button.setAttribute('aria-label', 'Toggle code block');
			} else if (className.indexOf('clean') >= 0) {
				button.setAttribute('aria-label', 'Toggle clean text');
			} else if (className.indexOf('indent') >= 0 && button.value === '-1') {
				button.setAttribute('aria-label', 'Toggle indentation');
			} else if (className.indexOf('indent') >= 0 && button.value === '+1') {
				button.setAttribute('aria-label', 'Toggle indentation');
			} else if (className.indexOf('emoji') >= 0) {
				button.setAttribute('aria-label', 'Toggle to add emoji');
			}
		}

		// Make pickers work with keyboard and apply aria labels
		// FIXME: When you open a submenu with the keyboard and close it with the mouse by click somewhere else, the menu aria-hidden value is incorrectly left to `false`
		const pickers = toolBar.getElementsByClassName('ql-picker');
		for (let i = 0; i < pickers.length; i++) {
			const picker = pickers[i];

			const label = picker.getElementsByClassName('ql-picker-label')[0];
			const optionsContainer = picker.getElementsByClassName('ql-picker-options')[0];
			const options = optionsContainer.getElementsByClassName('ql-picker-item');

			label.setAttribute('role', 'button');
			label.setAttribute('aria-haspopup', 'true');
			label.setAttribute('tabindex', '0');
			label.setAttribute('aria-label', 'ql-picker-label');

			if (i === 0) {
				// HACK ALERT
				// This is our size select box.. Works for us as we only have the one drop box
				label.setAttribute('aria-label', 'Font Size');
			}

			optionsContainer.setAttribute('aria-hidden', 'true');
			optionsContainer.setAttribute('aria-label', 'submenu');

			for (let x = 0; x < options.length; x++) {
				const item = options[x];
				item.setAttribute('tabindex', '0');
				item.setAttribute('role', 'button');

				// Read the css 'content' values and generate aria labels
				const size = window.getComputedStyle(item, ':before').content.replace('"', '');
				item.setAttribute('aria-label', size);
				item.addEventListener('keyup', (e: any) => {
					if (e.keyCode === 13) {
						item.click();
						optionsContainer.setAttribute('aria-hidden', 'true');
					}
				});
			}

			label.addEventListener('keyup', (e: any) => {
				if (e.keyCode === 13) {
					label.click();
					optionsContainer.setAttribute('aria-hidden', 'false');
				}
			});
		}
	};

	const renderErrorMessage = () => {
		const errorMsg = getErrorMessage(name, methods?.errors, true)
			? getErrorMessage(name, methods?.errors, true)
			: methods?.errors[name];

		return errorMsg ? (
			<div
				className={css({
					fontSize: '0.875rem',
					lineHeight: '1.25rem',
					marginTop: externalTopMargin || '0rem',
					marginBottom: '0.5rem',
					paddingTop: '0.5rem',
					paddingLeft: externalLeftPadding || '0rem',
					paddingRight: '0.5rem',
					paddingBottom: '0.5rem',
					textAlign: 'left',
					color: theme.inputErrorMsgColor,
				})}
			>
				{errorMsg.message}
			</div>
		) : (
			''
		);
	};

	const renderFieldLabel = () =>
		label && <FormLabel id={name} label={label} tooltip={tooltip} className={labelClassName} />;

	const errorMsgToDisplay = renderErrorMessage();

	return (
		<>
			<div
				className={`${css({
					userSelect: 'auto',
					marginBottom: borderless ? '0px' : errorMsgToDisplay ? '0px' : marginBottom,
					textAlign: 'left',
					position: 'relative',
				})} engt-quill-wrapper`}
				ref={wrapperRef}
				data-qa={dataQa}
			>
				{renderFieldLabel()}
				{showQuillEmoji && <QuillEmoji quillRef={reference} {...emojiProps} />}
				<ReactQuill
					id={id}
					ref={reference}
					className={css({
						height,
						border: 'none',
						color: theme.colors.primaryA,
						display: 'flex',
						flexDirection: 'column-reverse',
						...containerCss,
					})}
					theme='snow'
					modules={modules}
					formats={formats}
					placeholder={placeholder}
					onChange={onChange}
					onKeyDown={onKeyDownFunction}
					onFocus={onFocus}
					onBlur={onBlur}
					bounds='.engt-quill-wrapper'
					preserveWhitespace
					readOnly={isDisabled}
					{...rest}
				/>

				{formatSupport && (
					<div
						className={css({
							position: 'absolute',
							right: '0.625rem',
							bottom: '0.925rem',
							display: 'flex',
							gap: '0.5rem',
						})}
					>
						<div
							className={css({
								fontSize: '0.625rem',
								lineHeight: '0.75rem',
								color: theme.colors.primaryA,
							})}
						>
							{t('components:quill.formattingSupport')}
						</div>
						<Popover
							content={<FormatSupport />}
							ignoreBoundary
							placement={PLACEMENT.bottomRight}
							usage='channel'
							type='click'
						>
							<img
								src={TooltipIcon}
								alt='tooltip'
								className={css({
									fontSize: '0.625rem',
									lineHeight: '0.75rem',
									marginBottom: '0.05rem',
									cursor: 'pointer',
								})}
							/>
						</Popover>
					</div>
				)}
			</div>
			{usage !== 'email' && errorMsgToDisplay}
		</>
	);
}

export default QuillEditor;
