import { useStyletron } from 'baseui';

import { NEW_MESSAGE_INDICATOR } from 'shared/consts/QAConsts';
import { IObjProps } from 'shared/consts/types';

enum IBadgeSizeProp {
	mini = 'mini',
	default = 'default',
}
function ENGTInfoBadge({
	children,
	className,
	size,
}: {
	children: React.ReactNode | string;
	className?: IObjProps;
	size: IBadgeSizeProp[keyof IBadgeSizeProp];
}) {
	const [css, theme]: any = useStyletron();
	let styling = {};

	if (size === IBadgeSizeProp.mini) {
		styling = {
			borderTopRightRadius: '0.25rem',
			borderTopLeftRadius: '0.25rem',
			borderBottomRightRadius: '0.25rem',
			borderBottomLeftRadius: '0.25rem',
			paddingLeft: '0.325rem',
			paddingRight: '0.325rem',
			paddingBottom: '0.125rem',
			paddingTop: '0.125rem',
			lineHeight: '0.5rem',
			fontSize: '0.5rem',
		};
	} else {
		styling = {
			borderTopRightRadius: '0.5rem',
			borderTopLeftRadius: '0.5rem',
			borderBottomRightRadius: '0.5rem',
			borderBottomLeftRadius: '0.5rem',
			paddingLeft: '0.5rem',
			paddingRight: '0.5rem',
			paddingBottom: '0.25rem',
			paddingTop: '0.375rem',
			lineHeight: '0.5rem',
			fontSize: '0.625rem',
		};
	}

	return (
		<span
			className={css({
				color: theme.colors.primaryBtnTextColor,
				background: theme.engatiOrange,
				fontWeight: 600,
				display: 'flex',
				alignItems: 'center',
				...styling,
				...className,
			})}
			data-qa={NEW_MESSAGE_INDICATOR}
		>
			{children}
		</span>
	);
}

export default ENGTInfoBadge;
