import { ComponentType, default as React, useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { KIND, SIZE } from 'baseui/button';
import { HeadingLevel } from 'baseui/heading';
import { PLACEMENT, Popover } from 'baseui/popover';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ApiTriggerMessageTag from 'components/shared/ApiTriggerMessageTag/ApiTriggerMessageTag';
import TemplatePreviewCards from 'components/shared/MessageTemplatesRevamp/TemplatePreviewCards/TemplatePreviewCards';
import { VerticalDivider } from 'components/UI/Dividers/VerticalDivider';
import { default as Button, default as ENGTButton } from 'components/UI/ENGTButton/ENGTButton';
import ENGTInfoBadge from 'components/UI/ENGTInfoBadge/ENGTInfoBadge';
import ENGTTooltip from 'components/UI/ENGTTooltip/ENGTTooltip';

import { DEFAULT_LANGUAGE_CODE, TEXT_MEDIA_TEMPLATE_BUTTON_LIMIT } from 'shared/consts/consts';
import { CREATE_MESSAGE_TEMPLATES_PREVIEW_BOX } from 'shared/consts/QAConsts';
import { IObjProps } from 'shared/consts/types';
import { CTA_BUTTONS } from 'shared/enum';
import { PlgGTMTrackingIds } from 'shared/enum/trackingEnums';
import {
	decodeAndSanitizeInput,
	getSizeInMbOrKb,
	getTemplateUrlBasedOnCTAVersion,
	hexToRgb,
	waRtlLanguageCodeMapper,
} from 'shared/helpers';
import { checkIfValidUrl } from 'shared/helpers/broadcastHelpers';
import { linkify, processQuillContent } from 'shared/helpers/quillHelper';
import { getHtmlFormatText } from 'shared/helpers/templateHelpers';
import ic_document from 'shared/icons/ic_document.svg';
import ic_pdf_upload from 'shared/icons/ic_pdf_upload.svg';
import ic_whatsapp_template_image from 'shared/icons/ic_whatsapp_template_image.svg';
import VideoIcon from 'shared/icons/VideoIcon';
import WhatsappTemplateBackground from 'shared/icons/WhatsappTemplateBackground.svg';
import { sendPLGButtonClickEventToGA4 } from 'shared/trackingHelpers';
import { componentFormat, componentType, getCarouselPayload } from 'shared/whatsappTemplateHelpers';

import { isCreateMessageTemplatesV2EnabledSelector, isWhatsappAcceleratorTrialEnabled } from 'store/App/User';
import { API } from 'store/Broadcast/api';
import { updateMessagesWithTransaltedTextAction } from 'store/Message';
import { selectedUserSelector } from 'store/Message/messageList/selectors';
import { RootState } from 'store/rootReducer';

import { ThreeColumnRouteLinks } from 'router/links/ThreeColumnRouteLinks';

import TemplateButtonsPopOver from '../MessageTemplatesRevamp/TemplateButtonsPopOver';

export interface ITemplateProps {
	values?: any;
	redirectUrl?: string;
	template: any;
	buttonParams?: any;
	components?: any;
	showTranslatedTemplate?: any;
	addMargin?: boolean;
	templateName?: string;
	targetWhatsAppNumber?: string;
	formSuffix?: string;
	stageIndex?: number;
	messageId?: string;
	messageStatus?: JSX.Element | null;
	isLibraryTemplateCard?: boolean;
	setViewMode?: Function;
	setTemplateIsFromLibrary?: Function;
	setSelectedTemplateFromLibrary?: Function;
	showApiTriggerMessageTag?: boolean;
	trialTemplate?: boolean;
}

export const TEMPLATE_VARIABLE_LENGTH = 5;

const borderRadiusCss = {
	borderTopLeftRadius: '0.5rem',
	borderTopRightRadius: '0.5rem',
	borderBottomLeftRadius: '0.5rem',
	borderBottomRightRadius: '0.5rem',
};

const plgCss = (theme: any) => ({
	backgroundColor: theme.colors.primaryB,
	paddingLeft: '0.5rem',
	paddingRight: '0.5rem',
	fontFamily: 'Helvetica, Hind !important',
	maxHeight: '70vh',
	overflow: 'auto',
	...borderRadiusCss,
});

const containerCss = (theme: any, addMargin: boolean) => ({
	backgroundColor: theme.colors.primaryB,
	borderBottomWidth: '1px',
	borderLeftWidth: '1px',
	borderRightWidth: '1px',
	borderTopWidth: '1px',
	borderBottomColor: theme.colors.accent100,
	borderLeftColor: theme.colors.accent100,
	borderRightColor: theme.colors.accent100,
	borderTopColor: theme.colors.accent100,
	borderBottomStyle: 'solid',
	borderLeftStyle: 'solid',
	borderRightStyle: 'solid',
	borderTopStyle: 'solid',
	boxSizing: 'border-box',
	marginLeft: addMargin ? '0.5rem' : '0rem',
	marginRight: addMargin ? '0.5rem' : '0rem',
	position: 'relative',
	maxHeight: '70vh',
	overflow: 'auto',
	...borderRadiusCss,
});

const buttonCss = ($theme: any) => ({
	marginTop: '0.25rem',
	fontWeight: `500 !important`,
	fontSize: '0.875rem !important',
	lineHeight: '1.25rem !important',
	boxShadow: `0px 1px 2px ${hexToRgb($theme.colors.primaryA, 0.11)} ! important`,
	background: `${$theme.colors.accent100} !important`,
	':hover': {
		cursor: 'default',
	},
});

const buttonCssForPlg = ($theme: any) => ({
	color: $theme.whatsappBtnColor,
	boxShadow: 'unset',
	paddingTop: '0.625rem !important',
	paddingBottom: '0.425rem !important',
	fontSize: '0.875rem !important',
	lineHeight: '1rem !important',
	marginTop: '0.5rem !important',
	marginBottom: '0.5rem !important',
	':hover': {
		cursor: 'default',
	},
});

const headerCss = (theme: any) => ({
	boxSizing: 'border-box',
	width: '21rem',
	...borderRadiusCss,
});

const mediaCss = (theme: any) => ({
	display: 'flex',
	justifyContent: 'center',
	borderBottomWidth: '0.063rem',
	borderLeftWidth: '0.063rem',
	borderRightWidth: '0.063rem',
	borderTopWidth: '0.063rem',
	borderBottomColor: theme.colors.inputBorderColor,
	borderLeftColor: theme.colors.inputBorderColor,
	borderRightColor: theme.colors.inputBorderColor,
	borderTopColor: theme.colors.inputBorderColor,
	borderBottomStyle: 'solid',
	borderLeftStyle: 'solid',
	borderRightStyle: 'solid',
	borderTopStyle: 'solid',
	borderTopLeftRadius: '0.375rem',
	borderTopRightRadius: '0.375rem',
	borderBottomLeftRadius: '0.375rem',
	borderBottomRightRadius: '0.375rem',
	marginTop: '1rem',
	marginRight: '1rem',
	marginBottom: '1rem',
	marginLeft: '1rem',
});

function TemplatePreview({
	template,
	redirectUrl,
	buttonParams,
	values,
	components,
	showTranslatedTemplate = false,
	addMargin = false,
	templateName = '',
	targetWhatsAppNumber = '',
	formSuffix = '',
	stageIndex = -1,
	messageId,
	messageStatus,
	isLibraryTemplateCard = false,
	setViewMode,
	setSelectedTemplateFromLibrary,
	setTemplateIsFromLibrary,
	showApiTriggerMessageTag = false,
	trialTemplate = false,
}: ITemplateProps) {
	const [css, theme]: [any, any] = useStyletron();
	const { t } = useTranslation(['common', 'pages']);
	const dispatch = useDispatch<any>();
	const TEMPLATE_PARAMS = new RegExp(/{{\d+}}/gm);
	const [fileSize, setFileSize] = useState<any>('');
	const [showAllButtons, setShowAllButtons] = useState<boolean>(false);
	const [seeAllOptionsButtonClicked, setSeeAllOptionsButtonClicked] = useState<boolean>(false);

	const selectedUser = useSelector(selectedUserSelector);
	const isWhatsappAcceleratorTrialCustomer = useSelector(isWhatsappAcceleratorTrialEnabled);
	const agentPreferredLanguage = useSelector(
		(state: RootState) => state.User.profile.data.preferredLanguage || DEFAULT_LANGUAGE_CODE
	);
	const getQueryParams = new URLSearchParams(location.search);

	const navigate = useNavigate();

	const hoverCss: any = () => ({
		...headerCss(theme),
		position: 'relative',
		width: '100%',
		boxShadow: `1px 2px 2px ${hexToRgb(theme.colors.primaryA, 0.11)} !important`,
	});

	const templateData =
		stageIndex === -1
			? isLibraryTemplateCard && !trialTemplate
				? template.payload
				: template
			: template[stageIndex];
	const rtlLanguageCodeMapper: any = waRtlLanguageCodeMapper();
	const templateLanguage = templateData?.language;
	const [isHover, setIsHover] = useState<boolean>(false);
	const [buttonData, setButtonDataToBeShown] = useState<any>({});
	const [isLibraryTemplateHover, setLibraryTemplateHover] = useState<boolean>(false);
	const isCreateMessageTemplatesV2Enabled = useSelector(isCreateMessageTemplatesV2EnabledSelector);
	const [showCarouselPreview, setShowCarouselPreview] = useState<boolean>(false);
	const [carouselData, setCarouselData] = useState({ components: [], template: {} });

	useEffect(() => {
		if (components && components[0]?.parameters?.[0]) {
			const document = components[0]?.parameters[0]?.document;
			if (document) {
				API.getFileSize(document?.link).then((resp: any) =>
					resp && resp.data?.size ? setFileSize(`${resp.data.size.toFixed(2)} MB`) : '0.00 MB'
				);
			}
		}
		if (values && values(`${formSuffix}fileUrlDoc`)) {
			API.getFileSize(values(`${formSuffix}fileUrlDoc`)).then((resp: any) =>
				resp && resp.data?.size ? setFileSize(`${resp.data.size.toFixed(2)} MB`) : '0.00 MB'
			);
		}
	}, []);

	const [header, setHeader] = React.useState<any>();
	const [body, setBody] = React.useState<any>();
	const [footer, setFooter] = React.useState<any>();
	const [buttons, setButtons] = React.useState<any>();

	useEffect(() => {
		if (buttons?.length > TEXT_MEDIA_TEMPLATE_BUTTON_LIMIT && !showAllButtons) {
			setShowAllButtons(false);
		}
	}, [buttons]);

	const setComponentData = () => {
		if (templateData && templateData?.components?.length > 0) {
			if (
				isCreateMessageTemplatesV2Enabled &&
				templateData.components?.some((component: any) => component?.type === 'CAROUSEL')
			) {
				carouselData.components = components;
				carouselData.template = templateData;
				setCarouselData(carouselData);
				setShowCarouselPreview(true);
			} else {
				setHeader(templateData?.components?.find((component: any) => component.type === componentType.header));
				setBody(templateData?.components?.find((component: any) => component.type === componentType.body));
				setFooter(templateData?.components?.find((component: any) => component.type === componentType.footer));
				setButtons(
					templateData?.components?.find((component: any) => component.type === componentType.buttons)
						?.buttons
				);
			}
		} else {
			setShowCarouselPreview(false);
			setBody({ text: t('pages:broadcast.whatsapp.templateNotAvailable', { template_name: templateName }) });
		}
	};

	const setTranslatedComponentData = (
		componentTypeValue: any,
		componentTypeText: string,
		translatedMessages: any
	) => {
		const translatedText = { ...componentTypeValue };

		if (componentTypeText === componentType.header) {
			translatedText.text = translatedMessages[0]['translatedText'];
			setHeader(translatedText);
		} else if (componentTypeText === componentType.body) {
			translatedText.text = translatedMessages[1]['translatedText'];
			setBody(translatedText);
		} else if (componentTypeText === componentType.footer) {
			translatedText.text = translatedMessages[2]['translatedText'];
			setFooter(translatedText);
		}
	};

	useEffect(() => {
		setComponentData();
	}, [templateData, buttonParams]);

	useEffect(() => {
		if (showTranslatedTemplate) {
			const messagesToTranslate: any = [];
			const headerText = header && header.format === 'TEXT' ? formatContentForPreview(header.text, 'header') : '';
			const bodyText = body ? formatContentForPreview(body.text, 'body') : '';
			const footerText = footer ? footer.text : '';

			messagesToTranslate.push(headerText);
			messagesToTranslate.push(bodyText);
			messagesToTranslate.push(footerText);

			const indexOfActualMessage = messagesToTranslate.findIndex((el: any) => el);
			dispatch(
				updateMessagesWithTransaltedTextAction(
					messagesToTranslate,
					agentPreferredLanguage,
					(translatedMessagesList: any) => {
						messagesToTranslate.splice(
							indexOfActualMessage,
							translatedMessagesList.length,
							...translatedMessagesList
						);

						if (messagesToTranslate[0]) {
							setTranslatedComponentData(header, componentType.header, messagesToTranslate);
						}

						if (messagesToTranslate[1]) {
							setTranslatedComponentData(body, componentType.body, messagesToTranslate);
						}

						if (messagesToTranslate[2]) {
							setTranslatedComponentData(footer, componentType.footer, messagesToTranslate);
						}
					}
				)
			);

			if (buttons) {
				const buttonsText: any = buttons?.map((button: any) => button.text);

				dispatch(
					updateMessagesWithTransaltedTextAction(
						buttonsText,
						agentPreferredLanguage,
						(translatedMessagesList: any) => {
							const translatedButtons = JSON.parse(JSON.stringify(buttons));

							translatedButtons.map((button: any, index: number) => {
								button.text = translatedMessagesList[index]['translatedText'];
							});
							setButtons(translatedButtons);
						}
					)
				);
			}
		} else {
			setComponentData();
		}
	}, [showTranslatedTemplate]);

	const formatContent = (text: string, name: string) => {
		text.match(TEMPLATE_PARAMS)?.forEach((param) => {
			const paramIndex = param.replace(/\D/g, '');
			text = text.replace(
				param,
				values(`${formSuffix}parameters[${name}][${parseInt(paramIndex) - 1}]`) || param
			);
		});

		return processQuillContent(text) ?? '';
	};

	let formatContentForPreview = (text: string, name: string) => {
		const type = components?.find((component: any) => component.type === name)?.parameters;
		text.match(TEMPLATE_PARAMS)?.forEach((param) => {
			const paramIndex = param.replace(/\D/g, '');
			text = text.replaceAll(param, type ? type[parseInt(paramIndex) - 1]?.text || param : param);
		});
		text = processQuillContent(text) ?? '';
		text = linkify(text);

		return text;
	};

	const documentDetails = (values?: any, document?: any) => (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<div
				style={{
					textAlign: 'left',
					fontWeight: 500,
					fontSize: '0.75rem',
					lineHeight: '1rem',
					marginTop: '0.75rem',
					marginLeft: '0.5rem',
				}}
			>
				{values && values(`${formSuffix}mediaDoc`)?.[0]?.name
					? values(`${formSuffix}mediaDoc`)[0]?.name
					: values && values(`${formSuffix}mediaDoc`)}
				{document && document.filename}
			</div>
			<div
				style={{
					textAlign: 'left',
					marginLeft: '0.5rem',
					fontSize: '0.75rem',
					lineHeight: '1rem',
					marginRight: 'auto',
					color: theme.colors.accent50,
				}}
			>
				{values &&
					(values(`${formSuffix}mediaDoc`)?.[0]?.size
						? getSizeInMbOrKb(values(`${formSuffix}mediaDoc`)?.[0]?.size)
						: fileSize)}
				{document && fileSize}
			</div>
		</div>
	);

	const renderHeaderImage = () => {
		if (values) {
			if (values(`${formSuffix}fileUrl`)) {
				return values(`${formSuffix}fileUrl`);
			}

			return ic_whatsapp_template_image;
		}
		if (
			components[0] &&
			components[0].parameters &&
			components[0].parameters[0].image &&
			components[0].parameters[0].image.link
		) {
			return components[0].parameters[0].image.link;
		}

		return ic_whatsapp_template_image;
	};

	const renderHeaderVideo = () => {
		if (values && values(`${formSuffix}video`)) {
			return values(`${formSuffix}video`);
		}
		if (components?.[0]?.parameters?.[0]?.video?.link) {
			return components[0].parameters[0].video.link;
		}
	};

	const setDocumentLink = () => {
		if (values) {
			if (values(`${formSuffix}fileUrlDoc`)) {
				return values(`${formSuffix}fileUrlDoc`);
			}
		} else if (
			components[0] &&
			components[0].parameters &&
			components[0].parameters[0].document &&
			components[0].parameters[0].document.link
		) {
			return components[0].parameters[0].document.link;
		} else {
			return ic_document;
		}
	};

	const imagePadding = {
		paddingTop: '2.375rem',
		paddingBottom: '2.375rem',
		paddingLeft: '7rem',
		paddingRight: '7rem',
	};

	const cssForTemplate = isLibraryTemplateCard ? hoverCss() : values ? headerCss(theme) : {};

	const updateTemplateAnalyticsForTemplate = () =>
		API.updateTemplateAnalyticsCountFor(template.templateId).importCount();

	const renderCarouselPreview = (data: any) => {
		const { cardComponents, bodyText, language } = getCarouselPayload(data);

		return (
			<TemplatePreviewCards carouselPreviewCardsData={cardComponents} bubbleText={bodyText} language={language} />
		);
	};

	const renderButton = (index: number) => {
		if (index >= TEXT_MEDIA_TEMPLATE_BUTTON_LIMIT) {
			return false;
		}
		if (
			showAllButtons ||
			index < TEXT_MEDIA_TEMPLATE_BUTTON_LIMIT - 1 ||
			(index === TEXT_MEDIA_TEMPLATE_BUTTON_LIMIT - 1 &&
				!showAllButtons &&
				buttons?.length === TEXT_MEDIA_TEMPLATE_BUTTON_LIMIT)
		) {
			return true;
		}

		return false;
	};

	return (
		<>
			{showCarouselPreview ? (
				renderCarouselPreview(carouselData)
			) : (
				<div
					className={css(cssForTemplate)}
					onMouseEnter={() => setLibraryTemplateHover(true)}
					onMouseLeave={() => setLibraryTemplateHover(false)}
				>
					<div
						className={css(!isLibraryTemplateCard ? containerCss(theme, addMargin) : plgCss(theme))}
						id={messageId}
						data-qa={CREATE_MESSAGE_TEMPLATES_PREVIEW_BOX}
					>
						<div
							className={css({
								display: 'flex',
								flexDirection: 'inherit',
								marginTop:
									showApiTriggerMessageTag ||
									(targetWhatsAppNumber && selectedUser.user_id !== targetWhatsAppNumber)
										? '0.5rem'
										: '0rem',
							})}
						>
							{showApiTriggerMessageTag && (
								<ApiTriggerMessageTag
									overrides={{
										marginLeft: '0.5rem',
									}}
								/>
							)}
							{targetWhatsAppNumber && selectedUser.user_id !== targetWhatsAppNumber && (
								<ENGTInfoBadge
									className={{
										backgroundColor: theme.colors.infoBadge,
										justifyContent: 'center',
										width: 'fit-content',
										marginLeft: 'auto',
										marginRight: '1rem',
										color: theme.colors.primaryA,
										fontWeight: 500,
									}}
									size='default'
								>
									{t('common:sentTo', {
										phoneNo: targetWhatsAppNumber,
									})}
								</ENGTInfoBadge>
							)}
						</div>

						{header && header.format === componentFormat.text && (
							<HeadingLevel>
								<div
									className={css({
										paddingTop: '0.625rem',
										paddingRight: '0.625rem',
										paddingBottom: '0.625rem',
										paddingLeft: '0.625rem',
										color: theme.colors.primaryA,
										borderTopLeftRadius: '2px',
										borderTopRightRadius: '2px',
										fontWeight: '700',
										textAlign:
											Object.keys(rtlLanguageCodeMapper).findIndex(
												(lang) => lang === templateLanguage
											) === -1
												? 'left'
												: 'right',
									})}
								>
									{values
										? formatContent(header.text, 'header')
										: formatContentForPreview(header.text, 'header')}
								</div>
							</HeadingLevel>
						)}
						{header && header.format === componentFormat.image && (
							<div
								className={css(mediaCss(theme))}
								onClick={() => {
									window.open(renderHeaderImage(), '_blank');
								}}
							>
								<img
									src={renderHeaderImage()}
									alt='Template Header'
									height='100%'
									width='100%'
									style={isLibraryTemplateCard ? {} : imagePadding}
								/>
							</div>
						)}
						{header && header.format === componentFormat.documentType && (
							<div
								className={css(mediaCss(theme))}
								style={{
									justifyContent: 'flex-start',
									backgroundColor:
										(values && values(`${formSuffix}fileUrlDoc`)) ||
										(components?.[0] &&
											components[0]?.parameters &&
											components[0].parameters[0]?.document &&
											components[0].parameters[0].document?.link)
											? theme.colors.recentConvBotMsgBg
											: '',
								}}
								onClick={() => {
									window.open(setDocumentLink(), '_blank');
								}}
							>
								{(values && values(`${formSuffix}fileUrlDoc`)) ||
								(components?.[0] &&
									components[0]?.parameters &&
									components[0]?.parameters[0]?.document &&
									components[0]?.parameters[0]?.document?.link) ? (
									<div
										style={{
											height: '3.75rem',
											display: 'flex',
											marginLeft: '0.25rem',
										}}
									>
										<img
											src={ic_pdf_upload}
											alt='Document uploaded of a particular type'
											style={{
												marginRight: 'auto',
												marginTop: '0.25rem',
												marginBottom: '0.25rem',
											}}
										/>
										{values
											? documentDetails(values)
											: documentDetails(undefined, components?.[0]?.parameters[0]?.document)}
									</div>
								) : (
									<img
										src={ic_document}
										alt='No doc provided'
										style={{
											paddingTop: '2.375rem',
											paddingBottom: '2.375rem',
											paddingLeft: '7rem',
											paddingRight: '7rem',
										}}
									/>
								)}
							</div>
						)}
						{header && header.format === componentFormat.video && (
							<div className={css(mediaCss(theme))}>
								{(values && values(`${formSuffix}video`)) ||
								components?.[0]?.parameters[0]?.video?.link ? (
									<video
										controls
										src={renderHeaderVideo()}
										height='100%'
										width='100%'
										style={{
											paddingTop: '2.375rem',
											paddingBottom: '2.375rem',
											paddingLeft: '7rem',
											paddingRight: '7rem',
										}}
									/>
								) : (
									<VideoIcon width={200} height={200} fillColor='#C3C3C3' fill='none' />
								)}
							</div>
						)}
						{body && (
							<div
								dangerouslySetInnerHTML={{
									__html: decodeAndSanitizeInput(
										values
											? formatContent(getHtmlFormatText(body.text), 'body')
											: formatContentForPreview(getHtmlFormatText(body.text), 'body')
									),
								}}
								className={css({
									direction:
										Object.keys(rtlLanguageCodeMapper).findIndex(
											(lang) => lang === templateLanguage
										) === -1
											? 'ltr'
											: 'rtl',
									paddingTop: '0.625rem',
									paddingRight: '0.625rem',
									paddingBottom: '0.625rem',
									paddingLeft: '0.625rem',
									borderTopLeftRadius: '2px',
									borderTopRightRadius: '2px',
									whiteSpace: 'pre-line',
									wordWrap: 'break-word',
									fontSize: '0.875rem',
									lineHeight: '1.25rem',
									color: theme.colors.primaryA,
									textAlign:
										Object.keys(rtlLanguageCodeMapper).findIndex(
											(lang) => lang === templateLanguage
										) === -1
											? 'left'
											: 'right',
								})}
							/>
						)}
						{footer && (
							<HeadingLevel>
								<div
									className={css({
										textAlign:
											Object.keys(rtlLanguageCodeMapper).findIndex(
												(lang) => lang === templateLanguage
											) === -1
												? 'left'
												: 'right',
										paddingTop: '0.625rem',
										paddingRight: '0.625rem',
										paddingBottom: '0.625rem',
										paddingLeft: '0.625rem',
										color: theme.colors.primaryA,
										borderTopLeftRadius: '2px',
										borderTopRightRadius: '2px',
										fontSize: '0.875rem',
									})}
								>
									{footer.text}
								</div>
							</HeadingLevel>
						)}
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								marginBottom: messageStatus && buttons ? '0.75rem' : '0rem',
								paddingBottom: '0rem',
								paddingLeft: '0.625rem',
								paddingRight: '0.625rem',
							}}
						>
							{buttons?.map((button: any, index: any) => {
								const getProperButtonUrl = (url: string, btnIndex: number) => {
									const http = 'http:';
									const https = 'https:';
									const buttonsFromComponents = components?.filter(
										(component: any) => component?.type === 'button'
									);
									const buttonParameter = buttonsFromComponents?.[index]?.parameters?.[0]?.text;
									let urlButtonsRedirectionIndex = -1;

									for (let i = 0; i < buttonsFromComponents.length; i++) {
										if (
											buttonsFromComponents[i]?.type === 'button' &&
											buttonsFromComponents[i]?.sub_type === 'url'
										) {
											urlButtonsRedirectionIndex++;
											if (btnIndex === i) {
												break;
											}
										}
									}

									url = checkIfValidUrl(url)
										? decodeURIComponent(getTemplateUrlBasedOnCTAVersion(url))
										: url.substring(
													button.url.length - TEMPLATE_VARIABLE_LENGTH,
													button.url.length
											  ) === '{{1}}'
											? url.substring(0, button.url.length - TEMPLATE_VARIABLE_LENGTH)
											: url;
									if (url.includes(http)) {
										url.replace(http, https);
									}
									url = url.substring(0, url.indexOf('/')) === https ? url : `${https}//${url}`;
									if (redirectUrl && Array.isArray(redirectUrl)) {
										const redirectionUrl = decodeURIComponent(
											redirectUrl[urlButtonsRedirectionIndex]
										);
										url =
											redirectionUrl.substring(0, redirectionUrl.indexOf('/')) === https
												? redirectionUrl
												: `${https}//${redirectionUrl}`;
									} else if (values) {
										if (
											values(`${formSuffix}buttons?.[${index}]?.value`) &&
											values(`${formSuffix}buttons.[${index}].value`) !== '/'
										) {
											url += values(`${formSuffix}buttons[${index}].value`) ?? '';
										} else if (
											values().parameters?.button?.[0] &&
											values().parameters?.button?.[0] !== '/'
										) {
											url += values().parameters?.button?.[0] ?? '';
										}
									} else if (buttonParameter) {
										url += buttonParameter ?? '';
									}

									return url;
								};

								return (
									<>
										{renderButton(index) && (
											<React.Fragment key={`template-buttons-${index}`}>
												{renderButton(index) && (
													<React.Fragment key={`template-buttons-${index}`}>
														<VerticalDivider
															height='1px'
															overrides={{ backgroundColor: theme.colors.accent100 }}
														/>
													</React.Fragment>
												)}
												<VerticalDivider
													height='1px'
													overrides={{ backgroundColor: theme.colors.accent100 }}
												/>
												<Button
													className={css(buttonCssForPlg(theme))}
													kind={KIND['tertiary']}
													onHover={() => {
														<ENGTTooltip content={button?.text} ignoreBoundary>
															<></>
														</ENGTTooltip>;
														css({ color: theme.colors.primaryB });
													}}
													onMouseOver={() => {
														setIsHover(true);
														if (button?.type === CTA_BUTTONS.URL) {
															setButtonDataToBeShown({
																id: index,
																label: checkIfValidUrl(button?.url)
																	? decodeURIComponent(
																			getTemplateUrlBasedOnCTAVersion(button?.url)
																		)
																	: button?.url,
															});
														} else if (button?.type === CTA_BUTTONS.PHONE_NUMBER) {
															setButtonDataToBeShown({
																id: index,
																label: button?.phone_number,
															});
														} else if (button?.path) {
															setButtonDataToBeShown({ id: index, label: button?.path });
														} else {
															setButtonDataToBeShown({ id: index, label: '' });
														}
													}}
													onMouseOut={() => {
														setIsHover(false);
													}}
													size={SIZE.large}
													label={button.text}
													type='button'
													onClick={() => {
														if (button?.type === CTA_BUTTONS.URL) {
															window.open(
																getProperButtonUrl(button.url, index),
																'_blank'
															);
														}
													}}
													dataQa={`button-${index}`}
												/>
												{isHover && index === buttonData?.id ? (
													<div>
														<Popover
															content={buttonData.label}
															isOpen={isHover}
															placement={PLACEMENT.topRight}
															overrides={{
																Body: {
																	style: () => ({
																		marginTop: '1rem',
																		marginBottom: '3.5rem',
																		marginRight: '1rem',
																		marginLeft: '1rem',
																		'::-webkit-scrollbar': {
																			display: 'none',
																		},
																		zIndex: theme.colors.zIndex200,
																	}),
																},
															}}
														>
															<div />
														</Popover>
													</div>
												) : (
													<></>
												)}
											</React.Fragment>
										)}
									</>
								);
							})}
							{buttons?.length > TEXT_MEDIA_TEMPLATE_BUTTON_LIMIT && !showAllButtons && (
								<React.Fragment key='template-buttons-see-all-options}'>
									<VerticalDivider
										height='1px'
										overrides={{ backgroundColor: theme.colors.accent100 }}
									/>
									<Button
										className={css({
											...buttonCssForPlg(theme),
											cursor: 'pointer !important',
										})}
										kind={KIND['tertiary']}
										size={SIZE.large}
										label={t(
											'pages:broadcast.messageTemplates.createTemplate.button.seeAllOptions'
										)}
										type='button'
										onClick={() => setSeeAllOptionsButtonClicked(true)}
										dataQa='button-see-all-options'
									/>
								</React.Fragment>
							)}
							{seeAllOptionsButtonClicked && (
								<TemplateButtonsPopOver
									setSeeAllOptionsButtonClicked={setSeeAllOptionsButtonClicked}
									heading={t('common:allOptions')}
									buttonsArray={buttons}
								/>
							)}
						</div>
						<div className={css({ position: 'absolute', bottom: '0.125rem', right: '0.5rem' })}>
							{messageStatus}
						</div>
					</div>
					{isLibraryTemplateCard && isLibraryTemplateHover && (
						<>
							<div
								className={css({
									position: 'absolute',
									top: 0,
									left: 0,
									width: '100%',
									height: '100%',
									backgroundColor: 'rgba(255, 255, 255, 0.8)',
									...borderRadiusCss,
								})}
							/>
							<ENGTButton
								size={SIZE.compact}
								kind={isWhatsappAcceleratorTrialCustomer ? KIND.secondary : KIND.primary}
								label={t('pages:broadcast.messageTemplates.useTemplate')}
								className={css({
									align: 'center',
									top: '50%',
									left: '50%',
									transform: 'translate(-50%, -50%)',
									position: 'absolute',
									zIndex: theme.zIndex100,
								})}
								onClick={() => {
									isLibraryTemplateCard &&
										template?.templateId &&
										updateTemplateAnalyticsForTemplate();
									if (isCreateMessageTemplatesV2Enabled && !getQueryParams.get('mode')) {
										template.templateIsFromLibrary = true;
										navigate(ThreeColumnRouteLinks.createBroadcastMessageTemplates, {
											state: template,
										});
									} else {
										setViewMode?.(false);
										setTemplateIsFromLibrary?.(true);
										setSelectedTemplateFromLibrary?.(template);
									}
									if (isWhatsappAcceleratorTrialCustomer) {
										sendPLGButtonClickEventToGA4({
											gtmTrackingId: PlgGTMTrackingIds.SEND_BROADCAST_USE_TEMPLATE_CLICKED,
											restData: {
												templateName: template,
											},
										});
									}
								}}
							/>
						</>
					)}
				</div>
			)}
		</>
	);
}

const TemplateHeader = ({ useCase }: { type: string; useCase: string }) => {
	const [css, theme]: [any, any] = useStyletron();

	return (
		<div
			className={css({
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				gap: '0.5rem',
				paddingRight: '1.5rem',
				paddingLeft: '1.5rem',
				paddingTop: '1rem',
				paddingBottom: '1rem',
				borderBottom: `1px solid ${theme.colors.accent100}`,
				backgroundColor: theme.whatsApp.headerColor,
				borderTopLeftRadius: '0.25rem',
				borderTopRightRadius: '0.25rem',
				color: theme.colors.primaryBtnTextColor,
			})}
		>
			<div
				className={css({
					fontSize: '1rem',
					lineHeight: '1.5rem',
					fontWeight: 700,
					textTransform: 'capitalize',
				})}
			>
				{useCase}
			</div>
		</div>
	);
};

const withWhatsAppWrapper = <P extends IObjProps>(WrappedComponent: ComponentType<P>) => {
	const TemplateComponent = (props: IObjProps) => {
		const [css, theme]: any = useStyletron();
		const { template, useCase = '' }: { template?: IObjProps; useCase?: string } = props;

		return (
			<div
				className={css({
					borderRadius: '0.5rem',
					borderWidth: '1px',
					borderColor: theme.colors.inputBorderColor,
					borderStyle: 'solid',
					backgroundImage: `url(${WhatsappTemplateBackground})`,
					backgroundSize: 'cover',
				})}
			>
				<TemplateHeader type={template?.type || template?.template_category} useCase={useCase} />
				<div
					className={css({
						paddingLeft: '1.5rem',
						paddingRight: '1.5rem',
						paddingTop: '1rem',
						paddingBottom: '1rem',
						boxSizing: 'border-box',
					})}
				>
					<WrappedComponent {...(props as P)} />
				</div>
			</div>
		);
	};

	return TemplateComponent;
};

export default withWhatsAppWrapper(TemplatePreview);
