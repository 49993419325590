import React, { useEffect, useState } from 'react';
import { Card, StyledBody } from 'baseui/card';
import { useDispatch, useSelector } from 'react-redux';
import { useStyletron } from 'styletron-react';

import GameAvatar from 'components/Gamification/Avatar';
import getLevelAvatar from 'components/Gamification/LevelAvatar/getLevelAvatar';
import EmptyMedal from 'components/Gamification/Medals/EmptyMedal';
import MedalGold from 'components/Gamification/Medals/MedalGold';
import MedalGoldSecondary from 'components/Gamification/Medals/MedalGoldSecondary';
import MyAchievements from 'components/Gamification/MyAchievements.tsx/MyAchievements';
import XpBar from 'components/Gamification/XpBar';

import { fetchUserXp } from 'store/Gamification';
import { RootState } from 'store/rootReducer';

import starImage from './star.png';

import '../Gamification/PageFooter.scss';
import 'components/PageFooter/PageFooter.scss';

const PageFooter: React.FC = () => {
	const [openC, setOpenC] = useState<boolean>(false);
	const [css, theme]: any = useStyletron();
	const [level, setLevel] = useState<any>();
	const [isAchievementsOpen, setAchievementsOpen] = useState<boolean>(false);
	const [showXPIncreaseAnimation, setShowXPIncreaseAnimation] = useState<boolean>(false);
	const [showLevelUpAnimation, setShowLevelUpAnimation] = useState<boolean>(false);
	const currXp = useSelector((state: RootState) => state.UserXp.userXp);
	console.log('kd currXp:', currXp);
	const noOfAchievements = useSelector((state: RootState) => state.UserXp.noOfAchievements);
	const [nextXp, setNextXp] = useState<any>();
	const [prevXp, setPrevXp] = useState<any>();

	const dispatch = useDispatch<any>();

	function xpForLevel(level: number) {
		return ((level - 1) * level * 100) / 2;
	}

	function determineLevelAndNextXp(currentXp: number) {
		let level = 1;
		let xpForNextLevel = xpForLevel(level + 1);

		let xpForCurrentLevel = xpForLevel(level);

		while (currentXp >= xpForNextLevel) {
			level++;
			xpForCurrentLevel = xpForNextLevel;
			xpForNextLevel = xpForLevel(level + 1);
		}

		const nextLevelXp = xpForNextLevel > currentXp ? xpForNextLevel : null;
		const previousLevelXp = level > 1 ? xpForCurrentLevel : null;

		setNextXp(nextLevelXp);
		setPrevXp(previousLevelXp);
		setLevel(level);
	}

	const openCard = () => {
		setOpenC((openC) => !openC);
	};

	const stopPropagation = (e: React.MouseEvent) => {
		e.stopPropagation();
	};

	const levelValues = getLevelAvatar(level);
	const nextLevelValues = getLevelAvatar(level + 1);

	const myAchievements = () => {
		setOpenC(false);
		setAchievementsOpen(true);
	};

	const triggerIncreaseXPAnimation = () => {
		setShowXPIncreaseAnimation(true);
		setTimeout(() => setShowXPIncreaseAnimation(false), 4000);
	};

	const triggerLevelUpAnimation = () => {
		setShowLevelUpAnimation(true);
		setTimeout(() => setShowLevelUpAnimation(false), 4000);
	};

	const completedPercent = Math.trunc(((currXp - prevXp) / (nextXp - prevXp)) * 100);

	useEffect(() => {
		triggerLevelUpAnimation();
		setShowXPIncreaseAnimation(false);
	}, [level]);

	const stars = () => (
		<>
			{[...Array(5)].map((_, index) => (
				<div className='star' key={index} style={{ '--i': index } as any}>
					<img src={starImage} height={15} />
				</div>
			))}
		</>
	);
	useEffect(() => {
		dispatch(fetchUserXp());
		determineLevelAndNextXp(currXp);
		triggerIncreaseXPAnimation();
	}, [currXp]);

	return (
		<>
			<footer className='page-footer' style={{ position: 'fixed', bottom: 0, right: 0, zIndex: 999 }}>
				<div
					onClick={openCard}
					style={{
						display: 'flex',
						justifyContent: 'flex-end',
						position: 'relative',
						margin: '3rem',
						height: '70px',
						width: '70px',
					}}
				>
					{showXPIncreaseAnimation && stars()}
					<div className={`${showLevelUpAnimation ? 'avatar' : ''}`}>
						<GameAvatar />
					</div>
					{openC && (
						<div
							onClick={stopPropagation}
							style={{
								position: 'absolute',
								bottom: '80%',
								right: 3,
								zIndex: 1000,
								paddingRight: '3rem',
							}}
						>
							<div className='card'>
								<Card
									overrides={{
										Root: {
											style: {
												minWidth: '350px',
												width: 'auto',
												background: 'linear-gradient(135deg, #ae1536 0%, #fad0c4 100%)',
												borderRadius: '15px',
												boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',
												border: '2px solid #ae1536',
												overflow: 'hidden',
											},
										},
									}}
								>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											paddingBottom: '1rem',
											flexDirection: 'column',
											color: '#fff',
											fontFamily: 'Press Start 2P, cursive',
											textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
										}}
									>
										{levelValues.component}
										<span
											style={{
												paddingTop: '.5rem',
												fontSize: '1.5rem',
												fontWeight: 'bold',
											}}
										>{`Level ${level}: ${levelValues.name}`}</span>
									</div>
									<StyledBody
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
											height: 'auto',
											background: 'rgba(255, 255, 255, 0.9)',
											borderRadius: '0 0 15px 15px',
											padding: '1rem',
											boxShadow: 'inset 0 1px 2px rgba(0, 0, 0, 0.1)',
										}}
									>
										<div
											style={{
												flex: 1,
												display: 'flex',
												flexDirection: 'column',
												justifyContent: 'center',
											}}
										>
											<span style={{ fontSize: '1.2rem', fontWeight: 'bold', color: '#333' }}>
												XP
											</span>
											<XpBar
												bgcolor='black'
												completed={completedPercent}
												currentXp={currXp}
												maxXp={nextXp}
											/>
											{level !== 10 && (
												<>
													<span
														style={{
															paddingTop: '2rem',
															fontSize: '1rem',
															fontWeight: 'bold',
															color: '#333',
														}}
													>{`Next Level: \n${nextLevelValues.name}`}</span>
													<span style={{ textAlign: 'center' }}>
														{nextLevelValues.component}
													</span>
												</>
											)}
										</div>
										<div
											style={{
												borderLeft: '2px solid #ae1536',
												height: '11rem',
												margin: '0 1rem',
											}}
										/>
										<div
											style={{
												flex: 1,
												display: 'flex',
												flexDirection: 'column',
												justifyContent: 'center',
											}}
										>
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													justifyContent: 'center',
												}}
											>
												<EmptyMedal height={30} width={30} />
												<MedalGold height={30} width={30} />
												<MedalGoldSecondary height={30} width={30} />
											</div>
											<div style={{ display: 'flex', flexDirection: 'column' }}>
												<span
													style={{
														paddingTop: '1rem',
														fontSize: '0.8rem',
														fontWeight: 600,
														color: '#333',
														textAlign: 'center',
													}}
												>
													Achievements Earned
												</span>
												<span
													style={{
														alignContent: 'center',
														fontSize: '1.5rem',
														fontWeight: 600,
														color: '#333',
														textAlign: 'center',
													}}
												>
													{noOfAchievements}
												</span>
											</div>
											<div
												style={{
													paddingTop: '1rem',
													fontSize: '.9rem',
													color: '#007bff',
													textDecoration: 'none',
													fontWeight: 'bold',
													cursor: 'pointer',
													textAlign: 'center',
												}}
												onClick={myAchievements}
											>
												My Achievements
											</div>
										</div>
									</StyledBody>
								</Card>
							</div>
						</div>
					)}
				</div>
			</footer>
			{isAchievementsOpen && (
				<MyAchievements
					onClose={setAchievementsOpen}
					currentLevel={level}
					curreXp={currXp}
					maxXp={nextXp}
					completedPercent={completedPercent}
				/>
			)}
		</>
	);
};

export default PageFooter;
