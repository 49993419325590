import React from 'react';
import { Cell, Grid } from 'baseui/layout-grid';

import ENGTSpacer from 'components/UI/ENGTSpacer/ENGTSpacer';

import useResponsiveSize from 'shared/hooks/useResponsiveSize';

export enum MESSAGE_ALIGN {
	'LEFT' = 'left',
	'RIGHT' = 'right',
}

interface IENGTWrapper {
	align: MESSAGE_ALIGN.LEFT | MESSAGE_ALIGN.RIGHT;
	children: any;
	colSpan?: number;
	override?: boolean;
}

function Wrappper(props: IENGTWrapper) {
	const { align, colSpan = 10, override = true } = props;
	let skip = 0;

	const { isSmallDesktopScreen } = useResponsiveSize();

	const responsiveSpan = (span: number) => {
		if (span < 9 && isSmallDesktopScreen) {
			return span + 2;
		}

		return span;
	};

	if (align === MESSAGE_ALIGN.RIGHT) {
		skip = 12 - responsiveSpan(colSpan);
	}

	return (
		<ENGTSpacer marginLeft='0rem' marginRight='0rem' marginBottom='0rem' marginTop='0.5rem'>
			<Grid
				gridColumns={12}
				overrides={
					override
						? {
								Grid: {
									style: {
										maxWidth: '100%',
									},
								},
							}
						: {}
				}
			>
				<Cell
					span={responsiveSpan(colSpan)}
					skip={skip}
					overrides={
						override
							? {
									Cell: {
										style: {
											textAlign: align,
											wordBreak: 'break-all',
										},
									},
								}
							: {}
					}
				>
					{props.children}
				</Cell>
			</Grid>
		</ENGTSpacer>
	);
}

export default Wrappper;
