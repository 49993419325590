import { useEffect, useMemo, useState } from 'react';
import { useStyletron } from 'baseui';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Form from 'components/UI/Form/Form/Form';
import { FormInput } from 'components/UI/Form/FormInput/FormInput';
import Loader from 'components/UI/Loader/Loader';
import InfoModalBase from 'components/UI/Modal/InfoModal/InfoModalBase';
import ModalAlert from 'components/UI/Modal/ModalAlert/ModalAlert';
import ModalWithSections from 'components/UI/Modal/ModalWithSections/ModalWithSections';
import WhatsappTemplateMessage from 'components/WhatsappTemplateMessage/WhatsappTemplateMessage';
import WhatsappTemplatePreview from 'components/WhatsappTemplateMessage/WhatsappTemplatePreview';

import { MESSAGE_STATE_MAP } from 'shared/consts/consts';
import { TEMPLATE_MESSAGE } from 'shared/consts/QA/PathBuilder';
import { IObjProps } from 'shared/consts/types';
import { GTMTrackingIds } from 'shared/enum/trackingEnums';
import { waLanguageCodeMapper, WHATSAPP_PROVIDERS } from 'shared/helpers';
import { sendButtonClickEventToGA4 } from 'shared/trackingHelpers';
import { templateComponentParameters } from 'shared/whatsappTemplateHelpers';

import { isCreateMessageTemplatesV2EnabledSelector, isWhatsappAcceleratorTrialEnabled } from 'store/App/User';
import { API } from 'store/Broadcast/api';
import { messageOverviewActions } from 'store/Message/messageOverview/actions';
import { getAllFlowsAction, getFlowAttributesAction, templateMesaageNodeProcessData } from 'store/PathBuilder';
import { RootState } from 'store/withReducer';

function TemplateMessage(props: { isOpen: boolean; onClose: any; isAllTab: boolean; tab: string; waNumber?: string }) {
	const { isOpen: templateModalFromProps, onClose: setTemplateModalFromProps } = props;

	const { t } = useTranslation(['common', 'components', 'errors']);
	const [css, theme]: any = useStyletron();
	const navigate = useNavigate();
	const dispatch = useDispatch<any>();

	const [pathVariables, setPathVariables] = useState([]);
	const [isLoading, setLoading] = useState<boolean>(false);
	const [isStartConversationAlertOpen, openStartConversationAlert] = useState<boolean>(false);
	const [showTemplateModal, setShowTemplateModal] = useState<boolean>(false);
	const [showAlreadyAssignedModal, setShowAlreadyAssignedModal] = useState<boolean>(false);
	const [currentTemplate, setCurrentTemplate] = useState<any>(null);
	const [, setIsErrorState] = useState<boolean>(false);
	const [templateLanguage, setTemplateLanguage] = useState<any>();
	const [dateTimeError, setDateTimeError] = useState<string>('');
	const [templateComponents, setTemplateComponents] = useState<any>([]);
	const [templates, setTemplates] = useState<
		Array<{ id: string; label: string; components: any; namespace: any; language: string }>
	>([]);

	const provider = useSelector((state: RootState) => state.Bot.data.provider_configured);
	const selectedUser = useSelector((state: RootState) => state.Message.selectedUser);
	const flowAttributes = useSelector((state: RootState) => state.PathBuilder.flowAttributes);
	const uid = useSelector((state: RootState) => state.User.botAdmin.data.uid);
	const allFlows = useSelector((state: RootState) => state.PathBuilder.flows.data);
	const isWhatsappAcceleratorTrial = useSelector(isWhatsappAcceleratorTrialEnabled);
	const isCreateMessageTemplatesV2Enabled = useSelector(isCreateMessageTemplatesV2EnabledSelector);

	const availableFlows = useMemo(
		() =>
			allFlows.map((flow: any) => ({
				id: flow.path_key,
				label: flow.display_name,
			})),
		[allFlows]
	);

	const providerNotConfigured =
		provider !== WHATSAPP_PROVIDERS.dialog360 && provider !== WHATSAPP_PROVIDERS.whatsAppCloud;

	const defaultValues: any = {
		parameters: { body: [], header: [] },
		buttons: [{}],
		buttonTypeOptions: 'callToAction',
		path: [{ id: '', label: '' }],
	};

	const methods = useForm<any>({
		mode: 'onChange',
		defaultValues,
		shouldUnregister: false,
	});

	const { control, setValue, getValues, handleSubmit } = methods;

	const languageCodeMapper: any = waLanguageCodeMapper();

	const checkIfConversationNotAssigned = () => {
		if (
			selectedUser.actor_id !== undefined &&
			selectedUser.current_state !== undefined &&
			selectedUser.actor_id !== uid &&
			(selectedUser.current_state === MESSAGE_STATE_MAP.ASSIGNED ||
				selectedUser.current_state === MESSAGE_STATE_MAP.ENGAGING)
		) {
			setShowAlreadyAssignedModal(true);
			setShowTemplateModal(false);
		} else {
			setShowAlreadyAssignedModal(false);
			setShowTemplateModal(true);
		}
	};

	useEffect(() => {
		if (templateModalFromProps && !showTemplateModal) {
			checkIfConversationNotAssigned();
		}
	}, [templateModalFromProps, selectedUser]);

	useEffect(() => {
		if (!availableFlows.length) {
			dispatch(getAllFlowsAction());
		}
		if (!flowAttributes.length) {
			dispatch(getFlowAttributesAction());
		}
		API.getPathVariables().then((response: any) => {
			if (response.data) {
				setPathVariables(response.data);
			}
		});
	}, []);

	const closeTemplateModals = () => {
		setShowTemplateModal(false);
		setTemplateModalFromProps(false);
	};

	const closeAllModals = () => {
		closeTemplateModals();
		openStartConversationAlert(false);
	};

	const sendMessage = (formData: IObjProps) => {
		formData['isWhatsAppCloudConfigured'] = provider === WHATSAPP_PROVIDERS.whatsAppCloud;
		const templateComponent =
			typeof formData.templateComponent === 'string'
				? formData.templateComponent.split(',')
				: formData.templateComponent;
		if (templateComponent.indexOf(templateComponentParameters.expiration) !== -1) {
			if (!formData.expirationDateTime) {
				setDateTimeError(t('common:mandatoryField'));

				return;
			}
		}
		const messagePayload = templateMesaageNodeProcessData(formData, pathVariables);
		messagePayload.type = 'TEMPLATE_MESSAGE';
		messagePayload.number = props.waNumber || '';
		dispatch(messageOverviewActions.sendMessageAction(messagePayload, {}, selectedUser));
		setShowAlreadyAssignedModal(false);

		if (isWhatsappAcceleratorTrial) {
			openStartConversationAlert(true);
			closeTemplateModals();
		} else {
			closeAllModals();
		}
	};

	const showErrorMessage = () =>
		providerNotConfigured ? (
			<div
				className={css({
					marginTop: '1.125rem',
					display: 'block',
					textAlign: 'left',
					fontSize: '0.875rem',
					color: theme.modalTextColor,
				})}
			>
				<span>{t('components:node.templateMesaage.providerErrorMessage1')}</span>
				<span
					onClick={() => {
						navigate('/configure/deployment');
					}}
					className={css({
						textDecoration: 'underline',
						cursor: 'pointer',
					})}
				>
					{t('components:node.templateMesaage.deployment')}
				</span>
				<span>{t('components:node.templateMesaage.providerErrorMessage2')}</span>
			</div>
		) : (
			<div
				className={css({
					marginTop: '1.125rem',
					display: 'block',
					textAlign: 'left',
					fontSize: '0.875rem',
					color: theme.modalTextColor,
				})}
			>
				{t('components:node.templateMesaage.templateNotFound')}
			</div>
		);

	const sendTemplateMessagesSentDataToGA = () =>
		sendButtonClickEventToGA4({ gtmTrackingId: GTMTrackingIds.TEMPLATE_MESSAGES_SENT_FROM_ONEVIEW });

	const sendTemplateMessageModal = () => (
		<>
			<ModalWithSections
				heading={t('components:oneViewTextArea.templateMessage.heading')}
				modalSubHeading={t('components:node.templateMesaage.description2')}
				longSubHeading
				confirmBtnLabel={t('components:oneViewTextArea.buttons.send')}
				isLoading={isLoading}
				isConfirmBtnDisabled={isLoading}
				isOpen={showTemplateModal}
				onClose={closeAllModals}
				onConfirm={() => {
					sendTemplateMessagesSentDataToGA();
					dispatch(handleSubmit(sendMessage));
				}}
				submitType='submit'
				width='45rem'
			>
				{isLoading ? (
					<Loader loaderBlockHeight='100%' loaderBlockWidth='100%' />
				) : (
					<div className={css({ marginTop: '1rem' })}>
						{providerNotConfigured ? (
							showErrorMessage()
						) : (
							<FormProvider {...methods}>
								<Form id='whatsapp-template-form'>
									<WhatsappTemplateMessage
										currentTemplate={currentTemplate}
										dateTimeError={dateTimeError}
										setDateTimeError={setDateTimeError}
										setTemplateLanguage={setTemplateLanguage}
										pathVariables={pathVariables}
										availableFlows={availableFlows}
										flowAttributes={flowAttributes}
										isCopyMode={false}
										isResumeMode={false}
										isEditMode={false}
										setTemplateComponents={setTemplateComponents}
										setCurrentTemplate={setCurrentTemplate}
										languageCodeMapper={languageCodeMapper}
										templateLanguage={templateLanguage}
										methods={methods}
										setIsErrorState={setIsErrorState}
										templates={templates}
										setTemplates={setTemplates}
										setLoading={setLoading}
										isTemplateNode
									/>
									{currentTemplate && isCreateMessageTemplatesV2Enabled && (
										<WhatsappTemplatePreview
											currentTemplate={currentTemplate}
											templateComponent={templateComponents}
											pathVariables={pathVariables}
											getValues={getValues}
										/>
									)}
									<div className={css({ display: 'none' })}>
										<Controller
											name='templateComponent'
											control={control}
											render={() => (
												<FormInput
													name='templateComponent'
													overrides={{ display: 'none' }}
													type='hidden'
													value={templateComponents || ''}
													dataQa={TEMPLATE_MESSAGE.TEMPLATE_COMPONENT}
												/>
											)}
										/>
										<Controller
											name='templateNamespace'
											control={control}
											render={() => (
												<FormInput
													name='templateNamespace'
													overrides={{ display: 'none' }}
													type='hidden'
													value={currentTemplate?.namespace || ''}
													dataQa={TEMPLATE_MESSAGE.TEMPLATE_NAME_SPACE}
												/>
											)}
										/>
										<Controller
											name='tempLanguage'
											control={control}
											render={() => (
												<FormInput
													name='tempLanguage'
													overrides={{ display: 'none' }}
													type='hidden'
													value={currentTemplate?.language || ''}
													dataQa={TEMPLATE_MESSAGE.TEMPLATE_LANGUAGE}
												/>
											)}
										/>
									</div>
								</Form>
							</FormProvider>
						)}
					</div>
				)}
			</ModalWithSections>
		</>
	);

	const conversationAlreadyAssignedModal = () => (
		<ModalAlert
			confirmBtnLabel={t('common:yes')}
			closeBtnLabel={t('common:no')}
			description={t('components:oneViewTextArea.templateMessage.conversationAlreadyAssignedTo', {
				userName: selectedUser.actor_name,
			})}
			heading=''
			isOpen={showAlreadyAssignedModal}
			onClose={() => {
				setShowAlreadyAssignedModal(false);
				setTemplateModalFromProps(false);
			}}
			onConfirm={() => {
				setShowAlreadyAssignedModal(false);
				setShowTemplateModal(true);
			}}
			closeable={false}
			width='30.5%'
		/>
	);

	const showWAAcceleratorStartStatusModal = () => (
		<InfoModalBase
			isModalOpen={isStartConversationAlertOpen}
			onCloseFn={closeAllModals}
			heading=''
			description={
				<span>
					<span
						className={css({
							fontWeight: 700,
						})}
					>
						&quot;{t('components:oneViewTextArea.startConversationBtnText')}&quot;
					</span>
					{t('components:oneViewTextArea.templateMessage.startConversationAlert')}
				</span>
			}
		/>
	);

	return (
		<>
			{conversationAlreadyAssignedModal()}
			{sendTemplateMessageModal()}
			{showWAAcceleratorStartStatusModal()}
		</>
	);
}

export default TemplateMessage;
