import React from 'react';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';

import ApiTriggerMessageTag from 'components/shared/ApiTriggerMessageTag/ApiTriggerMessageTag';

import { IObjProps } from 'shared/consts/types';

interface IENGTFeedBack {
	backgroundColor: string;
	feedBack: IObjProps;
	showApiTriggerMessageTag?: boolean;
}

const ratingType = {
	star: 'STAR',
	emoji: 'EMOJI',
};

const indexForEmojiThreeScale: IObjProps = {
	'0': 1,
	'1': 3,
	'2': 5,
};

function FeedBack(props: IENGTFeedBack) {
	const { backgroundColor, feedBack, showApiTriggerMessageTag = false } = props;
	const [css]: [any, any] = useStyletron();
	let template = <></>;
	if (feedBack.rating_type === ratingType.star) {
		template = feedBack.options.map((option: any, i: number) => (
			<img
				src='https://branding-resources.s3.ap-south-1.amazonaws.com/default/portal/bot-icons/star_empty.svg'
				alt='star'
				className={css({
					height: '2rem',
					width: '2rem',
				})}
				key={i}
			/>
		));
	} else if (feedBack.rating_type === ratingType.emoji) {
		template = feedBack.options.map((option: any, i: number) => {
			const index = feedBack.scale === 5 ? i + 1 : indexForEmojiThreeScale[i];

			return (
				<img
					src={`https://branding-resources.s3.ap-south-1.amazonaws.com/default/portal/bot-icons/emoji${index}.svg`}
					alt=''
					className={css({
						height: '2rem',
						width: '2rem',
						paddingTop: '0rem',
						paddingRight: '0.375rem',
						paddingBottom: '0rem',
						paddingLeft: '0.375rem',
					})}
					key={i}
				/>
			);
		});
	}

	return (
		<Block
			className={css({
				paddingTop: '1rem',
				paddingRight: '0.875rem',
				paddingBottom: '0.875rem',
				paddingLeft: '1rem',
				display: 'inline-block',
				borderTopLeftRadius: '0.5rem',
				borderTopRightRadius: '0.5rem',
				borderBottomLeftRadius: '0.5rem',
				borderBottomRightRadius: '0.5rem',
				backgroundColor,
				textAlign: 'center',
			})}
		>
			{showApiTriggerMessageTag && <ApiTriggerMessageTag />}
			<Block
				className={css({
					paddingBottom: '0.5rem',
				})}
			>
				{feedBack.message}
			</Block>
			{template}
		</Block>
	);
}

export default React.memo(FeedBack);
