import React from 'react';
import { useStyletron } from 'baseui';

function MedalGoldSecondary({
	size,
	fillColor,
	height,
	width,
}: {
	size?: number;
	fillColor?: string;
	height: number;
	width: number;
}) {
	const [, theme] = useStyletron();

	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' id='medal' height={height} width={width}>
			<path fill='#212529' d='m8.26 3 17.68 30.62 12.12-7L24.42 3H8.26z' />
			<path
				fill='#111315'
				d='m38.06 26.62-7.21-12.5-3.28 5.67A8.81 8.81 0 0 1 25.65 21c-2 .48-4.44-.13-6.42 1.06l6.71 11.61Z'
			/>
			<path fill='#dd051d' d='m34.6 28.62-5.2 3L12.87 3h6.93l14.8 25.62z' />
			<path fill='#a60416' d='m34.6 28.62-6.06-10.5a4.88 4.88 0 0 1-5.18 3.05l6 10.45Z' />
			<path fill='#212529' d='M39.58 3 25.94 26.62l12.12 7L55.74 3H39.58z' />
			<path
				fill='#111315'
				d='M38.06 33.62 44.77 22c-2-1.19-4.46-.59-6.42-1.06-2.21-1-4.53-3.65-7.52-2.81l-4.89 8.48Z'
			/>
			<path fill='#dd051d' d='M51.13 3 34.6 31.62l-5.2-3L44.2 3h6.93z' />
			<path fill='#a60416' d='m34.6 31.62 6-10.45a9 9 0 0 1-2.25-.17A36.55 36.55 0 0 1 35 18.89l-5.6 9.73Z' />
			<path
				fill='#fccd1d'
				d='M50.55 40.5c0-2.11 1.57-4.44 1-6.34S48.2 31.24 47 29.6s-1.3-4.48-3-5.69-4.35-.42-6.32-1.05S34.11 20 32 20s-3.83 2.24-5.73 2.86-4.68-.14-6.32 1.05-1.75 4-3 5.69-3.85 2.59-4.49 4.56.95 4.23.95 6.34-1.57 4.44-.95 6.34S15.8 49.76 17 51.4s1.3 4.48 3 5.69 4.35.42 6.32 1S29.89 61 32 61s3.83-2.24 5.73-2.86 4.68.14 6.32-1 1.75-4 3-5.69 3.85-2.59 4.49-4.56-.99-4.28-.99-6.39Z'
			/>
			<circle cx='32' cy='40.5' r='14.5' fill='#f9a215' />
			<path
				fill='#fccd1d'
				d='m33.37 33 1.52 2.63a1.54 1.54 0 0 0 1.06.76L39 37a1.53 1.53 0 0 1 .85 2.56l-2.1 2.22a1.5 1.5 0 0 0-.4 1.22l.36 3a1.57 1.57 0 0 1-2.22 1.58l-2.81-1.27a1.6 1.6 0 0 0-1.32 0l-2.81 1.27A1.57 1.57 0 0 1 26.31 46l.36-3a1.5 1.5 0 0 0-.4-1.22l-2.1-2.22A1.53 1.53 0 0 1 25 37l3-.59a1.54 1.54 0 0 0 1.06-.76L30.63 33a1.59 1.59 0 0 1 2.74 0Z'
			/>
		</svg>
	);
}

export default MedalGoldSecondary;
