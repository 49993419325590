import { JSXElementConstructor, Key, ReactElement, ReactFragment, useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { toaster } from 'baseui/toast';

import { API } from 'components/Gamification/api.js';
import ENGTToasterContainer from 'components/UI/ENGTToaster/ENGTToasterContainer';
import ENGTTooltip from 'components/UI/ENGTTooltip/ENGTTooltip';

import { IObjProps } from 'shared/consts/types';
import CloseIcon from 'shared/icons/CloseIcon';

import getLevelAvatar from '../LevelAvatar/getLevelAvatar';
import BronzeMedal from '../Medals/BronzeMedal';
import MedalGold from '../Medals/MedalGold';
import SilverMedal from '../Medals/SilverMedal';
import XpBar from '../XpBar';

import './MyAchievements.scss';

const MyAchievements = (props: {
	onClose: any;
	currentLevel: number;
	curreXp: any;
	maxXp: any;
	completedPercent: any;
}) => {
	const { onClose, currentLevel, curreXp, maxXp, completedPercent } = props;
	const [css] = useStyletron();
	const [fetchedData, setData] = useState<any>();
	const userId = 234224;
	const [categoryNames, setNames] = useState<any>();

	const levelValues = getLevelAvatar(currentLevel, 70, 70);

	const fetchData = () => {
		API.getMyAchievements(userId)
			.then((resp: IObjProps) => {
				if (resp) {
					setData(resp.data);

					const categoryNames = resp.data.responseObject ? Object.keys(resp.data.responseObject) : [];
					setNames(categoryNames);
				} else {
					toaster.negative(
						<ENGTToasterContainer title='sankalp' description='common:somethingWentWrong' />,
						{}
					);
				}
			})
			.catch((err: any) => {
				console.log('erroe', err);
				toaster.negative(
					<ENGTToasterContainer title='common:error' description='common:somethingWentWrong' />,
					{}
				);
			});
	};
	useEffect(() => {
		fetchData();
	}, []);

	const LevelAvatars = () => {
		const levelsPerElevation = 2;
		const levelComponents = Array.from({ length: 10 }, (_, index) => index + 1).map((level, index) => {
			const { component, name } = getLevelAvatar(level, 40, 40);

			const isNextLevel = level > currentLevel;
			const isPastLevel = level < currentLevel;

			const elevationIndex = Math.floor(index / levelsPerElevation);
			const isRightToLeft = elevationIndex % 2 === 0;

			const groupIndex = index % levelsPerElevation;
			const bendAmount = 80;

			const transformX = isRightToLeft ? -bendAmount * groupIndex : bendAmount * groupIndex;

			return (
				<div
					key={level}
					className={css({
						position: 'relative',
						height: '80px',
						marginBottom: '10px',
						transform: `translateX(${transformX}px)`,
						paddingBottom: '35px',
						...(isNextLevel && {
							filter: 'blur(2px)',
						}),
					})}
				>
					{isPastLevel && (
						<div
							className={css({
								position: 'absolute',
								top: '24px',
								right: '16px',
								width: '20px',
								height: '20px',
								backgroundColor: 'green',
								color: 'white',
								borderRadius: '50%',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								fontSize: '14px',
								fontWeight: 'bold',
							})}
						>
							✓
						</div>
					)}
					<div
						className={css({
							position: 'absolute',
							left: '50%',
							top: '50%',
							transform: 'translate(-50%, -50%)',
							zIndex: 1,
						})}
					>
						{component}
					</div>

					<div className={css({ textAlign: 'center', marginTop: '80px', fontWeight: 500 })}>
						Lv {level}: {name}
					</div>
				</div>
			);
		});

		return (
			<div
				className={css({
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					position: 'relative',
					width: '100%',
					maxWidth: '200px',
					margin: '0 auto',
				})}
			>
				{levelComponents}
			</div>
		);
	};

	return (
		<div
			className={css({
				position: 'fixed',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
				backgroundColor: 'rgba(0, 0, 0, 0.8)',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				zIndex: 990,
				padding: '3rem',
				boxSizing: 'border-box',
			})}
			onClick={onClose}
		>
			<div
				className={`${css({
					background: 'linear-gradient(135deg, #ae1536 0%, #fad0c4 100%)',
					borderRadius: '15px',
					width: '100%',
					height: '100%',
					maxWidth: 'calc(100% - 10rem)',
					maxHeight: 'calc(100% - 6rem)',
					padding: '1rem',
					boxSizing: 'border-box',
					position: 'relative',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
				})} fade__animation`}
				onClick={(e) => e.stopPropagation()}
			>
				<div
					style={{ position: 'absolute', top: '1rem', right: '1rem', cursor: 'pointer' }}
					onClick={() => onClose(false)}
				>
					<CloseIcon height={20} width={20} />
				</div>
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
					<h3>My Achievements</h3>
				</div>
				<div
					className={css({
						backgroundColor: '#ECEBD8',
						borderRadius: '15px',
						boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',
						width: '100%',
						height: '100%',
						padding: '2rem',
						paddingLeft: '3rem',
						paddingRight: '3rem',
						maxWidth: 'calc(100% - 3rem)',
						overflowY: 'hidden',
						boxSizing: 'border-box',
						display: 'flex',
						flexDirection: 'column',
					})}
				>
					<div
						className={css({
							display: 'flex',
							alignItems: 'flex-start',
							justifyContent: 'space-between',
							height: '100%',
						})}
					>
						<div
							className={css({
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'flex-start',
								width: '30%',
								maxHeight: '100%',
								paddingRight: '1rem',
								overflowY: 'auto',
								alignSelf: 'flex-start',
							})}
						>
							<div
								className={css({
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'flex-start',
									marginBottom: '1rem',
								})}
							>
								<div style={{ display: 'flex', justifyContent: 'center' }}>
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<div style={{ padding: '.3rem' }}>{levelValues.component}</div>
										<div style={{ fontSize: '1rem', fontWeight: 'bold', color: '#333' }}>
											{`Level ${currentLevel} :  ${levelValues.name}`}
										</div>
									</div>
								</div>
								<div style={{ padding: '0.4rem', marginBottom: '1.5rem', marginTop: '1rem' }}>
									<XpBar
										currentXp={curreXp}
										maxXp={maxXp}
										completed={completedPercent}
										bgcolor='black'
									/>
								</div>

								<div style={{ padding: '1rem' }}>{LevelAvatars()}</div>
							</div>
						</div>
						<div
							className={css({
								borderLeft: '2px solid #ae1536',
								height: '100%',
								paddingLeft: '1rem',
								overflowY: 'auto',
								flex: 1,
							})}
						>
							{categoryNames?.map(
								(
									category:
										| boolean
										| symbol
										| Key
										| ReactElement<any, string | JSXElementConstructor<any>>
										| ReactFragment
										| Record<string, unknown>
										| null
										| undefined
										| any
								) => {
									const achievements =
										fetchedData.responseObject[category as keyof typeof fetchedData.responseObject];

									type Medal = {
										title: string;
										is_achieved: boolean;
										condition: string;
									};

									type Medals = {
										1: Medal[];
										2: Medal[];
										3: Medal[];
									};

									type MedalLevel = 1 | 2 | 3;

									const medals: Medals = {
										1: [],
										2: [],
										3: [],
									};

									achievements.forEach(
										(achievement: {
											achievement: {
												level: number;
												title: string;
												achievement_condition_text: string;
											};
											is_achieved: boolean;
										}) => {
											const level = achievement.achievement.level as MedalLevel;

											if (level in medals) {
												medals[level].push({
													title: achievement.achievement.title,
													is_achieved: achievement.is_achieved,
													condition: achievement.achievement.achievement_condition_text,
												});
											}
										}
									);

									const generateTooltipContent = (level: MedalLevel) => {
										const conditions = medals[level].map((medal) => medal.condition).join('\n');

										return (
											<div
												style={{
													overflow: 'scroll',
													maxHeight: '12.56rem',
													whiteSpace: 'pre-wrap',
												}}
											>
												{conditions}
											</div>
										);
									};

									return (
										<div
											key={category}
											className={css({
												marginBottom: '2rem',
											})}
										>
											<h4>{category}</h4>

											<div
												className={css({
													display: 'grid',
													gridTemplateColumns: 'repeat(3, 1fr)',
													gap: '1rem',
												})}
											>
												{[3, 2, 1].map((level) => {
													const medalLevel = level as MedalLevel;

													return (
														<div
															key={level}
															style={{
																opacity: medals[medalLevel].some(
																	(medal) => medal.is_achieved
																)
																	? 1
																	: 0.2,
															}}
														>
															<div
																style={{
																	display: 'flex',
																	flexDirection: 'column',
																	alignItems: 'center',
																}}
															>
																{level === 3 && (
																	<ENGTTooltip
																		content={
																			<div
																				style={{
																					overflow: 'scroll',
																					maxHeight: '12.56rem',
																					whiteSpace: 'pre-wrap',
																				}}
																			>
																				{generateTooltipContent(level)}
																			</div>
																		}
																		usage='list'
																		ignoreBoundary={false}
																	>
																		<span>
																			<MedalGold height={90} width={90} />
																		</span>
																	</ENGTTooltip>
																)}

																{level === 2 && (
																	<ENGTTooltip
																		content={
																			<div
																				style={{
																					overflow: 'scroll',
																					maxHeight: '12.56rem',
																					whiteSpace: 'pre-wrap',
																				}}
																			>
																				{generateTooltipContent(level)}
																			</div>
																		}
																		usage='list'
																		ignoreBoundary={false}
																	>
																		<span>
																			<SilverMedal height={90} width={90} />
																		</span>
																	</ENGTTooltip>
																)}

																{level === 1 && (
																	<ENGTTooltip
																		content={
																			<div
																				style={{
																					overflow: 'scroll',
																					maxHeight: '12.56rem',
																					whiteSpace: 'pre-wrap',
																				}}
																			>
																				{generateTooltipContent(level)}
																			</div>
																		}
																		usage='list'
																		ignoreBoundary={false}
																	>
																		<span>
																			<BronzeMedal height={90} width={90} />
																		</span>
																	</ENGTTooltip>
																)}
																<div
																	style={{ marginTop: '.4rem', textAlign: 'center' }}
																>
																	{medals[medalLevel].map((medal, index) => (
																		<div key={index}>{medal.title}</div>
																	))}
																</div>
															</div>
														</div>
													);
												})}
											</div>
										</div>
									);
								}
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MyAchievements;
