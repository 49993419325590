import React, { useEffect, useRef } from 'react';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';

import { IObjProps } from 'shared/consts/types';

interface IENGTCard {
	backgroundColor: string;
	card: IObjProps;
}

function Card(props: IENGTCard) {
	const { backgroundColor, card } = props;
	const [css]: [any, any] = useStyletron();
	const iframeRef: any = useRef(null);

	useEffect(() => {
		const code = iframeRef.current.contentWindow.document;
		code.open();
		code.writeln(`${card.html_content}<style>${card.css_content}</style>`);
		code.close();
		iframeRef.current.height = iframeRef.current.contentWindow.document.body.scrollHeight;
	}, []);

	const template = (
		<iframe
			frameBorder='0'
			ref={iframeRef}
			title={card.node_name}
			className={css({
				width: '100%',
			})}
		/>
	);

	return (
		<Block
			className={css({
				paddingLeft: '1rem',
				paddingRight: '1rem',
				paddingTop: '0.875rem',
				paddingBottom: '0.875rem',
				display: 'inline-block',
				borderTopLeftRadius: '0.5rem',
				borderTopRightRadius: '0.5rem',
				borderBottomLeftRadius: '0.5rem',
				borderBottomRightRadius: '0.5rem',
				backgroundColor,
				width: '100%',
				boxSizing: 'border-box',
			})}
		>
			{template}
		</Block>
	);
}

export default React.memo(Card);
