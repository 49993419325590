import { useTranslation } from 'react-i18next';

import InfoDivider from 'components/UI/Dividers/InfoDivider';

import { NEW_MESSAGE_INDICATOR_ELEMENT_ID } from 'shared/consts/consts';

function NewMessageIndicator({ totalMessagesWhenLoaded }: { totalMessagesWhenLoaded: number }) {
	const { t } = useTranslation(['common', 'pages']);

	return (
		<div {...{ 'data-messages-count': totalMessagesWhenLoaded }} id={NEW_MESSAGE_INDICATOR_ELEMENT_ID}>
			<InfoDivider
				wrapperCss={{
					marginTop: '1.5rem',
					marginBottom: '1.5rem',
					marginLeft: '-1rem',
					width: 'calc( 100% + 2rem )',
				}}
			>
				{t('pages:messages.new').toUpperCase()}
			</InfoDivider>
		</div>
	);
}

export default NewMessageIndicator;
