import React, { useMemo } from 'react';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';

import ApiTriggerMessageTag from 'components/shared/ApiTriggerMessageTag/ApiTriggerMessageTag';

import { isFacebookVideo, isVimeoVideo, isYoutubeVideo } from 'shared/pathBuilderHelpers';

interface IENGTVideo {
	backgroundColor: string;
	src: any;
	showApiTriggerMessageTag?: boolean;
}

const videCss = {
	paddingLeft: '1rem',
	paddingRight: '1rem',
	paddingTop: '0.875rem',
	paddingBottom: '0.875rem',
	display: 'inline-block',
	borderTopLeftRadius: '0.5rem',
	borderTopRightRadius: '0.5rem',
	borderBottomLeftRadius: '0.5rem',
	borderBottomRightRadius: '0.5rem',
};

const iframeOptions = {
	width: '100%',
	height: 180,
	style: { border: 'none', overflow: 'hidden', aspectRatio: '16/9' },
};

function Video(props: IENGTVideo) {
	const { backgroundColor, src, showApiTriggerMessageTag = false } = props;
	const [css]: [any, any] = useStyletron();

	const videoSource = useMemo(() => {
		let iframe: JSX.Element = <></>;

		if (src) {
			if (isVimeoVideo(src)) {
				const data = src.substr(src.length - 9);

				iframe = (
					<iframe
						title='vimeo video player'
						src={`https://player.vimeo.com/video/${data}`}
						width={iframeOptions.width}
						height={iframeOptions.height}
						style={iframeOptions.style}
						allow='autoplay; fullscreen; picture-in-picture'
						allowTransparency
						allowFullScreen
					/>
				);
			} else if (isYoutubeVideo(src)) {
				const data = src.substr(src.length - 11);

				iframe = (
					<iframe
						title='youtube video player'
						src={`https://www.youtube.com/embed/${data}`}
						width={iframeOptions.width}
						height={iframeOptions.height}
						style={iframeOptions.style}
						allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
						allowTransparency
						allowFullScreen
					/>
				);
			} else if (isFacebookVideo(src)) {
				iframe = (
					<iframe
						title='facebook video player'
						src={`https://www.facebook.com/plugins/video.php?href=${src}&show_text=0&width=560`}
						width={iframeOptions.width}
						height={iframeOptions.height}
						style={iframeOptions.style}
						allowTransparency
						allowFullScreen
					/>
				);
			} else {
				iframe = (
					<video
						controls
						className={css({
							height: '7.5rem',
							width: '15.625rem',
						})}
					>
						<source src={src} type='video/mp4' />
					</video>
				);
			}
		}

		return iframe;
	}, [src]);

	return (
		<Block
			className={css({
				...videCss,
				backgroundColor,
			})}
		>
			{showApiTriggerMessageTag && <ApiTriggerMessageTag />}
			{videoSource}
		</Block>
	);
}

export default React.memo(Video);
