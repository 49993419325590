import React, { useMemo, useState } from 'react';
import { useStyletron } from 'baseui';
import { useTranslation } from 'react-i18next';

import ENGTInfoBadge from 'components/UI/ENGTInfoBadge/ENGTInfoBadge';

import { MEDIA_TYPE_MAP, MESSAGE_SENDER_MAP, MESSAGE_TYPE_MAP } from 'shared/consts/consts';
import { IObjProps } from 'shared/consts/types';
import { decodeAndSanitizeInput } from 'shared/helpers';
import { linkify, regExp } from 'shared/helpers/quillHelper';
import Train from 'shared/icons/navigation/Train';

import { getIsValidLocationMessage, getLocationMessageHTML } from '../helpers/locationMessageHelper';

import MessageActionsPopup from './MessageActionsPopup';
import { FILE_RECEIVED, WA_FLOW_RECEIVED } from './Messages';
import ToggleLocationLink from './ToggleLocationLink';

const { bot: SENDER_BOT, user: SENDER_USER } = MESSAGE_SENDER_MAP;

interface IENGTQuotedMessage {
	backgroundColor: string;
	menuItems?: Array<any>;
	quotedMessageBg: string;
	quotedMessageFocusColor: string;
	trainIconBgColor: string;
	trainIconFill: string;
	children?: any;
	messageData: IObjProps;
	openMenu?: Function;
	closeMenu?: Function;
}

enum MEDIA_TYPE {
	IMAGE = 'IMAGE',
	VIDEO = 'VIDEO',
	FILE = 'FILE',
	AUDIO = 'AUDIO',
}

function QuotedMessage(props: IENGTQuotedMessage) {
	const IG_REPLY_TYPE: IObjProps = {
		COMMENT: 'COMMENT REPLY',
		PRIVATE_REPLY: 'PRIVATE REPLY',
	};

	const {
		messageData,
		backgroundColor,
		quotedMessageBg,
		openMenu,
		closeMenu,
		menuItems = [],
		quotedMessageFocusColor,
		trainIconBgColor,
		trainIconFill,
	} = props;
	const [css, theme]: [any, any] = useStyletron();
	const { t } = useTranslation(['components']);
	const [showLinkForLocation, setShowLinkForLocation] = useState<boolean>(true);

	const {
		messagetype,
		showTranslated,
		translatedMessage,
		message,
		quotedMessageId,
		quotedMessageType,
		quotedMessage,
		sender,
		messageId,
		marked_by,
	} = messageData || {};

	const textMessage = useMemo(() => {
		let msg = messagetype !== FILE_RECEIVED ? (showTranslated ? translatedMessage : message) : props.children;
		if (msg !== props.children) {
			msg = linkify(msg);
		}
		if (messagetype === WA_FLOW_RECEIVED) {
			msg = t('components:node.templateMesaage.sent');
		}
		try {
			if (msg.indexOf('<a href=') > -1) {
				return msg.replace(regExp, '<a class="clickableLinksA" target="_blank" href=');
			}
		} catch (e) {
			console.error('error parsing the message');

			return msg;
		}

		return msg;
	}, [messageData, props.children]);

	const onMenuHover = (event: any) => {
		event.stopPropagation();
		openMenu && openMenu(event);
	};

	const onMenuClose = (event: any) => {
		closeMenu && closeMenu(event);
	};

	const removeHighlightOnScrolledMessage = (selectedMessage: any) => {
		setTimeout(function () {
			selectedMessage.style.backgroundColor = '';
		}, 2000);
	};

	const scrollToTaggedMessage = () => {
		const selectedMessage: any = document.querySelector(`[id*="${quotedMessageId}"]`);
		if (selectedMessage) {
			selectedMessage.style.backgroundColor = quotedMessageFocusColor;
			removeHighlightOnScrolledMessage(selectedMessage);
			selectedMessage.scrollIntoView({
				behavior: 'smooth',
				block: 'nearest',
				inline: 'start',
			});
		}
	};

	const getMediaDetailsBasedOnExtension = () => {
		let mediaType = 'File';
		const fileUrl =
			quotedMessageType === MESSAGE_TYPE_MAP.image || quotedMessageType === MESSAGE_TYPE_MAP.file
				? quotedMessage
				: JSON.parse(quotedMessage)['url'];
		const fileName = fileUrl.split('/').pop();
		const fileExtension = fileUrl.split('.').pop();
		Object.keys(MEDIA_TYPE_MAP).forEach((key) => {
			const extensionList = MEDIA_TYPE_MAP[key].split(',');
			extensionList.forEach((extension: any) => {
				if (fileExtension === extension) {
					mediaType = key;
				}
			});
		});

		return { mediaType, url: fileUrl, fileName };
	};

	const locationMessage: string = useMemo(() => getLocationMessageHTML(message, sender), [message]);

	const isValidLocationMessage: boolean = getIsValidLocationMessage(locationMessage);

	const messageHTML = showLinkForLocation && isValidLocationMessage ? locationMessage : '' || textMessage || '';

	const renderQuotedMessageBasedOnType = (message: any) => {
		if (
			quotedMessageType !== MESSAGE_TYPE_MAP.file &&
			quotedMessageType !== MESSAGE_TYPE_MAP.fileReceived &&
			quotedMessageType !== MESSAGE_TYPE_MAP.image &&
			quotedMessageType !== MESSAGE_TYPE_MAP.template
		) {
			return (
				<span
					className={css({ 'white-space': 'pre-wrap' })}
					dangerouslySetInnerHTML={{
						__html: decodeAndSanitizeInput(quotedMessage),
					}}
				/>
			);
		}
		if (quotedMessageType === MESSAGE_TYPE_MAP.template) {
			return (
				<span
					className={css({ 'white-space': 'pre-wrap' })}
					dangerouslySetInnerHTML={{
						__html: decodeAndSanitizeInput(quotedMessageType),
					}}
				/>
			);
		}
		const { mediaType, url, fileName } = getMediaDetailsBasedOnExtension();
		switch (mediaType) {
			case MEDIA_TYPE.IMAGE:
				return (
					<img
						src={url}
						alt={quotedMessage}
						className={css({
							height: '1.75rem',
							width: '4.375rem',
						})}
					/>
				);
			case MEDIA_TYPE.VIDEO:
			case MEDIA_TYPE.AUDIO:
			case MEDIA_TYPE.FILE:
				return <span>{fileName}</span>;
		}
	};

	return (
		<span
			className={css({
				display: 'flex',
				alignItems: 'center',
				...(isValidLocationMessage && { marginBottom: '1rem' }),
				...(sender === SENDER_BOT && {
					flexDirection: 'row-reverse',
					justifyContent: 'flex-start',
				}),
			})}
			onMouseEnter={onMenuHover}
			onMouseLeave={onMenuClose}
		>
			<div
				className={css({
					paddingLeft: '1rem',
					paddingRight: '1rem',
					paddingTop: '0.875rem',
					paddingBottom: '0.875rem',
					display: 'inline-block',
					borderTopLeftRadius: '0.5rem',
					borderTopRightRadius: '0.5rem',
					borderBottomLeftRadius: '0.5rem',
					borderBottomRightRadius: '0.5rem',
					textAlign: 'left',
					wordBreak: 'break-word',
					backgroundColor,
					position: 'relative',
				})}
				id={messageId}
			>
				<div
					className={css({
						paddingTop: '0.5rem',
						paddingRight: '1rem',
						paddingBottom: '0.5rem',
						paddingLeft: '1rem',
						borderLeftWidth: '0.1875rem',
						borderLeftStyle: 'solid',
						borderLeftColor: theme.colors.borderSelected,
						borderTopLeftRadius: '0.5rem',
						borderTopRightRadius: '0.5rem',
						borderBottomLeftRadius: '0.5rem',
						borderBottomRightRadius: '0.5rem',
						backgroundColor: quotedMessageBg,
						marginBottom: '0.5rem',
						cursor: 'pointer',
						color: theme.colors.primaryA,
					})}
					onClick={scrollToTaggedMessage}
				>
					{renderQuotedMessageBasedOnType(messageData)}
				</div>
				{(messagetype === MESSAGE_TYPE_MAP.COMMENT || messagetype === MESSAGE_TYPE_MAP.PRIVATE_REPLY) && (
					<ENGTInfoBadge
						className={{
							alignSelf: 'center',
							justifySelf: 'flex-end',
							backgroundColor:
								messagetype === MESSAGE_TYPE_MAP.PRIVATE_REPLY
									? theme.instagramReplyMode.privateReplyColor
									: theme.instagramReplyMode.userCommentReply,
						}}
						size='mini'
					>
						{IG_REPLY_TYPE[messagetype]}
					</ENGTInfoBadge>
				)}
				<div
					className={css({
						cursor: menuItems.length ? 'pointer' : '',
					})}
				>
					{props.children ? (
						locationMessage || textMessage
					) : (
						<span
							className={css({ 'white-space': 'pre-wrap' })}
							dangerouslySetInnerHTML={{
								__html: decodeAndSanitizeInput(messageHTML),
							}}
						/>
					)}
				</div>
				{marked_by && (
					<div
						className={css({
							position: 'absolute',
							top: '0rem',
							right: '-0.75rem',
							borderTopLeftRadius: '100%',
							borderTopRightRadius: '100%',
							borderBottomLeftRadius: '100%',
							borderBottomRightRadius: '100%',
							height: '1.5rem',
							width: '1.5rem',
							backgroundColor: trainIconBgColor,
						})}
					>
						<Train size={24} fillColor={trainIconFill} />
					</div>
				)}
				{isValidLocationMessage && (
					<ToggleLocationLink showLink={showLinkForLocation} setShowLink={setShowLinkForLocation} />
				)}
			</div>
			<MessageActionsPopup messageId={messageId} {...props} />
		</span>
	);
}

export default React.memo(QuotedMessage);
